import { createContext, useContext } from "react";
import AccordionStore from "./accordionStore";
import CodesStore from "./codesStore";
import CommonStore from "./commonStore";
import ContactInfoStore from "./contactInfoStore";
import FormStore from "./formStore";
import HomePageStore from "./homePageStore";
import IsotopeGridStore from "./isotopeGridStore";
import LearningStore from "./LearningStore";
import MediaStore from "./mediaStore";
import ModalStore from "./modalStore";
import NavigationStore from "./navigationStore";
import PostCategoryStore from "./postCategoryStore";
import PostStore from "./postStore";
import SinglePostStore from "./singlePostStore";
import SliderStore from "./sliderStore";
import StatisticStore from "./statisticStore";
import UserStore from "./userStore";


interface Store {
  commonStore : CommonStore;
  userStore : UserStore;
  codesStore : CodesStore;
  modalStore: ModalStore;
  learningStore : LearningStore;
  singlePostStore: SinglePostStore;
  postStore: PostStore;
  postCategoryStore: PostCategoryStore;
  isotopeStore: IsotopeGridStore;
  mediaStore: MediaStore;
  homePageStore: HomePageStore;
  statisticStore:StatisticStore;
  navigationStore: NavigationStore;
  sliderStore: SliderStore;
  accordionStore: AccordionStore;
  formStore: FormStore;
  contactInfoStore : ContactInfoStore;
}


export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  codesStore: new CodesStore(),
  learningStore : new LearningStore(),
  postStore: new PostStore(),
  singlePostStore: new SinglePostStore(),
  postCategoryStore: new PostCategoryStore(),
  isotopeStore: new IsotopeGridStore(),
  mediaStore: new MediaStore(),
  homePageStore: new HomePageStore(),
  statisticStore: new StatisticStore(),
  navigationStore: new NavigationStore(),
  sliderStore: new SliderStore(),
  accordionStore: new AccordionStore(),
  formStore: new FormStore(),
  contactInfoStore : new ContactInfoStore()

}


export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
