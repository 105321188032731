import React from "react";
import { useTranslation } from "react-i18next";
import { MdLocationOn } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { ContactMessage } from "./ContactMessage";
import "./ContactMap.css";

export const ContactMap = () => {
  const { t } = useTranslation();
  return (
    <div className="contact_container">
      <div className="contact-left">
        <div className="contact_text">
          <div className="wrapper_contact">
            <h3>{t("contact")}</h3>
          </div>
          <p>
            <span>
              <MdLocationOn
                color="rgb(218, 20, 43)"
                size={15}
                className="location_icon"
              />
              {t("partizanski-odredi-48")} <br /> {t("skopje-north-macedonia")}
            </span>
          </p>
          <p>
            <BsFillTelephoneFill color="rgb(218, 20, 43)" size={12} />
            <span>02/3 224 504</span>
          </p>
          <p>
            <GrMail color="rgb(218, 20, 43)" size={17} />
            <span>E-mail: info@dit.gov.mk</span>
          </p>
        </div>
        <ContactMessage />
      </div>
      <iframe
        className="google-maps-style"
        allowFullScreen
        loading="lazy"
        width={600}
        height={586}
        frameBorder="0"
        tabIndex={0}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.971155879729!2d21.412158115340958!3d42.0008945792127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1354146dd389705b%3A0x8d18b2299a760895!2z0JTRgNC20LDQstC10L0g0LjQvdGB0L_QtdC60YLQvtGA0LDRgiDQt9CwINGC0YDRg9C0!5e0!3m2!1sen!2smk!4v1665561589051!5m2!1sen!2smk"
      ></iframe>
    </div>
  );
};

export default ContactMap;
