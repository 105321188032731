import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Segment, Tab } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../../app/stores/store";
import IsotopePane from "./shared/IsotopePane";

export default observer(function IstopeList() {
  const {
    isotopeStore: { loadIsotopeGridItems, sortedIsotopeGridItems },
  } = useStore();
  React.useEffect(() => {
    loadIsotopeGridItems();
  }, [loadIsotopeGridItems]);
  const defaultActiveIndex = parseInt(
    window.sessionStorage.getItem("isotope-tab-index") || "0"
  );
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const { t } = useTranslation();
  const panes = [
    IsotopePane(sortedIsotopeGridItems, "MK"),
    IsotopePane(sortedIsotopeGridItems, "EN"),
    IsotopePane(sortedIsotopeGridItems, "SQ"),
  ];

  return (
    <>
      <AdminHeader header={t("isotope-grid-item-list")} icon="wizard" />
      <Segment>
        <Tab
          panes={panes}
          onTabChange={(e, data) => {
            window.sessionStorage.setItem(
              "isotope-tab-index",
              data.activeIndex!.toString()
            );
            setActiveIndex(parseInt(data.activeIndex!.toString()));
          }}
          activeIndex={activeIndex}
        />
      </Segment>
    </>
  );
});
