import React from "react";
import { useField } from "formik";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Form, Label } from "semantic-ui-react";
import moment from "moment-timezone";
import "./InputStyle.css";

export default function MyDateInput(props: Partial<ReactDatePickerProps>) {
  const [field, meta, helpers] = useField(props.name!);
  const customTimeZone = moment.tz.setDefault("Europe/Skopje");
  const d = new Date();
  const diff = d.getTimezoneOffset();

  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <ReactDatePicker
        {...field}
        {...props}
        selected={field.value ? new Date(field.value) : null}
        onChange={(value) => helpers.setValue(value)}
        className="input-forms"
      />
      {meta.touched && !!meta.error ? (
        <Label basic color="red" style={{ marginTop: "5px" }}>
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}