import { useField } from "formik";
import { stateToHTML } from 'draft-js-export-html';
import React, { useState } from "react";
import { Form, Header, Icon, Message, Popup } from "semantic-ui-react";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import AddDocumentButton from "./wysiwygComponents/AddDocumentButton";
import { optionsExport } from "./wysiwygComponents/DraftJSOptions";
import { editorInitState } from "./wysiwygComponents/DraftJSFunctions";
import AddShortcodeButton from "./wysiwygComponents/AddShortcodeButton";
import AddShortcodeIframe from "./wysiwygComponents/AddShortcodeIframe";

interface Props {
  placeholder: string;
  name: string;
  rows?: number;
  label?: string;
  info?: string;
  hideCustomButtons?: boolean;
  onChange: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}


export default function MyEditorInput(props: Props) {
  const [field, meta] = useField(props.name);
  const [editorState, setEditorState] = useState(editorInitState.bind({}, field)());

  const onEditorChange = (state: EditorState) => {
    setEditorState(state);
    const blocks = state.getCurrentContent();
    const html = stateToHTML(blocks, optionsExport);
    // const decodedChildrenHtml = handleEditorCardChildren(html);
    props.onChange(field.name, html);
  }

  const customButtons = !props.hideCustomButtons ? [<AddDocumentButton />, <AddShortcodeButton />, <AddShortcodeIframe />] : [];

  return (
    <> <Header attached="top" color="blue" as="h3">{props.label}</Header>
      <Form.Field error={meta.touched && !!meta.error}>
        {props.label && <>
          {props.info && <Popup
           trigger={<Icon name="info circle" />}
           content={props.info} 
           position="right center"
          />}
        </>}
        <Editor
          editorState={editorState}
          toolbarCustomButtons={customButtons}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorChange}
        />
        {meta.touched && !!meta.error ? (
          <Message content={meta.error} />
        ) : null}
      </Form.Field>
    </>
  )

}

