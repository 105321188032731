import React from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

export const ButtonsReportsFooter = () => {
      const navigate = useNavigate();
  return (
    <>
    <Grid>
      <Grid.Row>
        <Grid.Column style={{ margin: "40px 0", padding: "0" }}>
          <Button
            style={{
              background: "rgb(161, 7, 5)",
              color: "white",
            }}
            onClick={() => navigate("/obrasci")}
          >
            <Icon name="arrow left" />
          </Button>
          <Button
            type="submit"
            floated="right"
            style={{
              marginBottom: "15px",
              background: "rgb(161, 7, 5)",
              color: "white",
            }}
          >
            Зачувај
          </Button>
        </Grid.Column>
      </Grid.Row>
      </Grid>
    </>
  );
};
