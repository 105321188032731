import _, { StringChain } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Accordion, AccordionItem } from "../models/CMS/Plugin/accordionItem";
// TODO
export default class AccordionStore {
  accordionRegister = new Map<string, Accordion>();
  // accordionItemsRegister = new Map<string, AccordionItem>();

  target: string | null = null;


  setTarget = (target: string | null) => {
    this.target = target;
  }

  parentDropdownt: Accordion[] | null = null;
  selectedAccordionItem: AccordionItem | null = null;

  clearAccordionItem = () => {
    this.selectedAccordionItem = null;
  }

  selectedAccordion: Accordion | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get accordionItems() {
    return Array.from(this.accordionRegister.values());
  }

  loadSingleAccordion = async (id: string) => {
    this.setLoading(true);
    try {
      const result = await agent.Accordions.details(id);
      runInAction(() => {
        this.accordionRegister.set(result.id, result);
        this.selectedAccordion = result;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadAccordion = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Accordions.list();
      runInAction(() => {
        this.accordionRegister.clear();
        result.forEach(x => {
          this.accordionRegister.set(x.id, x)
        });
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  createAccordion = async (accordion: Accordion) => {
    this.setLoading(true)
    try {
      await agent.Accordions.create(accordion);
      runInAction(() => {
        this.accordionRegister.set(accordion.id, accordion);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  updateAccordion = async (accordion: Accordion) => {
    this.setLoading(true)
    try {
      await agent.Accordions.update(accordion);
      runInAction(() => {
        this.accordionRegister.set(accordion.id, accordion);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }


  
  createAccordionItem = async (accordionItems: AccordionItem) => {
    this.setLoading(true)
    try {
      await agent.Accordions.createItem(accordionItems);
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadAccordionItem = async (id: string) => {
    this.selectedAccordionItem = await agent.Accordions.itemDetails(id);
  }


  deleteAccordion = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Accordions.delete(id);
      runInAction(() => {
        this.accordionRegister.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Isotope');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  deleteAccordionItem = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Accordions.deleteItem(id);
      runInAction(() => {
       const newDaddy =  _.cloneDeep(this.selectedAccordion);
       if(newDaddy){
         newDaddy.accordionItems = newDaddy.accordionItems?.filter(x=> x.id !== id);
         this.selectedAccordion = newDaddy;
       }
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting accordion item');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }


  updateAccordionItem = async (item: AccordionItem) => {
    this.setLoading(true)
    try {
      await agent.Accordions.updateItem(item);
      runInAction(() => {
        toast.info('Successfully updated!')
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  


  loading = false;
  loadingInitial = false;

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }



}