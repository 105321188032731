import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { SliderSlide } from "../../../../app/models/CMS/Homepage/sliderSlide";
import { useStore } from "../../../../app/stores/store";
import SliderForm from "./shared/SliderForm";

export default observer(function SliderEdit() {
  const { id } = useParams();
  const {
    sliderStore: {
      loadSingle,
      selectedSlide,
      clearSelectedSlide,
      loadingInitial,
      updateSlide,
    },
    mediaStore: { mediaList, loadingInitial : loadingMedia },
  } = useStore();
  const { t } = useTranslation();

  const navigate = useNavigate();
  React.useEffect(() => {
    if (id) {
      loadSingle(id);
    } else {
      navigate("/admin/homepage/slider/list");
    }

    return () => clearSelectedSlide();
  }, [id, loadSingle, navigate, clearSelectedSlide]);

  if (loadingInitial || !selectedSlide ) {
    return <LoadingComponent />;
  }

  const onSubmit = (values: SliderSlide) => {
    updateSlide(values)
    .then(()=> {
      toast.info(t('successfully-saved'))
    })
  };

  
  return (
    <>
      <AdminHeader header={t("edit-slider")} icon="film" />
      <SliderForm
        onSubmit={onSubmit}
        initialValues={selectedSlide}
        mediaList={mediaList}
      />
    </>
  );
});
