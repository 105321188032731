import { Markup } from 'interweave';
import React from 'react'
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import AccordionElement from '../components/AccordionElement/AccordionElement';

interface Props {
    slideContents?: eSlideContent[];
    colorSchemeArray: string[];
}

export default function AccordionParagraph2({ slideContents, colorSchemeArray }: Props) {

    const headerSticky = slideContents?.find(x => x.type.typeName === 'Header-sticky')?.body;
    const paragraph = slideContents?.find(x => x.type.typeName === 'Paragraph')?.body;
    const accordionItems = sortedContentArray(slideContents, 'Accordion-Item');
    const backgroundColor = colorSchemeArray[0];

    return (
        <>
            <div className="slide-img-par-1">
                <div className='header-sticky' style={{backgroundColor}}>
                    {headerSticky}
                </div>
                <div style={{ marginTop: '50px', marginBottom: '50px' }}>
                    {accordionItems.map(x => (<AccordionElement backgroundColor={colorSchemeArray[ 0 ] } header={`${x.header}`}
                    content={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ")} />))}
                </div>
                <div style={{ marginTop: '30px', marginLeft: '60px', marginBottom: '30px', }}>
                    <Markup content={paragraph} />
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}