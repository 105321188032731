import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../AdminHeader";
import { useStore } from "../../../../stores/store";
import { Button, Icon, Segment } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { v4 } from "uuid";
import MyTextInput from "../../../../../features/forms/inputs/MyTextInput";
import { ContactsInfoLocation } from "../../../../models/Plugins/pluginTypes";

export default observer(function ContactLocationCreate() {
  const {
    contactInfoStore: { createLocation },
  } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = (values: ContactsInfoLocation) => {
    createLocation(values).then(() => {
      navigate("/admin/plugins/contacts/list");
    })
  };

  return (
    <>
      <AdminHeader
        header={t("create-contact-location")}
        icon="list alternate outline"
      />
      <Segment>
        <Formik
          initialValues={{ id: v4(), name: "" }}
          enableReinitialize
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ submitForm }) => (
            <Form autoComplete="off">
              <>
                <Segment>
                  <div style={{ marginBottom: '20px' }}>
                    <MyTextInput name="name" label={t("contact-location-name")} />
                  </div>
                  <Button
                    color="red"
                    onClick={() => {
                      navigate("/admin/plugins/contacts/list");
                    }}
                  >
                    <Icon name="arrow left" />
                  </Button>
                  <Button
                    color="teal"
                    type="submit"
                  >
                    {t("save")}
                  </Button>
                </Segment>
              </>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
