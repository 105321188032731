import React from 'react'
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import { useStore } from '../../../app/stores/store';
import ElearningModal from '../components/ElearningModal';
import ModalPencilCase from '../components/ModalPencilCase';
import { PencilCaseItem } from '../components/PencilCase/PencilCaseList';
import SinglePencil from '../components/PencilCase/SinglePencil';
import "./../components/PencilCase/PencilCase.css";

interface Props {
    slideContents?: eSlideContent[];
    colorSchemeArray: string[];
}

export default function PencilCaseParagraph1({ slideContents, colorSchemeArray }: Props) {

    const headerSticky = slideContents?.find(x => x.type.typeName === 'Header-sticky')?.body;
    const pencilCase = sortedContentArray(slideContents, 'Pencilcase-Item');
    const { modalStore } = useStore();
    const { openModal } = modalStore;
    const backgroundColor = colorSchemeArray[0];

    const divStyle = pencilCase.length > 6 ?
    { height: '600px', paddingTop: '50px', paddingLeft: '220px' } :
    { height: '500px', paddingTop: '50px', paddingLeft: '220px' };

    return (
        <>
            <div className='slide-img-par-1'>
                <div className='header-sticky' style={{ backgroundColor }} >
                    {headerSticky}
                </div>
                <div style={divStyle}>
                    <div className="pencil-page">
                        {pencilCase.map((x, i) => (<>
                            <SinglePencil isRight={i % 2 === 0}
                                onClick={() => openModal(<ElearningModal color={backgroundColor} backgroundColor={backgroundColor}
                                    contentHeader={`${x.header}`} content={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll(" •", " ")} />)}
                                backgroundColor={colorSchemeArray[i % colorSchemeArray.length]}
                                content={x.header}
                            />
                            {i % 2 !== 0 && <br />}
                        </>))}
                    </div>
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}