import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Icon, Popup, Table } from "semantic-ui-react";
import ModalDialog from "../../app/common/modals/ModalDialog";
import AdminHeader from "../../app/layout/admin/AdminHeader";
import { useStore } from "../../app/stores/store";
import "./manageElearning.css";

export default observer(function ListTraining() {
  const { t } = useTranslation();
  const {
    learningStore: {
      sortedTrainings,
      loadTrainings,
      deleteTraining,
      target,
      trainingOrderIncrement,
      trainingOrderDecrement,
    },
    modalStore: { openModal, closeModal },
    commonStore: { moveUp, moveDown, movingUp, movingDown },
  } = useStore();

  React.useEffect(() => {
    loadTrainings();
  }, [loadTrainings]);

  return (
    <>
      <AdminHeader
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
        header={t("training")}
        subheader={t("list-of-trainings")}
      />
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("description")}</Table.HeaderCell>
            <Table.HeaderCell>{t("valid-months")}</Table.HeaderCell>
            <Table.HeaderCell>{t("order")}</Table.HeaderCell>
            <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedTrainings.map((x, i, arr) => {
            return (
              <Table.Row
                key={x.id}
                className={
                  movingUp === x.id
                    ? "move-up-order"
                    : movingDown === x.id
                    ? "move-down-order"
                    : ""
                }
              >
                <Table.Cell>{x.trainingName}</Table.Cell>
                <Table.Cell>{x.trainingDesc}</Table.Cell>
                <Table.Cell>{x.certValidMonths}</Table.Cell>
                <Table.Cell>
                  <ButtonGroup vertical>
                    {(x.trainingOrder > 0 || (arr.length === 2 && i === 1)) && (
                      <Button
                        disabled={!!movingUp || !!movingDown}
                        icon="arrow up"
                        color="green"
                        onClick={() => {
                          openModal(<ModalDialog
                          cancel={closeModal}
                          noText='Cancel'
                          confirm={()=> {moveUp(x.id, arr[i - 1].id, trainingOrderDecrement)
                          closeModal();
                          }}
                          title='Change order?'
                          description="Are you sure you want to change the order?"
                          />)
                        }}
                      />
                    )}
                    {arr.some((y) => y.trainingOrder > x.trainingOrder) && (
                      <Button
                        disabled={!!movingUp || !!movingDown}
                        icon="arrow down"
                        color="green"
                        onClick={() => {
                          openModal(<ModalDialog
                          cancel={closeModal}
                          noText='Cancel'
                          confirm={()=> {moveDown(x.id, arr[i + 1].id, trainingOrderIncrement)
                          closeModal();
                          }}
                          title='Change order?'
                          description="Are you sure you want to change the order?"
                          />)
                        }}
                      />
                    )}
                  </ButtonGroup>
                </Table.Cell>
                <Table.Cell>
                  <ButtonGroup>
                    <Button
                      as={Link}
                      to={`/admin/e-learning/edit/${x.id}`}
                      icon="edit"
                      inverted
                      color="green"
                    />
                    <Button
                      name={x.id}
                      loading={target === x.id}
                      onClick={(e) => {
                        openModal(
                          <ModalDialog
                            cancel={closeModal}
                            noText="Cancel"
                            confirm={() => {
                              deleteTraining(x.id);
                              closeModal();
                            }}
                            title={t(
                              "are-you-sure-you-want-to-delete-this-traning"
                            )}
                            description={t(
                              "once-you-delete-the-training-the-data-will-be-lost-forever"
                            )}
                          />
                        );
                      }}
                      icon="close"
                      inverted
                      color="red"
                    />
                    <Popup
                      trigger={
                        <Button
                          as={Link}
                          to={`/admin/e-learning/listModules/${x.id}`}
                          icon="arrow right"
                          color="blue"
                        />
                      }
                      content={t("towards-modules")}
                    />
                  </ButtonGroup>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <div style={{ textAlign: "right" }}>
        <Link to="/admin/e-learning/createTraning" className="ui button teal">
          <Icon name="plus" />
          {t("add-new-training")}
        </Link>
      </div>
    </>
  );
});
