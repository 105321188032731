import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CheckboxProps,
  Container,
  Input,
  Message,
  Radio,
  Segment,
} from "semantic-ui-react";
import PhotoUploadWidget from "../../../app/common/imageUpload/photoUploadWidget";
import ModalAlert from "../../../app/common/modals/ModalAlert";
import { useStore } from "../../../app/stores/store";

export default function AddNewMedia() {
  const { mediaStore, modalStore } = useStore();
  const { uploadPhotoMedia } = mediaStore;
  const { openModalBasic, closeModal } = modalStore;
  const [photoName, setPhotoName] = useState("");
  const [validationFailed, setValidationFailed] = useState<string[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <Input
        value={photoName}
        error={validationFailed.indexOf("photoName") > -1}
        onChange={(e, data) => {
          setValidationFailed(
            validationFailed.filter((x) => x !== "photoName")
          );
          setPhotoName(data.value.toString());
        }}
        label={t("photo-name")}
      />
      <PhotoUploadWidget
        uploadPhoto={(data) => {
          if (!photoName) {
            setValidationFailed([...validationFailed, "photoName"]);
            openModalBasic(
              <ModalAlert
                cancel={closeModal}
                description={t("please-insert-photo-name")}
                title={t("photo-name?")}
                icon="question"
              />,
              "tiny"
            );
            return;
          }

          return uploadPhotoMedia(data, photoName).then((x) => {
            navigate("/admin/media/list");
          });
        }}
      />
    </Container>
  );
}
