import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Message } from "semantic-ui-react";
import AdminHeader from "../../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../../app/stores/store";
import CategoryPostTable from "../../components/CategoryPostTable";

const singularName = "Announcement";
const pluralName = "Announcements";
const linkPath = "/admin/announcements";
const postType = "announcement";
const postTaxonomyType = "announcement_categories";
const iconName = "pin";

export default observer(function AnnouncementCategoryList() {
  const {
    postCategoryStore: {
      loadPostCategories,
      deletePostCategory,
      sortedPostCategories,
      loadingInitial,
      loading,
      target,
    },
  } = useStore();
  const { t } = useTranslation();
  useEffect(() => {
    loadPostCategories(postTaxonomyType);
  }, [loadPostCategories]);

  if (loadingInitial) return <LoadingComponent />;

  return (
    <>
      <AdminHeader
        header={t("announcement-categories")}
        icon={iconName}
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      {(!sortedPostCategories || sortedPostCategories.length === 0) && (
        <Message content={t("no-categories-found-please-create")} info />
      )}
      {!!sortedPostCategories && sortedPostCategories.length > 0 && (
        <CategoryPostTable
          sortedPostCategories={sortedPostCategories}
          submitting={loading}
          target={target || " "}
          deletePostCategory={deletePostCategory}
          postType={postType}
        />
      )}
    </>
  );
});
