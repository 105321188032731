import _ from 'lodash';
import { Table, Button, Icon } from 'semantic-ui-react';

export interface IPaginatedReportMeta {
  key: string;
  type: 'colName' | 'hide' | 'lookup' | 'order' | 'imageReplace';
  value: string | boolean;
};

export interface IReportMeta {
  key: string;
  type: 'colName' | 'hide' | 'lookup';
  value: string | boolean;
};
export interface ICodeListContainer {
  [key:string] : any[]
}

export const  replaceLookupValues = (row: any, meta?: IReportMeta[] | IPaginatedReportMeta[], codeLists?: ICodeListContainer ) => {
  const lookupReplaceArray = meta?.filter(m=> m.type ==='lookup');

  if(!lookupReplaceArray || !codeLists) return row;

  const newRow = _.cloneDeep(row);
  lookupReplaceArray.forEach(lr => {
    const columnName = lr.key.toString();
    newRow[columnName + '_replaced'] = ''
    const recordValue = newRow[columnName];

    if(!recordValue) return newRow;

    const codeListName = lr.value.toString();

    const codeList = codeLists[codeListName];

    if(!codeList){
      console.error('Code list not found!');
      return row;
    }
    const codeListItem = codeList.find(cl=> cl.value === recordValue);
    const newValue = codeListItem ? codeListItem.text : "";
    newRow[columnName + '_replaced'] = newValue;
  });

  return newRow;
};



export const getColNames = (data : any[] , meta?:IReportMeta[] | IPaginatedReportMeta[]) => {
  const firstObj = _.first(data) || {};
  const cols : string[] = Object.keys(firstObj);
  if(meta){
    const colNameMetaArray = meta.filter(m=> m.type === 'colName');
    if(!_.isEmpty(colNameMetaArray)){
      return cols.map(c=> colNameMetaArray.find(cn => cn.key === c)?.value.toString() || c);
    }
  }
  return cols;
}

export const removedHiddenCols = (data: any[],meta?: IReportMeta[] | IPaginatedReportMeta[]) => data.map(d=>{
  if(meta){
    const removedMetaKeys = meta?.filter(m => m.type === 'hide').map(v => v.key);
    if(!_.isEmpty(removedMetaKeys)){
      return _.omit(d, removedMetaKeys);
    }
  }
  return d;
});


