import _ from "lodash";
import { ContactsInfo } from "../../../app/models/Plugins/pluginTypes";
import { Styles } from "../../../app/models/styles";
import ContactInfoCard from "./ContactInfoCard";
import ContactInfoGroup from "./ContactInfoGroup";

interface Props 
{
  contactInfoArray?: ContactsInfo[];
}

const styles: Styles = {
  outerWrapper: { },
}

export default function ContactInfoPageMarkup({contactInfoArray}: Props) {
  if(!contactInfoArray){
    return <></>
  }
  const grouped_data = _.groupBy(contactInfoArray, x=> x.orgUnit)
  const arrayOfKeys = _.keys(grouped_data);
  const groupedArray = _.toArray(grouped_data)

  return <div style={styles.outerWrapper}>{groupedArray.map((orgUnit,i)=> <ContactInfoGroup 
    naslov={arrayOfKeys[i]}
    orgUnit={orgUnit}
    key={i}
    /> )}</div>
}