import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Input, Segment } from "semantic-ui-react";

interface Props {
  value: string;
  closeModal: () => void;
}

async function copyToClipboard(textToCopy: string) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }

}

export default function InfoCopyModal({ value, closeModal }: Props) {
  const { t } = useTranslation();
  return (<Segment>
    <Input fluid value={value}
      action={{
        icon: 'copy',
        onClick: () => {
          copyToClipboard(value);
          toast.info(t('copied'));
          closeModal();
        }
      }}


    />
  </Segment>)
}
