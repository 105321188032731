import { makeAutoObservable, runInAction } from "mobx";
import { DropdownItemProps } from "semantic-ui-react";
import agent from "../api/agent";
import { ProcessedGrievance } from "../models/Statistics/ProcessedGrievance";
import { ProcessedRegion } from "../models/Statistics/ProcessedRegion";
import { ProcessedRegionType } from "../models/Statistics/ProcessedRegionType";
import { SubmittedRegion } from "../models/Statistics/SubmittedRegion";
import { SubmittedRegionType } from "../models/Statistics/SubmitterdRegionType";


export default class StatisticStore {

  constructor(){
    makeAutoObservable(this);
  }
  
  loading: boolean = false;
  setLoading = (loading: boolean) =>{
    this.loading = loading;
  }

  submiRegion: SubmittedRegion[] | null = null; 
  submiRegionType: SubmittedRegionType[] | null = null; 
  processedRegion: ProcessedRegion[] | null = null;
  processedRegiType: ProcessedRegionType[] | null = null;
  processedRegiGrievance: ProcessedGrievance[] | null = null;




  get submittetRegion () {
    return this.submiRegion?.map(x=> {
      var d : DropdownItemProps = {
        text: x.region,
        value : x.noOfBrojs
      }
      return d 
    })
  }

  get submittetRegionType () {
    return this.submiRegionType?.map(x=> {
      var d : DropdownItemProps = {
        text: x.region,
        value : x.noOfBrojs,
        type:x.type
      }
      return d 
    })
  }

  get processedRegionStat () {
    return this.processedRegion?.map(x=> {
      var d : DropdownItemProps = {
        text: x.region,
        value : x.noOfBrojs,
      }
      return d 
    })
  }

  get processedRegionGrievance () {
    return this.processedRegiGrievance?.map(x=> {
      var d : DropdownItemProps = {
        text: x.region,
        value : x.noOfBrojs,
      }
      return d 
    })
  }


     
  loadSubmittedRegion = async () => {
    this.setLoading(true);
    try {
      const list = await agent.Reports.submitetRregion();
      runInAction(()=>{
        this.submiRegion = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

  loadSubmittedRegionByType = async () => {
    this.setLoading(true);
    try {
      const list = await agent.Reports.submitetRregionType();
      runInAction(()=>{
        this.submiRegionType = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

  loadProcessedRegion = async () => {
    this.setLoading(true);
    try {
      const list = await agent.Reports.processedRegion();
      runInAction(()=>{
        this.processedRegion = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

  loadProcessedRegionType = async () => {
    this.setLoading(true);
    try {
      const list = await agent.Reports.processedRegionType();
      console.log(list);
      runInAction(()=>{
        this.processedRegiType = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }
  
  loadProcessedGrievance = async () => {
    this.setLoading(true);
    try {
      const list = await agent.Reports.processedGrievance();
      runInAction(()=>{
        this.processedRegiGrievance = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }





}