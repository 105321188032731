import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import agent from "../api/agent";
import { Nkd } from "../models/CodeLists/Nkd";
import { StatusForm } from "../models/CodeLists/StatusForm";
import { ViolatedRights } from "../models/CodeLists/ViolatedRights";
import EmailRegions from "../models/EmailRegions/EmailBZR";
import { BZR } from "../models/Regions/BZR";
import { City } from "../models/Regions/City";
import { RO } from "../models/Regions/RO";
import { t } from "i18next";

export type CodeLists = 'bzrRegions' | 'roRegions';

export default class CodesStore {

  
  
  constructor(){
    makeAutoObservable(this);
  }
  
  loading: boolean = false;
  setLoading = (loading: boolean) =>{
    this.loading = loading;
  }
  

  bzrRegions : BZR[] | null = null;
  roRegions : RO[] | null = null;

  nkd : Nkd[] | null = null; 

  formStatus : StatusForm[] | null = null; 

  bzrRegistry = new Map<number, EmailRegions>();
  roRegistry = new Map<number, EmailRegions>();

  get bzrEmails() {
    return Array.from(this.bzrRegistry.values())
  }
  get roEmails() {
    return Array.from(this.roRegistry.values())
  }

  cities : City[] | null = null; 
  violatedRightsRo : ViolatedRights[] | null = null; 
  violatedRightsBzr : ViolatedRights[] | null = null; 





  get vrBzrDropdown () {
    return this.violatedRightsBzr?.map(x=> {
      var d : DropdownItemProps = {
        text: x.code,
        description: x.description,
        value : x.code
      }
      return d 
    })
  }


  get vrRoDropdown () {
    return this.violatedRightsRo?.map(x=> {
      var d : DropdownItemProps = {
        text: x.code,
        description: x.description,
        value : x.code
      }
      return d 
    })
  }

  get bzrDropdown () {
    return this.bzrRegions?.map(x=> {
      var d : DropdownItemProps = {
        text: x.regionName,
        value : x.id
       
      }
      return d 
    })
  }

  get roDropdown () {
    return this.roRegions?.map(x=> {
      var d : DropdownItemProps = {
        text: x.regionName,
        value : x.id
      }
      return d 
    })
  }

  get citiesDropdown () {
    return this.cities?.map(x=> {
      var d : DropdownItemProps = {
        text: x.name,
        value : x.id
      }
      return d 
    })
  }


  get status () {
    return this.formStatus?.map(x=> {
      var d : DropdownItemProps = {
        text: t(x.statusName).toString(),
        value : x.id
      }
      return d 
    })
  }


  
  get nkdDropdown () {
    return this.nkd?.map(x=> {
      var d : any = {
        title: x.name,
        description: x.description,
        value : x.id,
      }
      return d 
    })
  }

  
  loadVrBzr = async () => {
    this.setLoading(true);
    try {
      const list = await agent.CodeLists.listVrBzr();
      runInAction(()=>{
        this.violatedRightsBzr = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

  loadVrRo = async () => {
    this.setLoading(true);
    try {
      const list = await agent.CodeLists.listVrRo();
      runInAction(()=>{
        this.violatedRightsRo = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }



  loadBzrRegions = async () => {
    this.setLoading(true);
    try {
      const list = await agent.CodeLists.listRegionsBZR();
      runInAction(()=>{
        this.bzrRegions = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

  loadRoRegions = async () => {
    this.setLoading(true);
    try {
      const list = await agent.CodeLists.listRegionsRO();
      runInAction(()=>{
        this.roRegions = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

  loadCities = async () => {
    this.setLoading(true);
    try {
      const list = await agent.CodeLists.listCities();
      runInAction(()=>{
        this.cities = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }


  
  loadNkds = async () => {
    this.setLoading(true);
    try {
      const list = await agent.CodeLists.listNkd();
      runInAction(()=>{
        this.nkd = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

   
  loadStatus = async () => {
    this.setLoading(true);
    try {
      const list = await agent.CodeLists.listStatus();
      runInAction(()=>{
        this.formStatus = list;
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }
  


  loadBZR = async () => {
    this.setLoading(true);
    try {
      const list = await agent.RregionEmail.BzrEmailList();
      runInAction(()=>{
        list.forEach(x=> {
          this.bzrRegistry.set(x.id, x);
        })
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }

  saveRegionEmail = async (idBzr:(number | null),idRo: (number | null), email:string) => {
    this.setLoading(true);
    try {
      await agent.RregionEmail.createRegionalEmail(idBzr,idRo,email);
      runInAction(()=> {
        if(idBzr){
          var obj = this.bzrRegistry.get(idBzr)
          if(obj){
            this.bzrRegistry.set(idBzr,{...obj, email});
          }
        }
        if(idRo){
          var obj = this.roRegistry.get(idRo)
          if(obj){
            this.roRegistry.set(idRo,{...obj, email});
          }
        }
      })
    } catch (error) {
      console.log(error);
      toast.error('Failed to update');
    }
  }


  
  loadRo = async () => {
    this.setLoading(true);
    try {
      const list = await agent.RregionEmail.RoEmailList();
      runInAction(()=>{
        list.forEach(x=> {
          this.roRegistry.set(x.id, x);
        })
      });
    } catch (ex) {
      console.log(ex);
    }finally{
      this.setLoading(false);
    }
  }
}