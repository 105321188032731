import { observer } from "mobx-react-lite";
import React from "react";
import { Header, Message, Radio, Segment, SegmentGroup } from "semantic-ui-react";
import { eQuiz } from "../../../app/models/ELearning/eSlide";
import { useStore } from "../../../app/stores/store";

interface Props {
  colorSchemeArray : string[];
  quiz?: eQuiz;
}

export default observer(function LearningQuiz({colorSchemeArray, quiz}:Props) {
  const {learningStore: { setAnswer, answers, unloadQuiz, loadQuiz}} = useStore();

  React.useEffect(()=> {
    loadQuiz();

    return () => unloadQuiz();
  },[unloadQuiz, loadQuiz])

  if(!quiz){
    return <Message>Quiz not found</Message>
  }

  return <div>
    {quiz.questions.slice().sort((a,b)=> a.questionOrder - b.questionOrder).map((q)=> {
      const modalitites =  q.modalities
      .slice()
      .sort((a,b)=> {
        return a.tempOrder - b.tempOrder})
      return (<Segment key={q.id}>
        <Header attached="top">{q.questionText}</Header>
        <SegmentGroup piled>
        {modalitites
        .map(m=> {
          return ( <Segment key={m.id} onClick={()=>{setAnswer(q.id,m.id)}} >
            <Radio
              label={m.modalityText}
              name={q.id}
              value={m.id}
              checked={!!answers.find(x=> x === m.id)}
            /></Segment>)
        })}
        </SegmentGroup>

      </Segment>)
    })}
   
  </div>
})