import axios, { AxiosError, AxiosResponse } from "axios";
// import { toast } from "react-toastify";
import { BZR } from "../models/Regions/BZR";
import { RO } from "../models/Regions/RO";
import { CurrentUser, LoginFormValues, User, UserFormValues } from "../models/user";
import { store } from "../stores/store";
import { City } from "../models/Regions/City";
import { ViolatedRights } from "../models/CodeLists/ViolatedRights";
import { eTraining } from "../models/ELearning/eTraining";
import { eModule } from "../models/ELearning/eModule";
import { eAnswer, eSlide, eSlideContentType, eSlideType } from "../models/ELearning/eSlide";
import { eProgress } from "../models/ELearning/eProgress";
import { eCertificate } from "../models/ELearning/eCertificate";
import { eSlideContent } from "../../app/models/ELearning/eSlide";
import { Post, PostValues } from "../models/CMS/post";
import { PostCategory } from "../models/CMS/postCategory";
import { IsotopeGridItem } from "../models/CMS/Homepage/isotope";
import { PaginatedResult } from "../models/Pagination/pagination";
import { CMSMedia } from "../models/Media/media";
import { toast } from "react-toastify";
import { Nkd } from "../models/CodeLists/Nkd";
import { KnownSubmitterHeader, KnownSubmitterInterface } from "../models/Forms/KnownSubmitterInterface";
import { UnknownSubmitterInterface } from "../models/Forms/UnknownSubmitterInterface";
import { AccidentAtWorkHeader, AccidentAtWorkInterface } from "../models/Forms/AccidentAtWorkInterface";
import { ConstructionWorksHeader, ConstructionWorksInterface } from "../models/Forms/ConstructionWorksInterface";
import { WorkOnSundayHeader, WorkOnSundayInterface } from "../models/Forms/WorkOnSundayInterface";
import { WorkOnHolidayHeader, WorkOnHolidayInterface } from "../models/Forms/WorkOnHolidayInterface";
import { StartingActivityHeader, StartingActivityInterface } from "../models/Forms/StartingActivityInterface";
import { OvertimeWorkInterface, OvertimeWorktHeader } from "../models/Forms/OvertimeWorkInterface";
import { NightShiftHeader, NightShiftInterface } from "../models/Forms/NightShiftInterface";
import { TaxonomyTerm } from "../models/CMS/taxonomyTerm";
import { StatusForm } from "../models/CodeLists/StatusForm";
import { SubmittedRegion } from "../models/Statistics/SubmittedRegion";
import { SubmittedRegionType } from "../models/Statistics/SubmitterdRegionType";
import { ProcessedRegion } from "../models/Statistics/ProcessedRegion";
import { ProcessedRegionType } from "../models/Statistics/ProcessedRegionType";
import { ProcessedGrievance } from "../models/Statistics/ProcessedGrievance";
import EmailRegions from "../models/EmailRegions/EmailBZR";
import { SliderSlide } from "../models/CMS/Homepage/sliderSlide";
import { NavigationItem } from "../models/CMS/Homepage/navigationItem";
import { Accordion, AccordionItem } from "../models/CMS/Plugin/accordionItem";
import { OperatorForm } from "../models/OperatorForm/OperatorForm";
import { FormStatus } from "../models/OperatorForm/FormStatus";
import { RegionInfo } from "../models/HomePage/RegionInfo";
import { ContactsInfoLocation, DirectorInfo, Shortcode } from "../models/Plugins/pluginTypes";

axios.defaults.baseURL =  process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token) config.headers!.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // await sleep(1500);
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    switch (error.response?.status) {
      case 400:
        if (typeof error.response?.data === "string") {
          toast.error(error.response?.data);
        } else if (error.response?.data) {
          // const modelStateErrors = [];
          // for (const key in error.response?.errors) {
          //   if (error.response?.data.errors[key]) {
          //     modelStateErrors.push(key + ":" + data.errors[key]);
          //   }
          // }
          // throw modelStateErrors.flat();
        }
        break;
      case 401:
        toast.error("Unauthorized");
        localStorage.removeItem("jwt");
        break;
      case 404:
        toast.error("not-found");
        break;
      case 500:
        toast.error("server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T,>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T,>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T,>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T,>(url: string, body: {}) =>
    axios.put<T>(url, body).then(responseBody),
  delete: <T,>(url: string) => axios.delete<T>(url).then(responseBody),
};

const CodeLists = {
  listRegionsBZR: () => requests.get<BZR[]>("/Regions/region_bzr"),
  listRegionsRO: () => requests.get<RO[]>("/Regions/region_ro"),
  listCities: () => requests.get<City[]>("/Regions/cities"),
  listVrRo: () => requests.get<ViolatedRights[]>("/Codes/vr_ro"),
  listVrBzr: () => requests.get<ViolatedRights[]>("/Codes/vr_bzr"),
  listNkd: () => requests.get<Nkd[]>("/Codes/nkd"),
  listStatus: () => requests.get<StatusForm[]>("/regions/statuses"),
};

const Forms = {
  list: (params: URLSearchParams) => axios.get<PaginatedResult<OperatorForm[]>>('/operator', { params })
  .then(responseBody),
  getStatuses : () => requests.get<FormStatus[]>('/operator/status'),
  knownSubmit: (knownSubmitter: KnownSubmitterInterface) => requests.post<void>('/forms/knownsubmitter', knownSubmitter),
  unknownSubmit: (unknownSubmitter: UnknownSubmitterInterface) => requests.post<void>('/forms/knownsubmitter', unknownSubmitter),
  accidentAtWork: (accident: AccidentAtWorkInterface) => requests.post<void>('/forms/accident', accident),
  construction: (construction: ConstructionWorksInterface) => requests.post<void>('/forms/construction', construction),
  workOnSunday: (workOnSunday: WorkOnSundayInterface) => requests.post<void>('/forms/workonsunday', workOnSunday),
  workOnHoliday: (workOnHoliday: WorkOnHolidayInterface) => requests.post<void>('/forms/workonholiday', workOnHoliday),
  startingActivity: (startingActivity: StartingActivityInterface) => requests.post<void>('/forms/startingactivity', startingActivity),
  overtimeWork: (overtimeWork: OvertimeWorkInterface) => requests.post<void>('/forms/overtimework', overtimeWork),
  nightShift: (nightShift: NightShiftInterface) => requests.post<void>('/forms/nightshift', nightShift),
  statusChange: (obj: {id: string, status: number, formType: string} ) => requests.put<void>(`/operator/status`,obj),
  update: (isotope: IsotopeGridItem) => requests.put<void>('/isotope', isotope),
  
  // update
  accidentDetails: (id: string) => requests.get<AccidentAtWorkHeader>(`/forms/accident/${id}`),
  updateAccident: (accident: AccidentAtWorkInterface) => requests.put<AccidentAtWorkInterface>(`/forms/accident/${accident.idHeader}`, accident),
  
  constructionDetails: (id: string) =>  requests.get<ConstructionWorksHeader>(`/forms/construction/${id}`),
  updateConstruction: (construction: ConstructionWorksInterface) => requests.put<ConstructionWorksInterface>(`/forms/construction/${construction.idHeader}`, construction),
 
  knownSubmitterDetails: (id: string) => requests.get<KnownSubmitterHeader>(`/forms/knownsubmitter/${id}`),
  updateKnownSubmitter: (knownSubmitter: KnownSubmitterInterface) => requests.put<KnownSubmitterInterface>(`/forms/knownsubmitter/${knownSubmitter.idHeader}`, knownSubmitter),

  nightShiftDetails: (id: string) => requests.get<NightShiftHeader>(`/forms/nightshift/${id}`),
  updateNightShift: (nightShift: NightShiftInterface) => requests.put<NightShiftInterface>(`/forms/nightshift/${nightShift.idHeader}`, nightShift),

  overtimeWorkDetails: (id: string) => requests.get<OvertimeWorktHeader>(`/forms/overtimework/${id}`),
  updateOvertimeWork : (overtimeWork: OvertimeWorkInterface) => requests.put<OvertimeWorkInterface>(`/forms/overtimework/${overtimeWork.idHeader}`, overtimeWork),

  startingActivityDetails: (id: string) => requests.get<StartingActivityHeader>(`/forms/startingactivity/${id}`),
  updateStartingActivity : (startingActivity: StartingActivityInterface) => requests.put<StartingActivityInterface>(`/forms/startingactivity/${startingActivity.idHeader}`, startingActivity),

  workOnHolidayDetails: (id: string) => requests.get<WorkOnHolidayHeader>(`/forms/workonholiday/${id}`),
  updateWorkOnHoliday : (workOnHoliday: WorkOnHolidayInterface) => requests.put<WorkOnHolidayInterface>(`/forms/workonholiday/${workOnHoliday.idHeader}`, workOnHoliday),

  workOnSundayDetails: (id: string) => requests.get<WorkOnSundayHeader>(`/forms/workonsunday/${id}`),
  updateWorkOnSunday : (workOnSunday: WorkOnSundayInterface) => requests.put<WorkOnSundayInterface>(`/forms/workonsunday/${workOnSunday.idHeader}`, workOnSunday),

}



const Account = {
  current: () => requests.get<CurrentUser>('/account'),
  login: (user: LoginFormValues) => requests.post<CurrentUser>('/account/login', user)
}

const Users = {
  create: (user: UserFormValues) => requests.post<User>('/users', user),
  details: (id: string) => requests.get<User>(`/users/${id}`),
  update: (user: UserFormValues) => requests.put<User>("/users", user),
  changePassword: (userId: string, password: string) => requests.put<void>("/users/changePassword", { userId, password }),
  list: () => requests.get<User[]>("/users"),
  delete: (id: string) => requests.delete<void>(`/users/${id}`)
}

const Learning = {
  createTrainig: (id: eTraining) => requests.post<eTraining>('/learning/training', id),
  createModule: (id: eModule) => requests.post<eTraining>('/learning/module', id),
  createSlide: (id: eSlide) => requests.post<eTraining>('/learning/slidecreate', id),
  createSlideContent: (id: eSlideContent) => requests.post<eSlideContent>('/learning/slidecontentcreate', id),
  viewSlideContent: (id: string) => requests.get<eSlideContent[]>(`/learning/slidecontents/${id}`),
  update: (id: eTraining) => requests.put<eTraining>("/learning/training", id),
  updateModule: (id: eModule) => requests.put<eModule>(`/learning/module/`, id),
  updateSlide: (id: eSlide) => requests.put<eSlide>(`/learning/slideedit/`, id),
  updateContent: (id:eSlideContent) => requests.put<eSlideContent>(`/learning/slidecontentedit`, id),


  delete: (id: string) => requests.delete<void>(`/learning/training/${id}`),
  deleteModule: (id: string) => requests.delete<void>(`/learning/module/${id}`),
  deleteSlide: (id: string) => requests.delete<void>(`/learning/slidedelete/${id}`),
  deleteContent: (id: string) => requests.delete<void>(`/learning/slidecontentdelete/${id}`),
  listTrainings: () => requests.get<eTraining[]>("/learning"),
  loadTraining: (id: string) => requests.get<eTraining>(`/learning/${id}`),
  listModules: (id: string) => requests.get<eModule[]>(`/learning/training/${id}`),
  // viewSlideContent: (id: string) => requests.get<eSlideContent[]>(`/learning/slidescontentingle/${id}`),
  getQuizResults: (answers: eAnswer) => requests.post<boolean>('learning/quiz', answers),
  getModule: (id: string) => requests.get<eModule>(`/learning/module/${id}`),
  getSlide: (id: string) => requests.get<eSlide>(`/learning/slide/${id}`),
  getContent: (id: string) => requests.get<eSlideContent>(`/learning/slidescontentingle/${id}`),
  getSlideSingle: (id: string) => requests.get<eSlide>(`/learning/slidesingle/${id}`),


  nextSlide: (id: string) => requests.get<eSlide>(`/learning/nextslide/${id}`),
  listSlides: (id: string) => requests.get<eSlide[]>(`/learning/slides/${id}`),
  listSlideType: () => requests.get<eSlideType[]>('learning/slidetype'),
  listContentType: () => requests.get<eSlideContentType[]>('learning/slidecontenttype'),
  // listAllSlides: (id: string) => requests.get<eSlide[]>(`/learning/slideslistbymodule/${id}`),
  loadUserProgress: () => requests.get<eProgress>(`learning/progress/`),
  getUserCertificates: () => requests.get<eCertificate[]>(`/learning/certificates/`),
  downloadCertificate: (id: string) => axios.get(`/learning/certificatesfile/${id}`, { responseType: "blob" })
    .then(function (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'certificate.jpeg'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
}

const Posts = {
  list: (params: URLSearchParams) => axios.get<PaginatedResult<Post[]>>('/posts', { params })
    .then(responseBody),
  // list : () => requests.get<Post[]>("/posts"),
  details: (id: string) => requests.get<Post>(`/posts/${id}`),
  detailsBySlug: (postType: string, slug:string) => requests.get<Post>(`/posts/slug/${postType}/${slug}`),
  create: (post: PostValues) => requests.post<Post>('/posts', post),
  update: (post: PostValues) => requests.put<Post>('/posts', post),
  statusChange: (obj: {id: string, status: string} ) => requests.post<Post>(`/posts/status`,obj),
  deletePost: (postId: string) => requests.get<Post>(`/posts/delete/${postId}`),
  delete: (id: string) => requests.delete<void>(`/posts/${id}`),
  frontPageNews: () => requests.get<Post[]>('/frontpage/news')
}

const PostCategories = {
  create: (category: TaxonomyTerm) => requests.post<void>('postcategories', category),
  update: (category: TaxonomyTerm) => requests.put<void>('postcategories', category),
  delete: (id: string) => requests.delete<void>(`postcategories/${id}`),
  details: (id: string) => requests.get<TaxonomyTerm>(`postcategories/single/${id}`),
  detailsBySlug: (taxSlug: string, termSlug: string) => requests.get<TaxonomyTerm>(`postcategories/slug/${taxSlug}/${termSlug}`),
  list: (slug: string) => requests.get<TaxonomyTerm[]>(`postcategories/type/${slug}`)
}

const Isotope = {
  list: () => requests.get<IsotopeGridItem[]>("/isotope"),
  details: (id: string) => requests.get<IsotopeGridItem>(`/isotope/${id}`),
  create: (isotope: IsotopeGridItem) => requests.post<void>('/isotope', isotope),
  update: (isotope: IsotopeGridItem) => requests.put<void>('/isotope', isotope),
  delete: (id: string) => requests.delete<void>(`isotope/${id}`)

}

const Slider = {
  list: () => requests.get<SliderSlide[]>("/slider"),
  details: (id: string) => requests.get<SliderSlide>(`/slider/single/${id}`),
  create: (sliderSlide: SliderSlide) => requests.post<SliderSlide>('/slider', sliderSlide),
  update: (sliderSlide: SliderSlide) => requests.put<void>('/slider', sliderSlide),
  delete: (id: string) => requests.delete<void>(`slider/${id}`)
}

const Accordions = {
  list: () => requests.get<Accordion[]>("/acordion"),
  details: (id: string) => requests.get<Accordion>(`/acordion/single/${id}`),
  create: (accordion: Accordion) => requests.post<void>('/acordion', accordion),
  createItem: (accordionItem: AccordionItem) => requests.post<void>('/acordion/item', accordionItem),
  update: (accordion: Accordion) => requests.put<void>('/acordion', accordion),
  delete: (id: string) => requests.delete<void>(`/acordion/single/${id}`),
  itemDetails: (id: string) => requests.get<AccordionItem>(`/acordion/item/${id}`),
  deleteItem: (id: string) => requests.delete<void>(`/acordion/item/${id}`),
  updateItem: (accordion: AccordionItem) => requests.put<void>('/acordion/item', accordion),
}


const RregionEmail = {
  createRegionalEmail: (regionBzrId:(number | null) ,regionRoId:(number | null), email:string) => requests.post<void>('regions/mailregion', {regionBzrId,regionRoId, email}),
  BzrEmailList: () => requests.get<EmailRegions[]>("regions/bzrlist"),
  RoEmailList: () => requests.get<EmailRegions[]>("regions/rolist"),
}

const Navigation = {
  list: () => requests.get<NavigationItem[]>("/navigation"),
  listPublic: () => requests.get<NavigationItem[]>("/navigation/public"),
  details: (id: string) => requests.get<NavigationItem>(`/navigation/single/${id}`),
  create: (nav: NavigationItem) => requests.post<void>('/navigation', nav),
  update: (nav: NavigationItem) => requests.put<void>('/navigation', nav),
  delete: (id: string) => requests.delete<void>(`/navigation/${id}`)
}



const Files = {
  list: (params: URLSearchParams) => axios.get<PaginatedResult<CMSMedia[]>>('/files', { params })
    .then(responseBody),
  uploadPhoto: (formData: FormData) => {
    return axios.post<void>('files/photo', formData,
      {
        headers: { 'Content-type': 'multipart/form-data' }
      })
  },
  uploadDocument : (formData: FormData) => {
    return axios.post<void>('files/document', formData, 
    {
      headers:{'Content-type': 'multipart/form-data'}
    })
  },
  delete: (id: string) => requests.delete<void>(`/files/${id}`),
  createCategory: (category: PostCategory) => requests.post<void>('posts/category', category),
  listCategory: () => requests.get<PostCategory[]>("/posts/category")
}

const Reports = {
  downloadCsv: (reportName:string,params: URLSearchParams) => axios.get(`/reports/${reportName}`, { params })
    .then(function (response) {
      let fileName = `${reportName}_report.csv`;
      var type = 'text/csv';
      const url = window.URL.createObjectURL(new Blob([
        new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
        "Text",
        ...response.data], { type }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',fileName);
      document.body.appendChild(link);
      link.click();
    }),
  
  submitetRregion: () => requests.get<SubmittedRegion[]>('/reports/submitted_region'),  
  submitetRregionType: () => requests.get<SubmittedRegionType[]>('/reports/submitted_region_type'),  
  processedRegion: () => requests.get<ProcessedRegion[]>('/reports/processed_region'), 
  processedRegionType: () => requests.get<ProcessedRegionType[]>('/reports/processed_region_type'),  
  processedGrievance: () => requests.get<ProcessedGrievance[]>('/reports/processed_grievance'),
  finishedTrainings: (id: string) => requests.get<any[]>(`reports/finishedTrainings/${id}`)
}

const FrontPage = {
  regionInfo: () => requests.get<RegionInfo>('/frontpage/regionNumbers')
}

const Plugin = {
  getDirectorInfo: () => requests.get<DirectorInfo>('/plugin/director'),
  updateDirectorInfo: (info: DirectorInfo) => requests.put<void>(`/plugin/director`, info),


  getShortcode: (params: URLSearchParams) => axios.get<Shortcode>(`plugin/shortcode`,{params})
  .then((req)=> req.data)

}

const ContactInfos = {
  list: () => requests.get<ContactsInfoLocation[]>('/plugin/contacts'),
  details: (id: string) => requests.get<ContactsInfoLocation>(`/plugin/contacts/${id}`),
  update: (info: ContactsInfoLocation) => requests.put<void>(`/plugin/contacts`, info),
  create: (info: ContactsInfoLocation) => requests.post<void>(`/plugin/contacts`, info),
  delete: (id: string) => requests.delete<void>(`/plugin/contacts/${id}`)
}



const agent = {
  CodeLists,
  Account,
  Users,
  Learning,
  Posts,
  Isotope,
  Files,
  Forms,
  PostCategories,
  Reports,
  RregionEmail,
  Slider,
  Navigation,
  Accordions,
  ContactInfos,
  FrontPage,
  Plugin

};

export default agent;
