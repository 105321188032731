import _ from "lodash";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  Segment,
  Pagination,
  Icon,
  Select,
  DropdownItemProps,
} from "semantic-ui-react";

interface IProps {
  handlePaginationChange: (pageNumber: string | number | undefined) => void;
  handlePerPageChange: (pageSize: string | undefined) => void;
  numPages: number;
  PerPageOptions?: DropdownItemProps[];
  perPage: number;
  defaultPage?: number;
  activePage?: number;
}

const AdminTablePagination: React.FC<IProps> = ({
  defaultPage = 1,
  activePage,
  handlePaginationChange,
  handlePerPageChange,
  numPages,
  PerPageOptions,
  perPage,
}) => {
  const { t } = useTranslation();
  return (
    <Segment>
      {PerPageOptions && (
        <>
          <label>{t("per-page")} </label>
          <Select
            options={PerPageOptions}
            value={perPage}
            onChange={(e, data) =>
              handlePerPageChange(
                !_.isArray(data.value) ? data.value?.toString() : undefined
              )
            }
          />
        </>
      )}
      <div style={{ float: "right" }}>
        <Pagination
          activePage={activePage || defaultPage}
          onPageChange={(e, data) => handlePaginationChange(data.activePage)}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={numPages}
        />
      </div>
      <p style={{ clear: "both" }} />
    </Segment>
  );
};

export default AdminTablePagination;
