import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import FlipCard from '../components/FlipCard/FlipCard';

interface Props {
    slideContents?: eSlideContent[];
    colorSchemeArray: string[];
}

export default function FlipCardSlide2({ slideContents, colorSchemeArray }: Props) {

    const header = slideContents?.find(x => x.type.typeName === 'Header')?.body;
    const image = slideContents?.find(x => x.type.typeName === 'Image')?.body;
    const contentHeader = slideContents?.find(x => x.type.typeName === 'Content-Header')?.body;
    const flipCards = sortedContentArray(slideContents, 'Flip-Card');
    const paragraph = sortedContentArray(slideContents, 'Paragraph')
    const imgSrc = image || "unkown.jpg"
    const backgroundColor = colorSchemeArray[0];

    return (
        <>
            <div className="slide-img-par-1">
                <h1 className="slide-header">{header}</h1>
                <div className="slide-header-line" style={{ backgroundColor }}> </div>
                <div style={{ width: '500px' }}>
                    <LazyLoadImage alt={'featured-image'}
                        className='image-style-slide'
                        src={`/images/elearning/components/${imgSrc}.jpg`}
                        width={'100%'}
                        effect="blur"
                        placeholderSrc={`/images/elearning/thumbnail/${imgSrc}.jpg`}
                    />
                </div>
                <div style={{ marginTop: '30px', marginLeft: '60px' }}>
                    {contentHeader}
                    {paragraph?.map(x => x.body.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll('•', ' '))}
                </div>
                <div style={{ marginLeft: '', paddingTop: '180px', display: 'flex', gap: '60px', height: '400px', paddingRight: '430px' }}>
                    <div style={{ width: '100%' }}>
                        {flipCards.map((x, i) => (<FlipCard backgroundColor={colorSchemeArray[0]} offsetRight={i * 300} textColor='' text={`${x.header}`}
                            textBack={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll(" •", " ")} />))}
                    </div>
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}