import { Formik, FormikHelpers } from "formik";
import {
  Form,
  Grid,
  Header,
  Segment,
  DropdownItemProps,
} from "semantic-ui-react";
import { ConstructionWorksInterface } from "../../../../app/models/Forms/ConstructionWorksInterface";
import MyDateInput from "../../inputs/MyDateInput";
import MyRadio from "../../inputs/MyRadio";
import MyRadioGroup from "../../inputs/MyRadioGroup";
import MyTextArea from "../../inputs/MyTextArea";
import MyTextInput from "../../inputs/MyTextInput";
import { ConstructionWorksSchema } from "../../schemas/ConstructionWorksSchema";
import FormsLogo from "../FormsLogo";
import MiddleHeader from "./MiddleHeader";
import "./ConstructionForm.css";
import { ButtonsReportsFooter } from "./ButtonsReportsFooter";

const initValues: ConstructionWorksInterface = {
  cityId: null,
  employerName: "",
  employerAddress: "",
  streetNumber: "",
  employerPlace: "",
  employerMunicipality: "",
  employerPostalCode: "",
  tradeNumber: "",
  date: null,
  place: "",
  taxNumber: "",
  bankAccount: "",
  bankName: "",
  fullname: "",
  embg: "",
  address: "",
  phoneNumber: "",
  email: "",
  workActivity: "",
  startingDate: null,
  safetyAtWork: "",
  name: "",
  employeesNumber: null,
  workEquipment: "",
  submitterFullname: "",
  submitterWorkPosition: "",
  submitterPhoneNumber: "",
  submitterEmail: "",
};

interface Props {
  handleSubmit: (
    values: ConstructionWorksInterface,
    actions: FormikHelpers<ConstructionWorksInterface>
  ) => Promise<void>;
  citiesDropdown: DropdownItemProps[] | undefined;
  initialValues?: ConstructionWorksInterface | null;
}

export default function ConstructionForm({
  handleSubmit,
  citiesDropdown,
  initialValues,
}: Props) {
  return (
    <>
      <style>
        {`
        .background_img {
          background-image: url("/images/background.png");
          background-repeat: no-repeat;
          background-position: 84% 16%;
          background-size: contain;
        }
      `}
      </style>
      <Grid className="form-frame">
        <Segment className="form-segment">
          <Formik
            initialValues={initialValues || initValues}
            validationSchema={ConstructionWorksSchema}
            enableReinitialize={!!initialValues}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, handleSubmit }) => (
              <div className="background_img">
                <Form
                  className="form-margins"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <header>
                    <FormsLogo />
                    <MiddleHeader citiesDropdown={citiesDropdown} />
                  </header>
                  <main>
                    <div className="construction-main-title">
                      <Header>
                        <div className="construction-title-top">
                          Известување
                        </div>
                        <div className="construction-title-bottom">
                          за започнување на градежни работи
                        </div>
                      </Header>
                      <Header.Subheader>
                        (Член 23, став 2 од Законот за безбедност и здравје при
                        работа
                        <br />
                        „Службен весник на Република Македонија“ бр.92/07)
                      </Header.Subheader>
                    </div>
                    <ol className="ol">
                      <li>
                        <MyTextInput
                          label="Назив на работодавачот на подносителот на известувањето"
                          name="employerName"
                          type="text"
                          placeholder="Внесете назив на работодавачот"
                        />
                      </li>
                      <li>
                        <p style={{ marginBottom: "5px" }} className="asterisk">
                          Седиште на работодавачот на подносителот на
                          известувањето, во согласност со уписот во Централен
                          регистар
                        </p>
                        <p>улица</p>
                        <MyTextInput
                          name="employerAddress"
                          type="text"
                          placeholder="Внесете улица"
                        />
                        <p>број</p>
                        <MyTextInput
                          name="streetNumber"
                          type="text"
                          placeholder="Внесете број"
                        />
                        <p>место</p>
                        <MyTextInput
                          name="employerPlace"
                          type="text"
                          placeholder="Внесете место"
                        />
                        <p>општина</p>
                        <MyTextInput
                          name="employerMunicipality"
                          type="text"
                          placeholder="Внесете општина"
                        />
                        <p>поштенски број</p>
                        <MyTextInput
                          name="employerPostalCode"
                          type="text"
                          placeholder="Внесете поштенски број"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Трговски број"
                          name="tradeNumber"
                          type="text"
                          placeholder="Внесете трговски број"
                        />
                        <p>датум</p>
                        <MyDateInput
                          name="date"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Одберете датум"
                        />
                        <p>место</p>
                        <MyTextInput
                          name="place"
                          type="text"
                          placeholder="Внесете место"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Даночен број"
                          name="taxNumber"
                          type="text"
                          placeholder="Внесете даночен број"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Жиро сметка, број"
                          name="bankAccount"
                          type="text"
                          placeholder="Внесете жиро сметка"
                        />
                        <p>Банка - депонент</p>
                        <MyTextInput
                          name="bankName"
                          type="text"
                          placeholder="Внесете банка - депонент"
                        />
                      </li>
                      <li>
                        <p style={{ marginBottom: "5px" }} className="asterisk">
                          Податоци за одговорното лице на работодавачот
                        </p>
                        <p style={{ margin: "0 0 5px 2px" }}>Име и презиме</p>
                        <MyTextInput
                          name="fullname"
                          type="text"
                          placeholder="Внесете име и презиме"
                        />
                        <p>ЕМБГ</p>
                        <MyTextInput
                          name="embg"
                          type="text"
                          placeholder="Внесете единствен матичен број"
                        />
                        <p>Адреса (ул./број)</p>
                        <MyTextInput
                          name="address"
                          type="text"
                          placeholder="Внесете улица и број"
                        />
                        <p>Телефонски број</p>
                        <MyTextInput
                          name="phoneNumber"
                          type="tel"
                          placeholder="Внесете телефонски број"
                        />
                        <p>E-mail</p>
                        <MyTextInput
                          name="email"
                          type="email"
                          placeholder="Внесете e-mail адреса"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Место (адреса) каде ќе се изведуваат градежните работи"
                          name="workActivity"
                          type="text"
                          placeholder="Внесете адреса каде ќе се изведуваат градежните работи"
                        />
                      </li>
                      <li>
                        <label className="asterisk">
                          Датум на започнување на градежните работи
                        </label>
                        <MyDateInput
                          name="startingDate"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Одберете датум на започнување на градежните работи"
                        />
                      </li>
                      <li>
                        <p style={{ marginBottom: "5px" }} className="asterisk">
                          Дали имате план за безбедност и здравје при работа,
                          согласно Член 6 од Правилниот за минималните барања за
                          безбедност и здравје при работа на привремени и
                          мобилни градилишта?
                        </p>
                        <MyRadioGroup
                          touched={touched.safetyAtWork}
                          error={errors.safetyAtWork}
                        >
                          <Grid style={{ marginTop: "5px" }} stackable>
                            <MyRadio
                              type="radio"
                              name="safetyAtWork"
                              value="да"
                              label="да"
                            />
                            <MyRadio
                              type="radio"
                              name="safetyAtWork"
                              value="не"
                              label="не"
                            />
                          </Grid>
                        </MyRadioGroup>
                      </li>
                      <li>
                        <p
                          style={{ margin: "10px 0 5px 0" }}
                          className="asterisk"
                        >
                          Координатор за прашања за безбедност и здравје при
                          работа:
                        </p>
                        <MyTextInput
                          label="Име и презиме"
                          name="name"
                          type="text"
                          placeholder="Внесете име и презиме"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Број на вработени на градилиштето"
                          name="employeesNumber"
                          type="number"
                          placeholder="Внесете број на вработени на градилиштето"
                        />
                      </li>
                      <li>
                        <MyTextArea
                          label="Опрема за работа која ќе се користи на градилиштето"
                          name="workEquipment"
                          rows={10}
                          placeholder="Внесете опрема за работа која ќе се користи"
                        />
                      </li>
                    </ol>
                  </main>
                  <footer>
                    <Grid className="construction-field-by-part" stackable>
                      <Grid.Row
                        style={{
                          display: "block",
                        }}
                      >
                        <Header size="small" className="asterisk">
                          Пополнето од:
                        </Header>
                        <p style={{ margin: "0 0 5px 2px" }}>Име и презиме</p>
                        <MyTextInput
                          name="submitterFullname"
                          type="text"
                          placeholder="Внесете име и презиме"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>Работно место</p>
                        <MyTextInput
                          name="submitterWorkPosition"
                          type="text"
                          placeholder="Внесете работно место"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>
                          Телефонски број
                        </p>
                        <MyTextInput
                          name="submitterPhoneNumber"
                          type="tel"
                          placeholder="Внесeте телефонски број"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>Е-mail адреса</p>
                        <MyTextInput
                          name="submitterEmail"
                          type="email"
                          placeholder="Внесете e-mail адреса"
                        />
                      </Grid.Row>
                      <ButtonsReportsFooter />
                    </Grid>
                  </footer>
                </Form>
              </div>
            )}
          </Formik>
        </Segment>
      </Grid>
    </>
  );
}
