import { Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Segment,
  Form,
  Header,
  Label,
  Button,
  DropdownItemProps,
  Icon,
} from "semantic-ui-react";
import SearchInput from "../../../../app/common/Inputs/SearchInput";
import MyCheckbox from "../../inputs/MyCheckbox";
import MyCheckboxGroup from "../../inputs/MyCheckboxGroup";
import MyMultipleSelect from "../../inputs/MyMultipleSelect";
import MyRadio from "../../inputs/MyRadio";
import MyRadioGroup from "../../inputs/MyRadioGroup";
import MySelectInput from "../../inputs/MySelectInput";
import MyTextArea from "../../inputs/MyTextArea";
import MyTextInput from "../../inputs/MyTextInput";
import { KnownSubmitterSchema } from "../../schemas/KnownSubmitterSchema";
import { KnownSubmitterInterface } from "../../../../app/models/Forms/KnownSubmitterInterface";
import FormsLogo from "../FormsLogo";
import "./Grievances.css";

const initValues: KnownSubmitterInterface = {
  region: [],
  entity: "",
  submitterName: "",
  submitterAddress: "",
  email: "",
  phoneNumber: "",
  employerName: "",
  activity: null,
  address: "",
  cityId: null,
  violatedRight: [],
  request: "",
};

interface Props {
  handleSubmit: (
    values: KnownSubmitterInterface,
    actions: FormikHelpers<KnownSubmitterInterface>
  ) => Promise<void>;
  nkdDropdown: DropdownItemProps[];
  citiesDropdown: DropdownItemProps[] | undefined;
  vrBzrDropdown: DropdownItemProps[];
  vrRoDropdown: DropdownItemProps[];
  initialValues?: KnownSubmitterInterface | null;
}

export default function KnownForm({
  handleSubmit,
  initialValues,
  nkdDropdown,
  citiesDropdown,
  vrBzrDropdown,
  vrRoDropdown,
}: Props) {
  const navigate = useNavigate();
  return (
    <>
      <style>
        {`
        .background_img {
          background-image: url("/images/background.png");
          background-repeat: no-repeat;
          background-position: 65% 35%;
          background-size: contain;
        }
   `}
      </style>
      <Grid className="form-frame" stackable>
        <Segment className="form-segment">
          <Formik
            initialValues={initialValues || initValues}
            validationSchema={KnownSubmitterSchema}
            enableReinitialize={!!initialValues}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, values, setFieldValue, submitForm }) => (
              <div className="background_img">
                <Form className="form-margins" autoComplete="off">
                  <header>
                    <FormsLogo />
                  </header>
                  <main>
                    <div className="form-main-title">
                      <Header>
                        <div className="form-title-top">Пријава</div>
                        <div className="form-title-bottom">
                          од познат подносител
                        </div>
                      </Header>
                    </div>
                    <div className="form-area-entity">
                      <div className="form-area">
                        <p className="asterisk">
                          Област на која се однесува пријавата:
                        </p>
                        <MyCheckboxGroup
                          touched={touched.region}
                          error={errors.region}
                        >
                          <Grid className="form-area-checkboxes">
                            <MyCheckbox
                              type="checkbox"
                              name="region"
                              value="безбедност и здравје при работа"
                              label="безбедност и здравје при работа"
                            />
                            <MyCheckbox
                              type="checkbox"
                              name="region"
                              value="работни односи"
                              label="работни односи"
                            />
                          </Grid>
                        </MyCheckboxGroup>
                      </div>
                      <div className="form-area">
                        <p className="asterisk">Лице:</p>
                        <MyRadioGroup
                          touched={touched.entity}
                          error={errors.entity}
                        >
                          <Grid className="form-entity">
                            <MyRadio
                              name="entity"
                              value="физичко лице"
                              label="физичко лице"
                            />
                            <MyRadio
                              name="entity"
                              value="правно лице"
                              label="правно лице"
                            />
                          </Grid>
                        </MyRadioGroup>
                      </div>
                    </div>
                    <ol className="ol">
                      <li>
                        <MyTextInput
                          label="Име и презиме (подносител/и на претставка или синдикат):"
                          name="submitterName"
                          type="text"
                          placeholder="Внесете име и презиме"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Вашата адреса (подносител/и на претставка или синдикат)"
                          name="submitterAddress"
                          type="text"
                          placeholder="Внесете адреса"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Е-mail адреса"
                          name="email"
                          type="email"
                          placeholder="Внесете e-mail адреса"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Број за контакт (подносител/и на претставка или синдикат)"
                          name="phoneNumber"
                          type="tel"
                          placeholder="Внесете број за контакт"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Работодавач/компанија за кој/а се жалите"
                          name="employerName"
                          type="text"
                          placeholder="Внесете работодавач за кој/а се жалите"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Дејност-шифрарник</p>
                        <SearchInput
                          name="activity"
                          setFieldValue={setFieldValue}
                          source={nkdDropdown}
                          placeholder="Внесете дејност на работодавачот"
                          defaultValue={values.id ? values.activity : null}
                        />
                        {errors.activity && touched.activity ? (
                          <Label basic color="red" style={{ marginTop: "3px" }}>
                            {errors.activity}
                          </Label>
                        ) : null}
                      </li>
                      <li>
                        <MyTextInput
                          label="Адреса на субјектот за кој се жалите"
                          name="address"
                          type="text"
                          placeholder="Внесете адреса на субјектот"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Град/Општина</p>
                        <MySelectInput
                          name="cityId"
                          options={citiesDropdown || []}
                          placeholder="Одберете град"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Прекршено право</p>
                        <MyMultipleSelect
                          name="violatedRight"
                          options={[
                            ...(values.region.some(
                              (x) => x === "безбедност и здравје при работа"
                            )
                              ? vrBzrDropdown
                              : []),
                            ...(values.region.some(
                              (x) => x === "работни односи"
                            )
                              ? vrRoDropdown
                              : []),
                          ]}
                          placeholder="Одберете прекршено право"
                        />
                      </li>
                      <li>
                        <MyTextArea
                          label="Вашата преставка е"
                          name="request"
                          rows={8}
                          placeholder="Внесете преставка"
                        />
                      </li>
                    </ol>
                  </main>
                  <footer className="form-buttons-position">
                        <Button
                          style={{
                            background: "rgb(161, 7, 5)",
                            color: "white",
                          }}
                          onClick={() => navigate("/")}
                        >
                          <Icon name="arrow left" />
                        </Button>
                        <Button
                          type="submit"
                          style={{
                            background: "rgb(161, 7, 5)",
                            color: "white",
                          }}
                          onClick={submitForm}
                        >
                          Зачувај
                        </Button>
         
                  </footer>
                </Form>
              </div>
            )}
          </Formik>
        </Segment>
      </Grid>
    </>
  );
}
