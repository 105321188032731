import { useNavigate } from "react-router-dom";
import { Button, Header, Progress, Segment } from "semantic-ui-react";
import { eModule } from "../../app/models/ELearning/eModule";

interface Props {
  module: eModule;
  progress: number;
}

export default function ModuleDetails({ module, progress }: Props) {
  const navigate = useNavigate();
  return (<Segment style={{background:'whitesmoke'}}>
    <Header style={{background:'whitesmoke', border:'none', borderBottom:'2px solid #2185d0' }} attached="top" as="h2">{module.moduleName}</Header>
    <br />
    <Progress percent={progress} progress color="blue" />
    <Segment attached="top" style={{height: '268px', overflowY: 'scroll', background:'whitesmoke'}}>
      <p>
        {module.moduleDesc}
      </p>
    </Segment>
    <br />
    <Button fluid
     content={"Продолжете"}
     primary
     onClick={()=> navigate(`/e-learning/module/${module.id}`)}
     />
  </Segment>)
}