import React from 'react'
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import FlipCard from '../components/FlipCard/FlipCard';
import { Markup } from "interweave";
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
    slideContents?: eSlideContent[];
    colorSchemeArray: string[];
}

export default function FlipCard1({ slideContents, colorSchemeArray }: Props) {

    const header = slideContents?.find(x => x.type.typeName === 'Header')?.body;
    const contentHeader = slideContents?.find(x => x.type.typeName === 'Content-Header')?.body;
    const paragraph = slideContents?.find(x => x.type.typeName === 'Paragraph')?.body;
    const flipCards = sortedContentArray(slideContents, 'Flip-Card');
    const image = slideContents?.find(x => x.type.typeName === 'Image')?.body;

    const imgSrc = image || "unkown.jpg"

    const backgroundColor = colorSchemeArray[0];

    const divStyle = flipCards.length > 3 ?
        { marginLeft: '', paddingTop: '180px', display: 'flex', gap: '60px', height: '400px', paddingRight: '65px' } :
        { marginLeft: '', paddingTop: '180px', display: 'flex', gap: '60px', height: '400px', paddingRight: '230px' };

    return (
        <>
            <div className="slide-img-par-1">
                <h1 className="slide-header">{header}</h1>
                <div className="slide-header-line" style={{ backgroundColor }}> </div>
                <div style={{width: '650px'}}>
                    <LazyLoadImage alt={'featured-image'}
                        className='image-style-slide'
                        src={`/images/elearning/components/${imgSrc}.jpg`}
                        width={'100%'}
                        effect="blur"
                        placeholderSrc={`/images/elearning/thumbnail/${imgSrc}.jpg`}
                    />
                </div>
                <h3 className="slide-content-header" style={{ marginLeft: '60px' }} >
                    {contentHeader}
                </h3>
                <div style={{ marginTop: '30px', marginLeft: '60px' }}>
                    <Markup content={paragraph} />
                </div>
                <div style={divStyle}>
                    <div style={{ width: '100%' }}>
                        {flipCards.map((x, i) => (<FlipCard backgroundColor={colorSchemeArray[0]} offsetRight={i * 350} textColor='' text={`${x.header}`}
                            textBack={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll("•", " ")} />))}
                    </div>
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}