import { BZR } from "./Regions/BZR";
import { City } from "./Regions/City";
import { RO } from "./Regions/RO";

export interface User {
  id: string;
  username: string;
  password: string;
  image?: string;
  email: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: City;
  cityId?: number;
  zipCode?: string;
  gender?: number;
  regionBzr?: BZR;
  regionBzrId?: number;
  regionRo?: RO; 
  regionRoId?: number; 
  role?: string;
}

export interface CurrentUser extends User {
  token: string;
}


export class UserFormValues implements User {
  id: string = "";
  username: string = "";
  password: string ="";
  image?: string ="";
  email: string = "";
  displayName?: string = "";
  firstName?: string = "";
  lastName?: string = "";
  address?: string = "";
  city?: City = undefined;
  cityId?: number = 0
  zipCode?: string = "";
  gender?: number;
  regionBzr?: BZR = undefined;
  regionBzrId?: number = 0;
  regionRo?: RO = undefined; 
  regionRoId?: number = 0; 
  role?: string = "";

  constructor(init?: UserFormValues)
  {
    Object.assign(this, init);
  }
}


export interface UserFormValues extends Partial<User> {

}

export interface LoginFormValues {
  username: string;
  password: string;
}

export interface RegisterFormValues {
  email: string;
  username: string;
  password: string;
}