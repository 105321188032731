import moment from "moment";
import { Nkd } from "../CodeLists/Nkd";
import { City } from "../Regions/City";
import { JobPosition } from "./OvertimeWorkInterface";
export type FormValue = string | number | Date | Nkd | City | JobPosition[] | string[] | number[] | null | undefined;

export const formTypesToString = (key: string,
  value: FormValue
  ) => {
  if (!value)
    value = '';

  if (typeof value === 'number')
    value = value.toString();

  if (typeof value === 'object') {
    if (value instanceof Date) {
      value = moment(value).format('YYYY-MM-DD');
    }else if (value instanceof Array){
      if ( ['jobPosition','workPlaces','sundayJobPositions'].some(x=> x === key)){
        value = (value as JobPosition[])
          .reduce((acc,val)=>{ 
            let current = acc;
            if(current){
               current += ' ; ';
            }
            return current + val.jobPosition + ":" + val.employeesNumber }, "");
      } else {
        // debugger;
        value = value.map(x=> x.toString()).join(";")
      }
    } else if (key === 'city') {
      value = (value as City)?.name
    } else if (key === 'nkdCodes') {
      key = 'nkd-codes'
      value = (value as Nkd)?.description
    } else {
      value = '';
    }
  }
  return [key, value];
}