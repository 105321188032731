import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  ConstructionWorks,
  ConstructionWorksInterface,
} from "../../../app/models/Forms/ConstructionWorksInterface";
import { useStore } from "../../../app/stores/store";
import JsonView from "./JsonView";

export default observer(function ConstructionWorkView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formStore } = useStore();
  const {
    loadingInitial,
    selectedConstructionForm,
    loadSingleConstructionForm,
  } = formStore;

  useEffect(() => {
    if (id) loadSingleConstructionForm(id);
  }, [loadSingleConstructionForm, id]);

  console.log(selectedConstructionForm);

  if (loadingInitial || !selectedConstructionForm) {
    return <LoadingComponent />;
  }

  const jsonArr = new ConstructionWorks(
    selectedConstructionForm
  ).toStringArray();

  return (
    <>
      <AdminHeader header={t("view-construction-at-work")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedConstructionForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedConstructionForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedConstructionForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <JsonView arrJSONData={jsonArr} />
    </>
  );
});
