import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Segment } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { PostValues } from "../../../../app/models/CMS/post";
import { useStore } from "../../../../app/stores/store";
import PostForm from "../components/PostForm";

const singularName = "Page";
const pluralName = "Pages";
const linkPath = "/admin/pages";
const postType = "page";
const icon = "pin";

export default observer(function PageEdit() {
  const { id } = useParams();
  const {
    postStore: { loadingInitial, selectedPost, loadSinglePost, updatePost },
  } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) loadSinglePost(id);
  }, [loadSinglePost, id]);

  const onSubmit = (values: PostValues) => {
    // console.log(values.postTranslations?.find(x=> x.languageId === 'MK')?.postContent);
    updatePost(values).then(() => {
      const successMessage = t('uspesno_zacuvano');
      toast.success(successMessage);  
    }
    );
  };

  if (loadingInitial || !selectedPost) {
    return <LoadingComponent />;
  }

  return (
    <div>
      <AdminHeader
        header={t("edit-page")}
        icon={icon}
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <Segment>
        <PostForm
          onSubmit={onSubmit}
          initialValues={selectedPost}
          postType={postType}
          goBack={() => navigate(`${linkPath}/list`)}
          singularName={singularName}
        />
      </Segment>
    </div>
  );
});
