import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { StartingActivity } from "../../../app/models/Forms/StartingActivityInterface";
import { useStore } from "../../../app/stores/store";
import JsonView from "./JsonView";

export default observer(function StartingActivityView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formStore } = useStore();
  const {
    loadingInitial,
    selectedStartingActivityForm,
    loadSingleStartingActivityForm,
  } = formStore;

  useEffect(() => {
    if (id) loadSingleStartingActivityForm(id);
  }, [loadSingleStartingActivityForm, id]);


  if (loadingInitial || !selectedStartingActivityForm) {
    return <LoadingComponent />;
  }

  // const arr = Object.keys(selectedStartingActivityForm).map((key) => ({
  //   key,
  //   value: selectedStartingActivityForm[key as keyof StartingActivityInterface],
  // }));

  const jsonArr = new StartingActivity(
    selectedStartingActivityForm
  ).toStringArray();

  return (
    <>
      <AdminHeader header={t("view-starting-activity")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedStartingActivityForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedStartingActivityForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedStartingActivityForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <JsonView arrJSONData={jsonArr} />
    </>
  );
});
