import { Segment, Header, Icon } from 'semantic-ui-react';
import React from 'react';

export interface AdminHeaderProps {
    icon: string;
    header: string;
    subheader?: string;
    iconStyles?: any;
}

export default function AdminHeader({icon, header, subheader,iconStyles }: AdminHeaderProps) {
    return(
        <Segment>
            <Header as='h2' >
                <Icon className={icon} style={iconStyles} />
                <Header.Content>
                {header}
                <Header.Subheader>{subheader}</Header.Subheader>
                </Header.Content>
            </Header>
        </Segment>
    );
};
