import React, { useState } from 'react'
import { Button, Container, Icon } from 'semantic-ui-react';
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import { useStore } from '../../../app/stores/store';

interface Props {
    slideContents?: eSlideContent[];
    content: string;
    contentHeader: string;
    color: string;
    backgroundColor: string;
}

export default function ({ slideContents, content, contentHeader, color, backgroundColor }: Props) {

    const { modalStore } = useStore();
    const { closeModal } = modalStore;
    const [isImage, setIsImage] = useState(false)
    // const body = slideContents?.find(x => x.type.typeName === 'Body')?.body;
    const body = sortedContentArray(slideContents, 'Body');
    const image = sortedContentArray(slideContents, 'Image');
    // const image = slideContents?.find(x => x.type.typeName === 'Image')?.body;
    const imgSrc = image || "unkown.jpg"

    const imageStyle = image?.length > 1 ? 'image-elearning' : 'content-elearning'

    return (
        <>
            <div style={{ height: '700px', overflowY: 'scroll', fontSize: '15px', }}>
                <div style={{ width: '100%', height: '80px', }}>
                    <div style={{ padding: '28px', fontSize: '22px', color }}>
                        {contentHeader}
                    </div>
                    <div style={{ content: ' ', height: '2px', width: '80%', marginLeft: '20px', backgroundColor }} > </div>
                </div>
                <div className='close-button' style={{ padding: '20px' }}>
                    <button color='transparent' onClick={closeModal}>
                        <Icon name='close' />
                    </button>
                </div>
                <div style={{ padding: '40px 30px 20px', wordSpacing: '3px', lineHeight: '1.2' }}>
                    <div className='image-elearning'>
                        {/* <img src={`/images/elearning/components/${imgSrc}.jpg`} width={1000} className='image-style-slide'  />  */}
                    </div>
                    <div className='content-elearning'>
                        {content}
                    </div>
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}