import {
  Document,
  Page,
  Font,
  Text,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import background from "../pdf/pictures/pozadina1.png";

// Create styles

const fontLight =
  "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf";
Font.register({ family: "RobotoLight", src: fontLight });
const fontRegular =
  "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf";
Font.register({ family: "RobotoRegular", src: fontRegular });

const fontMedium =
  "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf";
Font.register({ family: "RobotoMedium", src: fontMedium });

const styles = StyleSheet.create({
  page: {
    fontFamily: "RobotoLight",
    paddingBottom: 50,
    paddingHorizontal: 50,
  },
  view: {
    position: "absolute",
    top: "0",
    right: "50",
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  image: {
    objectFit: "cover",
  },
  // logoTextTop: {
  //   fontFamily: "RobotoLight",
  //   marginLeft: "5",
  //   fontSize: 11,
  // },
  // logoTextTopAl: {
  //   fontFamily: "RobotoLight",
  //   marginLeft: "5",
  //   fontSize: 11,
  //   borderBottom: "2px solid rgb(207, 50, 14)",
  // },

  // logoTextBottom: {
  //   fontFamily: "RobotoRegular",
  //   marginLeft: "5",
  //   fontSize: 11,
  // },
  institutionLabel: {
    fontFamily: "RobotoRegular",
    fontSize: 11,
  },
  regionLabel: {
    fontFamily: "RobotoRegular",
    fontSize: 11,
  },
  regionValue: {
    fontFamily: "RobotoLight",
    marginLeft: "5",
    fontSize: 11,
  },
  certificationLabel: {
    width: 150,
    fontSize: 11,
    fontFamily: "RobotoLight",
    textAlign: "center",
    position: "absolute",
    right: 40,
    top: 120,
  },
  title_subtitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    top: 150,
  },
  title: {
    fontSize: 17,
    fontFamily: "RobotoMedium",
  },
  subtitle: {
    fontSize: 10.5,
    width: 320,
  },
  inputMainLabel: {
    fontSize: 12,
    fontFamily: "RobotoRegular",
    textAlign: "justify",
  },
  inputLabel: {
    fontSize: 11,
    marginTop: "3px",
    marginBottom: "3px",
    paddingLeft: "12px",
    fontFamily: "RobotoRegular",
    textAlign: "justify",
  },
  inputValue: {
    marginTop: "3px",
    marginBottom: "3px",
    paddingLeft: "12px",
    fontSize: 11,
    fontFamily: "RobotoLight",
    marginLeft: "5",
    textAlign: "justify",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export interface PdfFormFieldInfo {
  inputMainLabel?: string;
  inputLabel?: string;
  inputValue?: string | string[];
}

interface Props {
  institutionLabel?: string;
  regionLabel?: string;
  regionValue?: string;
  certificationLabel?: string;
  title: string;
  subtitle?: string;
  values: PdfFormFieldInfo[];
}

export const FormPDF = ({
  institutionLabel,
  regionLabel,
  regionValue,
  certificationLabel,
  title,
  subtitle,
  values,
}: Props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.view}>
          <Image style={styles.image} src={background} fixed />
        </View>

        <View style={{ top: "90", left: "20" }}>
          <Text style={styles.institutionLabel} fixed>
            {institutionLabel}
          </Text>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={styles.regionLabel} fixed>
              {regionLabel}
            </Text>
            <Text style={styles.regionValue} fixed>
              {regionValue}
            </Text>
          </View>
        </View>

        <Text style={styles.certificationLabel}>{certificationLabel}</Text>
        <View style={styles.title_subtitle}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
        </View>
        <View style={{ height: "130px" }}></View>
        <View style={{ top: "40px" }}>
          {values.map((x) => {
            return (
              <>
                <View>
                  {x.inputMainLabel && (
                    <Text style={styles.inputMainLabel}>
                      {x.inputMainLabel}
                    </Text>
                  )}
                  <View>
                    {x.inputLabel && (
                      <Text style={styles.inputLabel}>{x.inputLabel}</Text>
                    )}
                    {x.inputValue && (
                      <Text style={styles.inputValue}>{x.inputValue}</Text>
                    )}
                  </View>
                </View>
              </>
            );
          })}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};
