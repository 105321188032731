import _ from "lodash";
import { action, makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import agent from "../api/agent";
import { TaxonomyTerm } from "../models/CMS/taxonomyTerm";

export default class PostCategoryStore {
  categoryRegistry = new Map<string, TaxonomyTerm>();
  target: string | null = null;

  private loadedTaxonomySlug : string | undefined = undefined;

  selectedCategory: TaxonomyTerm | null = null;
  setSelectedCategoryById(id: string) {
    const category = this.categoryRegistry.get(id);
    this.selectedCategory = category || null;
  }
  clearSelectedCategory = ()=> {
    this.selectedCategory = null;
  }

  get parentCategoryDropdown() {
    return Array.from(this.categoryRegistry.values())
      .filter(x => x.id !== this.selectedCategory?.id)
      .filter(x => x.taxonomySlug === this.loadedTaxonomySlug)
      .map(x => {
        const ddItem: DropdownItemProps = {
          value: x.id,
          text: x.slug
        }
        return ddItem;
      })
  }

  get allCategoryDropdown() {
    return Array.from(this.categoryRegistry.values())
      .map(x => {
        const ddItem: DropdownItemProps = {
          value: x.id,
          text: x.slug
        }
        return ddItem;
      })
  }

  get allCategorySlugs() {
    return Array.from(this.categoryRegistry.values())
      .map(x => {
        const ddItem: DropdownItemProps = {
          value: x.slug,
          text: x.slug
        }
        return ddItem;
      })
  }

  constructor() {
    makeAutoObservable(this, {
      setSelectedCategoryById: action.bound
    });
  }

  loading = false;
  loadingInitial = false;

  get sortedPostCategories() {
    return Array.from(this.categoryRegistry.values());
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }
  setTarget = (target: string | null) => {
    this.target = target;
  }

  createPostCategory = async (category: TaxonomyTerm) => {
    this.setLoading(true)
    try {
      await agent.PostCategories.create(category);
      runInAction(() => {
        this.categoryRegistry.set(category.id, category);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  deletePostCategory = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.PostCategories.delete(id);
      runInAction(() => {
        this.categoryRegistry.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting post');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }


  updatePostCategory = async (category: TaxonomyTerm) => {
    this.setLoading(true)
    try {
      await agent.PostCategories.update(category);
      runInAction(() => {
        this.categoryRegistry.set(category.id, category);
      })
    } catch (error) {
      console.log(error)
      throw error;
    } finally {
      this.setLoading(false)
    }
  }

  loadPostCategory = async (categoryId: string) => {
    const categoryTerm = this.categoryRegistry.get(categoryId);
    if (categoryTerm) {
      this.selectedCategory = categoryTerm;
      return
    }
    this.setLoadingInitial(true);
    try {
      const categoryTermDb = await agent.PostCategories.details(categoryId);
      runInAction(() => {
        this.categoryRegistry.set(categoryTermDb.id, categoryTermDb);
        this.selectedCategory = categoryTermDb;
      })
    } catch (error) {
      console.log(error)
      throw error;
    } finally {
      this.setLoadingInitial(false)
    }
  }

  loadPostCategoryBySlug = async (taxSlug: string, termSlug: string) => {
    this.setLoadingInitial(true);
    try {
      const categoryTermDb = await agent.PostCategories.detailsBySlug(taxSlug,termSlug);
      runInAction(() => {
        this.categoryRegistry.set(categoryTermDb.id, categoryTermDb);
        this.selectedCategory = categoryTermDb;
      })
      return categoryTermDb;
    } catch (error) {
      console.log(error)
      throw error;
    } finally {
      this.setLoadingInitial(false)
    }
  }

  loadPostCategories = async (categorySlug: string) => {
    this.setLoading(true);
    try {
      const categories = await agent.PostCategories.list(categorySlug);
      this.loadedTaxonomySlug = _.first(categories)?.taxonomySlug;
      this.categoryRegistry.clear();
      runInAction(() => {
        categories.forEach(x => {
          this.categoryRegistry.set(x.id, x);
        });
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }
}