import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../../app/stores/store";
import PostPaginatedTable from "../components/PostPaginatedTable";

export default observer(function PostsList() {
  const { t } = useTranslation();
  const {
    postStore: { setMultipleStaticFilterParam, clearStaticFilterParams },
    postCategoryStore: { loadPostCategories },
  } = useStore();

  const [activeItem, setActiveItem] = useState("home");

  useEffect(() => {
    if (activeItem === "trash") {
      setMultipleStaticFilterParam([
        { name: "type", value: "post" },
        { name: "status", value: "trash" },
      ]);
      return;
    }

    setMultipleStaticFilterParam([
      { name: "type", value: "post" },
      { name: "postStatusNot", value: "trash" },
    ]);
    loadPostCategories("post_categories");

    return () => clearStaticFilterParams();
  }, [activeItem, setMultipleStaticFilterParam, clearStaticFilterParams, loadPostCategories]);

  return (
    <div>
      <AdminHeader
        header={t("Posts")}
        icon={"pin"}
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <div>
        <Menu attached="top">
          <Menu.Item
            name={t("home")}
            active={activeItem === "home"}
            onClick={() => setActiveItem("home")}
          />
          <Menu.Item
            name={t("trash")}
            active={activeItem === "trash"}
            onClick={() => setActiveItem("trash")}
          />
        </Menu>

        <PostPaginatedTable
          linkPath="/admin/posts"
          isTrash={activeItem === "trash"}
        />
      </div>

      <div style={{ textAlign: "right" }}>
        <Link to="/admin/posts/create" className="ui button teal">
          <Icon name="plus" />
          {t("add-new-post")}
        </Link>
      </div>
    </div>
  );
});
