import { Markup } from 'interweave';
import React from 'react'
import { RiInformationFill } from 'react-icons/ri';
import './NumbersDiagram.css';

interface Props {
    backgroundColor: string;
    text: string;
    onClick: () => void;
    offsetWidth: number;
}

export default function NumbersDiagramItem({ offsetWidth, onClick, text, backgroundColor }: Props) {

    const width = `${offsetWidth}px`


    return (
        <>
            <div className='numbersdiagram-wrap'>
                <div className='block-diagram' style={{ width, backgroundColor }} onClick={onClick}>
                    <div style={{ display: 'flex' }}>
                        <div className='block-main-info' >
                            <div className='stair-box-icon' >
                                <RiInformationFill size={28} />
                            </div>
                        </div>
                        <div className='block-main-text' style={{ width }} >
                          <Markup content={text} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}