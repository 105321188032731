import { useNavigate } from "react-router-dom";
import { Button, Header, Progress, Segment } from "semantic-ui-react";
import { eTraining } from "../../app/models/ELearning/eTraining"

interface Props {
  training: eTraining;
  progress: number;
}

export default function TrainingDetails({ training, progress }: Props) {
  const navigate = useNavigate();
  return (<Segment style={{background:'whitesmoke'}}>
    <Header style={{background:'whitesmoke', border:'none', borderBottom:'2px solid #2185d0'}} attached="top" as="h2">{training.trainingName}</Header>
    <br />
    <Progress percent={progress} progress color="blue" />
    <Segment attached="top" style={{height: '268px', overflowY: 'scroll', background:'whitesmoke'}}>
      <p>
        {training.trainingDesc}
      </p>
    </Segment>
    <br />
    <Button fluid
     content={progress === 0 ? "Start Training" : "Продолжете"}
     primary
     onClick={()=> navigate(`/e-learning/training/${training.id}`)}
     />
  </Segment>)
}