import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Card, Grid, Segment } from "semantic-ui-react";
import agent from "../../../../api/agent";
import PhotoSelectWidget from "../../../../common/imageUpload/photoSelectWidget";
import LabelledInput from "../../../../common/Inputs/LabelledInput";
import MyEditorInput from "../../../../common/Inputs/MyEditorInput";
import { DirectorInfo } from "../../../../models/Plugins/pluginTypes";
import LoadingComponent from "../../../LoadingComponent";
import AdminHeader from "../../AdminHeader";



export default function DirectorInfoPlugin() {
  const [loading,setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<DirectorInfo>( {
   directorPhotoId:'',
   email:'',
   excerpt:'',
   firstName:'',
   lastName:''
  });
  const { t } = useTranslation();

  useEffect(()=> {
    agent.Plugin.getDirectorInfo()
    .then(data=> {setInitialValues(data)
    })
    .finally(()=> setLoading(false))
  },[t])

  if(loading){
    return <LoadingComponent />
  }

  const onSubmit = (values: DirectorInfo) => {
    agent.Plugin.updateDirectorInfo(values).then(()=> {
      toast.info(t('successfully-updated'))
    });
  }


  const extraContent = (setFieldValue: (name: string, value: string) => void,
    values: DirectorInfo) => {
      return  (idstring: string) => {
          return (<Card.Content extra>
            <div className='ui two buttons'>
              <Button content="Select" color='green'
                disabled={values.directorPhotoId === idstring}
                onClick={(e) => {
                  e.preventDefault();
                  setFieldValue('directorPhotoId', idstring)
                }}
              />
            </div>
          </Card.Content>)
      }
  }


  return (<>
    <style>
      {`
        .public-DraftEditor-content {
        border: 1px solid #e5e5e5;
        }
       `}
    </style>

    <AdminHeader header={t("director-section")} icon="chess queen" />
    <br /><br />

    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue }) =>(
        <Form>
          <Segment>
            <Grid columns={2}>
              <Grid.Column>
                <LabelledInput label={t('first-name')} placeholder="John" name="firstName" type="text" />
                <LabelledInput label={t('last-name')} placeholder="Doe" name="lastName" type="text" />
                <LabelledInput label={t('email')} placeholder="john.doe@acme.com" name="email" type="email" />
                <Segment>
                  <MyEditorInput
                    name="excerpt"
                    label={t('excerpt')}
                    placeholder={"Input excerpt here"}
                    rows={4}
                    hideCustomButtons={true}
                    onChange={setFieldValue}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <PhotoSelectWidget
                  extraContent={extraContent(setFieldValue,values)}
                />
              </Grid.Column>
            </Grid>

            <Segment>
              <Button type="submit" primary>{t('submit')}</Button>
            </Segment>
          </Segment>
        </Form>)}
    </Formik></>)
}