import React, { useEffect } from "react";
import {  FormikHelpers } from "formik";
import {
  Segment,
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { StartingActivityInterface } from "../../app/models/Forms/StartingActivityInterface";
import agent from "../../app/api/agent";
import { FormPDF } from "./pdf/FormPDF";
import { PdfFormFieldInfo } from "./pdf/FormPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useStore } from "../../app/stores/store";
import moment from "moment-timezone";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useNavigate } from "react-router-dom";
import "./FormsModal.css";
import { toast } from "react-toastify";
import StartingForm from "./commonForms/Reports/StartingForm";

export default observer(function StartingActivityForm() {
  const { modalStore, codesStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { citiesDropdown, loadCities, loadNkds, nkdDropdown } = codesStore;
  const navigate = useNavigate();

  useEffect(() => {
    loadCities();
    loadNkds();
  }, [loadCities, loadNkds]);

  if (!citiesDropdown || !nkdDropdown) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: StartingActivityInterface,
    actions: FormikHelpers<StartingActivityInterface>
  ) => {
    try {
      await agent.Forms.startingActivity(values);
    } catch {
      toast.error("Неуспешно испратено известување");
      return;
    }

    const city = citiesDropdown.find((x) => x.value === values.cityId)?.text;

    const occupation = nkdDropdown.find(
      (x) => x.value === values.workActivity
    )?.description;

    const infoArray: PdfFormFieldInfo[] = [
      {
        inputMainLabel:
          "1. Назив на работодавачот на подносителот на известувањето:",
        inputValue: values.employerName,
      },
      {
        inputMainLabel:
          "2. Седиште на работодавачот на подносителот на известувањето, во согласност со уписот во Централен регистар:",
      },
      {
        inputLabel: "улица:",
        inputValue: values.employerAddress,
      },
      {
        inputLabel: "број:",
        inputValue: values.streetNumber,
      },
      {
        inputLabel: "место:",
        inputValue: values.employerPlace,
      },
      {
        inputLabel: "Општина:",
        inputValue: values.employerMunicipality,
      },
      {
        inputLabel: "Поштенски број:",
        inputValue: values.employerPostalCode,
      },
      {
        inputMainLabel: "3. Назив на правниот субјект:",
        inputValue: values.legalEntityName,
      },
      {
        inputMainLabel: "4. ЕМБС (Единствен матичен број на субјект):",
        inputValue: values.embs,
      },
      {
        inputLabel: "датум:",
        inputValue: moment
          .utc(values.date)
          .local()
          .format("D MMMM YYYY, h:mm A")
          .toString(),
      },
      {
        inputMainLabel: "5. Даночен број:",
        inputValue: values.taxNumber,
      },
      {
        inputMainLabel: "6. Жиро сметка, број:",
        inputValue: values.bankAccount,
      },
      {
        inputLabel: "Банка - депонент:",
        inputValue: values.bankName,
      },
      {
        inputMainLabel: "7. Податоци за одговорното лице  на работодавачот:",
      },
      {
        inputLabel: "Име и презиме:",
        inputValue: values.fullname,
      },
      {
        inputLabel: "Адреса:",
        inputValue: values.address,
      },
      {
        inputLabel: "Телефонски број:",
        inputValue: values.phoneNumber,
      },
      {
        inputLabel: "E-mail:",
        inputValue: values.email,
      },
      {
        inputMainLabel:
          "8. Дејност која ќе се извршува во работната просторија и/или простор:",
        inputValue: occupation?.toString(),
      },
      {
        inputMainLabel:
          "9. Адреса на работната просторија, простор и/или подружница каде ќе се врши дејност:",
        inputValue: values.workplaceAddress,
      },
      {
        inputMainLabel: "10. Датум на започнување со вршење на дејност:",
        inputValue: moment
          .utc(values.startingDate)
          .local()
          .format("D MMMM YYYY, h:mm A")
          .toString(),
      },
      {
        inputMainLabel: "Пополнето од:",
      },
      {
        inputLabel: "Име и презиме",
        inputValue: values.submitterFullname,
      },
      {
        inputLabel: "Работно место",
        inputValue: values.submitterWorkPosition,
      },
      {
        inputLabel: "Телефонски број",
        inputValue: values.submitterPhoneNumber,
      },
      {
        inputLabel: "Е-mail адреса",
        inputValue: values.submitterEmail,
      },
    ];
    openModal(
      <Segment placeholder>
        <Grid>
          <GridColumn>
            <div>
              <PDFDownloadLink
                document={
                  <FormPDF
                    values={infoArray}
                    certificationLabel={
                      "место за заверка на Државниот инспекторат за труд"
                    }
                    institutionLabel={"Државен инспекторат за труд"}
                    regionLabel={"Подрачје:"}
                    regionValue={`Град ${city}`}
                    subtitle={
                      "(Правилник за формата и содржината на образецот за известување за започнување со вршење на дејност („Службен весник на Република Македонија“ бр.136/07))"
                    }
                    title={"ИЗВЕСТУВАЊЕ ЗА ЗАПОЧНУВАЊЕ СО ВРШЕЊЕ НА ДЕЈНОСТ"}
                  />
                }
                fileName="ЗапочнувањеДејност.pdf"
                className="btn-form-submit"
              >
                <p
                  onClick={(e) => e.preventDefault()}
                  className="text-form-modal"
                >
                  Дали сакате да превземете во PDF-формат?
                </p>
                <button
                  className="button-form-modal"
                  onClick={() => {
                    navigate("/");
                    // closeModal();
                  }}
                >
                  Превземи
                </button>
              </PDFDownloadLink>
            </div>
          </GridColumn>
        </Grid>
        <div className="close-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            X
          </button>
        </div>
      </Segment>
    );
  };

  return (
    <StartingForm
      nkdDropdown={nkdDropdown}
      handleSubmit={handleSubmit}
      citiesDropdown={citiesDropdown}
    />
  );
});
