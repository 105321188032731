import React from "react";
import { To, useNavigate } from "react-router-dom";
import "./HomePageDocumentsItem.css";



interface Props {
  to: To;
  image: string;
  content: string;
}

export default function HomePageDocumentsItem({ to, image, content }: Props) {
  const navigate = useNavigate();

  return (
    <li className="documents-list-item" onClick={() => navigate(to)}>
      <div>
        <img src={image} alt="patterns" />
        <span>{content}</span>
      </div>
    </li>
  );
}
