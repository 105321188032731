import { ContentBlock } from "draft-js";
import { RenderConfig } from "draft-js-export-html";
import { Options as OptionsImport } from "draft-js-import-html";
import _ from "lodash";

export const optionsExport = {
  inlineStyleFn: (styles: any) => {
    const retObj: {element: string, style: {
      color?: string;
      fontSize?: string;
      fontFamily?: string;
    }} = {
      element: 'span',
      style:{}
    }
    let key = 'color-';
    let color = styles.find((value: any) => value.startsWith(key));

    if (color) {
      retObj.style.color = color.replace(key, '')
    }
    key = 'fontsize-';
    const fontSize = styles.find((value: any)=> value.startsWith(key))
    if(fontSize) {
      retObj.style.fontSize = fontSize.split('-')[1].concat('px');
    }

    key = 'fontfamily-';
    const fontFamily = styles.find((value: any)=> value.startsWith(key))
    if(fontFamily) {
      retObj.style.fontFamily = fontFamily.split('-')[1];
      console.log(retObj)
    }

    return retObj;

  },
  blockStyleFn: (block: ContentBlock) : RenderConfig | undefined => {
    if (block.getData().get('text-align')) {
      const textAlign = block.getData().get('text-align')
      return {
        style: {
          textAlign
        },
      }
    }
  }
};

export const optionsImport: OptionsImport = {
  customBlockFn: (el) => {
    const style = el.getAttribute('style');
    if (!style) {
      return 
    }
    const styleArray = style.split(':');
    const styleCollection: { key: string, value: string }[] = [];
    styleArray.forEach((x, ind) => {
      if (ind % 2 === 0) {
        styleCollection.push({
          key: x.trim(),
          value: ''
        })
        return;
      }
      const lastElement = _.last(styleCollection)
      if (lastElement)
        lastElement.value = x.trim();
    })
    const data = _.mapValues(_.keyBy(styleCollection, 'key'), 'value')
    return {
      data
    }
  },
  customInlineFn: (el,inlineCreators) => {
    const attr = el.getAttribute('style')
    if(!attr)
      return null

    const attrArr = attr.split(';');
    const trimmedAttrArr = attrArr.map(x=> {
     const arr = x.split(":").map(y=>{
      return y.trim().replace("px",'').replaceAll('-','')
    });
      return arr.join('-');
    });
    const style = inlineCreators.Style(trimmedAttrArr.join(";")) 

    if(!style)
      return null

    return style;
  }

}