import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Header, Modal, Segment, Table } from "semantic-ui-react";
import { JobPosition } from "../../models/Forms/OvertimeWorkInterface";
import { City } from "../../models/Regions/City";

interface Props {
  closeModal: () => void;
  id: string | undefined;
  arr: {
    key: string;
    value:
      | string
      | number
      | Date
      | null
      | undefined
      | string[]
      | number[]
      | JobPosition[]
      | City[];
  }[];
}

export default function HistoryModal({ closeModal, arr }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Header icon="wpforms" content={t("form-view")} />
      <Modal.Content>
        <Segment>
          <Table basic="very" celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("key")}</Table.HeaderCell>
                <Table.HeaderCell>{t("value")}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <>
              {arr.map((x) => (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4" image>
                        <Header.Content>{t(x.key)}</Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{x.value?.toString()}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}
            </>
          </Table>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          content={t("cancel")}
          onClick={() => {
            closeModal();
          }}
        />
      </Modal.Actions>
    </>
  );
}
