import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { NavigationItem } from "../../../../models/CMS/Homepage/navigationItem";
import { Languages } from "../../../../common/types/CustomTypes";
import { useNavigate } from "react-router-dom";

interface Props {
  x: NavigationItem;
  language: Languages;
}

export const HeaderInfoItemDesktop = ({ x, language }: Props) => {
  const navigate = useNavigate();
  return (
    <li
      className={x.children && x.children.length > 0 ? "dropdown" : ""}
      onClick={(ev) => {
        ev.stopPropagation();
        navigate(x.linkPath);
      }}
    >

      {x.translations?.find((x) => x.languageId === language)?.title}
      <MdKeyboardArrowDown
        size={15}
        style={{
          display: x.children && x.children.length > 0 ? "block" : "none",
        }}
      />

      
      {x.children && x.children.length > 0 && (
        <ul className={`dropdown-menu`}>
          {x.children
            .sort((a, b) => a.order - b.order)
            .map((y) => (
              <li key={y.id}
                onClick={(ev) => {
                  ev.stopPropagation();
                  navigate(y.linkPath);
                }}
              >
                
                {y.translations?.find((z) => z.languageId === language)?.title}
              </li>
            ))}
        </ul>
      )}
    </li>
  );
};
