import React from 'react'
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import { useStore } from '../../../app/stores/store';
import ElearningModal from '../components/ElearningModal';
import NumbersDiagramItem from '../components/NumbersDiagram/NumbersDiagramItem';

interface Props {
    slideContents?: eSlideContent[];
    colorSchemeArray: string[];
}

export default function NumbersDiagram({ slideContents, colorSchemeArray }: Props) {

    const header = slideContents?.find(x => x.type.typeName === 'Header')?.body;
    const numbersDiagram = sortedContentArray(slideContents, 'NumberDiagrams-Item');
    const { modalStore } = useStore();
    const { openModal } = modalStore;
    const backgroundColor = colorSchemeArray[0];

    return (
        <>
            <div className="slide-img-par-1">
                <h1 className="slide-header">{header}</h1>
                <div className="slide-header-line" style={{backgroundColor}}> </div>
                <div style={{ marginTop: '50px', marginBottom: '30px' }}>
                    {numbersDiagram.map((x, i) => (
                        <NumbersDiagramItem
                            onClick={() => openModal(<ElearningModal color={backgroundColor} backgroundColor={backgroundColor} 
                            contentHeader={`${x.header}`} content={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll(" •", " ")} />)}
                            offsetWidth={(i * 70) + 400}
                            text={`${x.header}`}
                            backgroundColor={colorSchemeArray[i % colorSchemeArray.length]}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}