import React, { useEffect } from "react";
import { FormikHelpers } from "formik";
import {
  Segment,
  Grid,
  GridColumn,
} from "semantic-ui-react";

import { WorkOnHolidayInterface } from "../../app/models/Forms/WorkOnHolidayInterface";
import { FormPDF } from "./pdf/FormPDF";
import { PdfFormFieldInfo } from "./pdf/FormPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useStore } from "../../app/stores/store";
import moment from "moment-timezone";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../app/layout/LoadingComponent";
import agent from "../../app/api/agent";
import { useNavigate } from "react-router-dom";
import "./FormsModal.css";
import { toast } from "react-toastify";
import HolidayForm from "./commonForms/Reports/HolidayForm";

export default observer(function WorkOnHolidayForm() {
  const { modalStore, codesStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { citiesDropdown, loadCities, loadNkds, nkdDropdown } = codesStore;
  const navigate = useNavigate();

  useEffect(() => {
    loadCities();
    loadNkds();
  }, [loadCities, loadNkds]);

  if (!citiesDropdown || !nkdDropdown) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: WorkOnHolidayInterface,
    actions: FormikHelpers<WorkOnHolidayInterface>
  ) => {
    try {
      await agent.Forms.workOnHoliday(values);
    } catch {
      toast.error("Неуспешно испратено известување");
      return;
    }
    const city = citiesDropdown.find((x) => x.value === values.cityId)?.text;

    const occupation = nkdDropdown.find(
      (x) => x.value === values.employerOccupation
    )?.description;

    const infoArray: PdfFormFieldInfo[] = [
      {
        inputMainLabel: "1. Назив на работодавачот:",
        inputValue: values.employerName,
      },
      {
        inputMainLabel: "2. Седиште на работодавачот:",
      },
      {
        inputLabel: "Улица, број, место:",
        inputValue: values.employerAddress,
      },
      {
        inputLabel: "Општина:",
        inputValue: values.employerMunicipality,
      },
      {
        inputLabel: "Поштенски број:",
        inputValue: values.employerPostalCode,
      },
      {
        inputMainLabel: "3. Дејност на работодавачот:",
        inputValue: occupation?.toString(),
      },
      {
        inputMainLabel:
          "4. Локација на работното место каде што ќе се извршува работата: ",
        inputValue: values.workplaceLocation,
      },
      {
        inputMainLabel:
          "5. Време на извршување на работа на државен празник (датум и час)",
        inputLabel: "Почеток на работно време:",
        inputValue: moment
          .utc(values.startTime)
          .local()
          .format("D MMMM YYYY, h:mm A")
          .toString(),
      },
      {
        inputLabel: "Крај на работно време:",
        inputValue: moment
          .utc(values.endTime)
          .local()
          .format("D MMMM YYYY, h:mm A")
          .toString(),
      },
      {
        inputMainLabel:
          "6. Организациона единица во која ќе се извршува работа на државен празник:",
        inputValue: values.organizationalUnit,
      },
      {
        inputMainLabel:
          "7. Работни места на кои ќе се работи на државен празник:",
        inputValue: values.workPlaces
          .map((x) => {
            return (
              "Работно место: " +
              x.jobPosition +
              `      Број на вработени: ` +
              x.employeesNumber
            );
          })
          .join(" \n "),
      },
      {
        inputMainLabel: "Пополнето од:",
      },
      {
        inputLabel: "Име и презиме",
        inputValue: values.submitterFullName,
      },
      {
        inputLabel: "Работно место",
        inputValue: values.submitterWorkPosition,
      },
      {
        inputLabel: "Телефонски број",
        inputValue: values.submitterPhoneNumber,
      },
      {
        inputLabel: "E-mail адреса",
        inputValue: values.submitterEmail,
      },
    ];
    openModal(
      <Segment placeholder>
        <Grid>
          <GridColumn>
            <div>
              <PDFDownloadLink
                document={
                  <FormPDF
                    values={infoArray}
                    certificationLabel={
                      "место за заверка на Државниот инспекторат за труд"
                    }
                    institutionLabel={"Државен инспекторат за труд"}
                    regionLabel={"Подрачје:"}
                    regionValue={`Град ${city}`}
                    subtitle={
                      "(Член 148 Став 3 од Законот за работните односи „Службен весник на Република Македонија“ бр.25/13)"
                    }
                    title={"ИЗВЕСТУВАЊЕ ЗА РАБОТА НА ДРЖАВЕН ПРАЗНИК"}
                  />
                }
                fileName="РаботаПразник.pdf"
                className="btn-form-submit"
              >
                <p
                  onClick={(e) => e.preventDefault()}
                  className="text-form-modal"
                >
                  Дали сакате да превземете во PDF-формат?
                </p>
                <button
                  className="button-form-modal"
                  onClick={() => {
                    navigate("/");
                    // closeModal();
                  }}
                >
                  Превземи
                </button>
              </PDFDownloadLink>
            </div>
          </GridColumn>
        </Grid>
        <div className="close-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            X
          </button>
        </div>
      </Segment>
    );
  };

  return (
    <HolidayForm
      handleSubmit={handleSubmit}
      citiesDropdown={citiesDropdown}
      nkdDropdown={nkdDropdown}
    />
  );
});
