import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { PostValues } from "../../../../app/models/CMS/post";
import { useStore } from "../../../../app/stores/store";
import PostForm from "../components/PostForm";

const singularName = "Announcement";
const pluralName = "Announcements";
const linkPath = "/admin/announcements";
const postType = "announcement";

export default observer(function AnnouncementCreate() {
  const {
    postStore: { createPost },
  } = useStore();
  const navigate = useNavigate();

  const onSubmit = (values: PostValues) => {
    createPost(values).then(() => navigate(`${linkPath}/list`));
  };
  const { t } = useTranslation();

  return (
    <div>
      <AdminHeader
        header={t("create-announcement")}
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <Segment>
        <PostForm
          goBack={() => navigate(`${linkPath}/list`)}
          postType={postType}
          onSubmit={onSubmit}
          singularName={singularName}
        />
      </Segment>
    </div>
  );
});
