import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { PostValues } from "../../../../app/models/CMS/post";
import { useStore } from "../../../../app/stores/store";
import PostForm from "../components/PostForm";
import { useTranslation } from "react-i18next";

export default observer(function PostCreate() {
  const { t } = useTranslation();
  const {
    postStore: { createPost },
  } = useStore();
  const navigate = useNavigate();

  const onSubmit = (values: PostValues) => {
    createPost(values).then(() => navigate("/admin/posts/list"));
  };

  return (
    <div>
      <AdminHeader
        header={t("create-post")}
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <Segment>
        <PostForm
          goBack={() => navigate("/admin/posts/list")}
          postType="post"
          onSubmit={onSubmit}
          singularName="Post"
        />
      </Segment>
    </div>
  );
});
