import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { FaFileDownload } from "react-icons/fa";
import { Button, Grid, Segment } from "semantic-ui-react";
import agent from "../../../app/api/agent";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { Accident } from "../../../app/models/Reports/Accident";
import { NightShift } from "../../../app/models/Reports/NightShift";
import { Overtimework } from "../../../app/models/Reports/Overtimework";
import { StartingActivity } from "../../../app/models/Reports/StartingActivity";
import { WorkOnHolidays } from "../../../app/models/Reports/WorkOnHolidays";
import { useStore } from "../../../app/stores/store";
import MyDateInput from "../../forms/inputs/MyDateInput";

const initialValues: WorkOnHolidays = {
  dateFrom: null,
  dateTo: null,
};

export default function WorkOnHolidaysReport() {
  const { t } = useTranslation();
  return (
    <>
      <AdminHeader
        header={t("work-on-holiday-report")}
        icon="file excel outline"
      />

      <Grid style={{ marginTop: "10px" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            const params = new URLSearchParams();
            if (values.dateFrom) {
              params.append(
                "dateFrom",
                moment(values.dateFrom).format("YYYY-MM-DD")
              );
            }
            if (values.dateTo) {
              params.append(
                "dateTo",
                moment(values.dateTo).format("YYYY-MM-DD")
              );
            }

            agent.Reports.downloadCsv("workonholidays", params);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Segment raised>
                <div>
                  <MyDateInput
                    name="dateFrom"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    dropdownMode="select"
                    placeholderText={t("from")}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <MyDateInput
                    name="dateTo"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    dropdownMode="select"
                    placeholderText={t("to")}
                  />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <Button
                    type="submit"
                    fluid
                    style={{
                      marginBottom: "15px",
                      background: "rgb(161, 7, 5)",
                      color: "white",
                    }}
                    icon={<FaFileDownload size={18} />}
                  ></Button>
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
