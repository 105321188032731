import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import AccordionForm from "./AccordionItemForm";
import AdminHeader from "../../../AdminHeader";
import { AccordionItem } from "../../../../../models/CMS/Plugin/accordionItem";
import { useStore } from "../../../../../stores/store";
import LoadingComponent from "../../../../LoadingComponent";

export default observer(function AccordionItemEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    accordionStore: {
      updateAccordionItem,
      loadAccordionItem,
      selectedAccordionItem,
      clearAccordionItem,
    },
  } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) loadAccordionItem(id);

    return () => clearAccordionItem();
  }, [loadAccordionItem, id, clearAccordionItem]);

  const onSubmit = (values: AccordionItem) => {
    updateAccordionItem(values).then(() => {
      navigate(
        `/admin/plugins/accordion/edit/${selectedAccordionItem?.acordionId}`
      );
    });
  };

  if (!id || !selectedAccordionItem) {
    navigate("/admin/plugins/accordion/list");
    return <LoadingComponent />;
  }

  return (
    <>
      <AdminHeader
        header={t("edit-accordion-item")}
        icon="list alternate outline"
      />
      <AccordionForm
        onSubmit={onSubmit}
        initialValues={selectedAccordionItem}
      />
    </>
  );
});
