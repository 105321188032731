import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../../../AdminHeader";
import { AccordionItem } from "../../../../../models/CMS/Plugin/accordionItem";
import { useStore } from "../../../../../stores/store";
import AccordionItemForm from "./AccordionItemForm";
import { v4 } from "uuid";
import LoadingComponent from "../../../../LoadingComponent";

export default observer(function AccordionItemCreate() {
  const { id } = useParams();
  const {
    accordionStore: { createAccordionItem },
  } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = (values: AccordionItem) => {
    createAccordionItem(values).then((x) => {
      navigate(`/admin/plugins/accordion/edit/${values.acordionId}`);
    });
  };
  if (!id) {
    navigate("/admin/plugins/accordion/list");
    return <LoadingComponent />;
  }

  return (
    <>
      <AdminHeader
        header={t("create-accordion-item")}
        icon="list alternate outline"
      />
      <AccordionItemForm
        onSubmit={onSubmit}
        initialValues={{
          id: v4(),
          acordionId: id,
          translations: [
            { content: "", header: "", id: v4(), languageId: "MK" },
            { content: "", header: "", id: v4(), languageId: "SQ" },
            { content: "", header: "", id: v4(), languageId: "EN" },
          ],
        }}
      />
    </>
  );
});
