import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Message } from "semantic-ui-react";
import AdminHeader from "../../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../../app/stores/store";
import CategoryPostTable from "../../components/CategoryPostTable";

const singularName = "Page";
const pluralName = "Pages";
const linkPath = "/admin/pages";
const postType = "page";
const postTaxonomyType = "page_categories";
const iconName = "pin";

export default observer(function PostCategoryList() {
  const {
    postCategoryStore: {
      loadPostCategories,
      deletePostCategory,
      sortedPostCategories,
      loadingInitial,
      loading,
      target,
    },
  } = useStore();

  useEffect(() => {
    loadPostCategories(postTaxonomyType);
  }, [loadPostCategories]);

  if (loadingInitial) return <LoadingComponent />;

  return (
    <>
      <AdminHeader
        header={t("page-categories")}
        icon={iconName}
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      {(!sortedPostCategories || sortedPostCategories.length === 0) && (
        <Message content="No categories found. Please Create" info />
      )}
      {!!sortedPostCategories && sortedPostCategories.length > 0 && (
        <CategoryPostTable
          sortedPostCategories={sortedPostCategories}
          submitting={loading}
          target={target || " "}
          deletePostCategory={deletePostCategory}
          postType={postType}
        />
      )}
    </>
  );
});
