import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const WorkOnHolidaySchema = yup.object().shape({
  cityId: yup.number().required("Ова поле е задолжително").nullable(),
  employerName: yup.string().required("Ова поле е задолжително").max(255, "Називот не смее да надминува повеќе од 255 карактери"),
  employerAddress: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employerMunicipality: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employerPostalCode: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employerOccupation: yup
    .number()
    .required("Ова поле е задолжително")
    .nullable(),
  workplaceLocation: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  startTime: yup.date().required("Одберете датум и време").nullable(),
  endTime: yup.date().required("Одберете датум и време").nullable(),
  organizationalUnit: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  workPlaces: yup.array().of(
    yup.object().shape({
      jobPosition: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
      employeesNumber: yup
        .number()
        .positive("Бројот на работници мора да биде позитивен број")
        .integer()
        .required("Ова поле е задолжително")
        .nullable(),
    })
  ),
  submitterFullName: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  submitterWorkPosition: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  submitterPhoneNumber: yup
    .string()
    .matches(phoneRegExp, "Внесете валиден телефонски број")
    .required("Ова поле е задолжително").max(50, "Телефонскиот број не смее да надминува повеќе од 50 карактери"),
  submitterEmail: yup
    .string()
    .email("Внесете валидна e-mail адреса")
    .required("Ова поле е задолжително").max(100, "Електронската пошта не смее да надминува повеќе од 100 карактери"),
  // date: yup.date().required("Одберете датум").nullable(),
});
