import { User } from "../../../../app/models/user";
import React from "react";
import { Link } from "react-router-dom";
import { Table, ButtonGroup, Button } from "semantic-ui-react";
import { SyntheticEvent } from "react";

interface Props {
  user: User;
  submitting: boolean;
  target: string;
  deleteUser: (event: SyntheticEvent<HTMLButtonElement>, id: string) => void;
}

const UserTableRow = ({ user, submitting, target, deleteUser }: Props) => {
  return (
    <Table.Row key={user.id}>
      <Table.Cell>{`${user.firstName} ${user.lastName}`}</Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell>{user.role}</Table.Cell>
      <Table.Cell>{user.regionBzr?.regionName}</Table.Cell>
      <Table.Cell>{user.regionRo?.regionName}</Table.Cell>
      <Table.Cell>
        <ButtonGroup>
          <Button
            as={Link}
            to={`/admin/users/edit/${user.id}`}
            icon="edit"
            inverted
            color="green"
          />
          <Button
            name={user.id}
            loading={target === user.id && submitting}
            onClick={(e) => {
              deleteUser(e, user.id);
            }}
            icon="close"
            inverted
            color="red"
          />
        </ButtonGroup>
      </Table.Cell>
    </Table.Row>
  );
};

export default UserTableRow;
