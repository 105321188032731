import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { Segment, ButtonGroup, Button, Table } from "semantic-ui-react";
import HistoryModal from "../../../app/common/modals/HistoryModal";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  StartingActivity,
  StartingActivityInterface,
} from "../../../app/models/Forms/StartingActivityInterface";
import { useStore } from "../../../app/stores/store";

export default observer(function StartingActivityHistory() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { codesStore, formStore, modalStore } = useStore();
  const { citiesDropdown, loadCities } = codesStore;
  const {
    selectedStartingActivityForm,
    loadSingleStartingActivityForm,
    loadingInitial,
    loadHistoryStartingActivityForm,
    selectedStartingActivityHistoryForm,
  } = formStore;
  const { openModal, closeModal } = modalStore;

  useEffect(() => {
    if (id)
      loadSingleStartingActivityForm(id).then(() =>
        loadHistoryStartingActivityForm(id)
      );

    loadCities();
  }, [loadSingleStartingActivityForm, loadCities, id, loadHistoryStartingActivityForm]);

  if (loadingInitial || !selectedStartingActivityHistoryForm) {
    return <LoadingComponent />;
  }

  return (
    <>
      <AdminHeader header={t("history-starting-activity")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedStartingActivityForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedStartingActivityForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedStartingActivityForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <Segment>
        <Table basic="very" celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("submission-time")}</Table.HeaderCell>
              <Table.HeaderCell>{t("view")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <>
            <Table.Body>
              {selectedStartingActivityHistoryForm.detailsHistory.map((x) => {
                const arr = new StartingActivity(x).toStringArray();
                return (
                  <Table.Row key={x.id}>
                    <Table.Cell>
                      {x.userName ? x.userName : t("original-submitter")}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(x.dateSubmitted).format("DD.MM.YYYY - HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        content={t("view")}
                        color="blue"
                        onClick={() =>
                          openModal(
                            <HistoryModal
                              closeModal={closeModal}
                              id={x.id}
                              arr={arr}
                            />
                          )
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </>
        </Table>
      </Segment>
    </>
  );
});
