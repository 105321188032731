import * as React from 'react';
import { Card, Header,Image } from 'semantic-ui-react';

interface Props {
 id: string;
 imageName: string;
 imagePath: string;
 imagePathOrigin: string;
 extraContent?: ((id:string, imagePath?: string)=> JSX.Element ) ;
 extraContentName?: ((contentName:string)=> JSX.Element ) ;
 type? : number;
}

export default function PhotoCard ({
  id,
  imageName,
  imagePath,
  imagePathOrigin,
  extraContent,
  extraContentName,
  type
}: Props) {
 return ( 
    <Card key={id}>
      <Card.Header>
        <Header textAlign='center' as="h4">{imageName}</Header>
      </Card.Header>
           <Card.Content>
             <Image size='large' src={type && type === 3 ? '/media/public/pdf.png' :imagePath} style={{maxHeight: '100px', display:'flex',  justifyContent:"center"}}
                href={imagePathOrigin}
                target='_blank'
             />
           </Card.Content>
        {extraContent && extraContent(id, imagePath)}   
        {extraContentName && extraContentName(imageName)}   
     </Card>
   )
}