import { CMSMedia } from "../Media/media";

export enum ContentPartTypes{
  NORMAL= 'normal',
  DIRECTOR_INFO= 'DirectorInfo',
  CONTACT_INFO= 'ContactsInfo',
  ACCORDION= 'Accordion',
  BZR_MAP= 'BzrMap',
  RO_MAP= 'RoMap',
  BUTTON= 'Button',
  IFRAME= 'IFrame'
}

export interface ButtonInfo {
  name: string;
  link: string;
}

export interface IFrameInfo {
  src: string;
  height: string;
  width: string;
}

export interface  ContactsInfoLocation {
  id: string;
  name: string;
  contactsInfos?: ContactsInfo[];
}

export interface ContactsInfo {
  email: string;
  id: string;
  name: string;
  orgUnit: string;
  position: string;
  orderOfAppearance:number;
  telephone: string;
  vocation: string;
  infoLocationId: string
}

export interface DirectorInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  excerpt?: string;
  directorPhotoId?: string;
  directorPhoto?: CMSMedia;
}

export interface Shortcode {
  type: string;
  jsonInfo: string;
}

export interface HtmlContentPart {
  type: string;
  parameter?: string;
  paramsObject?: URLSearchParams;
  text?: string;
  info?: unknown;
  handled?: boolean;
  order: number;
}

function getScType(shortcode: string) {
  return captureRegexFromString(shortcode,/SC:([^(]*)/);
}

function createParamsObj(part: HtmlContentPart) {
  const params = new URLSearchParams();
  params.append("type", part.type);
  if (part.parameter)
    params.append("id", part.parameter || "");
  return params;
}

function isShortcode(text: string) {
  const regex = /^SC:/;
  return regex.test(text);
}
function scContainsParameter(text: string) {
  const regex = /[(].*[)]/
  return regex.test(text);
}
function extractScParam(text: string) {
  return captureRegexFromString(text,/\((.*?)\)/);
}

function captureRegexFromString (text: string, regex : RegExp) {
  const match = text.match(regex);
  if(!match){
    throw new Error("Error finding regex param");
  }
  return match[1];
}


export class HtmlContentPart implements HtmlContentPart {
  handled?: boolean | undefined = false;

  constructor(text: string, index: number) {
    this.order = index + 1;
    const isSc = isShortcode(text);
    if(!isSc) {
      this.type = ContentPartTypes.NORMAL;
      this.text = text;
      this.handled=true;
      return;
    }

    const type = getScType(text);
    this.type = type;

    const isMapType = type === ContentPartTypes.BZR_MAP
     || type === ContentPartTypes.RO_MAP
  
    if (isMapType) {
      this.handled = true;
      return;
    }
    const isButtonType = type === ContentPartTypes.BUTTON;

    const containsParam = scContainsParameter(text);

    if (containsParam) {
      this.parameter = extractScParam(text);
    }

    if(isButtonType) {
      this.handled = true;
      if(!this.parameter){
        return;
      }
      const [name, link] = this.parameter.split(',');
      this.info = {name,link} ;
      return;
    }

    const isIFrameType = type === ContentPartTypes.IFRAME;

    if(isIFrameType) {
      this.handled = true;
      if(!this.parameter){
        return;
      }
      const [src, height, width] = this.parameter.split(',');
      this.info = {src, height, width};
      return;
    }

    this.paramsObject = createParamsObj(this);

  }
}