import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { MessageInterface } from "../../models/HomePage/MessageInterface";
import MyTextInput from "../../../features/forms/inputs/MyTextInput";
import MyTextArea from "../../../features/forms/inputs/MyTextArea";
import "./ContactMessage.css";

const initialValues: MessageInterface = {
  name: "",
  email: "",
  title: "",
  message: "",
};
const messageSchema = yup.object().shape({
  name: yup.string().required("Ова поле е задолжително"),
  email: yup
    .string()
    .email("Внесете валидна e-mail адреса")
    .required("Ова поле е задолжително"),
  title: yup.string().required("Ова поле е задолжително"),
  message: yup.string().required("Ова поле е задолжително"),
});

export const ContactMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="contact-message">
      <Formik
        initialValues={initialValues}
        validationSchema={messageSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
          }, 400);
          console.log("entered values", values);
          actions.resetForm();
        }}
      >
        <Form autoComplete="off" className="inputs_container">
          <ul>
            <li>
              <MyTextInput name="name" type="text" placeholder={t("name*")} />
            </li>
            <li>
              <MyTextInput name="email" type="email" placeholder="E-mail *" />
            </li>
            <li>
              <MyTextInput name="title" type="text" placeholder={t("title")} />
            </li>
            <li>
              <MyTextArea name="message" rows={7} placeholder={t("message")} />
            </li>
          </ul>
          <button type="submit">{t("Send")}</button>
        </Form>
      </Formik>
    </div>
  );
};
