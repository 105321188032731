import React from "react";
import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";
import _ from "lodash";

interface Props {
  placeholder?: string;
  name: string;
  label: string;
  type?: string;
  info?: string;
  inputStyle?: React.CSSProperties
}

export default function LabelledInput(props: Props) {
  const [field, meta] = useField(props.name);

  return (
    <>
    <Form.Field error={meta.touched && !!meta.error}>
      <div className="ui labeled focus input">
        <div className="ui label">
          {props.label}
        </div>
        <input
          style={props.inputStyle}
          {...field}
          {..._.omit(props,'inputStyle')}
          value={field.value}
          className="input-forms"
          autoComplete="off"
        />
      </div>

      {meta.touched && !!meta.error ? (
        <Label basic color="red" style={{ marginTop: "3px" }}>
          {meta.error}
        </Label>
      ) : null}

    </Form.Field>
    <br />
    </>
  )

}