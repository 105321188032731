import React from 'react'
import { Grid, Image} from 'semantic-ui-react'
import "./FormsLogo.css";

const FormsLogo = () => {
  return (
    <>
    <Grid className='forms-logo'>
    <Image
      src={"/images/logo.png"}
      className="forms-img"
    />
    <div className='forms-logo-text' style={{padding: "0"}}>
      <span>Република Северна Македонија</span> <br />
      <span> Republika e Maqedonisë së Veriut</span>
      <br />
      <span className='overline-forms-logo'>
        Министерство за труд и социјална политика <br />
      </span>
      <span style={{ fontWeight: "900" }}>
        Ministria e Punës dhe Politikës Sociale
      </span>
    </div>
  </Grid>
  </>
  )
}

export default FormsLogo