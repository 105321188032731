import { action, makeAutoObservable, reaction } from "mobx";
import { DropdownItemProps, DropdownProps } from "semantic-ui-react";
import i18n from "../../i18n/config";
import { LanguageEnum, Languages } from "../common/types/CustomTypes";
import { ServerError } from "../models/serverError";

export default class CommonStore {
  error: ServerError | null = null;
  language: Languages = (localStorage.getItem("language") as Languages) || LanguageEnum.MK;

  languageDropdown : DropdownItemProps[] = [
    {
      value: LanguageEnum.MK,
      text: LanguageEnum.MK
    }, {
      value: LanguageEnum.EN,
      text: LanguageEnum.EN
    }, {
      value: LanguageEnum.SQ,
      text: LanguageEnum.SQ
    },
]
  languageExists = (lang: string) => {
    if(lang === LanguageEnum.MK || 
      lang === LanguageEnum.EN ||
      lang === LanguageEnum.SQ
    ) return true;

    return false
  } 

  token: string | null = window.localStorage.getItem('jwt');
  appLoaded = false;
  
  constructor(){
    makeAutoObservable(this, {
      selectLanguage : action.bound
    });
    reaction
    (()=> this.token,
      token => {
        if(token){
          window.localStorage.setItem('jwt',token);
        } else 
        {
          window.localStorage.removeItem('jwt');
        }
      }
    )
  }

  selectLanguage(language: Languages) {
    localStorage.setItem("language", language);
    this.language = language;
  }

  setServerError(error: ServerError){
    this.error = error;
  }

  setToken = (token: string | null) => {
    this.token = token;
  }

  setAppLoaded = ()=>{
    this.appLoaded = true;
    i18n.changeLanguage(this.language.toLocaleUpperCase() || LanguageEnum.MK);
    sessionStorage.setItem('loaded','true');
  }

  movingUp: string = '';
  movingDown: string = '';

  setMovingUp = (id: string) => {
    this.movingUp = id;
  }

  setMovingDown = (id: string) => {
    this.movingDown = id;
  }
  
  moveUp = (id: string, idSwap: string, callback: (id: string) => Promise<void>) => {
    this.setMovingUp(id);
    this.setMovingDown(idSwap);
    setTimeout(() => {
      callback(id).then(() => {
        this.setMovingUp('')
        this.setMovingDown('')
      })
    }, 1000)
  }

  moveDown = (id: string, idSwap: string, callback: (id: string) => Promise<void>) => {
    this.setMovingDown(id);
    this.setMovingUp(idSwap);
    setTimeout(() => {
      callback(id).then(() => {
        this.setMovingUp('')
        this.setMovingDown('')
      })
    }, 1000)
  }


}