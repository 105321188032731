import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { FaFileDownload } from "react-icons/fa";
import { Button, Grid, Segment } from "semantic-ui-react";
import agent from "../../../app/api/agent";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { Grievance } from "../../../app/models/Reports/Grievance";
import { useStore } from "../../../app/stores/store";
import MyDateInput from "../../forms/inputs/MyDateInput";
import MySelectInput from "../../forms/inputs/MySelectInput";

const initialValues: Grievance = {
  dateFrom: null,
  dateTo: null,
  isBzr: null,
  statusId: null,
  isSubmitterKnown: null,
};

export default observer(function GrievancesReprot() {
  const { codesStore } = useStore();
  const { loadCities, status, loadStatus } = codesStore;
  const { t } = useTranslation();

  useEffect(() => {
    loadStatus();
  }, [loadCities, loadStatus]);

  const isSubmitterKnown = [
    { id: 1, text: t("known"), value: 1 },
    { id: 2, text: t("unknown"), value: 2 },
  ];

  const isBzr = [
    { id: 1, text: t("bzr"), value: 1 },
    { id: 2, text: t("ro"), value: 2 },
  ];

  return (
    <>
      <AdminHeader header={t("grievance-report")} icon="file excel outline" />

      <Grid style={{ marginTop: "10px" }}>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              const params = new URLSearchParams();
              if (values.dateFrom) {
                params.append(
                  "dateFrom",
                  moment(values.dateFrom).format("YYYY-MM-DD")
                );
              }
              if (values.isBzr) {
                params.append("isBzr", values.isBzr.toString());
              }
              if (values.isSubmitterKnown) {
                params.append(
                  "isSubmitterKnown",
                  values.isSubmitterKnown.toString()
                );
              }
              if (values.dateTo) {
                params.append(
                  "dateTo",
                  moment(values.dateTo).format("YYYY-MM-DD")
                );
              }
              if (values.statusId) {
                params.append("statusId", values.statusId.toString());
              }

              agent.Reports.downloadCsv("grievances", params);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Segment raised>
                  <div>
                    <MyDateInput
                      name="dateFrom"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      dropdownMode="select"
                      placeholderText={t("from")}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <MyDateInput
                      name="dateTo"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      dropdownMode="select"
                      placeholderText={t("to")}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <MySelectInput
                      options={isSubmitterKnown || []}
                      label={t("known-submitter")}
                      name="isSubmitterKnown"
                      placeholder={t("known-submitter")}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <MySelectInput
                      options={status || []}
                      label={t("form-status")}
                      name="statusId"
                      placeholder={t("please-enter-a-form-status")}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <MySelectInput
                      options={isBzr || []}
                      label={t("type")}
                      name="isBzr"
                      placeholder={t("type")}
                    />
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <Button
                      type="submit"
                      fluid
                      style={{
                        marginBottom: "15px",
                        background: "rgb(161, 7, 5)",
                        color: "white",
                      }}
                      icon={<FaFileDownload size={18} />}
                    ></Button>
                  </div>
                </Segment>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
    </>
  );
});
