import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { IsotopeGridItem } from "../../../../app/models/CMS/Homepage/isotope";
import { useStore } from "../../../../app/stores/store";
import IsotopeForm from "./shared/IsotopeForm";

export default observer(function IsotopeEdit() {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    isotopeStore: {
      loadSingleIsotopeGridItem,
      selectedIsotope,
      clearSelectedIsotope,
      loadingInitial,
      updateIsotope,
    },
    mediaStore: { mediaList },
  } = useStore();
  const [selectedPhotoId, setSelectedPhotoId] = useState<string | null>(null);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (id) {
      loadSingleIsotopeGridItem(id);
    } else {
      navigate("/admin/homepage/isotope/list");
    }

    return () => clearSelectedIsotope();
  }, [id, loadSingleIsotopeGridItem, navigate, clearSelectedIsotope]);

  if (loadingInitial || !selectedIsotope) {
    return <LoadingComponent />;
  }

  const onSubmit = (values: IsotopeGridItem) => {
    const newPhoto = mediaList.find((x) => x.id === selectedPhotoId);
    if (newPhoto) {
      values.imagePath = newPhoto.path;
      values.photoId = newPhoto.id;
    }
    updateIsotope(values);
  };

  return (
    <>
      <AdminHeader header={t("edit-isotope-grid-item")} icon="wizard" />
      <IsotopeForm
        onSubmit={onSubmit}
        initialValues={selectedIsotope}
        selectedPhotoId={selectedPhotoId}
        setSelectedPhotoId={setSelectedPhotoId}
      />
    </>
  );
});
