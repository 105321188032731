import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { Button, Card, Container, Divider, Grid, Header, Segment } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import ModuleDetails from "./ModuleDetails";
import './Learning.css';
import ModuleCard from "./ModuleCard";
import ELearningHeader from "./ELearningHeader";
import ELearningStickyHeader from "./ELearningStickyHeader";

export default observer(function LearningTraining() {
  const { id } = useParams();
  const { learningStore: {
    learningTrainingOnload,
    moduleProgressPercent,
    selectedModule,
    setSelectedModuleById,
    selectedTraining,
    loadingInitial,
    moduleLocked,
    sortedModules } } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      learningTrainingOnload(id);
    } else {
      navigate('/e-learning/dashboard');
    }
  }, [id, navigate, learningTrainingOnload])

  if (loadingInitial) {
    return <LoadingComponent />
  }

  const popupContent = `Овој модул е недостапен
  бидејќи претходниот модул не е завршен.
  Продолжете со модулите редоследно`;


  return (
  <>
  <ELearningStickyHeader />
  <Container style={{ marginTop: '20px' }}>

    <ELearningHeader
      content={`Добре дојдовте на обуката: ${selectedTraining?.trainingName} `}
    />

    <Segment className="learning-training">
      <Grid stackable style={{background:'whitesmoke'}}>
        <Grid.Column width={4} style={{background:'whitesmoke'}}>
          <Segment style={{border:'none', background:'whitesmoke'}}>
            <Button fluid onClick={() => {
              navigate('/e-learning/dashboard')
            }} content="Назад" icon="chevron left" labelPosition="left" color="red"  />
          </Segment>
          <Segment style={{background:'whitesmoke'}}>
            <Header style={{border:'none', background:'whitesmoke'}} as="h2" attached="top" textAlign="center" content="Добре дојдовте на екранот за обука" />
            <Divider style={{background:'#2185d0'}}/>
            <br />
            <p>
              За да продолжите со обуката, ве молиме да го изберете модулот кој сакате да го разгледате повторно или да одберете продолжете.
            </p>
            {/* <p>
              To proceed with the training please choose the module that you wish to revisit, or
              choose continue.
            </p> */}
          </Segment>
        </Grid.Column>
        <Grid.Column width={8} style={{background:'whitesmoke'}}>
          <Segment style={{ minHeight: "500px", border:'none',background:'whitesmoke' }}>
          <div style={{ minHeight: "500px" }}>

            <Card.Group style={{ gap: '5px' }}>
              {sortedModules.map((x, i) => <ModuleCard
                finished={moduleLocked(x.id)}
                desc={x.moduleDesc}
                name={x.moduleName}
                popupContent={popupContent}
                key={x.id}
                locked={moduleLocked(x.id)}
                onClick={() => setSelectedModuleById(x.id)}
                selected={x.id === selectedModule?.id}
              />)}
            </Card.Group>
          </div>
          </Segment>
        </Grid.Column>
        {selectedModule && <Grid.Column style={{background:'whitesmoke'}} width={4}>
          <ModuleDetails
            progress={moduleProgressPercent || 0}
            module={selectedModule} />
        </Grid.Column>}
      </Grid>
    </Segment>
  </Container>
  {/* <ELearningStickyFooter /> */}
  </>
  )
})