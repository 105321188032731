import HeroMenuItem from "./HeroMenuItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./HeroMenu.css";

export default function HeroMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="hero-menu">
      <ul>
        <HeroMenuItem
          backgroundColor="rgb(189, 12, 32)"
          imageSrc="/images/icons/text_1.png"
          imageAlt="text"
          text={t("news")}
          onClick={() => {
            navigate("/category/post/aktuelno");
          }}
        />
        <HeroMenuItem
          backgroundColor="rgb(153, 156, 161)"
          imageSrc="/images/icons/ads.png"
          imageAlt="ads"
          text={t("Announcements")}
          onClick={() => {
            navigate("/single/post/soopstenija");
          }}
        />
        <HeroMenuItem
          backgroundColor="rgb(41, 44, 49)"
          imageSrc="/images/icons/report.png"
          imageAlt="submitting"
          text={t("grievance-known-submitter")}
          onClick={() => {
            navigate("/forms/known-submitter");
          }}
        />
        <HeroMenuItem
          backgroundColor="rgb(221, 153, 52)"
          imageSrc="/images/icons/report.png"
          imageAlt="submitting"
          text={t("grievance-unknown-submitter")}
          onClick={() => {
            navigate("/forms/unknown-submitter");
          }}
        />
      </ul>
    </div>
  );
}
