import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import {
  Button,
  DropdownItemProps,
  DropdownProps,
  GridRow,
  Input,
  Modal,
  Select,
} from "semantic-ui-react";
import { Form } from "semantic-ui-react";
import { Grid, Header, Icon } from "semantic-ui-react";
import { FilterOption } from "../../../app/common/types/FilterOption";

interface Props {
  closeModal: () => void;
  setFilter: (filterName: string, filterValue: string) => void;
  filterOptions: FilterOption[];
}

export default function FilterModal({
  closeModal,
  setFilter,
  filterOptions,
}: Props) {
  const [selectedFilter, setSelectedFilter] = useState<FilterOption>();
  const [filterValue, setFilterValue] = useState<any>();
  const [dateFilterValue, setDateFilterValue] = useState<any>();
  const { t } = useTranslation();

  const mainFilterOptions = filterOptions.map((x) => {
    const option: DropdownItemProps = {
      text: t(x.text),
      value: x.name,
    };
    return option;
  });

  const onChange = (e: any, data: DropdownProps) => {
    if (!data.value || _.isArray(data.value)) return;
    const filter = filterOptions.find((x) => x.name === data.value);
    if (!filter) return;

    setSelectedFilter(filter);
    setFilterValue("");
  };

  const onChangeSelect = (e: any, data: DropdownProps) => {
    if (!data.value || _.isArray(data.value)) return;

    setFilterValue(data.value.toString());
  };

  return (
    <>
      <Modal.Header>
        <Icon name="filter" />
        {t("select-filter")}
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Header>{t("filter-name")}</Header>
          <Grid>
            <GridRow>
              <Grid.Column width={6}>
                <Select
                  placeholder={t("select")}
                  options={mainFilterOptions}
                  value={selectedFilter?.name}
                  onChange={onChange}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                {selectedFilter?.type === "text" && (
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={filterValue}
                    placeholder={t("enter-text")}
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                )}
                {selectedFilter?.type === "select" &&
                  selectedFilter.options && (
                    <Select
                      placeholder={t("select")}
                      options={selectedFilter.options}
                      onChange={selectedFilter.onChange || onChangeSelect}
                    />
                  )}
                {selectedFilter?.type === "date" && (
                  <ReactDatePicker
                    selected={dateFilterValue}
                    onChange={(date: Date) => {
                      setDateFilterValue(new Date(date));
                      setFilterValue(moment(date).format("YYYY-MM-DD"));
                    }}
                  />
                )}
                {selectedFilter?.type === "date" && selectedFilter.options && (
                  <Select
                    placeholder={t("select")}
                    options={selectedFilter.options}
                    onChange={selectedFilter.onChange || onChangeSelect}
                  />
                )}
              </Grid.Column>
            </GridRow>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t("cancel")}
          labelPosition="right"
          icon="close"
          onClick={() => {
            closeModal();
          }}
          color="red"
        />
        <Button
          color="green"
          icon="check"
          content="OK"
          disabled={!selectedFilter}
          onClick={() => {
            if (!selectedFilter?.name) return;

            setFilter(selectedFilter?.name, filterValue);
            closeModal();
          }}
        ></Button>
      </Modal.Actions>
    </>
  );
}
