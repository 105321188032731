import moment from "moment";
import { EnsureDate } from "../../common/types/TypeFunctions";
import { Nkd } from "../CodeLists/Nkd";
import { City } from "../Regions/City";
import { formTypesToString, FormValue } from "./SharedFunctions";

export interface WorkOnSundayInterface {
  id?:string;
  dateSubmitted?: Date | null;
  idHeader?:string;
  cityId: number | null; //ro //connected with City ID
  city?: City | null;
  employerName: string;
  employerAddress: string;
  employerMunicipality: string,
  employerPostalCode: string,
  taxNumber: string;
  embs: string;
  employerOccupation: number | null; // connected with NKD ID
  nkdCodes?: Nkd | null;
  responsibleFullname: string,
  responsibleAddress: string,
  responsibleMunicipality: string,
  responsiblePostalCode: string,
  workPlace: string;
  workProcess: string;
  contractNumber: string;
  contractDate: Date | null;
  sundayJobPositions: SundayJobPosition[];
  submitterFullname: string;
  submitterWorkPosition: string;
  submitterPhoneNumber: string;
  submitterEmail: string,
  userName?:string
  userId?:string
  // date: Date | null;
}

export class WorkOnSunday implements WorkOnSundayInterface {
  id?: string | undefined;
  dateSubmitted?: Date | null | undefined;
  userName?: string | undefined;
  userId?: string | undefined;
  idHeader?: string | undefined;
  cityId: number | null = null;
  city: City | null = null;
  employerName: string = "";
  employerAddress: string = "";
  employerMunicipality: string = "";
  employerPostalCode: string = "";
  taxNumber: string = '';
  embs: string = '';
  employerOccupation: number | null = null;
  nkdCodes: Nkd | null = null;
  responsibleFullname: string = '';
  responsibleAddress: string = '';
  responsibleMunicipality: string = '';
  responsiblePostalCode: string = '';
  workPlace: string = '';
  workProcess: string = '';
  contractNumber: string = '';
  contractDate: Date | null = null;
  sundayJobPositions: SundayJobPosition[] = [];
  submitterFullname: string = '';
  submitterWorkPosition: string = '';
  submitterPhoneNumber: string = '';
  submitterEmail: string = '';
 

  constructor(init: WorkOnSundayInterface){
    Object.assign(this,init);
    this.dateSubmitted = EnsureDate(this.dateSubmitted);
    this.contractDate = EnsureDate(this.contractDate);

  }
  filteredObjectKeys() {
    const keys = Object.keys(this);
    const filterOut = ['id', 'cityId', 'idHeader', 'userId', "employerOccupation"];
    return keys.filter(x => !filterOut.some(y => y === x));
  }

  toStringArray() {
    return this.filteredObjectKeys().map((key) => {
      let value: FormValue = this[key as keyof WorkOnSundayInterface];
      [key, value] = formTypesToString(key, value);

      return {
        key,
        value
      }
    });
  }

}


export interface SundayJobPosition
{
  jobPosition: string,
  employeesNumber: number | null,
  workDescription: string,
  reasons: string,
  startTime: Date | null,
  endTime: Date | null,
}


export interface WorkOnSundayHeader {
  id:string;
  dateSubmitted: Date | null;
  detailsHistory: WorkOnSundayInterface[];
  latestVersion: WorkOnSundayInterface;
  status: string;
  statusId: string;
 
}