import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { PostValues } from "../../../../app/models/CMS/post";
import { useStore } from "../../../../app/stores/store";
import PostForm from "../components/PostForm";

const singularName = "Page";
const pluralName = "Pages";
const linkPath = "/admin/pages";
const postType = "page";

export default observer(function PageCreate() {
  const {
    postStore: { createPost },
  } = useStore();
  const navigate = useNavigate();

  const onSubmit = (values: PostValues) => {
    createPost(values).then(() => navigate(`${linkPath}/list`));
  };

  return (
    <div>
      <AdminHeader
        header={t("create-page")}
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <Segment>
        <PostForm
          goBack={() => navigate(`${linkPath}/list`)}
          postType={postType}
          onSubmit={onSubmit}
          singularName={singularName}
        />
      </Segment>
    </div>
  );
});
