import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../../app/stores/store";
import PostPaginatedTable from "../components/PostPaginatedTable";
const postType = "page";
const singularName = "Page";
const pluralName = t("pages");
const linkPath = "/admin/pages";

export default observer(function PageList() {
  const {
    postStore: { setMultipleStaticFilterParam, clearStaticFilterParams },
    postCategoryStore: { loadPostCategories },
  } = useStore();

  const [activeItem, setActiveItem] = useState("home");
  const { t } = useTranslation();
  useEffect(() => {
    if (activeItem === "trash") {
      setMultipleStaticFilterParam([
        { name: "type", value: postType },
        { name: "postStatus", value: "trash" },
      ]);
      return;
    }

    setMultipleStaticFilterParam([
      { name: "type", value: postType },
      { name: "postStatusNot", value: "trash" },
    ]);
    loadPostCategories("page_categories");

    return () => clearStaticFilterParams();
  }, [activeItem, setMultipleStaticFilterParam, clearStaticFilterParams, loadPostCategories]);

  return (
    <div>
      <AdminHeader
        header={pluralName}
        icon={"pin"}
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <div>
        <Menu attached="top">
          <Menu.Item
            name={t("home-pages")}
            active={activeItem === "home"}
            onClick={() => setActiveItem("home")}
          />
          <Menu.Item
            name={t("trash")}
            active={activeItem === "trash"}
            onClick={() => setActiveItem("trash")}
          />
        </Menu>
        <PostPaginatedTable
          linkPath={linkPath}
          isTrash={activeItem === "trash"}
        />
      </div>
      <div style={{ textAlign: "right" }}>
        <Link to={`${linkPath}/create`} className="ui button teal">
          <Icon name="plus" />
          {t("add-new-page")}
        </Link>
      </div>
    </div>
  );
});
