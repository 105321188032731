import React from 'react'
import { Cropper } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { observer } from 'mobx-react-lite';

interface Props 
{
  setCropper : (cropper: Cropper) => void;
  imagePreview: string;
  aspectRatio: number | undefined

}

export default function PhotoWidgetCropper({setCropper,imagePreview,aspectRatio}:Props) {
  return (
    <Cropper
      src={imagePreview}
      style={{width: "100%"}}
      initialAspectRatio={aspectRatio}
      aspectRatio={aspectRatio}
      preview=".img-preview"
      guides={false}
      viewMode={1}
      autoCropArea={1}
      background={false}
      onInitialized={cropper => setCropper(cropper)}
    />
  )
}