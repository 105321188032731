import { FieldArray, Form, Formik } from "formik";
import { Button, Icon, Segment, Tab } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import MyTextInput from "../../../forms/inputs/MyTextInput";
import { v4 as uuid } from "uuid";
import { useStore } from "../../../../app/stores/store";
import { TaxonomyTerm } from "../../../../app/models/CMS/taxonomyTerm";
import MyTextArea from "../../../forms/inputs/MyTextArea";
import SelectInput from "../../../../app/common/Inputs/SelectInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
interface Props {
  initialValues?: TaxonomyTerm;
  onSubmit: (values: TaxonomyTerm) => void;
  goBack: () => void;
}

export default observer(function PostCategoryForm({
  initialValues,
  onSubmit,
  goBack,
}: Props) {
  const initValues: TaxonomyTerm = {
    id: uuid(),
    parentId: null,
    slug: "",
    taxonomySlug: "",
    translations: [
      {
        id: uuid(),
        languageId: "MK",
        termDescription: "",
        termId: null,
        termName: "",
      },
      {
        id: uuid(),
        languageId: "SQ",
        termDescription: "",
        termId: null,
        termName: "",
      },
      {
        id: uuid(),
        languageId: "EN",
        termDescription: "",
        termId: null,
        termName: "",
      },
    ],
  };
  const { t } = useTranslation();
  const {
    postCategoryStore: { parentCategoryDropdown },
  } = useStore();

  return (
    <>
      <Segment>
        <Formik
          initialValues={initialValues || initValues}
          enableReinitialize
          onSubmit={(values) => {
            values.parentId = values.parentId ? values.parentId : null;
            onSubmit(values);
          }}
        >
          {({ errors, values }) => (
            <Form autoComplete="off">
              <>
                <Segment>
                  <FieldArray name="translations">
                    {() => {
                      const translations = values.translations || [];
                      const panes = translations
                        .sort((a, b) => {
                          if (a.languageId === "MK") return -1;
                          if (b.languageId === "MK") return 1;
                          if (a.languageId === "EN") return 1;
                          if (b.languageId === "EN") return -1;

                          return 0;
                        })
                        .map((x, index) => {
                          return {
                            menuItem: x.languageId,
                            render: () => (
                              <Tab.Pane key={x.languageId}>
                                <Segment>
                                  <MyTextInput
                                    name={`translations.${index}.termName`}
                                    label={t("category-name")}
                                  />
                                  <MyTextArea
                                    name={`translations.${index}.termDescription`}
                                    label={t("category-description")}
                                  />
                                </Segment>
                              </Tab.Pane>
                            ),
                          };
                        });
                      return <Tab panes={panes} />;
                    }}
                  </FieldArray>
                  <MyTextInput name="slug" label={t("slug")} />
                  <SelectInput
                    name="parentId"
                    options={parentCategoryDropdown}
                    label={t("parent-category")}
                  />
                </Segment>
                <Segment>
                  <Button color="red" onClick={goBack}>
                    <Icon name="arrow left" />
                  </Button>
                  <Button color="teal" type="submit">
                    {t("save")}
                  </Button>
                </Segment>
              </>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
