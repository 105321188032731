import React from 'react'
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import { useStore } from '../../../app/stores/store';
import ElearningModal from '../components/ElearningModal';
import StairCaseItem from '../components/StairCase/StairCaseItem';

interface Props {
  slideContents?: eSlideContent[];
  colorSchemeArray: string[];
}

export default function StairCaseParagraph({ slideContents, colorSchemeArray }: Props) {

  const header = slideContents?.find(x => x.type.typeName === 'Header')?.body;
  const stairCases = sortedContentArray(slideContents, 'Staircase-Card');

  const { modalStore } = useStore();
  const { openModal } = modalStore;

  const backgroundColor = colorSchemeArray[0];
  const divStyle = stairCases.length > 3 ?
    { height: '400px', paddingLeft: '163px', paddingTop: '40px' } :
    { height: '400px', paddingLeft: '263px', paddingTop: '40px' };

  return (
    <>
      <div className="slide-img-par-1">
        <h1 className="slide-header">{header}</h1>
        <div className="slide-header-line" style={{ backgroundColor }}> </div>
        <div style={{ alignItems: 'center' }}>
          <div style={divStyle}>
            <div className='staircase-container'>
              {stairCases.map((x, i) => (
                <StairCaseItem
                  backgroundColor={colorSchemeArray[i % colorSchemeArray.length]}
                  onClick={() => {
                    openModal(<ElearningModal color={backgroundColor} backgroundColor={backgroundColor}
                      contentHeader={`${x.header}`} content={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll(" •", " ")} />)
                  }}
                  key={1}
                  text={`${x.header}`}
                  offsetTop={i * 20}
                  offsetRight={i * 5}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
  return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}