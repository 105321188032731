import { useField, useFormikContext } from "formik";
import React from "react";

import {
  DropdownItemProps,
  Form,
  Icon,
  Label,
  Popup,
  Select,
} from "semantic-ui-react";

interface Props {
  placeholder?: string;
  name: string;
  options: DropdownItemProps[];
  label?: string;
  info?: string;
  connectedField?: string;
  onClick?: () => void;
}

export default function SelectInput(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  const { setFieldValue } = useFormikContext();

  return (
    <Form.Field
      error={meta.touched && !!meta.error}
    >
      {props.label && (
        <>
          <label className="label" >
            {props.label}
          </label>
          {props.info && (
            <Popup
              trigger={<Icon name="info circle" />}
              content={props.info}
              position="right center"
            />
          )}
        </>
      )}
      <Select
        clearable
        options={props.options}
        fluid
        value={field.value || null}
        onChange={(e, d) => {
          helpers.setValue(d.value);
          if (props.connectedField) {
            setFieldValue(props.connectedField, 0);
          }
        }}
        onBlur={() => helpers.setTouched(true)}
        placeholder={props.placeholder}
      />
      {meta.touched && !!meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
