import React from "react";
import { Field, useField } from "formik";

interface Props {
  placeholder?: string;
  name: string;
  value: string | number;
  type?: string;
  label: string;
  info?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MyRadio(props: Props) {
  const [field, meta] = useField(props.name);

  return (
    <label
      style={{ paddingBottom: "5px", display: "flex", alignItems: "center" }}
    >
      <Field
        type="radio"
        name={field.name}
        error={meta.touched && !!meta.error}
        value={props.value}
        onChange={props.onChange || field.onChange}
      />
      &nbsp; {props.label}
    </label>
  );
}
