import { ContentState, convertFromRaw, convertToRaw, DraftInlineStyleType, EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { FieldInputProps } from "formik";
import _ from "lodash";
import { optionsImport } from "./DraftJSOptions";

export const editorInitState = (field: FieldInputProps<any>) => {
  const fieldValue = field.value;

  let contentState = stateFromHTML(fieldValue,optionsImport);

  // Multiple inline styles fix
  const raw = convertToRaw(contentState);

  raw.blocks.forEach(block => {
    block.inlineStyleRanges.forEach((inlineStyle, i, arr) => {
      const stylesArr = inlineStyle.style.split(';');
      if (stylesArr.length > 1) {
        inlineStyle.style = (stylesArr.shift() as DraftInlineStyleType);
        stylesArr.forEach(x => {
          const clone = _.cloneDeep(inlineStyle);
          clone.style = (x as DraftInlineStyleType);
          arr.push(clone);
        })
      }
    })
  })

  let editorState = EditorState.createWithContent(convertFromRaw(raw))
  return editorState;
}

const logBlockMap = (state: ContentState) => {
  console.log('--- Begin')

  state.getBlockMap().toArray().forEach((x, i) => {
    console.log({ key: x.getKey(), i, text: x.getText(), type: x.getType(), a: x.getEntityAt(0) })
  })
  console.log('--- END')
}
