import { FieldArray, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Segment } from "semantic-ui-react";
import { v4 } from "uuid";
import MyTextInput from "../../../../../features/forms/inputs/MyTextInput";
import LabelledInput from "../../../../common/Inputs/LabelledInput";
import { ContactsInfoLocation } from "../../../../models/Plugins/pluginTypes";
import { useStore } from "../../../../stores/store";
import LoadingComponent from "../../../LoadingComponent";
import AdminHeader from "../../AdminHeader";

export default observer(function ContactLocationEdit() {
  const { t } = useTranslation();
  const { contactInfoStore: { loadLocation, updateLocation, loadingInitial, seletedLocation } } = useStore();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      loadLocation(id).catch(() => {
        toast.error(t('error-loading-location'));
      })
    }
  }, [id, loadLocation, t])

  if (loadingInitial || !seletedLocation) {
    return <LoadingComponent />
  }

  const onSubmit = (values: ContactsInfoLocation) => {
    updateLocation(values).then(()=>{
      toast.info(t('uspesno_zacuvano'));
    })
  }


  return (<>
    <AdminHeader header={t("contacts-section")} icon="address card outline" />
    <br /><br />

    <Formik enableReinitialize initialValues={seletedLocation} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <FieldArray name="contactsInfos">
            {({ remove, push }) => (<>
              <Segment style={{ width: '450px' }}>
                <MyTextInput name="name" label={t("contact-location-name")} />
              </Segment>

              <Segment style={{ display: 'flex', flexWrap: 'wrap' }}>
                {values.contactsInfos &&
                  values.contactsInfos.length > 0 &&
                  values.contactsInfos.map((friend, index) => (
                    <div style={{ width: "350px", margin: '10px' }} key={index}>
                      <Segment>
                        <Button
                          icon="close"
                          color="red"
                          onClick={(e) => {
                            e.preventDefault();
                            remove(index)
                          }}
                          floated="right"
                        />
                        <p style={{ clear: 'both' }} />
                        <LabelledInput
                          label={t('name')}
                          placeholder="Внесете име..."
                          name={`contactsInfos.${index}.name`}
                          type="text"
                          inputStyle={{ width: '196px' }}
                        />
                        <LabelledInput
                          label={"E-Mail"}
                          placeholder="Внесете емаил..."
                          name={`contactsInfos.${index}.email`}
                          inputStyle={{ width: '256px' }}
                          type="email" />
                        <LabelledInput
                          label={t('telephone')}
                          placeholder="Внесете телефон..."
                          name={`contactsInfos.${index}.telephone`}
                          inputStyle={{ width: '235px' }}
                          type="text" />
                        <LabelledInput
                          label={t('order-appearance')}
                          placeholder=""
                          name={`contactsInfos.${index}.orderOfAppearance`}
                          inputStyle={{ width: '125px' }}
                          type="number" />
                        <LabelledInput
                          label={t('vocation')}
                          placeholder="Внесете вокација"
                          name={`contactsInfos.${index}.vocation`}
                          inputStyle={{ width: '233px' }}
                          type="text" />
                        <LabelledInput
                          label={t('job-position')}
                          placeholder="Внесете работна позиција..."
                          name={`contactsInfos.${index}.position`}
                          inputStyle={{ width: '175px' }}
                          type="text" />
                        <LabelledInput
                          label={t('org-unit')}
                          placeholder="Внесете  единица..."
                          name={`contactsInfos.${index}.orgUnit`}
                          inputStyle={{ width: '125px' }}
                          type="text" />
                      </Segment>
                    </div>
                  ))}
              </Segment>
              <Segment>
                <Button
                  secondary
                  icon="plus"
                  style={{ marginLeft: '15px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    push({
                      id: v4(),
                      name: '',
                      email: '',
                      position: '',
                      vocation: '',
                      infoLocationId:id
                    })
                  }}
                  content={t("add-new")}
                />
              </Segment>
            </>
            )}
          </FieldArray>
          <br />

          <Segment>
            <Button primary icon="arrow left" color="red" onClick={(e) => {
              e.preventDefault();
              navigate("/admin/plugins/contacts/list");
            }} />
            <Button primary type="submit">{t("save")}</Button>
          </Segment>
        </Form>)}
    </Formik>

  </>)
})