import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHeader,
  Image,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  ButtonGroup,
  Button,
  ButtonOr,
  TransitionGroup,
} from "semantic-ui-react";
import { IsotopeGridItem } from "../../../../../app/models/CMS/Homepage/isotope";
import { useStore } from "../../../../../app/stores/store";

interface Props {
  sortedIsotopeGridItems: IsotopeGridItem[];
}
export default observer(function IsotopeTable({
  sortedIsotopeGridItems,
}: Props) {
  const navigate = useNavigate();
  const {
    isotopeStore: { deleteIsotope, orderIncrement, orderDecrement },
    commonStore: { moveUp, moveDown, movingUp, movingDown },
  } = useStore();
  const { t } = useTranslation();

  return (
    <>
      <style>
        {`

      `}
      </style>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>{t("image-path")}</TableHeaderCell>
            <TableHeaderCell>{t("filter-name")}</TableHeaderCell>
            <TableHeaderCell>{t("link-url")}</TableHeaderCell>
            <TableHeaderCell>{t("width")}</TableHeaderCell>
            <TableHeaderCell>{t("height")}</TableHeaderCell>
            <TableHeaderCell>{t("item-order")}</TableHeaderCell>
            <TableHeaderCell>{t("actions")}</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedIsotopeGridItems.map((x, i, arr) => {
            return (
              <TableRow
                key={x.id}
                className={
                  movingUp === x.id
                    ? "move-up"
                    : movingDown === x.id
                    ? "move-down"
                    : ""
                }
              >
                <TableCell>
                  {
                    <Image
                      style={{ width: "150px" }}
                      src={`/media/thumbnail/${x.imagePath}`}
                      href={`/media/images/${x.imagePath}`}
                      target="_blank"
                    />
                  }
                </TableCell>
                <TableCell>{x.filterName}</TableCell>
                <TableCell>{x.linkUrl}</TableCell>
                <TableCell>{x.width}</TableCell>
                <TableCell>{x.height}</TableCell>
                <TableCell>
                  <ButtonGroup vertical>
                    {(x.itemOrder > 0 || (arr.length === 2 && i === 1)) && (
                      <Button
                        disabled={!!movingUp || !!movingDown}
                        icon="arrow up"
                        color="green"
                        onClick={() =>
                          moveUp(x.id, arr[i - 1].id, orderDecrement)
                        }
                      />
                    )}
                    {arr.some((y) => y.itemOrder > x.itemOrder) && (
                      <Button
                        disabled={!!movingUp || !!movingDown}
                        icon="arrow down"
                        color="green"
                        onClick={() =>
                          moveDown(x.id, arr[i + 1].id, orderIncrement)
                        }
                      />
                    )}
                  </ButtonGroup>
                </TableCell>
                <TableCell>
                  <ButtonGroup>
                    <Button
                      content={t("edit")}
                      color="green"
                      disabled={!!movingUp || !!movingDown}
                      onClick={() =>
                        navigate(`/admin/homepage/isotope/edit/${x.id}`)
                      }
                    />
                    <ButtonOr text={t("or")} />
                    <Button
                      content={t("move-to-trash")}
                      onClick={() => deleteIsotope(x.id)}
                      disabled={!!movingUp || !!movingDown}
                      color="red"
                    />
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
});
