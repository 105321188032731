import { observer } from "mobx-react-lite";
import React from "react";
import { Modal } from "semantic-ui-react";
import { useStore } from "../../stores/store";

export default observer(function ModalContainer () {
  const {modalStore} = useStore();
  const {closeModal, modal} = modalStore
  return (
    <Modal open={modal.open} onClose={closeModal} size={modal.size} basic={modal.basic}>
        {modal.body}
    </Modal>
  )
})