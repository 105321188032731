import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { useStore } from "../../../stores/store";
import { Languages } from "../../../common/types/CustomTypes";
import CommonStore from "../../../stores/commonStore";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "./LangSwitcher.css";
import { observer } from "mobx-react-lite";

const LangSwitcher = () => {
  const { i18n } = useTranslation();
  const {
    commonStore: { selectLanguage, language },
  } = useStore();
  function changeLanguage(lang: Languages) {
    i18n.changeLanguage(lang);
    selectLanguage(lang);
  }
  return (
    <div className="header_right">
      <div className="search">
        <div className="lang-menu">
          <button className="selected-lang">
            {language}
            <MdOutlineKeyboardArrowDown size={18} />
          </button>
          <ul>
            <li onClick={() => changeLanguage("MK")}>MK</li>
            <li onClick={() => changeLanguage("SQ")}>SQ</li>
            <li onClick={() => changeLanguage("EN")}>EN</li>
          </ul>
        </div>
        {/* <button className="search-btn">
        <AiOutlineSearch size={23} style={{ marginLeft: "1rem" }} />
      </button> */}
      </div>
    </div>
  );
};
export default observer(LangSwitcher);
