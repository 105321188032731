import { action, makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IsotopeGridItem } from "../models/CMS/Homepage/isotope";
import { store } from "./store";


export default class IsotopeGridStore {
  isotopeRegistry = new Map<string, IsotopeGridItem>();
  target: string | null = null;

  selectedIsotope: IsotopeGridItem | null = null;
  setSelectedIsotopeById(id: string) {
    const isotopeGridItem = this.isotopeRegistry.get(id);
    this.selectedIsotope = isotopeGridItem || null;
  }
  clearSelectedIsotope = () => {
    this.selectedIsotope = null;




  }

  constructor() {
    makeAutoObservable(this, {
      setSelectedIsotopeById: action.bound
    });
  }

  loading = false;
  loadingInitial = false;

  get sortedIsotopeGridItems() {
    return Array.from(this.isotopeRegistry.values()).sort((a, b) => a.itemOrder - b.itemOrder)
  }

  get sortedIsotopeGridItemsSelectedLang() {
    return Array.from(this.isotopeRegistry.values())
    .filter(x=> x.languageId === store.commonStore.language)
    .sort((a, b) => a.itemOrder - b.itemOrder)
  }



  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }
  setTarget = (target: string | null) => {
    this.target = target;
  }

  loadIsotopeGridItems = async () => {
    this.setLoading(true)
    try {
      const isotopeGridItems = await agent.Isotope.list();
      runInAction(() => {
        isotopeGridItems.forEach(x => {
          this.isotopeRegistry.set(x.id, x);
        })
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadSingleIsotopeGridItem = async (id: string) => {
    this.setLoading(true)
    try {
      const isotopeGridItem = await agent.Isotope.details(id);
      runInAction(() => {
        this.isotopeRegistry.set(isotopeGridItem.id, isotopeGridItem);
        this.selectedIsotope = isotopeGridItem;

      })
      return isotopeGridItem;
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }

  }

  deleteIsotope = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Isotope.delete(id);
      runInAction(() => {
        this.isotopeRegistry.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Isotope');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  createIsotope = async (isotope: IsotopeGridItem) => {
    this.setLoading(true)
    try {
      await agent.Isotope.create(isotope);
      runInAction(() => {
        this.isotopeRegistry.set(isotope.id, isotope);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  updateIsotope = async (isotope: IsotopeGridItem) => {
    this.setLoading(true)
    try {
      await agent.Isotope.update(isotope);
      runInAction(() => {
        this.isotopeRegistry.set(isotope.id, isotope);
        this.selectedIsotope = isotope;
        toast.info('Successfully updated!')
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  private reorderItem = async (id: string, increment?: boolean) => {
    this.setLoading(true)
    try {
      const arr = Array.from(this.isotopeRegistry.values())
      const currentItem = this.isotopeRegistry.get(id);
      if (!currentItem) {
        return;
      }
      const oldItemOrder = currentItem.itemOrder;
      const newItemOrder = increment ? oldItemOrder + 1 : oldItemOrder -1; 
      const swapsOrderWith = arr.filter(x => x.itemOrder === newItemOrder);
      await swapsOrderWith.forEach(async x => {
        x.itemOrder=oldItemOrder;
        await agent.Isotope.update(x);
        this.isotopeRegistry.set(x.id, x);
      });
      currentItem.itemOrder =newItemOrder;
      await agent.Isotope.update(currentItem);
      this.isotopeRegistry.set(currentItem.id, currentItem);
    } catch (error) {
      console.log(error)
      toast.error('Failed to update order');
    } finally {
      this.setLoading(false)
    }
  }

  orderDecrement = async (id:string) => {
    return await this.reorderItem(id, false);
  }
  orderIncrement = async (id: string) => {
    return await this.reorderItem(id, true);
  }
}