import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { Segment, ButtonGroup, Button, Header, Table } from "semantic-ui-react";
import HistoryModal from "../../../app/common/modals/HistoryModal";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  WorkOnSunday,
  WorkOnSundayInterface,
} from "../../../app/models/Forms/WorkOnSundayInterface";
import { useStore } from "../../../app/stores/store";

export default observer(function WorkOnSundayHistory() {
  const { codesStore, formStore, modalStore } = useStore();
  const { citiesDropdown, loadCities } = codesStore;
  const { t } = useTranslation();
  const {
    selectedWorkOnSundayForm,
    loadSingleWorkOnSundayForm,
    loadingInitial,
    loadHistoryWorkOnSundayForm,
    selectedWorkOnSundayHistoryForm,
  } = formStore;
  const { openModal, closeModal } = modalStore;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id)
      loadSingleWorkOnSundayForm(id).then(() =>
        loadHistoryWorkOnSundayForm(id)
      );

    loadCities();
  }, [loadSingleWorkOnSundayForm, loadCities, id, loadHistoryWorkOnSundayForm]);

  if (loadingInitial || !selectedWorkOnSundayHistoryForm) {
    return <LoadingComponent />;
  }

  return (
    <>
      <AdminHeader header={t("history-work-on-sunday")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedWorkOnSundayForm!.idHeader}`)
              // navigate('123')
            }
          />
        </ButtonGroup>
      </Segment>
      <Segment>
        <Table basic="very" celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("submission-time")}</Table.HeaderCell>
              <Table.HeaderCell>{t("view")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <>
            <Table.Body>
              {selectedWorkOnSundayHistoryForm.detailsHistory.map((x) => {
                const arr = new WorkOnSunday(x).toStringArray();
                return (
                  <Table.Row key={x.id}>
                    <Table.Cell>
                      {x.userName ? x.userName : t("original-submitter")}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(x.dateSubmitted).format("DD.MM.YYYY - HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        content={t("view")}
                        color="blue"
                        onClick={() =>
                          openModal(
                            <HistoryModal
                              closeModal={closeModal}
                              id={x.id}
                              arr={arr}
                            />
                          )
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </>
        </Table>
      </Segment>
    </>
  );
});
