import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, FieldArray } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Form, Icon, Segment, Tab } from "semantic-ui-react";
import MyTextInput from "../../../../../../features/forms/inputs/MyTextInput";
import MyEditorInput from "../../../../../common/Inputs/MyEditorInput";
import { AccordionItem } from "../../../../../models/CMS/Plugin/accordionItem";

interface Props {
  initialValues: AccordionItem;
  onSubmit: (data: AccordionItem) => void;
}

export default observer(function AccordionItemForm({
  onSubmit,
  initialValues,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Segment>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ submitForm, values, setFieldValue }) => (
            <Form autoComplete="off">
              <>
                <Segment>
                  <FieldArray name="translations">
                    {() => {
                      const translations = values.translations || [];
                      const panes = translations
                        .sort((a, b) => {
                          if (a.languageId === "MK") return -1;
                          if (b.languageId === "MK") return 1;
                          if (a.languageId === "EN") return 1;
                          if (b.languageId === "EN") return -1;

                          return 0;
                        })
                        .map((x, index) => {
                          return {
                            menuItem: x.languageId,
                            render: () => (
                              <Tab.Pane key={x.languageId}>
                                <Segment>
                                  <MyTextInput
                                    name={`translations.${index}.header`}
                                    label={t("header")}
                                  />
                                  <MyEditorInput
                                    name={`translations.${index}.content`}
                                    label={t("content")}
                                    onChange={setFieldValue}
                                    placeholder=""
                                  />
                                </Segment>
                              </Tab.Pane>
                            ),
                          };
                        });
                      return <Tab panes={panes} />;
                    }}
                  </FieldArray>

                  <Button
                    color="red"
                    onClick={() => {
                      navigate(
                        `/admin/plugins/accordion/edit/${initialValues.acordionId}`
                      );
                    }}
                  >
                    <Icon name="arrow left" />
                  </Button>
                  <Button color="teal" type="submit" onClick={submitForm}>
                    {t("save")}
                  </Button>
                </Segment>
              </>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
