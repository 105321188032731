import React from "react";
import { useTranslation } from "react-i18next";
import { FaInstagram } from "react-icons/fa";
import { ImYoutube2, ImFacebook, ImLinkedin2 } from "react-icons/im";
import "./MainHeaderTop.css";

export default function MainHeaderTop() {
  const { t } = useTranslation();

  return (
    <section className="header_top">
      <div className="ui container">
        <div className="header_top_flex_container">
          <div className="contact">
            <div>+ 389 2 3224504</div>
            <div className="vertical_line"></div>
            <div>info@dit.gov.mk</div>
            <div className="vertical_line"></div>
            <div>{t("partizanski-odredi-48")}</div>
          </div>
          {/* <nav>
            <ul>
              <li>
                <ImYoutube2 size={31} />
              </li>
              <li>
                <ImFacebook size={14} />
              </li>
              <li>
                <ImLinkedin2 size={18} />
              </li>
              <li>
                <FaInstagram size={19} />
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </section>
  );
}
