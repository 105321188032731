import React from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import {
  Message,
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Segment,
  Icon,
} from "semantic-ui-react";
import MyTextInput from "../../../../../features/forms/inputs/MyTextInput";
import { Accordion } from "../../../../models/CMS/Plugin/accordionItem";
import { useStore } from "../../../../stores/store";
import LoadingComponent from "../../../LoadingComponent";
import AdminHeader from "../../AdminHeader";

export default observer(function AccordionEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    accordionStore: {
      loadingInitial,
      loadSingleAccordion,
      updateAccordion,
      selectedAccordion,
      deleteAccordionItem,
    },
  } = useStore();

  React.useEffect(() => {
    if (id) {
      loadSingleAccordion(id);
    }
  }, [loadSingleAccordion, id]);

  if (loadingInitial || !selectedAccordion) {
    return <LoadingComponent />;
  }

  const onSubmit = (values: Accordion) => {
    updateAccordion(values);
  };

  return (
    <>
      <AdminHeader header={t("edit-accordion")} icon="list alternate outline" />
      <Formik
        initialValues={selectedAccordion}
        enableReinitialize
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ submitForm }) => (
          <Form autoComplete="off">
            <>
              <MyTextInput name="name" label={t("accordion-title")} />
              {!selectedAccordion?.accordionItems?.length && (
                <Message info content={t("no-accordion-items")} />
              )}
              {!!selectedAccordion?.accordionItems?.length && (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>{t("header-mk")}</TableHeaderCell>
                      <TableHeaderCell>{t("header-sq")}</TableHeaderCell>
                      <TableHeaderCell>{t("header-en")}</TableHeaderCell>
                      <TableHeaderCell>{t("actions")}</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {selectedAccordion.accordionItems?.map((y) => (
                      <TableRow key={y.id}>
                        <TableCell>
                          {
                            y.translations?.find((x) => x.languageId === "MK")
                              ?.header
                          }
                        </TableCell>
                        <TableCell>
                          {
                            y.translations?.find((x) => x.languageId === "SQ")
                              ?.header
                          }
                        </TableCell>
                        <TableCell>
                          {
                            y.translations?.find((x) => x.languageId === "EN")
                              ?.header
                          }
                        </TableCell>
                        <TableCell>
                          <Button
                            color="green"
                            onClick={(ev) =>{
                              ev.preventDefault();
                              navigate(
                                `/admin/plugins/accordion/item/edit/${y.id}`
                              )}
                            }
                            icon="edit"
                          />
                          <Button
                            color="red"
                            onClick={(ev) => {
                              ev.preventDefault();
                              deleteAccordionItem(y.id)
                            }}
                            icon="delete"
                          /> 
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}

              <Button
                primary
                onClick={() => {
                  navigate(`/admin/plugins/accordion/item/create/${id}`);
                }}
                content={t("create-new-item")}
                icon="plus"
                labelPosition="right"
              />
              <Segment>
                <Button
                  color="red"
                  onClick={() => {
                    navigate("/admin/plugins/accordion/list");
                  }}
                >
                  <Icon name="arrow left" />
                </Button>
                <Button
                  color="teal"
                  type="submit"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {t("save")}
                </Button>
              </Segment>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
});
