import { Formik, FormikHelpers } from "formik";
import {
  Grid,
  Segment,
  Form,
  Header,
  Label,
  DropdownItemProps,
} from "semantic-ui-react";
import SearchInput from "../../../../app/common/Inputs/SearchInput";
import { StartingActivityInterface } from "../../../../app/models/Forms/StartingActivityInterface";
import MyDateInput from "../../inputs/MyDateInput";
import MyTextInput from "../../inputs/MyTextInput";
import { StartingActivitySchema } from "../../schemas/StartingActivitySchema";
import FormsLogo from "../FormsLogo";
import { ButtonsReportsFooter } from "./ButtonsReportsFooter";
import MiddleHeader from "./MiddleHeader";
import "./StartingForm.css";

const initValue: StartingActivityInterface = {
  cityId: null,
  employerName: "",
  employerAddress: "",
  streetNumber: "",
  employerPlace: "",
  employerMunicipality: "",
  employerPostalCode: "",
  legalEntityName: "",
  embs: "",
  date: null,
  place: "",
  taxNumber: "",
  bankAccount: "",
  bankName: "",
  fullname: "",
  address: "",
  phoneNumber: "",
  email: "",
  workActivity: null,
  workplaceAddress: "",
  startingDate: null,
  submitterFullname: "",
  submitterWorkPosition: "",
  submitterPhoneNumber: "",
  submitterEmail: "",
};

interface Props {
  nkdDropdown: DropdownItemProps[];
  citiesDropdown: DropdownItemProps[] | undefined;
  handleSubmit: (
    values: StartingActivityInterface,
    actions: FormikHelpers<StartingActivityInterface>
  ) => Promise<void>;
  initialValues?: StartingActivityInterface | null;
}

export default function StartingForm({
  nkdDropdown,
  handleSubmit,
  citiesDropdown,
  initialValues,
}: Props) {
  return (
    <>
      <style>
        {`
        .background_img {
          background-image: url("/images/background.png");
          background-repeat: no-repeat;
          background-position: 75% 25%;
          background-size: contain;
        }
          `}
      </style>
      <Grid className="form-frame">
        <Segment className="form-segment">
          <Formik
            initialValues={initialValues || initValue}
            validationSchema={StartingActivitySchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched, handleSubmit }) => (
              <>
                <div className="background_img">
                  <Form
                    className="form-margins"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <header>
                      <FormsLogo />
                      <MiddleHeader citiesDropdown={citiesDropdown} />
                    </header>
                    <main>
                      <div className="starting-main-title">
                        <Header>
                          <div className="starting-title-top">Известување</div>
                          <div className="starting-title-bottom">
                            за започнување со вршење на дејност
                          </div>
                        </Header>
                        <Header.Subheader>
                          (Правилник за формата и содржината на образецот за
                          известување за започнување <br />
                          со вршење на дејност („Службен весник на Република
                          Македонија“ бр.136/07))
                        </Header.Subheader>
                      </div>
                      <ol className="ol">
                        <li>
                          <MyTextInput
                            label="Назив на работодавачот на подносителот на известувањето"
                            name="employerName"
                            type="text"
                            placeholder="Внесете назив на работодавачот"
                          />
                        </li>
                        <li>
                          <p className="asterisk">
                            Седиште на работодавачот на подносителот на
                            известувањето, во согласност со уписот во Централен
                            регистар
                          </p>
                          <p>улица</p>
                          <MyTextInput
                            name="employerAddress"
                            type="text"
                            placeholder="Внесете улица"
                          />
                          <p>број</p>
                          <MyTextInput
                            name="streetNumber"
                            type="text"
                            placeholder="Внесете број"
                          />
                          <p>место</p>
                          <MyTextInput
                            name="employerPlace"
                            type="text"
                            placeholder="Внесете место"
                          />
                          <p>Општина</p>
                          <MyTextInput
                            name="employerMunicipality"
                            type="text"
                            placeholder="Внесете општина"
                          />
                          <p>Поштенски број</p>
                          <MyTextInput
                            name="employerPostalCode"
                            type="text"
                            placeholder="Внесете поштенски број"
                          />
                        </li>
                        <li>
                          <MyTextInput
                            label="Назив на правниот субјект"
                            name="legalEntityName"
                            type="text"
                            placeholder="Внесете назив на правниот субјект"
                          />
                        </li>
                        <li>
                          <MyTextInput
                            label="ЕМБС (Единствен матичен број на субјект)"
                            name="embs"
                            type="text"
                            placeholder="Внесете EMBC"
                          />
                          <p>датум</p>
                          <MyDateInput
                            name="date"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Внесете датум"
                          />
                          <p>место</p>
                          <MyTextInput
                            name="place"
                            type="text"
                            placeholder="Внесете место"
                          />
                        </li>
                        <li>
                          <MyTextInput
                            label="Даночен број"
                            name="taxNumber"
                            type="text"
                            placeholder="Внесете даночен број"
                          />
                        </li>
                        <li>
                          <MyTextInput
                            label="Жиро сметка, број"
                            name="bankAccount"
                            type="text"
                            placeholder="Внесете жиро сметка"
                          />
                          <p style={{ margin: "8px 0 5px 2px" }}>
                            Банка - депонент
                          </p>
                          <MyTextInput
                            name="bankName"
                            type="text"
                            placeholder="Внесете банка - депонент"
                          />
                        </li>
                        <li>
                          <p className="asterisk">
                            Податоци за одговорното лице на работодавачот
                          </p>
                          <p>Име и презиме</p>
                          <MyTextInput
                            name="fullname"
                            type="text"
                            placeholder="Внесете име и презиме"
                          />
                          <p>Адреса</p>
                          <MyTextInput
                            name="address"
                            type="text"
                            placeholder="Внесете улица и број"
                          />
                          <p>Телефонски број</p>
                          <MyTextInput
                            name="phoneNumber"
                            type="tel"
                            placeholder="Внесете телефонски број"
                          />
                          <p>E-mail</p>
                          <MyTextInput
                            name="email"
                            type="email"
                            placeholder="Внесете e-mail адреса"
                          />
                        </li>
                        <li>
                          <p className="asterisk">
                            Дејност која ќе се извршува во работната просторија
                            и/или простор
                          </p>
                          <SearchInput
                            name="workActivity"
                            setFieldValue={setFieldValue}
                            source={nkdDropdown}
                            placeholder="Внесете дејност на работодавачот"
                            defaultValue={
                              values.id ? values.workActivity : null
                            }
                          />
                          {touched.workActivity && !!errors.workActivity ? (
                            <Label
                              basic
                              color="red"
                              style={{ marginTop: "3px" }}
                            >
                              {errors.workActivity}
                            </Label>
                          ) : null}
                        </li>
                        <li>
                          <MyTextInput
                            label="Адреса на работната просторија, простор и/или подружница каде ќе се врши дејност"
                            name="workplaceAddress"
                            type="text"
                            placeholder="Внесете адреса на работната просторија"
                          />
                        </li>
                        <li>
                          <p
                            style={{ margin: "0 0 5px 2px" }}
                            className="asterisk"
                          >
                            Датум на започнување со вршење на дејност
                          </p>
                          <MyDateInput
                            name="startingDate"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Внесете датум на започнување со вршење на дејност"
                          />
                        </li>
                      </ol>
                    </main>
                    <footer>
                      <Grid stackable className="starting-field-by-part">
                        <Grid.Row
                          style={{
                            display: "block",
                          }}
                        >
                          <Header size="small" className="asterisk">
                            Пополнето од:
                          </Header>
                          <p style={{ margin: "0 0 5px 2px" }}>Име и презиме</p>
                          <MyTextInput
                            name="submitterFullname"
                            type="text"
                            placeholder="Внесете име и презиме"
                          />
                          <p style={{ margin: "8px 0 5px 2px" }}>
                            Работно место
                          </p>
                          <MyTextInput
                            name="submitterWorkPosition"
                            type="text"
                            placeholder="Внесете работно место"
                          />
                          <p style={{ margin: "8px 0 5px 2px" }}>
                            Телефонски број
                          </p>
                          <MyTextInput
                            name="submitterPhoneNumber"
                            type="tel"
                            placeholder="Внесeте телефонски број"
                          />
                          <p style={{ margin: "8px 0 5px 2px" }}>
                            Е-mail адреса
                          </p>
                          <MyTextInput
                            name="submitterEmail"
                            type="email"
                            placeholder="Внесете e-mail адреса"
                          />
                        </Grid.Row>
                        <ButtonsReportsFooter />
                      </Grid>
                    </footer>
                  </Form>
                </div>
              </>
            )}
          </Formik>
        </Segment>
      </Grid>
    </>
  );
}
