import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Segment,
  Image,
  Grid,
  Header,
  Card,
  Icon,
  Button,
  Input,
  Modal,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../forms/inputs/MyTextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";

import { User, UserFormValues } from "../../../app/models/user";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { values } from "mobx";
import MySelectInput from "../../forms/inputs/MySelectInput";
import UserForgotPassword from "./List/UserForgotPassword";
import { t } from "i18next";

export const UserRoles = [
  { text: t("super-admin"), value: "superAdmin" },
  { text: t("content-admin"), value: "contentAdmin" },
  { text: t("super-operator"), value: "superOperator" },
  { text: t("operator"), value: "operator" },
  { text: t("learner"), value: "learner" },
  { text: t("trainer"), value: "trainer" },
];

export default observer(function UserEdit() {
  const { t } = useTranslation();
  const {
    userStore: { selectedUser, loadUser },
    codesStore: {
      loadBzrRegions,
      loadRoRegions,
      loadCities,
      bzrDropdown,
      roDropdown,
      citiesDropdown,
    },
  } = useStore();
  const { id } = useParams();
  const { userStore, modalStore } = useStore();
  const { openModal } = modalStore;

  const navigate = useNavigate();

  useEffect(() => {
    loadBzrRegions();
    loadRoRegions();
    loadCities();
    if (id) {
      loadUser(id);
    } else {
      navigate("/admin/users/list");
    }
  }, [id, loadUser, navigate, loadBzrRegions, loadRoRegions, loadCities]);

  if (!selectedUser) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div>
        <Segment>
          <Header size="large">
            <Icon name="user" />
            {t("user-profile")}
          </Header>
        </Segment>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Formik
            initialValues={selectedUser}
            onSubmit={(values) => {
              userStore
                .updateUser(values)
                .then(() => navigate("/admin/users/list"));
            }}
          >
            <Form>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ width: "50%" }}>
                  <MyTextInput name="email" label={t("Email")} />
                  <MyTextInput name="firstName" label={t("First-name")} />
                  <MyTextInput name="lastName" label={t("Last-name")} />
                  <MyTextInput name="address" label={t("Address")} />
                  <Button
                    style={{ marginTop: "10px" }}
                    color="teal"
                    type="submit"
                    content={t("submit")}
                  ></Button>
                </div>
                <div style={{ width: "50%" }}>
                  <Segment>
                    <MySelectInput
                      name="role"
                      options={UserRoles}
                      label={t("Role")}
                    />
                    <br />
                    {citiesDropdown && (
                      <>
                        <MySelectInput
                          name="cityId"
                          options={citiesDropdown}
                          label={t("City")}
                        />
                      </>
                    )}
                    <br />
                    {bzrDropdown && roDropdown && (
                      <>
                        <MySelectInput
                          name="regionBzrId"
                          options={bzrDropdown}
                          label={t("Region-BZR")}
                        />
                        <br />
                        <MySelectInput
                          name="regionRoId"
                          options={roDropdown}
                          label={t("Region-RO")}
                        />
                      </>
                    )}
                    <br />
                  </Segment>
                </div>
              </div>
            </Form>
          </Formik>
        </Segment>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Button color="red" onClick={() => navigate("/admin/users/list")}>
            <Icon name="arrow left" />
          </Button>
          {/* <Button style={{ marginTop: '10px' }} color="green" type="submit" content='Submit'></Button> */}
          <Button
            onClick={(e) => {
              openModal(<UserForgotPassword userId={id!} />);
            }}
            color="grey"
          >
            {t("change-password")}{" "}
          </Button>
        </Segment>
      </div>
    </>
  );
});
