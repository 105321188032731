import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Icon, Table } from "semantic-ui-react";
import { useStore } from "../../../../stores/store";
import AdminHeader from "../../AdminHeader";
import InfoCopyModal from "../../../../common/modals/InfoCopyModal";
import { ContentPartTypes } from "../../../../models/Plugins/pluginTypes";
import { GenerateShortcode } from "../ShortcodeFunctions";

export default observer(function AccordionList() {
  const { t } = useTranslation();
  const { accordionStore, modalStore: {openModal, closeModal} } = useStore();
  const { deleteAccordion, accordionItems, target, loadAccordion } =
    accordionStore;

  React.useEffect(() => {
    loadAccordion();
  }, [loadAccordion]);

  return (
    <>
      <AdminHeader header={t("accordion-list")} icon="list alternate outline" />
      <>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Title")}</Table.HeaderCell>
              <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {accordionItems.map((x) => {
              return (
                <Table.Row key={x.id}>
                  <Table.Cell>{x.name || ""}</Table.Cell>
                  <Table.Cell>
                    <ButtonGroup>
                    <Button
                        icon="eye"
                        inverted
                        primary
                        onClick={()=> {
                          openModal(<InfoCopyModal 
                            closeModal={closeModal}
                            value={GenerateShortcode(ContentPartTypes.ACCORDION,x.id)} 
                          />)
                        }}
                      />
                      <Button
                        as={Link} 
                        to={`/admin/plugins/accordion/edit/${x.id}`}
                        icon="edit"
                        inverted
                        color="green"
                      />
                      <Button
                        name={x.id}
                        loading={target === x.id}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteAccordion(x.id);
                        }}
                        icon="close"
                        inverted
                        color="red"
                      />
                    </ButtonGroup>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <div style={{ textAlign: "right" }}>
          <Link
            to={`/admin/plugins/accordion/create/`}
            className="ui button teal"
          >
            <Icon name="plus" />
            {t("add-new-accordion")}
          </Link>
        </div>
      </>
    </>
  );
});
