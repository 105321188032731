import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { eSlideContent } from "../../../../app/models/ELearning/eSlide";
import { useStore } from "../../../../app/stores/store";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { observer } from "mobx-react-lite";
import { Button, Grid, Header, Icon, Segment,  } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { v4 as uuid } from "uuid";
import MyTextInput from "../../../forms/inputs/MyTextInput";
import MyTextArea from "../../../forms/inputs/MyTextArea";
import MySelectInput from "../../../forms/inputs/MySelectInput";

export default observer(function CreateSlideContent() {
  const { t } = useTranslation();
  const { id, slideId } = useParams();
  const navigate = useNavigate();
  const {
    learningStore: {
      createSlideContent,
      loadContent,
      contentTypes,
      loadContentType,
    },
  } = useStore();


  React.useEffect(() => {
    if (id) {
      loadContent(id);
      loadContentType();
    }
  }, [loadContent, loadContentType, id]);

  const initialValues: eSlideContent = {
    id: "",
    type: {
      id: "",
      typeDescription: "",
      typeName: "",
    },
    typeId: "",
    slideId: id!,
    extra: "",
    header: "",
    body: "",
    contentOrder: 0,
    moduleId: '',
  };

  return (
    <>
      <AdminHeader
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
        header={t("slide-content")}
        subheader={t("create-slide-content")}
      />
      <Segment>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            values.id = uuid();
            createSlideContent(values).then(
              () =>
                // sortedSlides.map(x=>
                navigate(`/admin/e-learning/viewSlide/${id}/${slideId}`)
              // )
            );
          }}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form autoComplete="off">
              <Grid>
                <Grid.Column width={16}>
                  <MyTextInput name="header" label={t("Title")} />
                  <MyTextArea rows={7} name="body" label={t("content")} />
                  <MyTextInput name="contentOrder" label={t("order")} />
                  {/* <MyTextInput name="slideId" label={t("slide-id")} /> */}

                  <div style={{ marginTop: "25px" }}>
                    <MySelectInput
                      name="typeId"
                      label={t("type")}
                      options={contentTypes!}
                    />
                  </div>
                </Grid.Column>

                {/* <Header content={t("featured-image")} />
                {!photoEditMode && (
                  <Segment
                    className="image-segment"
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      src={
                        values?.body
                          ? `/media/images/${values?.body}`
                          : `/images/no-image.jpg`
                      }
                      style={{ width: "300px", margin: "auto" }}
                    />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setPhotoEditMode(true);
                      }}
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        background: "#babababa",
                        opacity: "0.8",
                        height: "100%",
                        display: "none",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon name="edit" size="huge" color="green" />
                    </Button>
                  </Segment>
                )}
                <Segment className={`${!photoEditMode && "hidden"}`}>
                  <Button
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPhotoEditMode(false);
                    }}
                    icon="close"
                    color="red"
                    style={{ marginBottom: "15px" }}
                  />
                  <PhotoSelectWidget
                    itemsPerRow={3}
                    extraContent={(id, path) => (
                      <Button
                        disabled={id === values.body}
                        fluid
                        content={t("select")}
                        color="green"
                        onClick={() => {
                          setFieldValue("featuredImageId", id);
                          setFieldValue(
                            "featuredImagePath",
                            path?.substring(14)
                          );
                          setFieldValue("featuredImage", {
                            id,
                            path: path?.substring(14),
                          });
                        }}
                      />
                    )}
                  />
                </Segment> */}

              </Grid>
              <Segment>
                <Button
                  color="red"
                  onClick={() => navigate(`/admin/e-learning/viewSlide/${id}/${slideId}`)}
                >
                  <Icon name="arrow left" />
                </Button>
                <Button color="teal" type="submit">
                  {t("create")}
                </Button>
              </Segment>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
