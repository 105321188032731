import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderInfoItemDesktop } from "./HeaderInfoItemDesktop";

import { useNavigate } from "react-router-dom";
import "./MainHeaderBottomDesktop.css";
import NavBarLogo from "../../Logo/NavBarLogo";
import LangSwitcher from "../../LanguageSwitcher/LangSwitcher";
import { useStore } from "../../../../stores/store";
import { observer } from "mobx-react-lite";

const MainHeaderBottomDesktop = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const {
    navigationStore: { sortedNavPublicItems },
    commonStore: { language },
  } = useStore();

  return (
    <section>
      <div className="header_bottom">
        {/* <div className="ui container"> */}
        <div className="header_flex_container">
          <NavBarLogo />
          <div>
            <div>
              <nav>
                <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
                  {sortedNavPublicItems.map((x) => (
                    <HeaderInfoItemDesktop key={x.id} x={x} language={language} />
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <LangSwitcher />
          <button
            className="header-login-button"
            onClick={() => {
              navigate("/login");
            }}
          >
            {t("log-in")}
          </button>
        </div>
      </div>
      <div style={{ position: "absolute", right: "40px" }}></div>
      {/* </div> */}
    </section>
  );
};

export default observer(MainHeaderBottomDesktop);
