import * as React from 'react';
import { Button, Header, Icon, Modal, SemanticICONS } from 'semantic-ui-react';

interface Props {
  title: string;
  description: string;
  cancel: () => void;
  icon?: SemanticICONS;
}

export default function ModalAlert({ title, description, cancel, icon="delete"}: Props) {
  return (
    <>
      <Header icon={!!icon} >
       {icon && <Icon name={icon} />}
       {title}
      </Header>
      <Modal.Content>
        <p>
          {description}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' inverted onClick={() => cancel()}>
          <Icon name='remove' />
        </Button>
   
      </Modal.Actions>
    </>
  )
}