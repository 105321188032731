import moment from "moment";
import { EnsureDate } from "../../common/types/TypeFunctions";
import { Nkd } from "../CodeLists/Nkd";
import { City } from "../Regions/City";
import { formTypesToString, FormValue } from "./SharedFunctions";

export interface StartingActivityInterface {
  id?: string;
  dateSubmitted?: Date | null;
  idHeader?: string;
  city?: City | null;
  cityId: number | null;  //bzr //connected with City ID
  employerName: string;
  employerAddress: string;
  streetNumber: string;
  employerPlace: string;
  employerMunicipality: string;
  employerPostalCode: string;
  legalEntityName: string;
  embs: string;
  date: Date | null;
  place: string;
  taxNumber: string;
  bankAccount: string;
  bankName: string;
  fullname: string;
  address: string;
  phoneNumber: string;
  email: string;
  workActivity: number | null; //connected with NKD ID
  nkdCodes?: Nkd | null;
  workplaceAddress: string;
  startingDate: Date | null;
  submitterFullname: string;
  submitterWorkPosition: string;
  submitterPhoneNumber: string;
  submitterEmail: string;
  userName?: string
  userId?: string
}

export class StartingActivity implements StartingActivityInterface {
  id?: string | undefined;
  userId?: string | undefined;
  userName?: string | undefined;
  dateSubmitted?: Date | null | undefined;
  idHeader?: string | undefined;
  city: City | null = null;
  cityId: number | null = null;
  employerName: string = "";
  employerAddress: string = "";
  streetNumber: string = "";
  employerPlace: string = "";
  employerMunicipality: string = "";
  employerPostalCode: string = "";
  legalEntityName: string = "";
  embs: string = "";
  date: Date | null = null;
  place: string = "";
  taxNumber: string = "";
  bankAccount: string = "";
  bankName: string = "";
  fullname: string = "";
  address: string = "";
  phoneNumber: string = "";
  email: string = "";
  nkdCodes: Nkd | null = null;
  workActivity: number | null = null;
  workplaceAddress: string = "";
  startingDate: Date | null = null;
  submitterFullname: string = "";
  submitterWorkPosition: string = "";
  submitterPhoneNumber: string = "";
  submitterEmail: string = "";

  constructor(init: StartingActivityInterface) {
    Object.assign(this, init)
    this.dateSubmitted = EnsureDate(this.dateSubmitted);
    this.startingDate = EnsureDate(this.startingDate);
    this.date = EnsureDate(this.date);
  }

  filteredObjectKeys() {
    const keys = Object.keys(this);
    const filterOut = ['id', 'cityId', 'idHeader', 'userId', 'workActivity'];
    return keys.filter(x => !filterOut.some(y => y === x))
  }

  toStringArray() {
    return this.filteredObjectKeys().map((key) => {
      let value: FormValue = this[key as keyof StartingActivityInterface];
      [key, value] = formTypesToString(key, value);

      return {
        key,
        value
      }
    });
  }

}



export interface StartingActivityHeader {
  id: string;
  dateSubmitted: Date | null;
  detailsHistory: StartingActivityInterface[];
  latestVersion: StartingActivityInterface;
  status: string;
  statusId: string;

}