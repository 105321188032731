import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Container, Grid, Header, Message, Segment, Image, Divider } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { useStore } from "../../app/stores/store";
import ELearningHeader from "./ELearningHeader";

export default observer(function Certificates() {
  const { learningStore: { getUserCertificates, userCertificates, loadTrainings, sortedTrainings } } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    getUserCertificates();
    loadTrainings();
  }, [getUserCertificates, loadTrainings])


  return (<Container>
    <br />
    <ELearningHeader
      content="Сертификати"
    />

    {/* <Header as={'h1'} textAlign='center' attached="top" image='/images/icons/school.png' content='Сертификати' /> */}
    <Segment>
      <Grid>
        <Grid.Column width={4} style={{background:'whitesmoke'}}>
          <Segment style={{border:'none', background:'whitesmoke'}}>
            <Button fluid onClick={() => {
              navigate(`/e-learning/dashboard`)
            }} content="Назад" icon="chevron left" labelPosition="left" color="red" />
          </Segment>
          <Segment style={{background:'whitesmoke'}}>
            <Header style={{border:'none', background:'whitesmoke'}} as="h2" attached="top" textAlign="center" content="Добре дојдовте на екранот за Сертификатите" />
            <Divider style={{background:'#2185d0'}}/>
            <br />
            <p>Овде ќе можете да ги симнете вашите постигнати сертификати.
              Сертификатите се стекнуваат откако успешно ќе го завршите целиот тренинг.
            </p>
          </Segment>
        </Grid.Column>
        <Grid.Column width={12} style={{background:'whitesmoke'}}>
          <Segment style={{border:'none', background:'whitesmoke'}}>
            {userCertificates && userCertificates.map(x => {
              return <>
                <Card.Group>
                  <Card color="blue">
                    <Image src='/images/elearning/components/CertificateTemplate.jpg' />
                    <Card.Content>
                      <Card.Header>{x.training.trainingName}</Card.Header>
                      <Card.Description>
                        Превземи го сертификатот <strong></strong>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <div className='ui two buttons'>
                        <Button content="Превземи" onClick={() => agent.Learning.downloadCertificate(x.id)} />
                      </div>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </>
            })}
            {!userCertificates && <Message content="You have not earned any certificates or they have expired" info />}
          </Segment>
        </Grid.Column>
      </Grid>
    </Segment>
  </Container>)
})