import React from 'react'
import NumbersDiagramItem from './NumbersDiagramItem'

export default function NumbersDiagram() {

    var tempArr = [{}, {}, {}, {}, {},];

    var colors =['red','green','orange']

    return (
        <>
            {tempArr.map((x, i) => (
                <NumbersDiagramItem
                    onClick={() => {}}
                    offsetWidth={(i * 70) + 400}
                    text='dusko'
                    backgroundColor={'green'}
                />
            ))}
        </>
    )
}