import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";

export default function ELearningStickyHeader() {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const { currentLoggedInUser } = userStore;

  return (
    <>
      <div style={{ width: "100%", height: "50px", background: "whitesmoke" }}>
        <Container>
          <div style={{ display: "flex" }}>
            <div
              style={{
                padding: "15px",
                color: "red",
                fontSize: "16px",
                width: "90%",
                fontWeight: "bold",
              }}
            >
              Добре дојде{" " + currentLoggedInUser?.firstName}
            </div>
            <div style={{ padding: "15px", width: "10%" }}>
              <button
                className="header-login-button"
                onClick={() => {
                  navigate("/");
                }}
              >
                Одјави се
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
