import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { SliderSlide } from "../models/CMS/Homepage/sliderSlide";
import { store } from "./store";


// TODO
export default class SliderStore {
  sliderRegister = new Map<string, SliderSlide>();
  target: string | null = null;

  selectedSlide: SliderSlide | null = null;
  setSelectedSlideId(id: string) {
    const slide = this.sliderRegister.get(id);
    this.selectedSlide = slide || null;
  }
  clearSelectedSlide = () => {
    this.selectedSlide = null;
  }

  constructor() {
    makeAutoObservable(this);
  }

  get sliderSlides() {
    return Array.from(this.sliderRegister.values());
  }

  get sliderSlidesSelectedLang() {
    return Array.from(this.sliderRegister.values())
    .filter(x=> x.translations.find(x=>x.languageId)?.toString() === store.commonStore.language)
    .sort((a, b) => a.itemOrder - b.itemOrder)
  }



  loadSlider = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Slider.list();
      runInAction(() => {
        this.sliderRegister.clear();
        result.forEach(x => {
          this.sliderRegister.set(x.id,x)
        });
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loading = false;
  loadingInitial = false;

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  } 
  setTarget = (target: string | null) => {
    this.target = target;
  }


  load = async () => {
    this.setLoading(true)
    try {
      const sliderSlides = await agent.Slider.list();
      runInAction(() => {
        sliderSlides.forEach(x => {
          this.sliderRegister.set(x.id, x);
        })
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadSingle = async (id: string) => {
    this.setLoading(true)
    try {
      const sliderSlide = await agent.Slider.details(id);
      runInAction(() => {
        this.sliderRegister.set(sliderSlide.id, sliderSlide);
        this.selectedSlide = sliderSlide;
      })
      return sliderSlide;
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }

  }

  removeSlide = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Slider.delete(id);
      runInAction(() => {
        this.sliderRegister.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Isotope');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  createSlide = async (slide: SliderSlide) => {
    this.setLoading(true)
    try {
      await agent.Slider.create(slide);
      runInAction(() => {
        this.sliderRegister.set(slide.id,slide);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  updateSlide = async (slide: SliderSlide) => {
    this.setLoading(true)
    try {
      await agent.Slider.update(slide);
      runInAction(() => {
        this.sliderRegister.set(slide.id, slide);
        this.selectedSlide = slide;
      })
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false)
    }
  }

  
  private reorderItem = async (id: string, increment?: boolean) => {
    this.setLoading(true)
    try {
      const arr = Array.from(this.sliderRegister.values())
      const currentItem = this.sliderRegister.get(id);
      if (!currentItem) {
        return;
      }
      const oldItemOrder = currentItem.itemOrder;
      const newItemOrder = increment ? oldItemOrder + 1 : oldItemOrder -1; 

      const swapsOrderWith = arr.filter(x => x.itemOrder === newItemOrder);
      await swapsOrderWith.forEach(async x => {
        x.itemOrder=oldItemOrder;
        await agent.Slider.update(x);
        this.sliderRegister.set(x.id, x);
      });
      currentItem.itemOrder =newItemOrder;
      await agent.Slider.update(currentItem);
      this.sliderRegister.set(currentItem.id, currentItem);
    } catch (error) {
      console.log(error)
      toast.error('Failed to update order');
    } finally {
      this.setLoading(false)
    }
  }

  orderDecrement = async (id:string) => {
    return await this.reorderItem(id, false);
  }
  orderIncrement = async (id: string) => {
    return await this.reorderItem(id, true);
  }

}