import React, { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import MyDateInput from "../forms/inputs/MyDateInput";
import "./CategorySearch.css";
import { BiSearchAlt2 } from "react-icons/bi";

interface InitialValues {
  year: Date | null;
  term?: string;
}

const initialValues: InitialValues = {
  year: null,
  term: "",
};

interface Props {
  onSubmit: ((values: InitialValues) => void),
}

export const CategorySearch = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<string>();

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
     {({ submitForm }) => (
        <Form autoComplete="Off">
          <div className="category-search">
            <div className="search-by-year" >
              <p>{t("search-by-year")}</p>
              <MyDateInput
                name="year"
                showYearPicker
                dateFormat="yyyy"
                onSelect={(date)=> {setSelectedDate(date.getFullYear().toString())
                  submitForm();
                }}

              />
            </div>
            <div className="search-bar" >
              <p>{t("search-by-keyword")}</p>
              <div className="search-input">
                <Field
                  name="term"
                />
              </div>
            </div>

            <div className="submit-search-button" >
              <button className="submit-search" type="submit">
                <BiSearchAlt2 size={18} />
              </button>
            </div>
          </div>
        </Form>
     )}
      </Formik>
    </>
  );
};
