import { Markup } from "interweave";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Icon, Accordion, AccordionTitle, AccordionContent } from "semantic-ui-react";
import { Languages } from "../../../app/common/types/CustomTypes";
import { Accordion as AccordionObj } from "../../../app/models/CMS/Plugin/accordionItem";
import { Styles } from "../../../app/models/styles";

interface Props {
  accordion: AccordionObj;
  language: Languages;
}

const styles: Styles = {
  // image: { width: '200px'},
  // imageWrap:{ float: 'left', padding: "10px", paddingTop:"0"},
  // inner: {border: '1px solid #e5e5e5',padding:'20px'},
  // clearfix: {clear: "both"},
  // directorName: {fontSize: "24px", textAlign: 'center', marginBottom: 0},
  // email: {textAlign:'center'},
  // header: {fontWeight: 700}
}

export default function AccordionPageMarkup({ accordion, language }: Props) {
  const [activeItem,setActiveItem] = useState<any>('');
  const { t } = useTranslation();
  return <div>
    <h3 style={styles.header}>{accordion.name}</h3>
    <Accordion fluid styled>
      {accordion.accordionItems?.map(x=> {
        const translation = x.translations?.find(x=> x.languageId === language )
        if(translation){
          return <Fragment key={x.id}>
          <AccordionTitle active={activeItem === x.id}
          index={x.id}
          onClick={(e,titleProps)=> {
            if(titleProps.index === activeItem){
              setActiveItem('');
              return
            }
            setActiveItem(titleProps.index)}}
          >{translation.header}</AccordionTitle>
          <AccordionContent active={activeItem === x.id}><Markup content={translation.content} /></AccordionContent>
        </Fragment>
        }
        return <></>
      })}
    </Accordion>
  </div>
}