import React from "react";
import { Label } from "semantic-ui-react";

interface Props {
  touched?: boolean;
  error?: string;
  children?: React.ReactChild | React.ReactChild[];
}

const MyRadioGroup: React.FC<Props> = ({ touched, children, error }) => {
  return (
    <div>
      {children}
      {touched && !!error ? (
        <Label basic color="red" style={{ marginTop: "15px" }}>
          {error}
        </Label>
      ) : null}
    </div>
  );
};

export default MyRadioGroup;
