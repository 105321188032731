import { Link } from "react-router-dom";
import { Button, ButtonGroup, Icon, Table } from "semantic-ui-react";
import { TaxonomyTerm } from "../../../../app/models/CMS/taxonomyTerm";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import ModalDialog from "../../../../app/common/modals/ModalDialog";
interface Props {
  sortedPostCategories: TaxonomyTerm[];
  deletePostCategory: (id: string) => void;
  submitting: boolean;
  target: string;
  postType: string;
}

export default function CategoryPostTable({
  sortedPostCategories,
  deletePostCategory,
  submitting,
  target,
  postType,
}: Props) {
  const { t } = useTranslation();
  const {modalStore} = useStore();
  const {openModal, closeModal} = modalStore;
  return (
    <>
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("category-name-mk")}</Table.HeaderCell>
            <Table.HeaderCell>{t("category-name-al")}</Table.HeaderCell>
            <Table.HeaderCell>{t("category-name-en")}</Table.HeaderCell>
            <Table.HeaderCell>{t("slug")}</Table.HeaderCell>
            <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedPostCategories.map((category) => {
            const mkTrans = category.translations.find(
              (x) => x.languageId === "MK"
            );
            const sqTrans = category.translations.find(
              (x) => x.languageId === "SQ"
            );
            const enTrans = category.translations.find(
              (x) => x.languageId === "EN"
            );
            return (
              <>
                <Table.Row key={category.id}>
                  <Table.Cell>{mkTrans?.termName || ""}</Table.Cell>
                  <Table.Cell>{sqTrans?.termName || ""}</Table.Cell>
                  <Table.Cell>{enTrans?.termName || ""}</Table.Cell>
                  <Table.Cell>{category.slug}</Table.Cell>
                  <Table.Cell>
                    <ButtonGroup>
                      <Button
                        as={Link}
                        to={`/admin/${postType}s/category/edit/${category.id}`}
                        icon="edit"
                        inverted
                        color="green"
                      />
                      <Button
                        name={category.id}
                        loading={target === category.id && submitting}
                        onClick={(e) => {
                          openModal(
                            <ModalDialog 
                          cancel={closeModal}
                          noText='Cancel'
                          confirm={() => {
                          e.preventDefault();
                          deletePostCategory(category.id);
                          closeModal();
                        }}
                        title={t(
                          "are-you-sure-you-want-to-delete-this?"
                        )}
                        description={t(
                          "once-you-delete-the-user-the-data-will-be-lost-forever"
                        )}
                          />
                          )
                        }}
                        icon="close"
                        inverted
                        color="red"
                      />
                    </ButtonGroup>
                  </Table.Cell>
                </Table.Row>
              </>
            );
          })}
        </Table.Body>
      </Table>

      <div style={{ textAlign: "right" }}>
        <Link
          to={`/admin/${postType}s/category/create`}
          className="ui button teal"
        >
          <Icon name="plus" />
          {t("add-new-category")}
        </Link>
      </div>
    </>
  );
}
