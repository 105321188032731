import React, { useEffect, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import {
  Segment,
  Button,
  Image,
  Header,
  Grid,
  GridColumn,
  Label,
} from "semantic-ui-react";
import MyTextInput from "./inputs/MyTextInput";
import MyDateInput from "./inputs/MyDateInput";
import MyTextArea from "./inputs/MyTextArea";
import MySelectInput from "./inputs/MySelectInput";
import MyRadio from "./inputs/MyRadio";
import MyRadioGroup from "./inputs/MyRadioGroup";
import MyCheckboxGroup from "./inputs/MyCheckboxGroup";
import MyCheckbox from "./inputs/MyCheckbox";
import SearchInput from "../../app/common/Inputs/SearchInput";
import MyMultipleSelect from "./inputs/MyMultipleSelect";
import { KnownSubmitterSchema } from "./schemas/KnownSubmitterSchema";
import { KnownSubmitterInterface } from "../../app/models/Forms/KnownSubmitterInterface";
import { FormPDF } from "./pdf/FormPDF";
import { PdfFormFieldInfo } from "./pdf/FormPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useStore } from "../../app/stores/store";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
// import { City } from "../../app/models/Regions/City";
import moment from "moment-timezone";
import agent from "../../app/api/agent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { values } from "mobx";
import KnownForm from "./commonForms/Grievances/KnownFrom";


export default observer(function KnownSubmitterForm() {
  const { modalStore, codesStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const navigate = useNavigate();
  const {
    loadCities,
    citiesDropdown,
    loadNkds,
    nkdDropdown,
    loadVrBzr,
    vrBzrDropdown,
    loadVrRo,
    vrRoDropdown,
  } = codesStore;

  useEffect(() => {
    loadVrBzr();
    loadVrRo();
    loadCities();
    loadNkds();
  }, [loadVrBzr, loadVrRo, loadCities, loadNkds]);

  if (!vrBzrDropdown || !vrRoDropdown || !nkdDropdown || !citiesDropdown) {
    return <LoadingComponent />;
  }

  const rightsArray = [...vrBzrDropdown, ...vrRoDropdown];

  const handleSubmit = async (
    values: KnownSubmitterInterface,
    actions: FormikHelpers<KnownSubmitterInterface>
  ) => {
    try {
      await agent.Forms.knownSubmit(values);
    } catch {
      toast.error("Неуспешно испратена пријава");
      return;
    }

    const city = citiesDropdown.find((x) => x.value === values.cityId)?.text;

    const occupation = nkdDropdown.find(
      (x) => x.value === values.activity
    )?.description;

    const vr = values.violatedRight;
    const rights = vr
      .map((x) => rightsArray.find((y) => x === y.value)?.description)
      .join("\n\n");

    const infoArray: PdfFormFieldInfo[] = [
      {
        inputMainLabel: "ОБЛАСТ НА КОЈА СЕ ОДНЕСУВА ПРИЈАВАТА:",
        inputValue: values.region.join("\n"),
      },
      {
        inputMainLabel: "ЛИЦЕ:",
        inputValue: values.entity,
      },
      {
        inputMainLabel:
          "1. Име и презиме (подносител/и на претставка или синдикат):",
        inputValue: values.submitterName,
      },
      {
        inputMainLabel:
          "2. Вашата адреса (подносител/и на претставка или синдикат):",
        inputValue: values.submitterAddress,
      },
      {
        inputMainLabel: "3. Е-mail адреса:",
        inputValue: values.email,
      },
      {
        inputMainLabel:
          "4. Број за контакт (подносител/и на претставка или синдикат):",
        inputValue: values.phoneNumber,
      },
      {
        inputMainLabel: "5. Работодавач/компанија за кој/а се жалите:",
        inputValue: values.employerName,
      },
      {
        inputMainLabel: "6. Дејност-шифрарник:",
        inputValue: occupation?.toString(),
      },
      {
        inputMainLabel: "7. Адреса на субјектот за кој се жалите:",
        inputValue: values.address,
      },
      {
        inputMainLabel: "8. Град/Регион:",
        inputValue: city?.toString(),
      },
      {
        inputMainLabel: "9. Прекршени права:",
        inputValue: rights.toString(),
      },
      {
        inputMainLabel: "10. Вашата преставка е:",
        inputValue: values.request,
      },
    ];
    openModal(
      <Segment placeholder>
        <Grid>
          <GridColumn>
            <div>
              <PDFDownloadLink
                document={
                  <FormPDF
                    values={infoArray}
                    title={"ПРИЈАВА ОД ПОЗНАТ ПОДНОСИТЕЛ"}
                  />
                }
                fileName="ПознатПодносител.pdf"
                className="btn-form-submit"
              >
                <p
                  onClick={(e) => e.preventDefault()}
                  className="text-form-modal"
                >
                  Дали сакате да превземете во PDF-формат?
                </p>
                <button
                  className="button-form-modal"
                  onClick={() => {
                    window.location.href = '/'
                    // closeModal();
                  }}
                >
                  Превземи
                </button>
              </PDFDownloadLink>
            </div>
          </GridColumn>
        </Grid>
        <div className="close-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            X
          </button>
        </div>
      </Segment>
    );
  };

  return (
    <KnownForm
      nkdDropdown={nkdDropdown}
      citiesDropdown={citiesDropdown}
      handleSubmit={handleSubmit}
      vrBzrDropdown={vrBzrDropdown}
      vrRoDropdown={vrRoDropdown}
    />
  );
});
