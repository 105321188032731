import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Segment,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { useStore } from "../../stores/store";
import ReportTablePagination from "./ReportTablePagination";

interface Props {
  selectedPath: string;
  setDocumentPath: (doc: string) => void;
}

export default observer(function DocumentTable({
  selectedPath,
  setDocumentPath,
}: Props) {
  const [searchParam, setSearchParam] = useState<string>("");
  const {
    mediaStore: {
      loadDocuments,
      unloadDocuments,
      documentList,
      pagination,
      setPagingParams,
      addFilterParam,
      pagingParams,
    },
  } = useStore();
  const { t } = useTranslation();
  React.useEffect(() => {
    loadDocuments();

    return () => {
      unloadDocuments();
    };
  }, [loadDocuments, unloadDocuments]);

  const handlePerPageChange = (value?: string) => {
    const pageSize = parseInt(value || "0");
    setPagingParams({
      pageNumber: 1,
      pageSize,
    });
  };

  const handlePaginationChange = (activePage?: string | number) => {
    if (activePage) {
      const pageNumber = parseInt(activePage.toString());
      const { pageSize } = pagingParams;
      setPagingParams({
        pageNumber,
        pageSize,
      });
    }
  };
  return (
    <div style={{ overflowY: "scroll" }}>
      <Segment>
        <Input
          type="text"
          onChange={(e, data) => setSearchParam(data.value)}
          value={searchParam}
        />
        <Button
          icon="search"
          onClick={(e) => {
            e.preventDefault();
            addFilterParam("Name", searchParam);
          }}
        />
      </Segment>
      <Table>
        <TableHeader>
          <TableHeaderCell>{t("document-path")}</TableHeaderCell>
          <TableHeaderCell>{t("actions")}</TableHeaderCell>
        </TableHeader>
        <TableBody>
          {documentList.map((x) => {
            return (
              <TableRow>
                <TableCell>{x.path}</TableCell>
                <TableCell>
                  <Button
                    disabled={x.path === selectedPath}
                    onClick={() => setDocumentPath(x.path)}
                    content={t("select")}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ReportTablePagination
        handlePaginationChange={handlePaginationChange}
        handlePerPageChange={handlePerPageChange}
        numPages={pagination!.totalPages}
        perPage={pagination!.itemsPerPage}
        activePage={pagination!.currentPage}
      />
    </div>
  );
});
