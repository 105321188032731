import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";
import {
  getColNames,
  ICodeListContainer,
  IPaginatedReportMeta,
  removedHiddenCols,
  replaceLookupValues,
} from "./ReportTableFunctions";
import { debug } from "console";

interface Props {
  data: any[];
  actionButtons?: (obj?: any) => React.ReactNode;
  meta?: IPaginatedReportMeta[];
  codeLists?: ICodeListContainer;
}

const cellStyle = { paddingTop: "0", paddingBottom: "0", fontSize: "10px" };

export default function PaginatedReportTable({
  data,
  meta,
  actionButtons,
  codeLists,
}: Props) {
  const { t } = useTranslation();
  const sortColumnOrder = (a: string, b: string) => {
    const findColumnOrder = (a: string) => {
      return meta?.find((x) => x.type === "order" && x.key === a)?.value || -1;
    };
    const columnOrderA = findColumnOrder(a);
    const columnOrderB = findColumnOrder(b);

    const conditionMoveUp = columnOrderA < columnOrderB;
    const conditionStaySame = columnOrderA === columnOrderB;

    if (conditionMoveUp) return 1;

    if (conditionStaySame) return 0;

    return -1;
  };

  const rawRows = Object.values(data);
  const lookupReplacedRecords =
    meta && codeLists
      ? rawRows.map((r) => replaceLookupValues(r, meta, codeLists))
      : rawRows;

  const removedHiddenColumns = removedHiddenCols(lookupReplacedRecords, meta);

  const cols = Object.keys(_.first(removedHiddenColumns) || {}).sort(
    sortColumnOrder
  );
  const colNames = getColNames(removedHiddenColumns, meta).sort(
    sortColumnOrder
  );

  const tableHeaderCells = colNames.map((x) => (
    <Table.HeaderCell key={x}>{t(x)}</Table.HeaderCell>
  ));
  const tableBody = lookupReplacedRecords.map((x, ind) => {
    meta
      ?.filter((y) => y.type === "imageReplace" && y.value)
      .forEach((z) => {
        if (typeof x[z.key] === "string")
          x[z.key] = <img src={`/media/thumbnail/${x[z.key]}`} alt="product" />;
      });

    return (
      <Table.Row key={ind}>
        {_.values(_.pick(x, cols)).map((x, ind) => (
          <Table.Cell style={cellStyle} key={ind}>
            {x}
          </Table.Cell>
        ))}
        {actionButtons && <Table.Cell>{actionButtons(x)}</Table.Cell>}
      </Table.Row>
    );
  });
  return (
    <>
      <Table compact className="productTable">
        <Table.Header>
          <Table.Row>
            {tableHeaderCells}
            {actionButtons && (
              <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
    </>
  );
}
