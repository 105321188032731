import React from "react";
import { DropdownItemProps, Grid, Header } from "semantic-ui-react";
import MySelectInput from "../../inputs/MySelectInput";
import "./MiddleHeader.css";


interface Props {
    citiesDropdown: DropdownItemProps[] | undefined;
  }

export default function MiddleHeader({citiesDropdown}: Props) {
  return (
    <>
      <Grid className="middle-header-position" stackable>
        <Grid.Row>
          <div  className="dit-label">
            <p>
              Државен инспекторат за труд
            </p>
            <MySelectInput
              options={citiesDropdown || []}
              label="Подрачје"
              name="cityId"
              placeholder="Внесете град"
            />
            </div>
        </Grid.Row>
        <Grid.Row>
          <div className="place-attestation-position">
            <Header className="place-attestation">
              место за заверка на <br /> Државниот инспекторат за труд
            </Header>
            </div>
        </Grid.Row>
      </Grid>
    </>
  );
}
