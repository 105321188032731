import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Post } from "../models/CMS/post";
import { store } from "./store";

export default class HomePageStore {
  newsRegistry = new Map<string, Post>();

  constructor() {
    makeAutoObservable(this);
  }

  get news() {
    return Array.from(this.newsRegistry.values());
  }

  loadLatestNews = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Posts.frontPageNews();
      runInAction(() => {
        this.newsRegistry.clear();
        result.forEach(x => {
          this.newsRegistry.set(x.id, store.postStore.handlePost(x))
        });
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loading = false;
  loadingInitial = false;

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }



}