import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TaxonomyTerm } from "../../../../../app/models/CMS/taxonomyTerm";
import { useStore } from "../../../../../app/stores/store";
import PostCategoryForm from "../../components/PostCategoryForm";

const singularName = "Page";
const pluralName = "Pages";
const linkPath = "/admin/pages";
const postType = "page";
const postTaxonomyType = "page_categories";
const iconName = "pin";

export default observer(function PageCategoryCreate() {
  const {
    postCategoryStore: {
      createPostCategory,
      loadPostCategories,
      clearSelectedCategory,
    },
  } = useStore();

  React.useEffect(() => {
    loadPostCategories(postTaxonomyType);

    return () => clearSelectedCategory();
  }, [loadPostCategories, clearSelectedCategory]);

  const navigate = useNavigate();
  const onSubmit = (values: TaxonomyTerm) => {
    values.taxonomySlug = postTaxonomyType;
    createPostCategory(values).then(() =>
      navigate(`${linkPath}/category/list`)
    );
  };

  return (
    <PostCategoryForm
      goBack={() => navigate(`${linkPath}/category/list`)}
      onSubmit={onSubmit}
    />
  );
});
