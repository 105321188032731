import { Icon } from "semantic-ui-react";

interface Props {
  backgroundColor: string;
  imageSrc: string;
  imageAlt: string;
  text: string;
  onClick: () => void;
}

export default function HeroMenuItem({ backgroundColor, imageSrc, imageAlt, text, onClick }: Props) {
  return (
    <li style={{ backgroundColor }} className="hero-item" onClick={onClick}>
      <div className="hero-item-img">
        <img src={imageSrc} alt={imageAlt}  />
      </div>
      <div className="hero-item-text-card" >
        <div className="card-inner">
          <p className="text">
            {text}
          </p>
            <Icon name="chevron right" />
          <p style={{ clear: 'both' }} />
        </div>
      </div>
    </li>
  )
}