import { ContactsInfo } from "../../../app/models/Plugins/pluginTypes";
import { Styles } from "../../../app/models/styles";

interface Props {
  contact: ContactsInfo;
}

const inheritedStyles: Styles = {
  cardInfoDiv: { textAlign: 'center', lineHeight: '1.3', marginBottom: '5px' }
}
const styles: Styles = {
  outer: {
    width: "250px",
    padding: '10px',
    border: '1px solid #e5e5e5',
    margin: '5px',
    boxShadow: '0 2px 5px rgba(0,0,0,.075)'
  },
  header: { ...inheritedStyles.cardInfoDiv, fontWeight: 'bold', fontSize: '16px' },
  subtitle: { ...inheritedStyles.cardInfoDiv, color: '#33333399' },
  content: { ...inheritedStyles.cardInfoDiv, marginBottom: '10px' },
  telephone: { ...inheritedStyles.cardInfoDiv, marginBottom: '10px' },
  buttonWrapper: { ...inheritedStyles.cardInfoDiv, },
  button: {
    padding: "5px 12px",
    borderRadius: "5px",
    color: '#FFF',
    textDecoration: 'none',
    background: "#1674c5",
    lineHeight: '1.3'
  },
}

export default function ContactInfoCard({ contact }: Props) {
  return <div style={styles.outer} className="contact-card-outer">
    <div style={styles.header}>{contact.name}</div>
    <div style={styles.subtitle}>{contact.vocation}</div>
    <div style={styles.content}>{contact.position}</div>
    {!!contact.telephone && <div style={styles.telephone}>{contact.telephone}</div>}
    {contact.email ? <div style={styles.buttonWrapper}>
      <div style={styles.buttonWrapper}>
        <a style={styles.button} target="_blank" href={`mailto:${contact.email}`} rel="noreferrer" >E-Mail</a>
      </div>
    </div> : <></>}

  </div>
}