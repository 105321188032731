import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { FaFileDownload } from "react-icons/fa";
import { Button, Grid, Segment } from "semantic-ui-react";
import agent from "../../../app/api/agent";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { Accident } from "../../../app/models/Reports/Accident";
import { Construction } from "../../../app/models/Reports/Construction";
import { useStore } from "../../../app/stores/store";
import MyDateInput from "../../forms/inputs/MyDateInput";
import MySelectInput from "../../forms/inputs/MySelectInput";

const initialValues: Construction = {
  dateFrom: null,
  dateTo: null,
  statusId: null,
};

export default observer(function ConstructionReport() {
  const { codesStore } = useStore();
  const { status, loadStatus } = codesStore;
  const { t } = useTranslation();

  useEffect(() => {
    loadStatus();
  }, [loadStatus]);


  return (
    <>
      <AdminHeader
        header={t("construction-works-report")}
        icon="file excel outline"
      />

      <Grid style={{ marginTop: "10px" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            const params = new URLSearchParams();
            if (values.dateFrom) {
              params.append(
                "dateFrom",
                moment(values.dateFrom).format("YYYY-MM-DD")
              );
            }
            if (values.dateTo) {
              params.append(
                "dateTo",
                moment(values.dateTo).format("YYYY-MM-DD")
              );
            }
            if (values.statusId) {
              params.append("statusId", values.statusId.toString());
            }

            agent.Reports.downloadCsv("construction", params);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Segment raised>
                <div>
                  <MyDateInput
                    name="dateFrom"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    dropdownMode="select"
                    placeholderText={t("from")}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <MyDateInput
                    name="dateTo"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    dropdownMode="select"
                    placeholderText={t("to")}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <MySelectInput
                    options={status || []}
                    label={t("form-status")}
                    name="statusId"
                    placeholder={t("please-enter-a-form-status")}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Button
                    type="submit"
                    fluid
                    style={{
                      marginBottom: "15px",
                      background: "rgb(161, 7, 5)",
                      color: "white",
                    }}
                    icon={<FaFileDownload size={18} />}
                  ></Button>
                </div>
              </Segment>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
});
