import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import agent from "../api/agent";
import { NavigationItem } from "../models/CMS/Homepage/navigationItem";

import { store } from "./store";
// TODO
export default class NavigationStore {
  navRegister = new Map<string, NavigationItem>();
  target: string | null = null;


  setTarget = (target: string | null) => {
    this.target = target;
  }

  parentDropdownt: NavigationItem[] | null = null;
  selectedNavItem: NavigationItem | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get sortedNavItems() {
    return Array.from(this.navRegister.values()).sort((a, b) => a.order - b.order);
  }

  // ...
  get sortedNavPublicItems() {
    return Array.from(this.navRegister.values()).sort((a, b) => a.order - b.order);
  }


  get parentNavigationDropdown() {
    return Array.from(this.navRegister.values())
      .filter(x => x.id !== this.selectedNavItem?.id)
      .filter(x => x.translations?.filter(x=>x.languageId  === "MK"))
      .map(x => {
        const ddItem: DropdownItemProps = {
          value: x.id,
          text: x.translations?.find(x=>x.languageId === "MK")?.title
        }
        return ddItem;
      })
  }


  loadSingleNavigation = async (id: string) => {
    this.setLoading(true);
    try {
      const result = await agent.Navigation.details(id);
      runInAction(() => {
        this.navRegister.set(result.id, result);
        this.selectedNavItem = result;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadNavigation = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Navigation.list();
      runInAction(() => {
        this.navRegister.clear();
        result.forEach(x => {
          this.navRegister.set(x.id, x)
        });
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  
  loadPublicNavigation = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Navigation.listPublic();
      runInAction(() => {
        this.navRegister.clear();
        result.forEach(x => {
          this.navRegister.set(x.id, x)
        });
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }


  createNavigation = async (navigation: NavigationItem) => {
    this.setLoading(true)
    try {
      await agent.Navigation.create(navigation);
      runInAction(() => {
        this.navRegister.set(navigation.id, navigation);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  deleteNavigation = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Navigation.delete(id);
      runInAction(() => {
        this.navRegister.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Isotope');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  updateNavigation = async (nav: NavigationItem) => {
    this.setLoading(true)
    try {
      await agent.Navigation.update(nav);
      runInAction(() => {
        this.navRegister.set(nav.id, nav);
        toast.info('Successfully updated!')
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }


  loading = false;
  loadingInitial = false;

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }



}