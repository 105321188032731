import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Table, TableRow } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";

const linkPath = "/admin/email";

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export default observer(function BzrEmailList() {
  const { codesStore } = useStore();
  const { loadBZR, bzrEmails, saveRegionEmail } = codesStore;
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [inputText, setInputText] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    loadBZR();
  }, [loadBZR]);

  return (
    <Fragment>
      {bzrEmails && (
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("e-mail")}</Table.HeaderCell>
              <Table.HeaderCell>{t("region-bzr")}</Table.HeaderCell>
              <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bzrEmails.map((x) => {
              const isSelected = selectedRowId === x.id;
              const selectMe = () => {
                setInputText(x.email || "");
                setSelectedRowId(parseInt(x.id?.toString() || ""));
              };
              return (
                <TableRow key={x.id}>
                  <Table.Cell>
                    {isSelected ? (
                      <>
                        <Input
                          type="text"
                          value={inputText}
                          onChange={(ev, data) => setInputText(data.value)}
                        />
                        <Button
                          color="blue"
                          content={t("Save")}
                          onClick={() => {
                            if (!validateEmail(inputText)) {
                              toast.error(t("email-not-valid"));
                              return;
                            }
                            saveRegionEmail(x.id, null, inputText).then(() => {
                              setSelectedRowId(-1);
                              toast.success(t("successfully-created-email"));
                            });
                          }}
                        />
                        <Button
                          color="red"
                          content="X"
                          onClick={() => setSelectedRowId(-1)}
                        />
                      </>
                    ) : (
                      x.email
                    )}
                  </Table.Cell>
                  <Table.Cell>{x.regionName}</Table.Cell>
                  <Table.Cell>
                    <Button
                      icon="edit"
                      inverted
                      color="green"
                      disabled={isSelected}
                      onClick={selectMe}
                    />
                  </Table.Cell>
                </TableRow>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </Fragment>
  );
});
