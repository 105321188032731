import { t } from "i18next";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ContactsInfoLocation } from "../models/Plugins/pluginTypes";

export default class ContactInfoStore {
  // Store Registry
  contactInfoRegistry = new Map<string, ContactsInfoLocation>();
  // Selected Obeservable
  seletedLocation: ContactsInfoLocation | null = null;

  // Variable declarations
  loading = false;
  loadingInitial = false;
  target: null | string = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Getters

  get contactLocations() {
    return Array.from(this.contactInfoRegistry.values());
  }

  // Setters 

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }
  setTarget = (state: string | null) => {
    this.target = state;
  }

  // Store methods

  createLocation = async (location: ContactsInfoLocation) => {
    this.setLoading(true);
    try {
      await agent.ContactInfos.create(location);
      runInAction(() => {
        this.contactInfoRegistry.set(location.id, location);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadLocations = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.ContactInfos.list();
      runInAction(() => {
        this.contactInfoRegistry.clear();
        result.forEach(x => {
          this.contactInfoRegistry.set(x.id, x)
        });
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoadingInitial(false)
    }
  }

  loadLocation = async (id: string) => {
    const fromMemory = this.contactInfoRegistry.get(id);
    if (fromMemory) {
      this.seletedLocation = fromMemory;
      return;
    }
    
    this.setLoadingInitial(true);
    try {
      const result = await agent.ContactInfos.details(id);
      runInAction(() => {
        this.contactInfoRegistry.set(result.id, result);
        this.seletedLocation = result;
      })
    } catch (error) {
      console.log(error)
    
      throw error;
    } finally {
      this.setLoadingInitial(false)
    }
  }

  updateLocation = async (location: ContactsInfoLocation) => {
    this.setLoading(true);
    try {
      await agent.ContactInfos.update(location);
      runInAction(() => {
        this.contactInfoRegistry.set(location.id, location);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  deleteLocation = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.ContactInfos.delete(id);
      runInAction(() => {
        this.contactInfoRegistry.delete(id);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
      this.setTarget(null);
    }
  }

}