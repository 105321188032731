import _ from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import MainFooter from "../../app/layout/MainFooter";
import MainHeader from "../../app/layout/MainHeader/MainHeader";
import { useStore } from "../../app/stores/store";
import PageMarkup from "./markup/PageMarkup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./PostPage.css";



export default observer(function PostPage() {
  const { postType, slug } = useParams();
  const {
    commonStore: {language},
    singlePostStore: { loadSinglePostBySlug, loadingInitial, content, title, featuredImagePath },
  } = useStore();
  React.useEffect(() => {
    if (postType && slug) {
      loadSinglePostBySlug(postType, slug);
    }
  }, [loadSinglePostBySlug, slug, postType]);

  if (loadingInitial || !content ) {
    return <LoadingComponent />;
  }

  return (
    <>
      <MainHeader />
      <main>
        <section>
          <div className="single-page">
            <Container>
              <div>
                <div>
                <LazyLoadImage alt={'featured-image'}
                  className="single-page-image"
                    // height={400}
                    src={`/media/images/${featuredImagePath}`} // use normal <img> attributes as props
                    // width={'100%'}
                    effect="blur"
                    placeholderSrc={`/media/thumbnail/${featuredImagePath}`}
                    />

                  {/* <img
                    className="single-page-image"
                    src={`/media/images/${featuredImagePath}`}
                    alt="featured"
                  /> */}
                  <h1 className="single-page-title">
                    {title}
                  </h1>
                  <div className="single-page-text">
                    {content.map((x,i)=> <PageMarkup contentPart={x} key={i} language={language} />)}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </section>
      </main>
      <footer>
        <MainFooter />
      </footer>
    </>
  );
});


