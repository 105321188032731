import {
  ContentBlock,
  EditorState,
  genKey,
  SelectionState,
} from "draft-js";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { useStore } from "../../../stores/store";
import ModalEditorButton from "./modals/ModalEditorButton";
import ModalEditorIFrame from "./modals/ModalEditorIFrame";
interface Props {
  onChange?: (a: any) => void;
  editorState?: any;
}

export default function AddShortcodeIframe({ onChange, editorState }: Props) {
  const { t } = useTranslation();
  const {
    modalStore: { openModal, closeModal },
  } = useStore();
  const addIframe = (iFrameSrc: string, iFrameHeight: string, iFrameWidth: string): void => {
    const newBlockKey = genKey();
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const currentBlock = contentState.getBlockForKey(
      selectionState.getStartKey()
    );
    const currentBlockKey = currentBlock.getKey();
    const blockMap = contentState.getBlockMap();
    const blocksBefore = blockMap
      .toSeq()
      .takeUntil((v: any) => v === currentBlock);
    const blocksAfter = blockMap
      .toSeq()
      .skipUntil((v: any) => v === currentBlock)
      .rest();

    // add new ContentBlock to editor state with appropriate text
    const newBlock = new ContentBlock({
      key: newBlockKey,
      type: "unstyled",
      text: `[SC:IFrame(${iFrameSrc},${iFrameHeight},${iFrameWidth})]`
    });

    const newBlockMap = blocksBefore
      .concat(
        [
          [currentBlockKey, currentBlock],
          [newBlockKey, newBlock],
        ],
        blocksAfter
      )
      .toOrderedMap();

    const selection = editorState.getSelection();

    const newContent = contentState.merge({
      blockMap: newBlockMap,
      selectionBefore: selection,
      selectionAfter: selection.merge({
        anchorKey: newBlockKey,
        anchorOffset: 0,
        focusKey: newBlockKey,
        focusOffset: 0,
        isBackward: false,
      }),
    });

    let newEditorState = EditorState.push(
      editorState,
      newContent,
      "split-block"
    );

    // programmatically apply selection on this text
    let newSelection = new SelectionState({
      anchorKey: newBlockKey,
      anchorOffset: 0,
      focusKey: newBlockKey,
      focusOffset: iFrameSrc.length,
    });

    newEditorState = EditorState.forceSelection(newEditorState, newSelection);

    // reset selection
    newSelection = new SelectionState({
      anchorKey: newBlockKey,
      anchorOffset: iFrameSrc.length,
      focusKey: newBlockKey,
      focusOffset: iFrameSrc.length,
    });

    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    if (onChange) onChange(newEditorState);
  };
  return (
    <Button
      content={t("add-iframe")}
      basic
      onClick={(e) => {
        e.preventDefault();
        openModal(
          <ModalEditorIFrame
            success={(iFrameSrc, iFrameHeight, iFrameWidth) => {
              addIframe(iFrameSrc, iFrameHeight, iFrameWidth);
              closeModal();
            }}
            cancel={closeModal}
          />
        );
      }}
    />
  );
}
