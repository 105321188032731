import { Card, CardProps, Icon, Popup } from "semantic-ui-react";

interface Props {
  backgroundImage?: string;
  locked: boolean;
  selected: boolean;
  name: string;
  desc?: string
  popupContent: string;
  finished?: boolean;
  onClick: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: CardProps) => void) | undefined
}

export default function ModuleCard({ locked, selected, name, desc, onClick, popupContent, backgroundImage }: Props) {
  const imagePath = `${backgroundImage}`;
  if (locked)
    return (<Popup position="bottom right" content={popupContent} trigger={<div className="module-locked">
      <Card
        style={{fontSize:'12px'}}
        color={selected ? "blue" : undefined}
        image={!!backgroundImage && imagePath}
        header={name}
        description={desc && desc.slice(0, 200) + (desc.length > 201 ? '...' : '')}
      />
      <Icon name="lock" size="huge" className="lock" />
    </div>} />
    )


    return (<>
    {/* <Popup  trigger={ */}
    
    <div className="module-finished">
      <Card
        link
        style={{fontSize:'12px'}}
        image={!!backgroundImage && imagePath}
        color={selected ? "blue" : undefined}
        onClick={onClick}
        header={name}
        description={desc && desc.slice(0, 200) + (desc.length > 201 ? '...' : '')}
      />
      <Icon name="check" size='big' className="check" color="green" />
    </div>
  
  {/* } /> */}
    </>
    )

}