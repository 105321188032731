import * as React from 'react';
import { Button, Header, Icon, Modal, SemanticICONS } from 'semantic-ui-react';

interface Props {
  title: string;
  description: string;
  confirm: () => void;
  cancel: () => void;
  icon?: SemanticICONS;
  yesText? : string;
  noText? : string;
}

export default function 
({ title, description, confirm, cancel, icon="delete", yesText="Yes", noText="No" }: Props) {
  return (
    <>
      <Header icon={!!icon} >
       {icon && <Icon name={icon} />}
       {title}
      </Header>
      <Modal.Content>
        <p>
          {description}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' inverted onClick={() => cancel()}>
          <Icon name='remove' /> {noText}
        </Button>
        <Button color='green' inverted onClick={() => confirm()}>
          <Icon name='checkmark' /> {yesText}
        </Button>
      </Modal.Actions>
    </>
  )
}