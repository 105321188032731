import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Button,
  ButtonProps,
  Header,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";

interface Props {
  cancel: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => void;
  success: (iFrameSrc: string, iFrameHeight: string, iFrameWidth:string) => void;
}

export default function ModalEditorIFrame({ success, cancel }: Props) {
  const { t } = useTranslation();
  const [iFrameSrc, setIFrameSrc] = React.useState("");
  const [iFrameHeight, setIFrameHeight] = React.useState("");
  const [iFrameWidth, setIFrameWidth] = React.useState("");
  return (
    <>
      <Header icon>
        <Icon name="save" />
        {t("add-iframe")}
      </Header>
      <Modal.Content>
        <Input
          type="text"
          label={t("iframe-src-label")}
          value={iFrameSrc}
          onChange={(e, data) => setIFrameSrc(data.value.toString())}
          fluid
        />
        <Input
            type="text"
            label={t("iframe-height-label")}
            value={iFrameHeight}
            onChange={(e, data) => setIFrameHeight(data.value.toString())}
            fluid
          />
        <Input
            type="text"
            label={t("iframe-width-label")}
            value={iFrameWidth}
            onChange={(e, data) => setIFrameWidth(data.value.toString())}
            fluid
          />
  
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          inverted
          onClick={() => {
            if (!iFrameSrc) {
              toast.error(t("please-enter-src"));
              return;
            }

            if (!iFrameHeight) {
              toast.error(t("please-enter-height"));
              return;
            }
            
            if (!iFrameWidth) {
              toast.error(t("please-enter-width"));
              return;
            }

            success(iFrameSrc,iFrameHeight, iFrameWidth);
          }}
        >
          <Icon name="check" />
        </Button>
        <Button color="red" inverted onClick={cancel}>
          <Icon name="remove" />
        </Button>
      </Modal.Actions>
    </>
  );
}
