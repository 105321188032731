import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileWithPath, useDropzone } from "react-dropzone";
import { Button, Header, Icon, List, Segment } from "semantic-ui-react";

interface Props {
  uploadFile: (dataUrl: string, fileName: string) => Promise<void>;
  cancel?: () => void;
}

export default function DocumentDropzone({ uploadFile, cancel }: Props) {
  const [document, setDocument] = useState<File>();
  const { t } = useTranslation();

  const dzStyles = {
    border: "dashed 3px #eee",
    borderColor: "#eee",
    textAlign: "center" as "center",
    paddingTop: "20px",
    paddingBottom: "10px",
  };
  const dzActive = {
    borderColor: "green",
  };

  const handleSaveDocumentClick = async () => {
    if (!document) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(document);
    reader.addEventListener(
      "load",
      function () {
        if (reader.result && typeof reader.result === "string")
          uploadFile(reader.result, document.name).then(() => {
            setDocument(undefined);
          });
      },
      false
    );
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles[0]) setDocument(acceptedFiles[0]);
    },
    [setDocument]
  );
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ accept: { "application/pdf": [] }, onDrop });

  const files = acceptedFiles.map((file: FileWithPath) => (
    <List key={file.path}>
      <Icon name="file pdf outline" size="big" /> {file.path}
    </List>
  ));

  return (
    <>
      <div
        {...getRootProps()}
        style={isDragActive ? { ...dzStyles, ...dzActive } : dzStyles}
      >
        <input {...getInputProps()} accept=".pdf" />
        <Icon size="huge" name="upload" />
        <Header content={t("drop-.PDF-file-here")} />
      </div>

      {document && (
        <Segment>
          <Header content={t("file")} />
          <span style={{ marginLeft: "40px" }}>{files}</span>
        </Segment>
      )}

      <Button
        disabled={!document}
        style={{ marginTop: "10px" }}
        fluid
        icon
        labelPosition="left"
        primary
        size="small"
        onClick={handleSaveDocumentClick}
      >
        <Icon name="plus" />
        {t("save-document")}
      </Button>

      {!!cancel && (
        <Button
          style={{ marginTop: "10px" }}
          icon
          fluid
          labelPosition="left"
          color="red"
          size="small"
          onClick={cancel}
        >
          <Icon name="delete" /> {t("cancel")}
        </Button>
      )}

      {!cancel && !!document && (
        <Button
          style={{ marginTop: "10px" }}
          icon
          fluid
          labelPosition="left"
          color="red"
          size="small"
          onClick={() => setDocument(undefined)}
        >
          <Icon name="delete" /> {t("cancel")}
        </Button>
      )}
    </>
  );
}
