import { Markup } from "interweave";
import moment from "moment";
import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Languages } from "../../app/common/types/CustomTypes";
import { Post } from "../../app/models/CMS/post";
import "./NewsCard.css";

interface Props {
  post: Post;
  language: Languages;
}

export const NewsCard = ({ post, language }: Props) => {
  const translations = post.postTranslations.find(
    (x) => x.languageId === language
  );
  const navigate = useNavigate();
  return (
    <>
      <div
        className="news-card"
        onClick={() => navigate(`/single/${post.postType}/${post.slug}`)}
      >
        <img
          src={`/media/images/${post.featuredImagePath}`}
          alt={translations?.postTitle}
        />
        <div className="news-content">
          <div className="date">
            <AiOutlineClockCircle color="red" />
            <span>{moment(post.postDate).format("DD.MM.YYYY")}</span>
          </div>
          <div className="text" style={{ height: "280px", overflow: "hidden" }}>
            <h4>{translations?.postTitle}</h4>
            {/* <Markup content={translations?.postExcerpt} className="content" /> */}
          </div>
        </div>
      </div>
    </>
  );
};
