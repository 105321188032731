import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  Table,
  Segment,
  Button,
  Icon,
  Input,
  Label,
  Popup,
} from "semantic-ui-react";
import ReportTablePagination from "./ReportTablePagination";
import {
  getColNames,
  ICodeListContainer,
  IReportMeta,
  removedHiddenCols,
  replaceLookupValues,
} from "./ReportTableFunctions";

export interface Props {
  data: any[];
  actionButtons?: (obj?: any) => React.ReactNode;
  meta?: IReportMeta[];
  codeLists?: ICodeListContainer;
  activeRow?: string;
  defaultSortField?: string;
}

const ReportTable = ({
  data,
  actionButtons,
  meta,
  codeLists,
  activeRow,
  defaultSortField,
}: Props) => {
  const [sortBy, setSortBy] = useState(defaultSortField || "");
  const [reverseSort, setReverseSort] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const PerPageOptions = [
    { text: 10, value: 10 },
    { text: 50, value: 50 },
  ];

  const [filter, setFilter] = useState<any[]>([]);
  // const [filterM,setFilterM] = useState<any[]>([]);
  const [activePage, setActivePage] = useState(1);

  const renderSearchFilterHeaderCells = (item: string) => {
    const closeFilter = (name: string) => {
      const oldFilter = [...filter];
      const newFilter = oldFilter.filter((f) => f.name !== name);
      setFilter(newFilter);
    };
    const openFilter = (name: string) => {
      const newFilter = [...filter];
      newFilter.push({ name, value: "" });
      setFilter(newFilter);
    };
    const changeFilterValue = (name: string, value: string) => {
      const newFilter = [...filter];
      newFilter.filter((f) => f.name === name)[0].value = value;
      setFilter(newFilter);
    };

    const shown = filter.findIndex((it) => it.name === item) !== -1;
    if (shown) {
      return (
        <Table.HeaderCell key={item}>
          <Input
            labelPosition="left"
            type="text"
            fluid
            style={{ minWidth: "200px" }}
          >
            <Label>
              <Icon name="search" />
            </Label>
            <input
              type="text"
              onChange={(evt) => {
                changeFilterValue(item, evt.target.value);
                setActivePage(1);
              }}
            />
            <Button
              icon="delete"
              negative
              onClick={() => {
                closeFilter(item);
              }}
            />
          </Input>
        </Table.HeaderCell>
      );
    } else {
      return (
        <Table.HeaderCell key={item}>
          <Button
            fluid
            icon="search"
            onClick={() => {
              openFilter(item);
            }}
          />
        </Table.HeaderCell>
      );
    }
  };

  const handlePerPageChange = (value?: string) => {
    if (value) setPerPage(parseInt(value));
    setActivePage(1);
  };

  const handlePaginationChange = (activePage: string | number | undefined) => {
    if (activePage) setActivePage(parseInt(activePage.toString()));
  };

  const sortColumn = (colClicked: string) => {
    if (colClicked === sortBy && false === reverseSort) {
      setReverseSort(true);
    } else {
      setReverseSort(false);
    }
    setSortBy(colClicked);
  };

  const rawRows = Object.values(data);

  const lookupReplacedRecords = rawRows.map((r) =>
    replaceLookupValues(r, meta, codeLists)
  );
  const filterFunction = (el: any) => {
    const result: any[] = [];
    filter.forEach((f) => {
      const fieldText = el[f.name].text || el[f.name];
      const includes = fieldText.toString().includes(f.value);
      result.push(includes);
    });
    return result.length > 0 ? result.every((a) => !!a) : true;
  };

  const filteredRows = lookupReplacedRecords.filter(filterFunction);
  const sortFunction = (a: any, b: any) => {
    if (sortBy && a[sortBy] && b[sortBy]) {
      const asort = a[sortBy].text || a[sortBy];
      const bsort = b[sortBy].text || b[sortBy];

      if (false === reverseSort) {
        if (Number.isNaN(parseInt(asort, 10))) {
          return asort.localeCompare(bsort);
        }
        return asort - bsort;
      } else {
        if (Number.isNaN(parseInt(asort, 10))) {
          return bsort.localeCompare(asort);
        }
        return bsort - asort;
      }
    }
  };
  const sortedRows = filteredRows.sort(sortFunction);
  const paginatedRows = sortedRows.filter((d, ind) => {
    const beginAt = perPage * (activePage - 1);
    const endAt = beginAt + perPage;
    return ind >= beginAt && ind < endAt;
  });

  const removedHiddenColumns = removedHiddenCols(data, meta);
  const cols = Object.keys(_.first(removedHiddenColumns) || {});
  const colNames = getColNames(removedHiddenColumns, meta);

  const tableFilter = cols.map((el) => renderSearchFilterHeaderCells(el));

  const numPages = Math.ceil(filteredRows.length / perPage);
  const { t } = useTranslation();
  return (
    <Segment style={{ overflowX: "scroll" }}>
      <Table>
        <Table.Header>
          <Table.Row>
            {colNames.map((c, ind) => (
              <Table.HeaderCell key={ind} style={{ maxWidth: "400px" }}>
                <Button
                  fluid
                  onClick={() => {
                    sortColumn(cols[ind]);
                  }}
                >
                  {" "}
                  {c} <br />
                  {sortBy === cols[ind] && (
                    <Icon
                      name="arrow circle down"
                      flipped={reverseSort ? "vertically" : "horizontally"}
                      style={{ marginTop: "5px" }}
                    />
                  )}
                </Button>
              </Table.HeaderCell>
            ))}
            {actionButtons && (
              <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
            )}
          </Table.Row>
          <Table.Row>{tableFilter}</Table.Row>
        </Table.Header>
        <Table.Body>
          {paginatedRows.length > 0 &&
            paginatedRows.map(
              (r, ind) =>
                r && (
                  <Table.Row
                    key={ind}
                    style={
                      r.id && activeRow === r.id
                        ? { background: "rgba(0,181,173,0.1)" }
                        : {}
                    }
                  >
                    {cols.map((c) => (
                      <Table.Cell style={{ maxWidth: "400px" }} key={c}>
                        {r[c]?.text && (
                          <Popup
                            content={r[c].value}
                            trigger={
                              <span style={{ cursor: "pointer" }}>
                                {r[c].text}
                              </span>
                            }
                          />
                        )}
                        {!r[c]?.text && r[c]}
                      </Table.Cell>
                    ))}
                    {actionButtons && (
                      <Table.Cell>{actionButtons(r)}</Table.Cell>
                    )}
                  </Table.Row>
                )
            )}
        </Table.Body>
      </Table>

      <ReportTablePagination
        defaultPage={activePage}
        numPages={numPages}
        handlePaginationChange={handlePaginationChange}
        handlePerPageChange={handlePerPageChange}
        perPage={perPage}
        PerPageOptions={PerPageOptions}
      />
    </Segment>
  );
};

export default ReportTable;
