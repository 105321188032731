import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const KnownSubmitterSchema = yup.object().shape({
  region: yup
    .array()
    .min(1, "Одберете најмалку една од понудените области")
    .of(yup.string().required("Ова поле е задолжително")),
  entity: yup.string().required("Одберете лице"),
  submitterName: yup.string().required("Ова поле е задолжително").max(255, "Називот не смее да надминува повеќе од 255 карактери"),
  submitterAddress: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  email: yup
    .string()
    .email("Внесете валидна e-mail адреса")
    .required("Ова поле е задолжително").max(100, "Електронската пошта не смее да надминува повеќе од 100 карактери"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Внесете валиден телефонски број")
    .required("Ова поле е задолжително").max(50, "Телефонскиот број не смее да надминува повеќе од 50 карактери"),
  employerName: yup.string().required("Ова поле е задолжително").max(255, "Називот не смее да надминува повеќе од 255 карактери"),
  activity: yup.number().required("Одберете дејност").nullable(),
  address: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  cityId: yup.number().required("Одберете град").nullable(),
  violatedRight: yup
    .array()
    .min(1, "Одберете најмалку едно прекршено право")
    .of(yup.string().required("Ова поле е задолжително")),
  request: yup.string().required("Ова поле е задолжително").max(1000, "Текстот не смее да надминува повеќе од 1000 карактери"),
  // date: yup.date().required("Одберете датум").nullable(),
});
