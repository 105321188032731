import { Formik, FieldArray, FormikHelpers } from "formik";
import {
  Form,
  Grid,
  Segment,
  Header,
  Label,
  Button,
  DropdownItemProps,
} from "semantic-ui-react";
import SearchInput from "../../../../app/common/Inputs/SearchInput";
import { OvertimeWorkInterface } from "../../../../app/models/Forms/OvertimeWorkInterface";
import MyDateInput from "../../inputs/MyDateInput";
import MyTextInput from "../../inputs/MyTextInput";
import { OvertimeWorkSchema } from "../../schemas/OvertimeWorkSchema";
import FormsLogo from "../FormsLogo";
import { ButtonsReportsFooter } from "./ButtonsReportsFooter";
import MiddleHeader from "./MiddleHeader";
import "./OvertimeForm.css";

const initlValues: OvertimeWorkInterface = {
  cityId: null,
  employerName: "",
  employerAddress: "",
  employerMunicipality: "",
  employerPostalCode: "",
  employerOccupation: null,
  workplaceLocation: "",
  startTime: null,
  endTime: null,
  organizationalUnit: "",
  workPlaces: [
    {
      jobPosition: "",
      employeesNumber: null,
    },
  ],
  submitterFullname: "",
  submitterWorkPosition: "",
  submitterPhoneNumber: "",
  submitterEmail: "",
  // date: null,
};

interface Props {
  handleSubmit: (
    values: OvertimeWorkInterface,
    actions: FormikHelpers<OvertimeWorkInterface>
  ) => Promise<void>;
  nkdDropdown: DropdownItemProps[];
  citiesDropdown: DropdownItemProps[] | undefined;
  initialValues?: OvertimeWorkInterface | null;
}

export default function OvertimeWork({
  handleSubmit,
  nkdDropdown,
  citiesDropdown,
  initialValues,
}: Props) {
  // const customTimeZone = moment.tz.setDefault("Europe/Skopje");

  return (
    <>
      <style>
        {`
        .background_img {
          background-image: url("/images/background.png");
          background-repeat: no-repeat;
          background-position: 55% 45%;
          background-size: contain;
        }
        `}
      </style>
      <Grid className="form-frame ">
        <Segment className="form-segment">
          <Formik
            initialValues={initialValues || initlValues}
            validationSchema={OvertimeWorkSchema}
            enableReinitialize={!!initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched, handleSubmit }) => (
              <div className="background_img">
                <Form
                  className="form-margins"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <header>
                    <FormsLogo />
                    <MiddleHeader citiesDropdown={citiesDropdown} />
                  </header>
                  <main>
                    <div className="overtime-main-title">
                      <Header as="h1" className="overtime-title-top">
                        Известување за прекувремена работа
                      </Header>
                      <Header.Subheader>
                        (Член 117 Став 6 од Законот за работните односи <br />
                        „Службен весник на Република Македонија“ бр.25/13)
                      </Header.Subheader>
                    </div>
                    <ol className="ol">
                      <li>
                        <MyTextInput
                          label="Назив на работодавачот"
                          name="employerName"
                          type="text"
                          placeholder="Внесете назив на работодавачот"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Седиште на работодавачот</p>
                        <p>Улица, број, место</p>
                        <MyTextInput
                          name="employerAddress"
                          type="text"
                          placeholder="Внесете улица, број и место"
                        />
                        <p>Општина</p>
                        <MyTextInput
                          name="employerMunicipality"
                          type="text"
                          placeholder="Внесете општина"
                        />
                        <p>Поштенски број</p>
                        <MyTextInput
                          name="employerPostalCode"
                          type="text"
                          placeholder="Внесете поштенски број"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Дејност на работодавачот</p>
                        <SearchInput
                          name="employerOccupation"
                          setFieldValue={setFieldValue}
                          source={nkdDropdown}
                          placeholder="Внесете дејност на работодавачот"
                          defaultValue={
                            values.id ? values.employerOccupation : null
                          }
                        />
                        {errors.employerOccupation &&
                        touched.employerOccupation ? (
                          <Label basic color="red" style={{ marginTop: "3px" }}>
                            {errors.employerOccupation}
                          </Label>
                        ) : null}
                      </li>
                      <li>
                        <MyTextInput
                          label="Локација на работното место каде што ќе се извршува прекувремената работа (продавница, магацин, кат, работилница, канцеларија, друго и адреса)"
                          name="workplaceLocation"
                          type="text"
                          placeholder="Внесете локација на работното место"
                        />
                      </li>
                      <li>
                        <p className="asterisk">
                          Време на извршување на работа на прекувремената работа
                          (датум и час)
                        </p>
                        <div className="overtime-date-time">
                          <div className="overtime-date-time-field">
                            <p style={{ marginBottom: "5px" }}>
                              Почеток на работно време:
                            </p>
                            <MyDateInput
                              name="startTime"
                              showTimeSelect
                              dateFormat="d MMMM yyyy, h:mm "
                              placeholderText="Внесете почеток на работно време"
                            />
                          </div>
                          <div className="overtime-date-time-field">
                            <p style={{ marginBottom: "5px" }}>
                              Крај на работно време:
                            </p>
                            <MyDateInput
                              name="endTime"
                              showTimeSelect
                              dateFormat="d MMMM yyyy, h:mm "
                              placeholderText="Внесете крај на работно време"
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <MyTextInput
                          label="Организациона единица во која ќе се извршува прекувремената работа (во подружница или во процес од работата)"
                          name="organizationalUnit"
                          type="text"
                          placeholder="Внесете организациона единица"
                        />
                      </li>
                      <li>
                        <p className="asterisk">
                          Работни места на кои ќе се работи прекувремена работа
                        </p>
                        <FieldArray name="workPlaces">
                          {(fieldArrayProps) => {
                            const { push, remove, form } = fieldArrayProps;
                            const { values } = form;
                            const { workPlaces } = values;
                            return (
                              <div>
                                {workPlaces.map(
                                  (workPlace: any, index: any) => (
                                    <div key={index}>
                                      <div className="overtime-field-array">
                                        <div className="overtime-field">
                                          <MyTextInput
                                            name={`workPlaces[${index}].jobPosition`}
                                            placeholder="Внесете работно место"
                                            type="text"
                                          />
                                        </div>
                                        <div className="overtime-field">
                                          <MyTextInput
                                            name={`workPlaces[${index}].employeesNumber`}
                                            placeholder="Внесете број на работници"
                                            type="number"
                                          />
                                        </div>
                                      </div>
                                      {index > 0 && (
                                        <Button
                                          style={{
                                            marginTop: "5px",
                                            padding: "8px 10px",
                                          }}
                                          type="button"
                                          onClick={() => remove(index)}
                                        >
                                          Избришете работно место
                                        </Button>
                                      )}
                                    </div>
                                  )
                                )}
                                <Button
                                  style={{
                                    marginTop: "5px",
                                    padding: "8px 10px",
                                  }}
                                  floated="right"
                                  type="button"
                                  onClick={() =>
                                    push({
                                      jobPosition: "",
                                      employeesNumber: null,
                                    })
                                  }
                                >
                                  Додадете работно место
                                </Button>
                              </div>
                            );
                          }}
                        </FieldArray>
                      </li>
                    </ol>
                  </main>
                  <footer>
                    <Grid
                     className="overtime-field-by-part"
                      stackable
                    >
                      <Grid.Row
                        style={{
                          display: "block",
                        }}
                      >
                        <Header size="small" className="asterisk">
                          Пополнето од:
                        </Header>
                        <p style={{ margin: "0 0 5px 2px" }}>Име и презиме</p>
                        <MyTextInput
                          name="submitterFullname"
                          type="text"
                          placeholder="Внесете име и презиме"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>Работно место</p>
                        <MyTextInput
                          name="submitterWorkPosition"
                          type="text"
                          placeholder="Внесете работно место"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>
                          Телефонски број
                        </p>
                        <MyTextInput
                          name="submitterPhoneNumber"
                          type="tel"
                          placeholder="Внесeте телефонски број"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>Е-mail адреса</p>
                        <MyTextInput
                          name="submitterEmail"
                          type="email"
                          placeholder="Внесете e-mail адреса"
                        />
                      </Grid.Row>
                      <ButtonsReportsFooter />
                    </Grid>
                  </footer>
                </Form>
              </div>
            )}
          </Formik>
        </Segment>
      </Grid>
    </>
  );
}
