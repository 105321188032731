import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { useStore } from "../../../../stores/store";
import NavBarLogo from "../../Logo/NavBarLogo";
import HeaderInfoItemMobile from "./HeaderInfoItemMobile";
import { observer } from "mobx-react-lite";
import "./MainHeaderBottomMobile.css";

const MainHeaderBottomMobile = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(-1);
  const [titleOrder, setTitleOrder] = useState(-1);
  const {
    navigationStore: {  sortedNavPublicItems },
    commonStore: { language },
  } = useStore();

  return (
    <div className="header_bottom_mobile">
      <div className="ui container">
        <div>
          <NavBarLogo />
        </div>
        <nav>
          <ul className={`nav-links-mobile ${isOpen === true ? "open" : ""}`}>
            {sortedNavPublicItems.map((x) => (
              <HeaderInfoItemMobile
                key={x.id}
                x={x}
                active={active}
                setActive={setActive}
                titleOrder={titleOrder}
                setTitleOrder={setTitleOrder}
                language={language}
              />
            ))}
          </ul>
        </nav>
      </div>
      <button className="mobile-menu-icon" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <Icon name="times" size="big" />
        ) : (
          <Icon name="bars" size="big" />
        )}
      </button>
    </div>
  );
};
export default observer(MainHeaderBottomMobile);
