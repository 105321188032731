import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../forms/inputs/MyTextInput";

export default observer(function EditSlide() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, traningId } = useParams();
  const {
    learningStore: { updateSlide, selectedSlide, getSingleSlide },
  } = useStore();

  React.useEffect(() => {
    if (id) {
      getSingleSlide(id);
    }
  }, [getSingleSlide, id]);

  return (
    <>
      <div>
        <AdminHeader
          icon="pin"
          iconStyles={{ transform: "rotateZ(45deg)" }}
          header={t("edit-slide")}
          subheader={t("here-you-can-edit-your-slide")}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Formik
            enableReinitialize
            initialValues={selectedSlide!}
            onSubmit={(values) => {
              if (values) {
                updateSlide(values).then(() =>
                  navigate(
                    `/admin/e-learning/listSlides/${selectedSlide?.moduleId}/${traningId}`
                  )
                );
              }
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ width: "80%" }}>
                    <MyTextInput name="slideName" label={t("slide-name")} />
                    <MyTextInput
                      name="slideDescription"
                      label={t("slide-description")}
                    />
                    <MyTextInput name="slideOrder" label={t("slide-order")} />
                    <Button
                      style={{ marginTop: "10px" }}
                      color="teal"
                      type="submit"
                      content={t("submit")}
                    ></Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Segment>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Button
            color="red"
            onClick={() =>
              navigate(
                `/admin/e-learning/listSlides/${selectedSlide?.moduleId}/${traningId}`
              )
            }
          >
            <Icon name="arrow left" />
          </Button>
        </Segment>
      </div>
    </>
  );
});
