import { Formik, FormikHelpers } from "formik";
import {
  Grid,
  Segment,
  Form,
  Header,
  Label,
  DropdownItemProps,
} from "semantic-ui-react";
import SearchInput from "../../../../app/common/Inputs/SearchInput";
import { NightShiftInterface } from "../../../../app/models/Forms/NightShiftInterface";
import MyDateInput from "../../inputs/MyDateInput";
import MyTextInput from "../../inputs/MyTextInput";
import { NightShiftSchema } from "../../schemas/NightShiftSchema";
import FormsLogo from "../FormsLogo";
import { ButtonsReportsFooter } from "./ButtonsReportsFooter";
import MiddleHeader from "./MiddleHeader";
import "./NightForm.css";

const initValues: NightShiftInterface = {
  cityId: null,
  employerName: "",
  employerAddress: "",
  employerMunicipality: "",
  employerPostalCode: "",
  employerOccupation: null,
  workplaceLocation: "",
  startTime: null,
  endTime: null,
  submitterFullname: "",
  submitterWorkPosition: "",
  submitterPhoneNumber: "",
  submitterEmail: "",
  // date: null,
};

interface Props {
  citiesDropdown: DropdownItemProps[] | undefined;
  nkdDropdown: DropdownItemProps[];
  initialValues?: NightShiftInterface | null;
  handleSubmit: (
    values: NightShiftInterface,
    actions: FormikHelpers<NightShiftInterface>
  ) => Promise<void>;
}

export default function NightForm({
  citiesDropdown,
  nkdDropdown,
  handleSubmit,
  initialValues,
}: Props) {
  console.log(initialValues);

  return (
    <>
      <>
        <style>
          {`
        .background_img {
          background-image: url("/images/background.png");
          background-repeat: no-repeat;
          background-position: 17% 83%;
          background-size: contain;
        }
        `}
        </style>
        <Grid className="form-frame">
          <Segment className="form-segment">
            <Formik
              initialValues={initialValues || initValues}
              validationSchema={NightShiftSchema}
              enableReinitialize={!!initialValues}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched, handleSubmit }) => (
                <div className="background_img">
                  <Form
                    autoComplete="off"
                    className="form-margins"
                    onSubmit={handleSubmit}
                  >
                    <header>
                      <FormsLogo />
                      <MiddleHeader citiesDropdown={citiesDropdown} />
                    </header>
                    <main>
                      <div className="night-main-title">
                        <Header className="night-title-top ">
                          Известување за ноќна работа
                        </Header>
                        <Header.Subheader>
                          (Член 127 Став 3 од Законот за работните односи <br />
                          „Службен весник на Република Македонија“ бр.130/07)
                        </Header.Subheader>
                      </div>
                      <ol className="ol">
                        <li>
                          <MyTextInput
                            label="Назив на работодавачот"
                            name="employerName"
                            type="text"
                            placeholder="Внесете назив на работодавачот"
                          />
                        </li>
                        <li>
                          <MyTextInput
                            label="Седиште на работодавачот"
                            name="employerAddress"
                            type="text"
                            placeholder="Внесете седиште на работодавачот"
                          />
                          <p>Општина</p>
                          <MyTextInput
                            name="employerMunicipality"
                            type="text"
                            placeholder="Внесете општина"
                          />
                          <p>Поштенски број</p>
                          <MyTextInput
                            name="employerPostalCode"
                            type="text"
                            placeholder="Внесете поштенски број"
                          />
                        </li>
                        <li>
                          <p className="asterisk">Дејност на работодавачот</p>
                          <SearchInput
                            name="employerOccupation"
                            setFieldValue={setFieldValue}
                            source={nkdDropdown}
                            placeholder="Внесете дејност на работодавачот"
                            defaultValue={
                              values.id ? values.employerOccupation : null
                            }
                          />
                          {errors.employerOccupation &&
                          touched.employerOccupation ? (
                            <Label
                              basic
                              color="red"
                              style={{ marginTop: "3px" }}
                            >
                              {errors.employerOccupation}
                            </Label>
                          ) : null}
                        </li>
                        <li>
                          <MyTextInput
                            label="Локација на работното место каде што ќе се извршува ноќната работа (продавница, магацин, кат, работилница, канцеларија, друго и адреса)"
                            name="workplaceLocation"
                            type="text"
                            placeholder="Внесете локација на работното место"
                          />
                        </li>
                        <li>
                          <p className="asterisk">
                            Период во кој ќе се извршува ноќната работа
                          </p>
                          <div className="night-date-time">
                            <div className="night-date-time-field">
                              <p style={{ marginBottom: "5px" }}>
                                Почеток на работно време:
                              </p>
                              <MyDateInput
                                name="startTime"
                                timeIntervals={60}
                                showTimeSelect
                                dateFormat="d MMMM yyyy, h:mm aa"
                                placeholderText="Внесете почеток на работно време"
                              />
                            </div>
                            <div className="night-date-time-field">
                              <p style={{ marginBottom: "5px" }}>
                                Крај на работно време:
                              </p>
                              <MyDateInput
                                name="endTime"
                                showTimeSelect
                                dateFormat="d MMMM yyyy, h:mm aa"
                                placeholderText="Внесете крај на работно време"
                              />
                            </div>
                          </div>
                        </li>
                      </ol>
                    </main>
                    <footer>
                      <Grid className="night-field-by-part " stackable>
                        <Grid.Row
                          style={{
                            display: "block",
                          }}
                        >
                          <Header size="small" className="asterisk">
                            Пополнето од:
                          </Header>
                          <p style={{ margin: "0 0 5px 2px" }}>Име и презиме</p>
                          <MyTextInput
                            name="submitterFullname"
                            type="text"
                            placeholder="Внесете име и презиме"
                          />
                          <p style={{ margin: "8px 0 5px 2px" }}>
                            Работно место
                          </p>
                          <MyTextInput
                            name="submitterWorkPosition"
                            type="text"
                            placeholder="Внесете работно место"
                          />
                          <p style={{ margin: "8px 0 5px 2px" }}>
                            Телефонски број
                          </p>
                          <MyTextInput
                            name="submitterPhoneNumber"
                            type="tel"
                            placeholder="Внесeте телефонски број"
                          />
                          <p style={{ margin: "8px 0 5px 2px" }}>
                            Е-mail адреса
                          </p>
                          <MyTextInput
                            name="submitterEmail"
                            type="email"
                            placeholder="Внесете e-mail адреса"
                          />
                        </Grid.Row>
                        <ButtonsReportsFooter />
                      </Grid>
                    </footer>
                  </Form>
                </div>
              )}
            </Formik>
          </Segment>
        </Grid>
      </>
    </>
  );
}
