interface Props {
  heading: string;
  specialClass?: string;
}

export default function BoxHeading({heading, specialClass}: Props) {
return (<div className={`heading-wrapper ${specialClass} `}>
  <div className="box-heading">
    <h2>{heading}</h2>
  </div>
</div>)
}