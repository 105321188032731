import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, Card, Menu, Message, Segment } from "semantic-ui-react";
import ModalDialog from "../../../app/common/modals/ModalDialog";
import ReportTableFilters from "../../../app/common/table/ReportTableFilters";
import ReportTablePagination from "../../../app/common/table/ReportTablePagination";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import MediaFilterModal from "./MediaFilterModal";
import PhotoCard from "./photoCard";

export default observer(function MediaList() {
  const { t } = useTranslation();
  const { mediaStore, modalStore } = useStore();
  const { openModalBasic, closeModal, openModal } = modalStore;

  const {
    mediaList,
    pagination,
    setPagingParams,
    pagingParams,
    deleteMedia,
    loading,
    uploadTarget,
    loadingInitial,
    addFilterParam,
    clearFilterParams,
    filterParams,
    removeFilterParam,
    setMultipleStaticFilterParam,
    clearStaticFilterParams,
  } = mediaStore;

  const [activeItem, setActiveItem] = useState("photo");
  useEffect(() => {
    return () => clearStaticFilterParams();
  }, [clearStaticFilterParams]);

  useEffect(() => {
    console.log(activeItem);
    if (activeItem === "document") {
      setMultipleStaticFilterParam([{ name: "TypeId", value: "3" }]);
      return;
    }
    setMultipleStaticFilterParam([{ name: "TypeId", value: "1" }]);
  }, [activeItem, setMultipleStaticFilterParam]);

  if (loadingInitial) return <LoadingComponent />;

  const handlePerPageChange = (value?: string) => {
    const pageSize = parseInt(value || "0");
    setPagingParams({
      pageNumber: 1,
      pageSize,
    });
  };

  const handlePaginationChange = (activePage?: string | number) => {
    if (activePage) {
      const pageNumber = parseInt(activePage.toString());
      const { pageSize } = pagingParams;
      setPagingParams({
        pageNumber,
        pageSize,
      });
    }
  };

  const PerPageOptions = [
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 10, value: 10 },
    { text: 50, value: 50 },
  ];

  const extraContent = (idstring: string) => {
    const photoName = mediaList.find((x) => x.id === idstring)?.path;
    return (
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            icon="close"
            color="red"
            onClick={() =>
              openModalBasic(
                <ModalDialog
                  yesText={t("delete")}
                  noText={t("cancel")}
                  cancel={closeModal}
                  confirm={() => {
                    closeModal();
                    deleteMedia(idstring);
                  }}
                  title={t("delete-photo?")}
                  description={t("do-you-really-want-to-delete-the-photo")}
                />
              )
            }
            loading={loading && uploadTarget === idstring}
          />
        </div>
      </Card.Content>
    );
  };

  return (
    <>
      <div>
        <Menu attached="top">
          <Menu.Item
            name={t("photos")}
            active={activeItem === "photo"}
            onClick={() => setActiveItem("photo")}
          />
          <Menu.Item
            name={t("Documents")}
            active={activeItem === "document"}
            onClick={() => setActiveItem("document")}
          />
        </Menu>
        <Segment loading={loadingInitial} attached="bottom">
          <Segment>
            <Button
              content={t("add-filter")}
              style={{ marginBottom: "10px" }}
              onClick={(e) => {
                openModal(
                  <MediaFilterModal
                    closeModal={closeModal}
                    setFilter={addFilterParam}
                  />
                );
              }}
            />
            {filterParams.length > 0 && (
              <Button
                content={t("clear-all-filters")}
                onClick={(e) => {
                  clearFilterParams();
                }}
              />
            )}

            <ReportTableFilters
              filterParams={filterParams}
              removeFilterParam={removeFilterParam}
            />
          </Segment>

          {mediaList.length === 0 && (
            <Message info>{t("there-are-no-media-items")}</Message>
          )}

          <Card.Group itemsPerRow={6}>
            {mediaList.map((x) => {
              const id = x.id.toString();
              const imageName = x.path.substring(x.path.lastIndexOf("\\") + 1);
              const imagePath = `/media/thumbnail/${imageName}`;
              const type = x.typeId;
              const imagePathOrigin =
                type === 3
                  ? `/media/documents/${x.path}`
                  : `/media/images/${imageName}`;

              return (
                <PhotoCard
                  imageName={imageName}
                  imagePath={imagePath}
                  imagePathOrigin={imagePathOrigin}
                  id={id}
                  key={id}
                  extraContent={extraContent}
                  type={type}
                />
              );
            })}
          </Card.Group>
          <ReportTablePagination
            numPages={pagination?.totalPages || 0}
            perPage={pagination?.itemsPerPage || 0}
            PerPageOptions={PerPageOptions}
            activePage={pagination?.currentPage}
            handlePerPageChange={handlePerPageChange}
            handlePaginationChange={handlePaginationChange}
          />
        </Segment>
      </div>
    </>
  );
});
