import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Segment,
  Tab,
  Image,
  Header,
  SegmentGroup,
  Divider,
} from "semantic-ui-react";
import { v4 } from "uuid";
import PhotoSelectWidget from "../../../../app/common/imageUpload/photoSelectWidget";
import MyEditorInput from "../../../../app/common/Inputs/MyEditorInput";
import SelectInput from "../../../../app/common/Inputs/SelectInput";
import { Post, PostValues } from "../../../../app/models/CMS/post";
import { useStore } from "../../../../app/stores/store";
import MyCheckbox from "../../../forms/inputs/MyCheckbox";
import MyCheckboxGroup from "../../../forms/inputs/MyCheckboxGroup";
import MyTextInput from "../../../forms/inputs/MyTextInput";
interface Props {
  initialValues?: Post;
  onSubmit: (values: PostValues) => void;
  postType: string;
  goBack: () => void;
  singularName: string;
}

export default observer(function PostForm({
  initialValues,
  onSubmit,
  postType,
  goBack,
  singularName,
}: Props) {
  const {
    postStore: { clearSelectedPost, postStatusOptions },
    postCategoryStore: { loadPostCategories, allCategoryDropdown },
  } = useStore();
  const [photoEditMode, setPhotoEditMode] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    loadPostCategories(postType + "_categories");

    return () => clearSelectedPost();
  }, [clearSelectedPost, loadPostCategories, postType]);

  const initValues: PostValues = {
    id: "",
    featuredImageId: "",
    postName: "",
    postStatus: "created",
    postTranslations: [
      {
        id: v4(),
        languageId: "MK",
        postContent: "",
        postExcerpt: "",
        postTitle: "",
        postContentFiltered: "",
      },
      {
        id: v4(),
        languageId: "SQ",
        postContent: "",
        postExcerpt: "",
        postTitle: "",
        postContentFiltered: "",
      },
      {
        id: v4(),
        languageId: "EN",
        postContent: "",
        postExcerpt: "",
        postTitle: "",
        postContentFiltered: "",
      },
    ],
    postType,
    slug: "",
    taxonomyTermIds: [],
  };

  return (
    <>
      {initialValues && (
        <Segment>
          <Button
            as={Link}
            to={`/single/${postType}/${initialValues.slug}`}
            content={t("view-page")}
          />
        </Segment>
      )}
      <style>
        {`
      .ui.segment.image-segment,
      .ui.segment.image-segment:hover button {
        display: flex !important;
        min-height:343px;
      }
    `}
      </style>
      <Formik
        initialValues={initialValues || initValues}
        onSubmit={onSubmit}
        enableReinitialize={!!initialValues}
      >
        {({ errors, values, setFieldValue, touched }) => (
          <Form autoComplete="off">
            <Grid>
              <GridRow>
                <GridColumn width={8}>
                  <Segment>
                    <MyTextInput name="postName" label={t("post-name")} />
                    <MyTextInput name="slug" label={t("slug")} />
                  </Segment>
                </GridColumn>
                <GridColumn width={8}>
                  <Segment>
                    <Header attached="top" content={t("featured-image")} />
                    {!photoEditMode && (
                      <Segment
                        className="image-segment"
                        style={{ cursor: "pointer" }}
                      >
                        <Image
                          src={
                            values?.featuredImagePath
                              ? `/media/images/${values?.featuredImagePath}`
                              : `/images/no-image.jpg`
                          }
                          style={{ width: "300px", margin: "auto" }}
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setPhotoEditMode(true);
                          }}
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            background: "#babababa",
                            opacity: "0.8",
                            height: "100%",
                            display: "none",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Icon name="edit" size="huge" color="green" />
                        </Button>
                      </Segment>
                    )}
                    <Segment className={`${!photoEditMode && "hidden"}`}>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPhotoEditMode(false);
                        }}
                        icon="close"
                        color="red"
                        style={{ marginBottom: "15px" }}
                      />
                      <PhotoSelectWidget
                        itemsPerRow={3}
                        extraContent={(id, path) => (
                          <Button
                            disabled={id === values.featuredImageId}
                            fluid
                            content={t("select")}
                            color="green"
                            onClick={() => {
                              setFieldValue("featuredImageId", id);
                              setFieldValue(
                                "featuredImagePath",
                                path?.substring(14)
                              );
                              setFieldValue("featuredImage", {
                                id,
                                path: path?.substring(14),
                              });
                            }}
                          />
                        )}
                      />
                    </Segment>
                  </Segment>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={12}>
                  <FieldArray name="postTranslations">
                    {() => {
                      const translations = values.postTranslations || [];
                      const panes = translations
                        .sort((a, b) => {
                          if (a.languageId === "MK") return -1;
                          if (b.languageId === "MK") return 1;
                          if (a.languageId === "EN") return 1;
                          if (b.languageId === "EN") return -1;

                          return 0;
                        })
                        .map((x, index) => {
                          return {
                            menuItem: x.languageId,
                            render: () => (
                              <Tab.Pane key={x.languageId}>
                                <MyTextInput
                                  name={`postTranslations.${index}.postTitle`}
                                  label={t("post-title")}
                                />
                                <Segment>
                                  <MyEditorInput
                                    placeholder={`${singularName} Content`}
                                    onChange={setFieldValue}
                                    name={`postTranslations.${index}.postContent`}
                                    label={t("post-content")}
                                  />
                                </Segment>
                                <Segment>
                                  <MyEditorInput
                                    placeholder={`${singularName} Excerpt`}
                                    hideCustomButtons={true}
                                    onChange={setFieldValue}
                                    name={`postTranslations.${index}.postExcerpt`}
                                    label={t("post-excerpt")}
                                  />
                                </Segment>
                              </Tab.Pane>
                            ),
                          };
                        });
                      return <Tab panes={panes} />;
                    }}
                  </FieldArray>
                </GridColumn>
                <GridColumn width={4}>
                  <SegmentGroup>
                    <Segment>
                      <Header attached="top" content={t("status")} />
                      <Divider />
                      <SelectInput
                        name="postStatus"
                        options={postStatusOptions}
                      />
                    </Segment>
                    <Segment>
                      <Header attached="top" content={t("category")} />
                      <Divider />
                      <MyCheckboxGroup
                        touched={touched.category}
                        error={errors.category}
                      >
                        {allCategoryDropdown.map((x, i) => (
                          <MyCheckbox
                            key={i}
                            type="checkbox"
                            name="taxonomyTermIds"
                            value={x.value?.toString() || ""}
                            label={x.text?.toString() || ""}
                          />
                        ))}
                      </MyCheckboxGroup>
                    </Segment>
                  </SegmentGroup>
                </GridColumn>
              </GridRow>
              <GridRow></GridRow>
            </Grid>
            <Segment>
              <Button
                color="red"
                icon="arrow left"
                onClick={(e) => {
                  e.preventDefault();
                  goBack();
                }}
              />
              <Button content={t("save")} primary />
            </Segment>
          </Form>
        )}
      </Formik>
    </>
  );
});
