import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import ELearningHeader from "./ELearningHeader";
import ELearningStickyFooter from "./ELearningStickyFooter";
import ELearningStickyHeader from "./ELearningStickyHeader";
import TrainingDetails from "./TrainingDetails";

export default observer(function LearningDashboard() {
  const {
    learningStore: {
      loadTrainings,
      selectedTraining,
      sortedTrainings,
      setSelectedTrainingById,
      trainingProgressPercent,
      loadUserTrainingProgresses,
    },
  } = useStore();

  useEffect(() => {
    loadTrainings();
    loadUserTrainingProgresses();
  }, [loadTrainings, loadUserTrainingProgresses]);

  const navigate = useNavigate();

  return (
    <>
      <ELearningStickyHeader />
      <Container>
        <br />
        <ELearningHeader content="Добре дојдовте на Платформата за E-Учење" />

        {/* <Header as={'h1'} textAlign='center' attached="top" image='/images/icons/school.png' content='Добредојдовте на Платформата за E-Учење' /> */}
        <Segment>
          <Grid stackable style={{ background: "whitesmoke" }}>
            <Grid.Column width={4} style={{ background: "whitesmoke" }}>
              <Segment style={{ background: "whitesmoke" }}>
                <Header
                  as="h2"
                  attached="top"
                  textAlign="center"
                  content="Добре дојдовте на екранот за избор на обука"
                  style={{ border: "none", background: "whitesmoke" }}
                />
                <Divider style={{ background: "#2185d0" }} />
                <br />
                <p>
                  Екранот за обука ќе ги покаже обуките кои се достапни и
                  напредокот кој ви е постигнат во секој од нив. Овде, исто
                  така, ќе можете да ги симнете сертификатите за обуката што ја
                  стекнавте.
                </p>
                {/* <p>The training screen will show the trainings available and the proggress that you
            have achieved within each of them. Here, you will also be able to download the certificates
            for the trainings that you have acquired.
          </p> */}
              </Segment>
              <Segment style={{ border: "none", background: "whitesmoke" }}>
                <Button
                  primary
                  content="Сертификати"
                  fluid
                  onClick={() => navigate("/e-learning/certificates")}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={8} style={{ background: "whitesmoke" }}>
              {/* <Segment style={{ minHeight: "500px" }}> */}
              <div style={{ minHeight: "500px", background: "whitesmoke" }}>
                <Card.Group>
                  {sortedTrainings.map((x) => {
                    return (
                      <Card
                        link
                        key={x.id}
                        color={
                          x.id === selectedTraining?.id ? "blue" : undefined
                        }
                        onClick={() => setSelectedTrainingById(x.id)}
                        header={x.trainingName}
                        description={
                          x.trainingDesc.slice(0, 30) +
                          (x.trainingDesc.length > 31 ? "..." : "")
                        }
                      />
                    );
                  })}
                </Card.Group>
              </div>
              {/* </Segment> */}
            </Grid.Column>
            {selectedTraining && (
              <Grid.Column width={4} style={{ background: "whitesmoke" }}>
                <TrainingDetails
                  progress={trainingProgressPercent || 0}
                  training={selectedTraining}
                />
              </Grid.Column>
            )}
          </Grid>
        </Segment>
      </Container>
      <ELearningStickyFooter />
    </>
  );
});
