import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { FilterOption } from "../types/FilterOption";
import FilterModal from "./FilterModal";

interface Props {
  closeModal: () => void;
  setFilter: (filterName: string, filterValue: string) => void;
}

export default observer(function OperFormsFilterModal({
  closeModal,
  setFilter,
}: Props) {
  const {
    formStore: { formStatusOptions },
  } = useStore();
  const { t } = useTranslation();
  const formTypeOptions = [
    { key: "WorkOnSundays", text: t("work-on-sunday"), value: "WorkOnSundays" },
    {
      key: "AccidentAtWork",
      text: t("accident-at-work"),
      value: "AccidentAtWork",
    },
    {
      key: "ConstructionWorks",
      text: t("construction-works"),
      value: "ConstructionWorks",
    },
    { key: "Grievance", text: t("grievances"), value: "Grievance" },
    { key: "NightShifts", text: t("night-shift-work"), value: "NightShifts" },
    { key: "OvertimeWorks", text: t("overtime-work"), value: "OvertimeWorks" },

    {
      key: "StartingActivities",
      text: t("starting-an-activity"),
      value: "StartingActivities",
    },
    {
      key: "WorkOnHolidays",
      text: t("work-on-holiday"),
      value: "WorkOnHolidays",
    },
  ];

  const regionOptions = [
    { key: "01", text: "01", value: "01" },
    { key: "02", text: "02", value: "02" },
    { key: "03", text: "03", value: "03" },
    { key: "04", text: "04", value: "04" },
    { key: "05", text: "05", value: "05" },
    { key: "06", text: "06", value: "06" },
    { key: "07", text: "07", value: "07" },
    { key: "08", text: "08", value: "08" },
    { key: "09", text: "09", value: "09" },
  ];

  const filterOptions: FilterOption[] = [
    {
      name: "formType",
      text: t("form-type"),
      type: "select",
      options: formTypeOptions,
    },
    {
      name: "statusId",
      text: t("status"),
      type: "select",
      options: formStatusOptions,
    },
    {
      name: "regionCode",
      text: t("region-code"),
      type: "select",
      options: regionOptions,
    },
    { name: "employerName", text: t("employer-name"), type: "text" },
    { name: "edb", text: t("edb"), type: "text" },
    { name: "nkdCode", text: t("nkd-code"), type: "text" },
    { name: "nkdDesc", text: t("nkd-description"), type: "text" },
    { name: "unitAddress", text: t("unit-address"), type: "text" },
    { name: "employerAddress", text: t("employer-address"), type: "text" },
    { name: "dateFrom", text: t("date-from"), type: "date" },
    { name: "dateTo", text: t("date-to"), type: "date" },

    // { name: 'employerAddress', text: 'Employer Address', type: 'date' },
  ];

  return (
    <>
      <FilterModal
        filterOptions={filterOptions}
        closeModal={closeModal}
        setFilter={setFilter}
      />
    </>
  );
});
