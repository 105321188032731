import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { PostValues } from "../../../../app/models/CMS/post";
import { useStore } from "../../../../app/stores/store";
import PostForm from "../components/PostForm";
import { toast } from "react-toastify";

const singularName = "Announcement";
const pluralName = "Announcements";
const linkPath = "/admin/announcements";
const postType = "announcement";
const icon = "pin";

export default observer(function AnnouncementEdit() {
  const { id } = useParams();
  const {
    postStore: { loadingInitial, selectedPost, loadSinglePost, updatePost },
  } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) loadSinglePost(id);
  }, [loadSinglePost, id]);

  const onSubmit = (values: PostValues) => {
    updatePost(values).then(() => {
      const successMessage = t('uspesno_zacuvano');
      toast.success(successMessage);  
    });
  };
  const { t } = useTranslation();

  if (loadingInitial || !selectedPost) {
    return <LoadingComponent />;
  }

  return (
    <div>
      <AdminHeader
        header={t("edit-announcement")}
        icon={icon}
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <Segment>
        <PostForm
          onSubmit={onSubmit}
          initialValues={selectedPost}
          postType={postType}
          goBack={() => navigate(`${linkPath}/list`)}
          singularName={singularName}
        />
      </Segment>
    </div>
  );
});
