import React from "react";
import "../UsefulLinks/UsefulLinksItem.css";

interface Props {
  to: string;
  image: string;
  content?: string;
}

export default function UsefulLinksItem({ to, image, content }: Props) {
  return (
    <>
      <li className="useful-links-item">
        <a href={to}>
          <div>
            <div style={{ height: "110px" }}>
              <img src={image} alt="patterns" />
            </div>
            <span>{content}</span>
          </div>
        </a>
      </li>
    </>
  );
}
