import React from "react";
import { useTranslation } from "react-i18next";
import HomePageDocumentsItem from "./HomePageDocumentsItem";
import "./HomePageDocuments.css";
import HomePage from "../HomePage";


export default function HomePageDocuments() {
  const { t } = useTranslation();


  return (
    <div className="documents">
      <ul>
        <HomePageDocumentsItem
          content={t("reporting-forms")}
          image="/images/icons/doc_1_proba.png"
          to={"/obrasci"}
        />
        <HomePageDocumentsItem
          content={t("public-procurement-and-budget")}
          image="/images/icons/doc_2.png"
          to={"/single/page/javni-nabavki"}
        />
        <HomePageDocumentsItem
          content={t("information-of-a-public-character")}
          image="/images/icons/doc_3.png"
          to={"/single/page/informacii-od-javenkontakt"}
        />
        <HomePageDocumentsItem
          content={t("checklists")}
          image="/images/icons/doc_4.png"
          to={"single/page/listi-za-proverka"}
        />
        <HomePageDocumentsItem
          content={t("safety-and-health-at-work")}
          image="/images/icons/doc_6.png"
          to={"/single/page/bezbednost-zdravje-pri-rabota"}
        />
        <HomePageDocumentsItem
          content={t("work-relationships")}
          image="/images/icons/doc_5.png"
          to={"single/page/rabotni-odnosi"}
        />
      </ul>
    </div>
  );
}
