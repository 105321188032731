import { Markup } from 'interweave';
import React from 'react';
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import { useStore } from '../../../app/stores/store';
import ElearningModal from '../components/ElearningModal';
import SinglePencil from '../components/PencilCase/SinglePencil';

interface Props {
    slideContents?: eSlideContent[];
    colorSchemeArray: string[];
}

export default function PencilCaseParagraph2({ slideContents, colorSchemeArray }: Props) {

    const header = slideContents?.find(x => x.type.typeName === 'Header')?.body;
    const contentHeader = slideContents?.find(x => x.type.typeName === 'Content-Header')?.body;
    const paragraph = slideContents?.find(x => x.type.typeName === 'Paragraph')?.body;
    const pencilCase = sortedContentArray(slideContents, 'Pencilcase-Item');

    const { modalStore } = useStore();
    const { openModal } = modalStore;

    const backgroundColor = colorSchemeArray[0];

    return (
        <>
            <div className="slide-img-par-1">
                <h1 className="slide-header">{header}</h1>
                <div className="slide-header-line" style={{backgroundColor}}> </div>
                <h3 className="slide-content-header" style={{ marginLeft: '60px' }} >
                    {contentHeader}
                </h3>
                <div style={{ marginTop: '30px', marginLeft: '60px' }}>
                    <Markup content={paragraph} />
                </div>
                <div style={{ height: '500px', paddingTop: '50px', paddingLeft: '220px' }}>
                    <div className="pencil-page">
                        {pencilCase.map((x, i) => (<>
                            <SinglePencil isRight={i % 2 === 0}
                                onClick={() => openModal(<ElearningModal color={backgroundColor} backgroundColor={backgroundColor}
                                contentHeader={`${x.header}`} content={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll(" •", " ")} />)}
                                backgroundColor={colorSchemeArray[i % colorSchemeArray.length]}
                                content={x.header}
                            />
                            {i % 2 !== 0 && <br />}
                        </>))}
                    </div>
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}