import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Segment, Table, Header } from "semantic-ui-react";
import { JobPosition } from "../../../app/models/Forms/OvertimeWorkInterface";

interface Props {
  arrJSONData: {
    key: string;
    value: string;
  }[];
}

export default function JsonView({ arrJSONData }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const testKeyjob = arrJSONData
  //   .filter((x) => x.key === "workPlaces")
  //   .map((y) => y.value).map(z=>z.value).jpb;

  // console.log(testKeyjob);

  // const rights = vr
  // .map((x) => rightsArray.find((y) => x === y.value)?.description)
  // .join("\n\n");

  return (
    <>
      <Segment>
        <Table basic="very" celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("key")}</Table.HeaderCell>
              <Table.HeaderCell>{t("value")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <>
            <Table.Body>
              {arrJSONData.map((x) => (
                <Table.Row>
                  <Table.Cell>
                    <Header as="h4" image>
                      <Header.Content>{t(x.key)}</Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{x.value}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </>
        </Table>
      </Segment>
    </>
  );
}
