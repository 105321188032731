import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Card, Segment, SemanticWIDTHS } from "semantic-ui-react";
import PhotoCard from "../../../features/admin/media/photoCard";
import { useStore } from "../../stores/store";
import PhotoFilterModal from "../modals/PhotoFilterModal";
import ReportTableFilters from "../table/ReportTableFilters";
import ReportTablePagination from "../table/ReportTablePagination";

interface Props {
  setPhotoSelectMode?: (asd: boolean) => void;
  extraContent?: (id: string, path?: string) => JSX.Element;
  extraContentName?: (id: string) => JSX.Element;
  itemsPerRow?: SemanticWIDTHS;
}

export default observer(function PhotoSelectWidget({
  setPhotoSelectMode,
  extraContent,
  extraContentName,
  itemsPerRow = 6,
}: Props) {
  const { t } = useTranslation();
  const { mediaStore, modalStore } = useStore();
  const {
    mediaList,
    pagination,
    loadingInitial,
    pagingParams,
    addFilterParam,
    clearFilterParams,
    addStaticFilterParam,
    filterParams,
    removeFilterParam,
    setPagingParams,
  } = mediaStore;

  const { openModal, closeModal } = modalStore;

  const handlePerPageChange = (value?: string) => {
    const pageSize = parseInt(value || "0");
    setPagingParams({
      pageNumber: 1,
      pageSize,
    });
  };
  useEffect(() => {
    addStaticFilterParam("TypeId", "1");
  }, [addStaticFilterParam]);

  const handlePaginationChange = (activePage?: string | number) => {
    if (activePage) {
      const pageNumber = parseInt(activePage.toString());
      const { pageSize } = pagingParams;
      setPagingParams({
        pageNumber,
        pageSize,
      });
    }
  };

  const PerPageOptions = [
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 10, value: 10 },
    { text: 50, value: 50 },
  ];

  return (
    <div>
      <Segment>
        {setPhotoSelectMode && (
          <Button
            icon="caret left"
            content="Back"
            color="teal"
            onClick={() => setPhotoSelectMode(false)}
          />
        )}

        <Button
          content={t("add-filter")}
          onClick={(e) => {
            e.preventDefault();
            openModal(
              <PhotoFilterModal
                closeModal={closeModal}
                setFilter={addFilterParam}
              />
            );
          }}
        />
        {filterParams.length > 0 && (
          <Button
            content={t("clear-all-filters")}
            onClick={(e) => {
              clearFilterParams();
            }}
          />
        )}

        <ReportTableFilters
          filterParams={filterParams}
          removeFilterParam={removeFilterParam}
        />
      </Segment>
      <Segment loading={loadingInitial}>
        <Card.Group itemsPerRow={itemsPerRow}>
          {mediaList.map((x) => {
            const id = x.id.toString();
            const imageName = x.path.substring(x.path.lastIndexOf("\\") + 1);
            const imagePath = `/media/images/${imageName}`;
            const imagePathOrigin = `/media/images/${imageName}`;
            return (
              <PhotoCard
                imageName={imageName}
                imagePath={imagePath}
                imagePathOrigin={imagePathOrigin}
                id={id}
                key={id}
                extraContent={extraContent}
                extraContentName={extraContentName}
              />
            );
          })}
        </Card.Group>
        <ReportTablePagination
          numPages={pagination?.totalPages || 0}
          perPage={pagination?.itemsPerPage || 0}
          PerPageOptions={PerPageOptions}
          activePage={pagination?.currentPage}
          handlePerPageChange={handlePerPageChange}
          handlePaginationChange={handlePaginationChange}
        />
      </Segment>
    </div>
  );
});
