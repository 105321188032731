import { Formik, Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Header, Icon, Segment } from "semantic-ui-react";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import * as Yup from "yup";

import MyTextInput from "../../../forms/inputs/MyTextInput";
import { toast } from "react-toastify";
interface Props {
  userId: string;
}

export default function UserForgotPassword({ userId }: Props) {
  const { t } = useTranslation();
  const { userStore, modalStore } = useStore();
  const { closeModal } = modalStore;

  const navigate = useNavigate();

  return (
    <>
      <div>
        <Segment>
          <Header color="teal" textAlign="center" size="large">
            <Icon name="lock" />
            {t("change-password")}
          </Header>
        </Segment>
      </div>
      <div>
        <Segment>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={Yup.object({
              password: Yup.string()
                .required(t("required"))
                .matches(
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                  t(
                    "must-contain-8-characters-one-uppercase-one-lowercase-one-number-and-one-special-case-character"
                  )
                ),
              confirmPassword: Yup.string().oneOf(
                [Yup.ref("password"), null],
                t("passwords-must-match")
              ),
            })}
            onSubmit={(values) => {
              userStore
                .changePassword(userId, values.password)
                .then((success) => {
                  if (success) {
                    toast.info(t("successfully-changed-the-password"));
                    navigate("/admin/users/list");
                    closeModal();
                    return;
                  }
                  toast.error(t("failed-to-change-password"));
                });
            }}
          >
            <Form>
              <MyTextInput
                name="password"
                label={t("set-password")}
                placeholder={t("Password")}
                type="password"
              />
              <br />
              <MyTextInput
                name="confirmPassword"
                label={t("confirm-password")}
                placeholder={t("confirm-password")}
                type="password"
              />
              <br />
              <Button
                style={{ marginTop: "10px" }}
                color="green"
                type="submit"
                content={t("submit")}
              ></Button>
              <Button color="red" onClick={closeModal}>
                {" "}
                <Icon name="close" />
              </Button>
            </Form>
          </Formik>
        </Segment>
      </div>
    </>
  );
}
