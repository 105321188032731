import React, { useState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import './AccordionElement.css'

interface Props {
  header: string;
  content: string;
  backgroundColor: string;
}

export default function AccordionElement({ header, content, backgroundColor }: Props) {

  const [activeIndex, setActiveIndex] = useState(-1);
  const handleClick = (event: any, titleProps: any) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index!;
    setActiveIndex(newIndex);
  }

  return (
    <>
      <Accordion styled style={{ width: '100%', }}>
        <Accordion.Title className='accordion-style ' style={{backgroundColor}}
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}  >
          <div className='accordion-title'>
            {header}
            <Icon name='dropdown' />
          </div>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0} >
          <div className='accordion-text'>
            {content}
          </div>
        </Accordion.Content>
      </Accordion>
    </>
  )
}