import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Icon,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import ModalDialog from "../../../app/common/modals/ModalDialog";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";

export default observer(function ListSlides() {
  const { t } = useTranslation();
  const {
    learningStore: {
      sortedSlides,
      deleteSlide,
      target,
      loadModuleAllSlides,
      slideOrderDecrement,
    },
    modalStore: { openModal, closeModal },
    commonStore: { moveUp, moveDown, movingUp, movingDown },
  } = useStore();
  const { id, traningId } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id) {
      loadModuleAllSlides(id);
    }
  }, [loadModuleAllSlides, id]);

  return (
    <>
      <AdminHeader
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
        header={t("slides")}
        subheader={t("list-of-all-slides-for-this-module")}
      />
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("slide-name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("slide-description")}</Table.HeaderCell>
            <Table.HeaderCell>{t("slide-type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("order")}</Table.HeaderCell>
            <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedSlides.map((x, i, arr) => {
            return (
              <Table.Row
                key={x.id}
                className={
                  movingUp === x.id
                    ? "move-up-order"
                    : movingDown === x.id
                    ? "move-down-order"
                    : ""
                }
              >
                <Table.Cell>{x.slideName}</Table.Cell>
                <Table.Cell>{x.slideDescription}</Table.Cell>
                <Table.Cell>{x.slideType?.slideTypeName}</Table.Cell>
                <Table.Cell>
                  <ButtonGroup vertical>
                    {(x.slideOrder > 0 || (arr.length === 2 && i === 1)) && (
                      <Button
                        disabled={!!movingUp || !!movingDown}
                        icon="arrow up"
                        color="green"
                        onClick={() => {
                          openModal(<ModalDialog
                          cancel={closeModal}
                          noText='Cancel'
                          confirm={()=> {moveUp(x.id, arr[i - 1].id, slideOrderDecrement)
                          closeModal();
                          }}
                          title='Change order?'
                          description="Are you sure you want to change the order?"
                          />)
                        }}
                      />
                    )}
                    {arr.some((y) => y.slideOrder > x.slideOrder) && (
                      <Button
                        disabled={!!movingUp || !!movingDown}
                        icon="arrow down"
                        color="green"
                        onClick={() => {
                          openModal(<ModalDialog
                          cancel={closeModal}
                          noText='Cancel'
                          confirm={()=> {moveDown(x.id, arr[i + 1].id, slideOrderDecrement)
                          closeModal();
                          }}
                          title='Change order?'
                          description="Are you sure you want to change the order?"
                          />)
                        }}
                      />
                    )}
                  </ButtonGroup>
                </Table.Cell>
                <Table.Cell>
                  <ButtonGroup>
                    <Button
                      as={Link}
                      to={`/admin/e-learning/editSlide/${x.id}`}
                      icon="edit"
                      inverted
                      color="green"
                    />

                    <Button
                      name={x.id}
                      loading={target === x.id}
                      onClick={(e) => {
                        openModal(
                          <ModalDialog
                            cancel={closeModal}
                            noText="Cancel"
                            confirm={() => {
                              deleteSlide(x.id);
                              closeModal();
                            }}
                            title={t(
                              "are-you-sure-you-want-to-delete-this-slide"
                            )}
                            description={t(
                              "once-you-delete-the-slide-the-data-will-be-lost-forever"
                            )}
                          />
                        );
                      }}
                      icon="close"
                      inverted
                      color="red"
                    />
                    <Popup
                      trigger={
                        <Button
                          as={Link}
                          to={`/admin/e-learning/viewSlide/${x.id}/${id}`}
                          icon="arrow right"
                          color="blue"
                        />
                      }
                      content={t("towards-content")}
                    />
                  </ButtonGroup>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Segment>
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "left", width: "50%" }}>
            <Button
              color="red"
              onClick={() =>
                navigate(
                  `/admin/e-learning/listModules/${traningId}`)} >
              <Icon name="arrow left" />
            </Button>
          </div>
          <div style={{ textAlign: "right", width: "50%" }}>
            <Link
              to={`/admin/e-learning/createSlide/${id}/${traningId}`}
              className="ui button teal"
            >
              <Icon name="plus" />
              {t("add-new-slide")}
            </Link>
          </div>
        </div>
      </Segment>
    </>
  );
});
