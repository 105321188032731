
import _ from "lodash";
import { action, makeAutoObservable, reaction, runInAction } from "mobx";
import moment from "moment";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import agent from "../api/agent";
import {
  AccidentAtWorkHeader,
  AccidentAtWorkInterface,
} from "../models/Forms/AccidentAtWorkInterface";
import {
  ConstructionWorksHeader,
  ConstructionWorksInterface,
} from "../models/Forms/ConstructionWorksInterface";
import {
  KnownSubmitterHeader,
  KnownSubmitterInterface,
} from "../models/Forms/KnownSubmitterInterface";
import {
  NightShiftHeader,
  NightShiftInterface,
} from "../models/Forms/NightShiftInterface";
import {
  OvertimeWorkInterface,
  OvertimeWorktHeader,
} from "../models/Forms/OvertimeWorkInterface";
import {
  StartingActivityHeader,
  StartingActivityInterface,
} from "../models/Forms/StartingActivityInterface";
import {
  WorkOnHolidayHeader,
  WorkOnHolidayInterface,
} from "../models/Forms/WorkOnHolidayInterface";
import {
  WorkOnSundayHeader,
  WorkOnSundayInterface,
  // WorkOnSundayInterfaceTest,
} from "../models/Forms/WorkOnSundayInterface";
import { FormStatus } from "../models/OperatorForm/FormStatus";
import { OperatorForm } from "../models/OperatorForm/OperatorForm";
import { FilterParam } from "../models/Pagination/FilterParam";
import { Pagination, PagingParams } from "../models/Pagination/pagination";
import {t} from "i18next"




export default class FormStore {
  formRegistry = new Map<string, OperatorForm>();
  target: string | null = null;

  accidentFormRegister = new Map<string, AccidentAtWorkHeader>();
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  formPagination: Pagination | null = null;
  pagingParams = new PagingParams();
  filterRegistry = new Map<string, FilterParam>();
  // staticFilterRegistry = new Map<string, FilterParam>();
  uploading = false;
  formStatuses: FormStatus[] | null = null;




  get formStatusOptions() {
    return this.formStatuses?.map((x) => {
      const item: DropdownItemProps = {
        key: x.id,
        value: x.id,
        text: t(x.statusName).toString(),
      };
      return item;
    });
  }



  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  addFilterParam = (name: string, value: string) => {
    const param = new FilterParam(name, value);
    this.filterRegistry.set(param.filterName, param);
    this.pagingParams = new PagingParams(1, this.pagingParams.pageSize);
    this.loadForms();
  };

  removeFilterParam = (id: string) => {
    this.filterRegistry.delete(id);
    this.loadForms();
  };

  clearFilterParams = () => {
    this.filterRegistry.clear();
    this.loadForms();
  };

  get filterParams() {
    return Array.from(this.filterRegistry.values());
  }

  setPagingParams = (pagingParams: PagingParams) => {
    if (pagingParams.pageSize < 0) {
      pagingParams.pageSize = this.pagingParams.pageSize;
    }
    this.pagingParams = pagingParams;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    const filters = Array.from(this.filterRegistry.values());
    filters.forEach((x) => {
      params.append(x.filterName, x.filterValue);
    });
    return params;
  }

  axiosParamsRelatedProduct = (
    pageNumber: number,
    pageSize: number,
    formId: string
  ) => {
    const params = new URLSearchParams();
    params.append("pageNumber", pageNumber.toString());
    params.append("pageSize", pageSize.toString());
    params.append("formId", formId.toString());
    const filters = Array.from(this.filterRegistry.values());
    filters.forEach((x) => {
      params.append(x.filterName, x.filterValue);
    });

    return params;
  };

  selectedAccidentForm: AccidentAtWorkInterface | null = null;
  selectedConstructionForm: ConstructionWorksInterface | null = null;
  selectedKnownSubmitterForm: KnownSubmitterInterface | null = null;
  selectedNightShiftForm: NightShiftInterface | null = null;
  selectedOvertimeWorkForm: OvertimeWorkInterface | null = null;
  selectedStartingActivityForm: StartingActivityInterface | null = null;
  selectedWorkOnHolidayForm: WorkOnHolidayInterface | null = null;
  selectedWorkOnSundayForm: WorkOnSundayInterface | null = null;

  selectedAccidentHistoryForm: AccidentAtWorkHeader | null = null;
  selectedConstructionHistoryForm: ConstructionWorksHeader | null = null;
  selectedKnownSubmitterHistoryForm: KnownSubmitterHeader | null = null;
  selectedNightShiftHistoryForm: NightShiftHeader | null = null;
  selectedOvertimeWorkHistoryForm: OvertimeWorktHeader | null = null;
  selectedStartingActivityHistoryForm: StartingActivityHeader | null = null;
  selectedWorkOnHolidayHistoryForm: WorkOnHolidayHeader | null = null;
  selectedWorkOnSundayHistoryForm: WorkOnSundayHeader | null = null;

  clearSelectedAccidentForm = () => {
    this.selectedAccidentForm = null;
  };

  clearSelectedNightShiftForm = () => {
    this.selectedNightShiftForm = null;
  };

  clearSelectedConstructionForm = () => {
    this.selectedConstructionForm = null;
  };

  clearSelectedKnownSubmitterForm = () => {
    this.selectedKnownSubmitterForm = null;
  };

  selectedForm: OperatorForm | null = null;

  setSelectedFormById(id: string) {
    const form = this.formRegistry.get(id);
    this.selectedForm = form || null;
  }

  clearSelectedForm = () => {
    this.selectedForm = null;
  };

  constructor() {
    makeAutoObservable(this, {
      setSelectedFormById: action.bound,
    });

    reaction(
      () => this.pagingParams,
      (params) => {
        console.log("paging change");
        this.loadForms();
      }
    );
  }

  get operetedForms() {
    return Array.from(this.formRegistry.values());
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
  setLoading = (state: boolean) => {
    this.loading = state;
  };
  setTarget = (target: string | null) => {
    this.target = target;
  };

  loadStatuses = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Forms.getStatuses();
      this.formStatuses = result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadForms = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Forms.list(this.axiosParams);
      this.formRegistry.clear();
      result.data.forEach((x) => {
        this.formRegistry.set(x.id, x);
      });
      this.setPagination(result.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  changeStatus = async (id: string, status: number, formType: string) => {
    this.setLoading(true);
    try {
      const form = _.cloneDeep(this.formRegistry.get(id));
      if (!form) {
        return;
      }
      await agent.Forms.statusChange({ id, status, formType });
      const newGuy = this.formRegistry.get(id);
      if (newGuy) {
        this.formRegistry.set(id, { ...newGuy, statusId: status });
        toast.success("Статусот е променет!");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  loadSingleAccidentForm = async (id: string) => {
    this.setLoading(true);
    try {
      const accident = await agent.Forms.accidentDetails(id);
      runInAction(() => {
        accident.latestVersion.birthDate = this.getDateFromResponse(
          accident.latestVersion.birthDate
        );
        accident.latestVersion.accidentTime = this.getDateFromResponse(
          accident.latestVersion.accidentTime
        );
        this.selectedAccidentForm = accident.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadHistoryAccidentForm = async (id: string) => {
    this.setLoading(true);
    try {
      const accident = await agent.Forms.accidentDetails(id);
      runInAction(() => {
        this.selectedAccidentHistoryForm = accident;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadHistoryConstructionForm = async (id: string) => {
    this.setLoading(true);
    try {
      const accident = await agent.Forms.constructionDetails(id);
      runInAction(() => {
        this.selectedConstructionHistoryForm = accident;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  // loadHistoryKnownSubmitterForm = async (id: string) => {
  //   this.setLoading(true);
  //   try {
  //     const accident = await agent.Forms.knownSubmitterDetails(id);
  //     runInAction(() => {
  //       this.selectedKnownSubmitterHistoryForm = accident;
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     this.setLoading(false);
  //   }
  // };

  loadHistoryNightShiftForm = async (id: string) => {
    this.setLoading(true);
    try {
      const accident = await agent.Forms.nightShiftDetails(id);
      runInAction(() => {
        this.selectedNightShiftHistoryForm = accident;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadHistoryOvertimeWorkForm = async (id: string) => {
    this.setLoading(true);
    try {
      const accident = await agent.Forms.overtimeWorkDetails(id);
      runInAction(() => {
        this.selectedOvertimeWorkHistoryForm = accident;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadHistoryStartingActivityForm = async (id: string) => {
    this.setLoading(true);
    try {
      const accident = await agent.Forms.startingActivityDetails(id);
      runInAction(() => {
        this.selectedStartingActivityHistoryForm = accident;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadHistoryWorkOnHolidayForm = async (id: string) => {
    this.setLoading(true);
    try {
      const accident = await agent.Forms.workOnHolidayDetails(id);
      runInAction(() => {
        this.selectedWorkOnHolidayHistoryForm = accident;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadHistoryWorkOnSundayForm = async (id: string) => {
    this.setLoading(true);
    try {
      const workOnSunday = await agent.Forms.workOnSundayDetails(id);
      runInAction(() => {
        this.selectedWorkOnSundayHistoryForm = workOnSunday;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSingleConstructionForm = async (id: string) => {
    this.setLoading(true);
    try {
      const construction = await agent.Forms.constructionDetails(id);
      runInAction(() => {
        construction.latestVersion.date = this.getDateFromResponse(
          construction.latestVersion.date
        );
        construction.latestVersion.startingDate = this.getDateFromResponse(
          construction.latestVersion.startingDate
        );
        construction.latestVersion.dateSubmitted = this.getDateFromResponse(
          construction.latestVersion.dateSubmitted
        );

        this.selectedConstructionForm = construction.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSingleKnownSubmitterForm = async (id: string) => {
    function assignRegionsFromRights(version: KnownSubmitterInterface) {
      version.region = [];
      if (version.violatedRight.some((x) => x > 200)) {
        version.region.push("работни односи");
      }
      if (version.violatedRight.some((x) => x < 200)) {
        version.region.push("безбедност и здравје при работа");
      }
    }

    this.setLoading(true);
    try {
      const submitter = await agent.Forms.knownSubmitterDetails(id);
      assignRegionsFromRights(submitter.latestVersion);
      submitter.detailsHistory.forEach((x) => assignRegionsFromRights(x));
      // console.log(submitter);
      runInAction(() => {
        this.selectedKnownSubmitterHistoryForm = submitter;
        this.selectedKnownSubmitterForm = submitter.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSingleNightShiftForm = async (id: string) => {
    this.setLoading(true);
    try {
      const nightshift = await agent.Forms.nightShiftDetails(id);
      runInAction(() => {
        nightshift.latestVersion.startTime = this.getDateFromResponse(
          nightshift.latestVersion.startTime
        );
        nightshift.latestVersion.endTime = this.getDateFromResponse(
          nightshift.latestVersion.endTime
        );

        this.selectedNightShiftForm = nightshift.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSingleOvertimeWorkForm = async (id: string) => {
    this.setLoading(true);
    try {
      const overtimeWork = await agent.Forms.overtimeWorkDetails(id);
      runInAction(() => {
        this.selectedOvertimeWorkForm = overtimeWork.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSingleStartingActivityForm = async (id: string) => {
    this.setLoading(true);
    try {
      const startingActivity = await agent.Forms.startingActivityDetails(id);
      runInAction(() => {
        startingActivity.latestVersion.dateSubmitted = this.getDateFromResponse(
          startingActivity.latestVersion.dateSubmitted
        );
        startingActivity.latestVersion.startingDate = this.getDateFromResponse(
          startingActivity.latestVersion.startingDate
        );
        startingActivity.latestVersion.date = this.getDateFromResponse(
          startingActivity.latestVersion.date
        );
        this.selectedStartingActivityForm = startingActivity.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSingleWorkOnHolidayForm = async (id: string) => {
    this.setLoading(true);
    try {
      const workOnHoliday = await agent.Forms.workOnHolidayDetails(id);
      runInAction(() => {
        workOnHoliday.latestVersion.dateSubmitted = this.getDateFromResponse(
          workOnHoliday.latestVersion.dateSubmitted
        );
        workOnHoliday.latestVersion.startTime = this.getDateFromResponse(
          workOnHoliday.latestVersion.startTime
        );
        workOnHoliday.latestVersion.endTime = this.getDateFromResponse(
          workOnHoliday.latestVersion.endTime
        );
        this.selectedWorkOnHolidayForm = workOnHoliday.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSingleWorkOnSundayForm = async (id: string) => {
    this.setLoading(true);
    try {
      const workOnSunday = await agent.Forms.workOnSundayDetails(id);
      runInAction(() => {
        // workOnSunday.latestVersion.contractDate = this.getDateFromResponse(workOnSunday.latestVersion.contractDate)
        // workOnSunday.latestVersion.dateSubmitted = this.getDateFromResponse(workOnSunday.latestVersion.dateSubmitted)

        this.selectedWorkOnSundayForm = workOnSunday.latestVersion;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  getDateFromResponse = (date: string | Date | null | undefined) =>
    new Date(moment(date).format("YYYY-MM-DD"));
}
