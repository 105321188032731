import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import AdminHeader from "../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import MyTextArea from "../forms/inputs/MyTextArea";
import MyTextInput from "../forms/inputs/MyTextInput";

export default observer(function EditTraning() {
  const { t } = useTranslation();
  const {
    learningStore: {
      updateTraining,
      loadTraining,
      selectedTraining,
    },
  } = useStore();
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      loadTraining(id);
    }
  }, [loadTraining, id, ]);

  const navigate = useNavigate();

  if (!selectedTraining) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div>
        <AdminHeader
          icon="pin"
          iconStyles={{ transform: "rotateZ(45deg)" }}
          header={t("edit-training")}
          subheader=" "
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Formik
            enableReinitialize
            initialValues={selectedTraining}
            onSubmit={(values) => {
              if (values) {
                updateTraining(values).then(() =>
                  navigate("/admin/e-learning/listTraning")
                );
              }
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ width: "80%" }}>
                    <MyTextInput
                      name="trainingName"
                      label={t("training-name")}
                    />
                    <MyTextArea
                      rows={7}
                      name="trainingDesc"
                      label={t("training-description")}
                    />
                    <MyTextInput
                      name="certValidMonths"
                      label={t("valid-months")}
                    />
                    <Button
                      style={{ marginTop: "10px" }}
                      color="teal"
                      type="submit"
                      content={t("submit")}
                    ></Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Segment>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Button
            color="red"
            onClick={() => navigate("/admin/e-learning/listTraning")} >
            <Icon name="arrow left" />
          </Button>
        </Segment>
      </div>
    </>
  );
});
