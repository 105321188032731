import { useField, useFormikContext } from "formik";
import React from "react";

import {
  Dropdown,
  DropdownItemProps,
  Form,
  Icon,
  Label,
  Popup,
} from "semantic-ui-react";

interface Props {
  placeholder?: string;
  name: string;
  options: DropdownItemProps[];
  label?: string;
  info?: string;
  connectedField?: string;
  onClick?: () => void;
}

export default function MyMultipleSelect(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      style={{ display: "flex", alignItems: "center" }}
    >
      {props.label && (
        <>
          <label
            style={{
              display: "inline-block",
              marginRight: "10px",
              width: "150px",
              textAlign: "right",
            }}
          >
            {props.label}
          </label>
          {props.info && (
            <Popup
              trigger={<Icon name="info circle" />}
              content={props.info}
              position="right center"
            />
          )}
        </>
      )}

      <Dropdown
        clearable
        placeholder={props.placeholder}
        value={field.value}
        fluid
        multiple
        selection
        options={props.options}
        onChange={(e, d) => {
          helpers.setValue(d.value);
          if (props.connectedField) {
            setFieldValue(props.connectedField, 0);
          }
        }}
        onBlur={() => helpers.setTouched(true)}
      />
      {meta.touched && !!meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
