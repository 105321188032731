import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { Segment, ButtonGroup, Button, Table } from "semantic-ui-react";
import HistoryModal from "../../../app/common/modals/HistoryModal";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { AccidentAtWorkInterface } from "../../../app/models/Forms/AccidentAtWorkInterface";
import { useStore } from "../../../app/stores/store";

export default observer(function AccidentAtWorkHistory() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { codesStore, formStore, modalStore } = useStore();
  const { loadCities } = codesStore;
  const {
    selectedAccidentForm,
    loadSingleAccidentForm,
    loadingInitial,
    loadHistoryAccidentForm,
    selectedAccidentHistoryForm,
  } = formStore;
  const { openModal, closeModal } = modalStore;

  useEffect(() => {
    if (id) loadSingleAccidentForm(id).then(() => loadHistoryAccidentForm(id));

    loadCities();
  }, [loadSingleAccidentForm, loadCities, id, loadHistoryAccidentForm]);

  if (loadingInitial || !selectedAccidentHistoryForm) {
    return <LoadingComponent />;
  }

  return (
    <>
      <AdminHeader header={t("history-accident-at-work")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedAccidentForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedAccidentForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedAccidentForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <Segment>
        <Table basic="very" celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("submission-time")}</Table.HeaderCell>
              <Table.HeaderCell>{t("view")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <>
            <Table.Body>
              {selectedAccidentHistoryForm.detailsHistory.map((x) => {
                const arr = Object.keys(x!).map((key) => ({
                  key,
                  value: x![key as keyof AccidentAtWorkInterface],
                }));

                return (
                  <Table.Row key={x.id}>
                    <Table.Cell>
                      {x.userName ? x.userName : t("original-submitter")}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(x.dateSubmitted).format("DD.MM.YYYY - HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        content={t("view")}
                        color="blue"
                        onClick={() =>
                          openModal(
                            <HistoryModal
                              closeModal={closeModal}
                              id={x.id}
                              arr={arr}
                            />
                          )
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </>
        </Table>
      </Segment>
    </>
  );
});
