import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Segment,
  Image,
  Grid,
  Header,
  Card,
  Icon,
  Button,
  Popup,
  Message,
} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../forms/inputs/MyTextInput";
import MySelectInput from "../../forms/inputs/MySelectInput";
import { UserFormValues } from "../../../app/models/user";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const UserRoles = [
  { text: t("super-admin"), value: "superAdmin" },
  { text: t("content-admin"), value: "contentAdmin" },
  { text: t("super-operator"), value: "superOperator" },
  { text: t("operator"), value: "operator" },
  { text: t("learner"), value: "learner" },
  { text: t("trainer"), value: "trainer" },
];

export default observer(function UserCreate() {
  const { t } = useTranslation();
  const {
    userStore: { createUser },
    codesStore: {
      loadBzrRegions,
      loadRoRegions,
      loadCities,
      citiesDropdown,
      roDropdown,
      bzrDropdown,
      bzrRegions,
      roRegions,
    },
  } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    loadBzrRegions();
    loadRoRegions();
    loadCities();
  }, [loadBzrRegions, loadRoRegions, loadCities]);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email(t("ivalid-email")).required(t("required")),
    password: Yup.string()
      .min(8, t("password-must-be-at-least-8-characters"))
      .matches(/[a-z]/, t("password-must-have-at-least-one-lowercase-letter"))
      .matches(/[A-Z]/, t("password-must-have-at-least-one-uppercase-letter"))
      .matches(/\d/, t("password-must-have-at-least-one-digit"))
      .matches(
        /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        t("need-one-special-character")
      ),
  });

  const genders = [
    { text: t("male"), value: 1 },
    { text: t("female"), value: 2 },
  ];

  // const initialValues = new UserFormValues({
  //   id: '',
  //   email: `${(Math.random() * 1000).toString()}tomce@semos.com.mk`,
  //   username: `${(Math.random() * 1000).toString()}tomce`,
  //   cityId: 1,
  //   address: '123',
  //   password: '1234',
  //   role: 'admin',
  //   firstName: 'tomce',
  //   lastName: 'vancev',
  //   regionBzrId: 1,
  //   regionRoId: 1,
  //   zipCode: '1000',
  //   gender: 1
  // });
  const initialValues = new UserFormValues({
    id: "",
    email: "",
    username: "",
    cityId: 0,
    address: "",
    password: "",
    role: "",
    firstName: "",
    lastName: "",
    regionBzrId: 0,
    regionRoId: 0,
    zipCode: "",
    gender: 0,
  });

  return (
    <div>
      <div>
        <Segment>
          <Header size="large">
            <Icon name="user" />
            {t("Create-user")}
          </Header>
        </Segment>
      </div>
      <Segment>
        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            values.id = uuid();
            createUser(values).then(() => {
              navigate("/admin/users/list");
            });
          }}
        >
          {({ errors, touched, values, submitForm }) => (
            <Form autoComplete="off">
              <Grid>
                <Grid.Column width={8}>
                  <MyTextInput name="username" label={t("Username")} />
                  <MyTextInput
                    name="password"
                    label={t("Password")}
                    type="password"
                  />
                  <MyTextInput name="email" label={t("Email")} />
                  <MyTextInput name="firstName" label={t("First-name")} />
                  <MyTextInput name="lastName" label={t("Last-name")} />
                  <MyTextInput name="address" label={t("Address")} />
                  <MyTextInput name="zipCode" label={t("Zip-Code")} />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment>
                    <MySelectInput
                      name="gender"
                      options={genders}
                      label={t("Gender")}
                    />
                    <br />
                    <MySelectInput
                      name="role"
                      options={UserRoles}
                      label={t("Role")}
                    />
                    <br />
                    {citiesDropdown && (
                      <>
                        <MySelectInput
                          name="cityId"
                          options={citiesDropdown}
                          label={t("City")}
                        />
                        <br />
                      </>
                    )}
                    {bzrDropdown && roDropdown && (
                      <>
                        <MySelectInput
                          name="regionBzrId"
                          options={bzrDropdown}
                          label={t("Region-BZR")}
                        />
                        {!!values.regionBzrId && (
                          <Message
                            info
                            content={`${t("cities")}: ${bzrRegions!
                              .find((x) => x.id === values.regionBzrId)
                              ?.cities.map((x) => x.name)
                              .join(" , ")}`}
                          />
                        )}
                        <br />
                        <MySelectInput
                          name="regionRoId"
                          options={roDropdown}
                          label={t("Region-RO")}
                        />
                        {!!values.regionRoId && (
                          <Message
                            info
                            content={`${t("cities")}: ${roRegions!
                              .find((x) => x.id === values.regionRoId)
                              ?.cities.map((x) => x.name)
                              .join(" , ")}`}
                          />
                        )}
                      </>
                    )}
                  </Segment>
                </Grid.Column>
              </Grid>
              <Segment>
                <Button
                  color="red"
                  onClick={() => navigate("/admin/users/list")}
                >
                  <Icon name="arrow left" />
                </Button>
                <Button color="teal" type="submit">
                  {t("Create")}
                </Button>
              </Segment>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  );
});
