import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const AccidentAtWorkSchema = yup.object().shape({
  cityId: yup.number().required("Ова поле е задолжително").nullable(),
  employerName: yup.string().required("Ова поле е задолжително").max(255, "Називот не смее да надминува повеќе од 255 карактери"),
  employerAddress: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employerMunicipality: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employerPostalCode: yup.string().required("Ова поле е задолжително").max(255, "Бројот не смее да надминува повеќе од 255 карактери"),
  workplaceLocation: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  injuredFullname: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  injuredAddress: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  injuredMunicipality: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  injuredPostalCode: yup.string().required("Ова поле е задолжително").max(255, "Бројот не смее да надминува повеќе од 255 карактери"),
  birthDate: yup.date().required("Одберете датум на раѓање").nullable(),
  gender: yup.string().required("Одберете пол"),
  occupation: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employmentPeriod: yup
    .string()
    .required("Одберете колку време е вработен кај работодавачот"),
  accidentTime: yup
    .date()
    .required("Одберете датум и час на случување на несреќата")
    .nullable(),
  shift: yup.string().required("Одберете смена"),
  hours: yup.string().required("Ова поле е задолжително").max(100, "Текстот не смее да надминува повеќе од 100 карактери"),
  injuryDescription: yup.string().required("Ова поле е задолжително").max(1000, "Текстот не смее да надминува повеќе од 1000 карактери"),
  accidentDescription: yup.string().required("Ова поле е задолжително").max(1000, "Текстот не смее да надминува повеќе од 1000 карактери"),
  doctorOpinion: yup.string().required("Ова поле е задолжително"),
  investigation: yup.string().required("Одберете дали е спроведена истрага"),
  damage: yup.string().required("Одберете дали е настаната значителна штета"),
  submitterFullname: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  submitterWorkPosition: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  submitterPhoneNumber: yup
    .string()
    .matches(phoneRegExp, "Внесете валиден телефонски број")
    .required("Ова поле е задолжително").max(50, "Телефонскиот број не смее да надминува повеќе од 50 карактери"),
  submitterEmail: yup
    .string()
    .email("Внесете валидна e-mail адреса")
    .required("Ова поле е задолжително").max(100, "Електронската пошта не смее да надминува повеќе од 100 карактери"),
  // date: yup.date().required("Одберете датум").nullable(),
});
