import React, { useEffect } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import {
  Segment,
  Grid,
  GridColumn,
  Divider,
} from "semantic-ui-react";
import MyTextInput from "./inputs/MyTextInput";
import MyDateInput from "./inputs/MyDateInput";
import MyTextArea from "./inputs/MyTextArea";
import MyRadio from "./inputs/MyRadio";
import MyRadioGroup from "./inputs/MyRadioGroup";
import MySelectInput from "./inputs/MySelectInput";
import { AccidentAtWorkSchema } from "./schemas/AccidentAtWorkSchema";
import { AccidentAtWorkInterface } from "../../app/models/Forms/AccidentAtWorkInterface";
import agent from "../../app/api/agent";
import { FormPDF } from "./pdf/FormPDF";
import { PdfFormFieldInfo } from "./pdf/FormPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useStore } from "../../app/stores/store";
import moment from "moment-timezone";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AccidentForm from "./commonForms/Reports/AccidentForm";
// import { toast } from "react-toastify";

export default observer(function AccidentAtWorkForm() {
  const { modalStore, codesStore, formStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { citiesDropdown, loadCities } = codesStore;
  const { loadingInitial } = formStore;
  const navigate = useNavigate();

  useEffect(() => {
    loadCities();
  }, [loadCities]);

  if (!citiesDropdown || loadingInitial) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: AccidentAtWorkInterface,
    actions: FormikHelpers<AccidentAtWorkInterface>
  ) => {
    try {
      await agent.Forms.accidentAtWork(values);
    } catch {
      toast.error("Неуспешно испратено известување");
      return;
    }
    const city = citiesDropdown.find((x) => x.value === values.cityId)?.text;

    const infoArray: PdfFormFieldInfo[] = [
      {
        inputMainLabel: "1. Назив на работодавачот:",
        inputValue: values.employerName,
      },
      {
        inputMainLabel: "2. Седиште на работодавачот:",
      },
      {
        inputLabel: "Улица, број, место:",
        inputValue: values.employerAddress,
      },
      {
        inputLabel: "Општина:",
        inputValue: values.employerMunicipality,
      },
      {
        inputLabel: "Поштенски број:",
        inputValue: values.employerPostalCode,
      },
      {
        inputMainLabel:
          "3. Локација на работното место (продавница, магацин, кат, адреса):",
        inputValue: values.workplaceLocation,
      },
      {
        inputMainLabel: "4. Лични податоци на повредениот:",
      },
      {
        inputLabel: "Име и презиме:",
        inputValue: values.injuredFullname,
      },
      {
        inputLabel: "Адреса на постојано живеење (улица, број, место):",
        inputValue: values.injuredAddress,
      },
      {
        inputLabel: "Општина:",
        inputValue: values.injuredMunicipality,
      },
      {
        inputLabel: "Поштенски број",
        inputValue: values.injuredPostalCode,
      },
      {
        inputLabel: "Датум на раѓање",
        inputValue: moment
          .utc(values.birthDate)
          .local()
          .format("D MMMM YYYY")
          .toString(),
      },
      {
        inputMainLabel: "5. Пол:",
        inputValue: values.gender,
      },
      {
        inputMainLabel: "6. Професија на работно место на повредената личност:",
        inputValue: values.occupation,
      },
      {
        inputMainLabel: "7. Колку време е вработен кај работодавачот?",
        inputValue: values.employmentPeriod,
      },
      {
        inputMainLabel: "8. Датум и час на случување на несреќата",
        inputValue: moment
          .utc(values.accidentTime)
          .local()
          .format("D MMMM YYYY, h:mm A")
          .toString(),
      },
      {
        inputLabel: "смена:",
        inputValue: values.shift,
      },
      {
        inputMainLabel:
          "9. Опис на повредата (не е третирана, прва помош, лекар (не е хоспитализиран), хоспитализиран",
        inputValue: values.injuryDescription,
      },
      {
        inputMainLabel: "10. Каде и како се случила несреќата?",
        inputValue: values.accidentDescription,
      },
      {
        inputMainLabel: "11. Дали имате наод и мислење од лекар?",
        inputValue: values.doctorOpinion,
      },
      {
        inputMainLabel: "12. Дали е спроведена истрага?",
        inputValue: values.investigation,
      },
      {
        inputMainLabel: "13. Дали е настаната значителна штета?",
        inputValue: values.damage,
      },
      {
        inputMainLabel: "Пополнето од:",
      },
      {
        inputLabel: "Име и презиме:",
        inputValue: values.submitterFullname,
      },
      {
        inputLabel: "Работно место:",
        inputValue: values.submitterWorkPosition,
      },
      {
        inputLabel: "Телефонски број:",
        inputValue: values.submitterPhoneNumber,
      },
      {
        inputLabel: "E-mail адреса:",
        inputValue: values.submitterEmail,
      },
    ];
    openModal(
      <Segment placeholder>
        <Grid>
          <GridColumn>
            <div>
              <PDFDownloadLink
                document={
                  <FormPDF
                    values={infoArray}
                    certificationLabel={
                      "место за заверка на Државниот инспекторат за труд"
                    }
                    institutionLabel={"Државен инспекторат за труд"}
                    regionLabel={"Подрачје:"}
                    regionValue={`Град ${city}`}
                    subtitle={
                      "(Член 36 од Законот за безбедност и здравје при работа „Службен весник на Република Македонија“ бр.92/07)"
                    }
                    title={"ИЗВЕСТУВАЊЕ ЗА НЕСРЕЌА НА РАБОТА"}
                  />
                }
                fileName="НесреќаРабота.pdf"
                className="btn-form-submit"
              >
                <p
                  onClick={(e) => e.preventDefault()}
                  className="text-form-modal"
                >
                  Дали сакате да превземете во PDF-формат?
                </p>
                <button
                  className="button-form-modal"
                  onClick={() => {
                    navigate("/");
                    // closeModal();
                  }}
                >
                  Превземи
                </button>
              </PDFDownloadLink>
            </div>
          </GridColumn>
        </Grid>
        <div className="close-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            X
          </button>
        </div>
      </Segment>
    );
  };

  return (
    <AccidentForm handleSubmit={handleSubmit} citiesDropdown={citiesDropdown} />
  );
});
