import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { Segment, Button, ButtonGroup } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { KnownSubmitterInterface } from "../../../app/models/Forms/KnownSubmitterInterface";
import agent from "../../../app/api/agent";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import KnownForm from "../commonForms/Grievances/KnownFrom";

export default observer(function KnownSubmitterEdit() {
  const { codesStore, formStore } = useStore();
  const { selectedKnownSubmitterForm, loadSingleKnownSubmitterForm } =
    formStore;
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    loadCities,
    citiesDropdown,
    loadNkds,
    nkdDropdown,
    loadVrBzr,
    vrBzrDropdown,
    loadVrRo,
    vrRoDropdown,
  } = codesStore;

  useEffect(() => {
    if (id) loadSingleKnownSubmitterForm(id);
    loadVrBzr();
    loadVrRo();
    loadCities();
    loadNkds();
  }, [loadVrBzr, loadVrRo, loadCities, loadNkds]);

  if (
    !vrBzrDropdown ||
    !vrRoDropdown ||
    !nkdDropdown ||
    !citiesDropdown ||
    !selectedKnownSubmitterForm
  ) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: KnownSubmitterInterface,
    actions: FormikHelpers<KnownSubmitterInterface>
  ) => {
    try {
      await agent.Forms.updateKnownSubmitter(values).then(() => {
        toast.success(t("successfully-edited-grievance!"));
      });
    } catch {
      toast.error(t("failed-to-edit-grievance!"));
      return;
    }
  };

  return (
    <>
      <AdminHeader header={t("edit-grievance")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedKnownSubmitterForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedKnownSubmitterForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedKnownSubmitterForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <KnownForm
        handleSubmit={handleSubmit}
        nkdDropdown={nkdDropdown}
        citiesDropdown={citiesDropdown}
        vrBzrDropdown={vrBzrDropdown}
        vrRoDropdown={vrRoDropdown}
        initialValues={selectedKnownSubmitterForm}
      />
    </>
  );
});
