import React from "react";
import { NewsCard } from "./NewsCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { CategorySearch } from "./CategorySearch";
import MainFooter from "../../app/layout/MainFooter";
import MainHeader from "../../app/layout/MainHeader/MainHeader";
import "./CategoryPage.css";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";

export default observer(function ArchivePage() {
  const {postType} = useParams();
  const { t } = useTranslation();
  const {postStore: {addStaticFilterParam, pagination, sortedPosts, setPagingParams}
  ,commonStore: {language}
} = useStore();
  React.useEffect(()=> {
    if(postType){
      addStaticFilterParam('type', postType);
    }
  },[postType,addStaticFilterParam])

  return (
    <>
      <div>
        <MainHeader />
      </div>
      <main style={{paddingTop:"140px"}}>
        <Container>
        <h1>{`${t('arhiva')}: ${t(postType || '')}`}</h1>
        <hr className="category-hr" /> 
        <section>
          <div className="category-news">
            <ul className="category-post-sort">
              {sortedPosts.map(x=> (<li>
                <NewsCard post={x} key={x.id} language={language} />
              </li>
              ))}
            </ul>
          </div>
          <div className="category-pagination">
            <div className="category-arrow"
             onClick={(e)=>{
              e.preventDefault();
              if(pagination && pagination.currentPage > 1){
                setPagingParams({
                  pageNumber: pagination!.currentPage -1,
                  pageSize: -1
                })
              }
            }}
            >
              <MdKeyboardArrowLeft
                size={21}
                style={{ display: "flex", alignItems: "center" }}
              />
            </div>
            <div className="category-pages">
              {Array.from(Array(pagination?.totalPages).keys()).map(x=> (  
              <div className={x+1 === pagination?.currentPage  ? "active": ''}
                onClick={(e)=>{
                  e.preventDefault();
                  setPagingParams({
                    pageNumber: x+1,
                    pageSize: -1
                  })
                } }
              >
                {x+1}
              </div>
              ))}
            </div>
            <div className="category-arrow" 
              onClick={(e)=>{
                e.preventDefault();
                if(pagination && pagination.currentPage < pagination.totalPages){
                  setPagingParams({
                    pageNumber: pagination.currentPage + 1,
                    pageSize: -1
                  })
                }
              } }
            >
              <MdKeyboardArrowRight
                size={21}
                style={{ display: "flex", alignItems: "center" }}
              />
            </div>
          </div>
        </section>
        </Container>
      </main>
      <footer>
        <MainFooter />
      </footer>
    </>
  );
});
