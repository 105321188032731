import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  WorkOnSundayInterface,
  // WorkOnSundayInterfaceTest,
  SundayJobPosition,
  WorkOnSunday,
} from "../../../app/models/Forms/WorkOnSundayInterface";
import { useStore } from "../../../app/stores/store";
import JsonView from "./JsonView";

export default observer(function WorkOnSundayView() {
  const { formStore } = useStore();
  const {
    loadingInitial,
    loadSingleWorkOnSundayForm,
    selectedWorkOnSundayForm,
  } = formStore;
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) loadSingleWorkOnSundayForm(id);
  }, [loadSingleWorkOnSundayForm, id]);

  if (loadingInitial || !selectedWorkOnSundayForm) {
    return <LoadingComponent />;
  }

  const jsonArr = new WorkOnSunday(selectedWorkOnSundayForm).toStringArray();

  return (
    <>
      <AdminHeader header={t("view-work-on-sunday")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <JsonView arrJSONData={jsonArr} />
    </>
  );
});
