import { makeAutoObservable } from "mobx";

interface Modal {
  open: boolean;
  body: JSX.Element | null;
  basic?: boolean;
  size?:"mini" | "tiny" | "small" | "large" | "fullscreen" | undefined;
}

export default class ModalStore {
  modal : Modal = {
    open: false,
    body: null
  }

  constructor(){
    makeAutoObservable(this);
  }


  openModal = (content: JSX.Element) =>{
    this.modal.open = true;
    this.modal.body = content;
  }


  openModalBasic = (content: JSX.Element, size?: "mini" | "tiny" | "small" | "large" | "fullscreen" | undefined ) =>{
    this.modal.open = true;
    this.modal.body = content;
    this.modal.basic= true;
    if(size)
      this.modal.size= size;
  }


  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
    this.modal.basic= false;
    this.modal.size = undefined;
  }
}

