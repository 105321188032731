import { t } from "i18next";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../stores/store";
import { FilterOption } from "../types/FilterOption";
import FilterModal from "./FilterModal";

interface Props {
  closeModal: () => void;
  setFilter: (filterName: string, filterValue: string) => void
}

export default observer(function PostFilterModal({ closeModal, setFilter }: Props) {
  const {postCategoryStore: { allCategorySlugs }} = useStore();

  const statusOptions = [
    { key: 'Published', value: 'Published', text: t('Published') },
    { key: 'Created', value: 'Created', text: t('Created') }
  ]

  const filterOptions: FilterOption[] = [
    { name: 'name', text: 'Name', type: 'text' },
    { name: 'slug', text: 'slug', type: 'text' },
    { name: 'categorySlug', text: 'Category', type: 'select', options: allCategorySlugs  },
    { name: 'status', text: 'Status', type: 'select', options: statusOptions },
  ]


  return (
    <>
      <FilterModal
        filterOptions={filterOptions}
        closeModal={closeModal}
        setFilter={setFilter} />
    </>
  )
})