import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import NavigationForm from "./NavigationForm";
import AdminHeader from "../AdminHeader";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../stores/store";
import { toast } from "react-toastify";
import LoadingComponent from "../../LoadingComponent";
import { NavigationItem } from "../../../models/CMS/Homepage/navigationItem";

export default observer(function CreateNavigation() {
  // const { lang } = useParams();
  const { t } = useTranslation();
  const {
    navigationStore: { createNavigation, loadingInitial },
    commonStore: { languageExists },
  } = useStore();
  const [selectedPhotoId, setSelectedPhotoId] = useState<string | null>(null);
  const navigate = useNavigate();
  // if(!lang || !languageExists(lang)){
  //   navigate('/admin/homepage/isotope/list');
  //   toast.error('Language does not exist');

  // }

  if (loadingInitial) {
    return <LoadingComponent />;
  }

  const onSubmit = (values: NavigationItem) => {
    createNavigation(values);
    // .then(()=> navigate('/admin/homepage/nav/list'))
  };

  return (
    <>
      <AdminHeader header={t("create-navigation")} icon="location arrow" />
      <NavigationForm onSubmit={onSubmit} />
    </>
  );
});
