import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Image,
  ButtonGroup,
  Button,
  ButtonOr,
  Card,
  CardContent,
  Message,
  Segment,
} from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../../app/stores/store";
import ModalDialog from "../../../../app/common/modals/ModalDialog";

export default observer(function SliderList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    sliderStore: {
      removeSlide,
      loadSlider,
      sliderSlides,
    }, modalStore: {openModal,closeModal},

    commonStore: {  movingUp, movingDown },
  } = useStore();

  React.useEffect(() => {
    loadSlider();
  }, [loadSlider]);

  return (
    <>
      <AdminHeader header={t("slider-slider-list")} icon="film" />
      <Segment attached="top">
        {sliderSlides.length === 0 && (
          <Message info content={t("there-are-no-slides")} />
        )}
        {sliderSlides.sort((a,b)=> a.itemOrder - b.itemOrder).map((x, i, arr) => {
          return (
            <Card>
              <CardContent>
                {
                  <Image
                    wrapped
                    src={`/media/images/${x.image?.path}`}
                    href={`/media/images/${x.image?.path}`}
                    target="_blank"
                  />
                }
              </CardContent>
              <CardContent extra>
                <ButtonGroup>
                  <Button
                    content={t("edit")}
                    color="green"
                    disabled={!!movingUp || !!movingDown}
                    onClick={() =>
                      navigate(`/admin/homepage/slider/edit/${x.id}`)
                    }
                  />
                  <ButtonOr text={t("or")} />
                  <Button
                    content={t("delete")}
                    onClick={() => {
                      openModal(<ModalDialog
                      cancel={closeModal}
                      noText='Cancel'
                      confirm={()=> {removeSlide(x.id); closeModal();}}
                      title={t('delete-slider?')}
                      description={t("are-you-sure-you-want-to-delete-slider?")}
                      />)
                    }}
                    disabled={!!movingUp || !!movingDown}
                    color="red"
                  />
                </ButtonGroup>
              </CardContent>
            </Card>
          );
        })}
      </Segment>
      <Segment textAlign="right" attached="bottom">
        <Button
          content={t("add-new-slide")}
          icon="plus"
          color="teal"
          onClick={() => navigate("/admin/homepage/slider/create")}
        />
      </Segment>
    </>
  );
});
