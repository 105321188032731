import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Icon, Segment } from "semantic-ui-react";
import AdminHeader from "../../app/layout/admin/AdminHeader";
import MyTextArea from "../forms/inputs/MyTextArea";
import MyTextInput from "../forms/inputs/MyTextInput";
import { eTraining } from "../../app/models/ELearning/eTraining";
import * as Yup from "yup";
import { useStore } from "../../app/stores/store";
import { v4 as uuid } from "uuid";
import { observer } from "mobx-react-lite";

export default observer(function CreateTraining() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    learningStore: { createTraining },
  } = useStore();

  const initialValues: eTraining = {
    id: "",
    trainingName: "",
    trainingDesc: "",
    trainingOrder: 0,
    certValidMonths: 0,
  };

  const messageSchema = Yup.object().shape({
    trainingName: Yup.string().required(t("this-field-is-required")),
  });

  return (
    <>
      <AdminHeader
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
        header={t("training")}
        subheader={t("create-trainings")}
      />
      <Segment>
        <Formik
          initialValues={initialValues}
          validationSchema={messageSchema}
          onSubmit={(values) => {
            values.id = uuid();
            createTraining(values).then(() =>
              navigate("/admin/e-learning/listTraning")
            );
          }} >
          {({ errors, touched, values, submitForm }) => (
            <Form autoComplete="off">
              <Grid>
                <Grid.Column width={16}>
                  <MyTextInput name="trainingName" label={t("training-name")} />
                  <MyTextArea
                    rows={7}
                    name="trainingDesc"
                    label={t("training-description")}
                  />
                  <MyTextInput
                    name="certValidMonths"
                    label={t("valid-months")}
                  />
                </Grid.Column>
              </Grid>
              <Segment>
                <Button
                  color="red"
                  onClick={() => navigate("/admin/e-learning/listTraning")}
                >
                  <Icon name="arrow left" />
                </Button>
                <Button color="teal" type="submit">
                  {t("create")}
                </Button>
              </Segment>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
