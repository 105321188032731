import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Icon, Segment, Table } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../../app/stores/store";
import ModalDialog from "../../../../app/common/modals/ModalDialog";
import _ from "lodash";

export default observer(function ViewSlideContent() {
  const { t } = useTranslation();
  const { id, slideId, traningId } = useParams();
  const navigate = useNavigate();
  const {
    learningStore: {
      loadContent,
      sortedContent,
      target,
      deleteContent,
      loadContentType,
      selectedContent,
      getSingleContent,
      // contentOrderIncrement,
      // contentOrderDecrement,
    },
    modalStore: { openModal, closeModal },
    // commonStore: { moveUp, moveDown, movingUp, movingDown },
  } = useStore();

  React.useEffect(() => {
    if (id) {
      loadContent(id);
      loadContentType();
    }
  }, [loadContent, loadContentType, id]);


  return (
    <>
      <AdminHeader
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
        header={t("slide-content")}
        subheader={t("view-slide-contents")}
      />

      <Segment>
        <Button
          as={Link}
          to={`/e-learning/slide/${id}}`.slice(0,-1)}
          content={t("view-page")} />
      </Segment>

      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Title")}</Table.HeaderCell>
            <Table.HeaderCell>{t("content")}</Table.HeaderCell>
            <Table.HeaderCell>{t("order")}</Table.HeaderCell>
            <Table.HeaderCell>{t("type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedContent.map((x) => {
            return (
              <Table.Row key={x.id}>
                <Table.Cell>{x.header}</Table.Cell>
                <Table.Cell>{x.body}</Table.Cell>
                <Table.Cell>{x.contentOrder}</Table.Cell>
                <Table.Cell>{x.slideTypeName}</Table.Cell>
                <Table.Cell>
                  <ButtonGroup>
                    <Button
                      as={Link}
                      to={`/admin/e-learning/editContent/${x.id}/${slideId}`}
                      icon="edit"
                      inverted
                      color="green"
                    />
                    <Button
                      name={x.id}
                      loading={target === x.id}
                      onClick={(e) => {
                        openModal(
                          <ModalDialog
                            cancel={closeModal}
                            noText="Cancel"
                            confirm={() => {
                              deleteContent(x.id);
                              closeModal();
                            }}
                            title={t(
                              "are-you-sure-you-want-to-delete-this-content"
                            )}
                            description={t(
                              "once-you-delete-the-content-the-data-will-be-lost-forever"
                            )}
                          />
                        );
                      }}
                      icon="close"
                      inverted
                      color="red"
                    />
                  </ButtonGroup>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Segment>
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "left", width: "50%" }}>
            <Button
              color="red"
              onClick={() =>
                navigate(
                  `/admin/e-learning/listSlides/${slideId}/${traningId}`)} >
              <Icon name="arrow left" />
            </Button>
          </div>
          <div style={{ textAlign: "right", width: "50%" }}>
            <Link
              to={`/admin/e-learning/createSlideContent/${id}/${slideId}`}
              className="ui button teal"
            >
              <Icon name="plus" />
              {t("add-content")}
            </Link>
          </div>
        </div>
      </Segment>
    </>
  );
});
