import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Segment, SegmentGroup, Tab } from "semantic-ui-react";
import { Languages } from "../../../../../app/common/types/CustomTypes";
import { IsotopeGridItem } from "../../../../../app/models/CMS/Homepage/isotope";
import IsotopeTable from "./IsotopeTable";

export default function IsotopePane(
  sortedIsotopeGridItems: IsotopeGridItem[],
  lang: Languages
) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return {
    menuItem: lang,
    render: () => (
      <Tab.Pane>
        <SegmentGroup>
          <Segment>
            <IsotopeTable
              sortedIsotopeGridItems={sortedIsotopeGridItems.filter(
                (x) => x.languageId === lang
              )}
            />
          </Segment>
          <Segment attached="top" textAlign="right">
            <Button
              color="teal"
              icon="plus"
              content={t("add-new")}
              onClick={() => navigate(`/admin/homepage/isotope/create/${lang}`)}
            />
          </Segment>
        </SegmentGroup>
      </Tab.Pane>
    ),
  };
}
