import FilterModal from "../../../app/common/modals/FilterModal";
import { useTranslation } from "react-i18next";
import { FilterOption } from "../../../app/common/types/FilterOption";

interface Props {
  closeModal: () => void;
  setFilter: (filterName: string, filterValue: string) => void;
}

export default function MediaFilterModal({ closeModal, setFilter }: Props) {
  const { t } = useTranslation();
  const filterOptions: FilterOption[] = [
    { name: "name", text: t("Name"), type: "text" },
  ];

  return (
    <>
      <FilterModal
        filterOptions={filterOptions}
        closeModal={closeModal}
        setFilter={setFilter}
      />
    </>
  );
}
