import { useTranslation } from "react-i18next";
import { Container, Segment } from "semantic-ui-react";
import MainFooter from "../../../app/layout/MainFooter";
import MainHeader from "../../../app/layout/MainHeader/MainHeader";
import PageHeader from "../../../app/layout/PageHeader/PageHeader";
import HomePageDocumentsItem from "../Documents/HomePageDocumentsItem";
import "./Izvestuvanja.css";

export default function IzvestuvanjaPage() {
  const { t } = useTranslation();
  return (
    <>
      <MainHeader isFixed={false} />
      <main style={{ marginTop: "150px" }}>
        <Container>
          <div>
            <PageHeader header={t("reports")} />
          </div>
          <Segment style={{ margin: "40px 0" }}>
            <div className="izvestuvanja">
              <ul>
                <HomePageDocumentsItem
                  to="/forms/overtime-work"
                  content={t("overtime-work")}
                  image="/images/icons/izvestuvanja/overtime.png"
                />
                <HomePageDocumentsItem
                  to="/forms/work-on-holiday"
                  content={t("work-on-holiday")}
                  image="/images/icons/izvestuvanja/holiday_proba.png"
                />
                <HomePageDocumentsItem
                  to="/forms/night-shift"
                  content={t("night-shift-work")}
                  image="/images/icons/izvestuvanja/night.png"
                />
                <HomePageDocumentsItem
                  to="/forms/work-on-sunday"
                  content={t("work-on-sunday")}
                  image="/images/icons/izvestuvanja/sunday.png"
                />
                <HomePageDocumentsItem
                  to="/forms/accident-at-work"
                  content={t("workplace-accident")}
                  image="/images/icons/izvestuvanja/accident.png"
                />
                <HomePageDocumentsItem
                  to="/forms/starting-activity"
                  content={t("starting-an-activity")}
                  image="/images/icons/izvestuvanja/starting.png"
                />
                <HomePageDocumentsItem
                  to="/forms/construction-works"
                  content={t("construction-works")}
                  image="/images/icons/izvestuvanja/construction.png"
                />
              </ul>
            </div>
          </Segment>
        </Container>
      </main>

      <footer>
        <MainFooter />
      </footer>
    </>
  );
}
