import React from "react";
import { Field, useField } from "formik";

interface Props {
  placeholder?: string;
  name: string;
  type?: string;
  value: string;
  label: string;
  info?: string;
}

export default function MyCheckbox(props: Props) {
  const [field, meta] = useField(props.name);
  return (
    <label
      style={{ display: "flex", alignItems: "center", paddingBottom: "5px" }}
    >
      <Field
        type="checkbox"
        name={field.name}
        // error={meta.touched && !!meta.error}
        value={props.value}
      />
      &nbsp; {props.label}
    </label>
  );
}
