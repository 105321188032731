import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Segment,
  Image,
  Form,
  Grid,
  GridColumn,
  ButtonGroup,
  Button,
  Icon,
  SegmentGroup,
  Header,
} from "semantic-ui-react";
import PhotoSelectWidget from "../../../../../app/common/imageUpload/photoSelectWidget";
import { IsotopeGridItem } from "../../../../../app/models/CMS/Homepage/isotope";
import MyRadio from "../../../../forms/inputs/MyRadio";
import MyRadioGroup from "../../../../forms/inputs/MyRadioGroup";
import MyTextInput from "../../../../forms/inputs/MyTextInput";

interface Props {
  initialValues?: IsotopeGridItem;
  onSubmit: (data: IsotopeGridItem) => void;
  setSelectedPhotoId: (id: string | null) => void;
  selectedPhotoId: string | null;
}

export default function IsotopeForm({
  initialValues,
  onSubmit,
  setSelectedPhotoId,
  selectedPhotoId,
}: Props) {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initValues = initialValues || {
    filterName: "",
    height: -1,
    width: -1,
    imagePath: "",
    itemOrder: 0,
    languageId: "",
    linkUrl: "",
    photoId: "",
    id: "",
  };

  return (
    <>
      <style>
        {`
      .ui.segment.image-segment,
      .ui.segment.image-segment:hover div {
        display: flex !important;
        min-height:343px;
      }
    `}
      </style>
      <Formik
        initialValues={initValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ errors, touched, setFieldValue, submitForm }) => (
          <Segment>
            <Form>
              <Grid>
                <GridColumn width={6}>
                  <Segment>
                    <MyTextInput label={t("filter-name")} name={"filterName"} />
                    <MyTextInput label={t("link-url")} name={"linkUrl"} />
                    <Segment>
                      <ButtonGroup fluid>
                        <Button
                          color="red"
                          onClick={(ev) => {
                            ev.preventDefault();
                            navigate("/admin/homepage/isotope/list");
                          }}
                        >
                          <Icon name="arrow left" />
                        </Button>
                        <Button
                          color="green"
                          type="submit"
                          content={t("submit")}
                          onClick={() => submitForm()}
                        />
                      </ButtonGroup>
                    </Segment>
                  </Segment>
                </GridColumn>
                <GridColumn width={10}>
                  <Segment textAlign="right">
                    <SegmentGroup horizontal>
                      <Segment compact textAlign="left">
                        <Header as="h3" content={t("width")}></Header>
                        <MyRadioGroup
                          touched={touched.width}
                          error={errors.width}
                        >
                          <MyRadio
                            type="radio"
                            name="width"
                            value={1}
                            label="1"
                            onChange={(e) =>
                              setFieldValue(
                                "width",
                                parseInt(e.currentTarget.value)
                              )
                            }
                          />
                          <br />
                          <MyRadio
                            type="radio"
                            name="width"
                            value={2}
                            label="2"
                            onChange={(e) =>
                              setFieldValue(
                                "width",
                                parseInt(e.currentTarget.value)
                              )
                            }
                          />
                          <br />
                          <MyRadio
                            type="radio"
                            name="width"
                            value={3}
                            label="3"
                            onChange={(e) =>
                              setFieldValue(
                                "width",
                                parseInt(e.currentTarget.value)
                              )
                            }
                          />
                        </MyRadioGroup>
                      </Segment>
                      <Segment compact textAlign="left">
                        <Header as="h3" content={t("height")}></Header>
                        <MyRadioGroup
                          touched={touched.height}
                          error={errors.height}
                        >
                          <MyRadio
                            type="radio"
                            name="height"
                            value={1}
                            label="1"
                            onChange={(e) =>
                              setFieldValue(
                                "height",
                                parseInt(e.currentTarget.value)
                              )
                            }
                          />
                          <br />
                          <MyRadio
                            type="radio"
                            name="height"
                            value={2}
                            label="2"
                            onChange={(e) =>
                              setFieldValue(
                                "height",
                                parseInt(e.currentTarget.value)
                              )
                            }
                          />
                          <br />
                          <MyRadio
                            type="radio"
                            name="height"
                            value={3}
                            label="3"
                            onChange={(e) =>
                              setFieldValue(
                                "height",
                                parseInt(e.currentTarget.value)
                              )
                            }
                          />
                        </MyRadioGroup>
                      </Segment>
                    </SegmentGroup>
                    <Segment className={`${!editMode && "hidden"}`}>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          setEditMode(false);
                          setSelectedPhotoId(initialValues?.photoId || null);
                        }}
                        icon="close"
                        color="red"
                        style={{ marginBottom: "15px" }}
                      />
                      <PhotoSelectWidget
                        extraContent={(id) => (
                          <Button
                            disabled={id === selectedPhotoId}
                            fluid
                            content="Select"
                            color="green"
                            onClick={() => setSelectedPhotoId(id)}
                          />
                        )}
                      />
                    </Segment>
                  </Segment>
                  {!editMode && (
                    <Segment
                      className="image-segment"
                      onClick={() => {
                        setEditMode(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        src={
                          initialValues?.imagePath
                            ? `/media/images/${initialValues?.imagePath}`
                            : `/images/no-image.jpg`
                        }
                        style={{ width: "300px", margin: "auto" }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          background: "#babababa",
                          opacity: "0.8",
                          height: "100%",
                          display: "none",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Icon name="edit" size="huge" color="green" />
                      </div>
                    </Segment>
                  )}
                </GridColumn>
              </Grid>
            </Form>
          </Segment>
        )}
      </Formik>
    </>
  );
}
