import { Markup } from 'interweave';
import React from 'react'
import { Embed } from 'semantic-ui-react';
import { eSlideContent } from '../../../app/models/ELearning/eSlide';

interface Props {
    slideContents: eSlideContent[];
    colorSchemeArray: string[];
}

export default function VideoSlide({ slideContents, colorSchemeArray }: Props) {

    const header = slideContents?.find(x => x.type.typeName === 'Header')?.body;
    const backgroundColor = colorSchemeArray[0];
    
    const video = findVideo(slideContents , 'Video')

    return (
        <>
            <div className="slide-img-par-1">
                <h1 className="slide-header"><Markup content={header} /> </h1>
                <div className="slide-header-line" style={{backgroundColor}}> </div>
                <div style={{ marginTop: '40px' }}>
                    <video width="1127" height="634" controls>
                        <source src={`/images/elearning/components/${video}.mp4`} type="video/mp4" />
                    </video>
                </div>
            </div>
        </>
    )
}

function findVideo(slideContents: eSlideContent[], contentTypeName: string) {
    return slideContents.find(x => x.type.typeName === contentTypeName)?.body;
  }