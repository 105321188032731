import React, { useEffect } from "react";
import {  FormikHelpers } from "formik";
import { UnknownSubmitterInterface } from "../../app/models/Forms/UnknownSubmitterInterface";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../app/layout/LoadingComponent";
import agent from "../../app/api/agent";
import { toast } from "react-toastify";
import "./FormsModal.css";
import { useNavigate } from "react-router-dom";
import UknowForm from "./commonForms/Grievances/UnknownForm";



export default observer(function UnknownSubmitterForm() {
  const { codesStore } = useStore();

  const {
    loadCities,
    citiesDropdown,
    loadNkds,
    nkdDropdown,
    loadVrBzr,
    vrBzrDropdown,
    loadVrRo,
    vrRoDropdown,
  } = codesStore;

  useEffect(() => {
    loadVrBzr();
    loadVrRo();
    loadCities();
    loadNkds();
  }, [loadVrBzr, loadVrRo, loadCities, loadNkds]);

  const navigate = useNavigate();

  if (!citiesDropdown || !nkdDropdown || !vrRoDropdown || !vrBzrDropdown) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: UnknownSubmitterInterface,
    actions: FormikHelpers<UnknownSubmitterInterface>
  ) => {
    try {
      await agent.Forms.unknownSubmit(values).then(() => {
        toast.success("Успешно испратена пријава");
        navigate("/");
      });
    } catch {
      toast.error("Неуспешно испратена пријава");
      return;
    }
  };

  return (
    <UknowForm
      nkdDropdown={nkdDropdown}
      citiesDropdown={citiesDropdown}
      handleSubmit={handleSubmit}
      vrBzrDropdown={vrBzrDropdown}
      vrRoDropdown={vrRoDropdown}
    />
  );
});
