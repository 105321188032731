import { LazyLoadImage } from "react-lazy-load-image-component";
import { eSlideContent } from "../../../app/models/ELearning/eSlide";

interface Props {
  slideContents?: eSlideContent[];
  colorSchemeArray: string[];
}

export default function ImageParagraph1({ slideContents, colorSchemeArray }: Props) {

  const header = slideContents?.find(x => x.type.typeName === 'Header')?.body;
  const image = slideContents?.find(x => x.type.typeName === 'Image')?.body;
  const contentHeader = slideContents?.find(x => x.type.typeName === 'Content-Header')?.body;
  // const paragraph = slideContents?.find(x => x.type.typeName === 'Paragraph')?.body;
  const paragraph = sortedContentArray(slideContents, 'Paragraph')
  const imgSrc = image || "unkown.jpg"
  const color = colorSchemeArray[0];
  const backgroundColor = colorSchemeArray[0];

  return (
    <div className="slide-img-par-1">
      <h1 className="slide-header"> {header}</h1>
      <div className="slide-header-line" style={{ backgroundColor }}> </div>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div className="slide-image-paragraph">
          <LazyLoadImage alt={'featured-image'}
            src={`/images/elearning/components/${imgSrc}.jpg`}
            width={450}
            effect="blur"
            placeholderSrc={`/images/elearning/thumbnail/${imgSrc}.jpg`}
          />
        </div>
        <div style={{ width: '60%' }}>
          <h3 className="slide-content-header" style={{ color }} >
            {contentHeader}
          </h3>
          <div style={{ marginTop: '20px' }}>
            {paragraph?.map(x => x.body.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll('•', ' ')
              .replaceAll('(i)', ' ').replaceAll('(ii)', ' ').replaceAll('(iii)', ' '))}
            {/* <Markup content={paragraph} /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
  return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}