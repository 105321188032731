import { action, makeAutoObservable, runInAction, values } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { eCertificate } from "../models/ELearning/eCertificate";
import { eModule } from "../models/ELearning/eModule";
import { eProgress } from "../models/ELearning/eProgress";
import { eAnswer, eSlide, eSlideContentType, eSlideType } from "../models/ELearning/eSlide";
import { eTraining } from "../models/ELearning/eTraining";
import { eSlideContent } from "../../app/models/ELearning/eSlide";
import { DropdownItemProps } from "semantic-ui-react";

export default class LearningStore {
  trainingsRegistry = new Map<string, eTraining>();
  modulesRegistry = new Map<string, eModule>();
  slidesRegistry = new Map<string, eSlide>();
  slidesContentRegistry = new Map<string, eSlideContent>();
  answerRegistry = new Map<string, string>();
  target: string | null = null;


  // Quiz

  nextDisabled = false;
  setNextDisabled = (setDisabled: boolean) => {
    this.nextDisabled = setDisabled;
  }

  clearAnswerRegistry = () => {
    this.answerRegistry.clear();
  }

  unloadQuiz = () => {
    this.clearAnswerRegistry();
    this.setNextDisabled(false);
  }

  loadQuiz = () => {
    this.setNextDisabled(true);
  }

  setTarget = (target: string | null) => {
    this.target = target;
  }

  setAnswer = (questionId: string, modalityId: string) => {
    this.answerRegistry.set(questionId, modalityId);
    const length = this.selectedSlide?.quiz?.questions.length;
    if (this.answerRegistry.size === length) {
      this.setNextDisabled(false);
    }
  }

  get answers() {
    return Array.from(this.answerRegistry.values());
  }

  get sildeTypes() {
    return this.listSlideTypes?.map(x => {
      var d: DropdownItemProps = {
        text: x.slideTypeName,
        description: x.slideTypeDesc,
        value: x.id

      }
      return d
    })
  }

  get contentTypes() {
    return this.listContentTypes?.map(x => {
      var d: DropdownItemProps = {
        text: x.typeName,
        description: x.typeDescription,
        value: x.id
      }
      return d
    })
  }

  loadSlideType = async () => {
    this.setLoading(true);
    try {
      const list = await agent.Learning.listSlideType();
      runInAction(() => {
        this.listSlideTypes = list
      });
    } catch (ex) {
      console.log(ex);
    } finally {
      this.setLoading(false);
    }
  }

  loadContentType = async () => {
    this.setLoading(true);
    try {
      const list = await agent.Learning.listContentType();
      runInAction(() => {
        this.listContentTypes = list
      });
    } catch (ex) {
      console.log(ex);
    } finally {
      this.setLoading(false);
    }
  }

  getQuizResults = async () => {
    return await agent.Learning.getQuizResults({
      modalityIds: this.answers,
      moduleId: this.selectedSlide!.moduleId
    });
  }

  userTrainingProgresses: eProgress | null = null;

  selectedTraining: eTraining | null = null;

  listSlideTypes: eSlideType[] | null = null;

  listContentTypes: eSlideContentType[] | null = null;


  clearSelectedTraining() {
    console.log('unload')
    this.selectedTraining = null;
  }

  setSelectedTrainingById(id: string) {
    const training = this.trainingsRegistry.get(id);
    this.selectedTraining = training || null;
  }
  userCertificates: eCertificate[] | null = null;

  selectedModule: eModule | null = null;
  clearSelectedModule() {
    this.selectedModule = null;
  }

  setSelectedModuleById(id: string) {
    this.selectedModule = this.modulesRegistry.get(id) || null;
  }


  selectedSlide: eSlide | null = null;
  clearSelectedSlide() {
    this.selectedSlide = null;
  }

  selectedContent: eSlideContent | null = null;
  clearSelectedContent() {
    this.selectedContent = null;
  }

  setSelectedContentById(id: string) {
    this.selectedContent = this.slidesContentRegistry.get(id) || null;
  }

  constructor() {
    makeAutoObservable(this, {
      setSelectedModuleById: action.bound,
      setSelectedTrainingById: action.bound,
      setSelectedContentById: action.bound,
      moduleLocked: action.bound,
      setAnswer: action.bound,
      clearSelectedTraining: action.bound,

    });
  }

  loading = false;
  loadingInitial = false;

  get trainingProgressPercent() {
    return this.trainingProgress?.percentFinished;
  }

  private get trainingProgress() {
    return this.userTrainingProgresses?.trainingProgress
      .find(x => x.trainingId === this.selectedTraining?.id)
  }

  moduleLocked(id: string) {
    const { userTrainingProgresses, sortedModules, moduleProgress } = this;
    if (!userTrainingProgresses) {
      return true;
    }
    const selectedProgressIx = sortedModules.findIndex(x => x.id === id);
    if (!moduleProgress) {
      return selectedProgressIx !== 0;
    }

    let moduleProgressIx = sortedModules.findIndex(x => x.id === moduleProgress!.moduleId);
    if (moduleProgress.percentFinished === 100) {
      moduleProgressIx++;
    }

    if (selectedProgressIx === 0) return false;

    return selectedProgressIx > moduleProgressIx;
  }

  get moduleProgressPercent() {
    const { selectedModule, sortedModules, userTrainingProgresses, moduleProgress } = this;
    const ixSelected = sortedModules.findIndex(x => x.id === selectedModule?.id);
    const ixProgress = sortedModules.findIndex(x => userTrainingProgresses?.moduleProgress.some(y => y.moduleId === x.id))
    if (ixSelected < ixProgress)
      return 100;

    if (ixSelected === ixProgress)
      return moduleProgress?.percentFinished

    return 0;
  }

  private get moduleProgress() {
    return this.userTrainingProgresses?.moduleProgress
      .find(x => x.trainingId === this.selectedTraining?.id);
  }

  get sortedTrainings() {
    return Array.from(this.trainingsRegistry.values()).sort((a, b) => a.trainingOrder - b.trainingOrder)
  }

  get sortedModules() {
    return Array.from(this.modulesRegistry.values()).sort((a, b) => a.moduleOrder - b.moduleOrder)
  }

  get sortedSlides() {
    return Array.from(this.slidesRegistry.values()).sort((a, b) => a.slideOrder - b.slideOrder)
  }

  get sortedContent() {
    return Array.from(this.slidesContentRegistry.values()).sort((a, b) => a.contentOrder - b.contentOrder)
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }

  loadCurrentSlide = async (slideId: string) => {
    this.setLoading(true)
    try {
      const slide = await agent.Learning.getSlide(slideId);
      runInAction(() => {
        if (slide.quiz) {
          slide.quiz.questions.forEach(x => {
            x.modalities
              .slice()
              .sort(() => Math.random() - Math.random())
              .forEach((m, i) => {
                var mod = x.modalities.find(x => x.id === m.id);
                if (mod) {
                  mod.tempOrder = i + 1;
                }
              })
          })
        }
        this.selectedSlide = slide;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadModuleAllSlides = async (moduleId: string) => {
    this.setLoading(true)
    try {
      this.slidesRegistry.clear();
      const slides = await agent.Learning.listSlides(moduleId);
      runInAction(() => {
        // this.slidesRegistry.clear();
        slides.forEach(x => {
          this.slidesRegistry.set(x.id, x);
        })
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadModuleSlides = async (moduleId: string) => {
    this.setLoading(true)
    try {
      const slides = await agent.Learning.listSlides(moduleId);
      runInAction(() => {
        this.slidesRegistry.clear();
        slides.forEach(x => {
          this.slidesRegistry.set(x.id, x);
        })
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadTrainingModules = async (trainingId: string) => {
    this.setLoading(true)
    try {
      const modules = await agent.Learning.listModules(trainingId);
      runInAction(() => {
        this.modulesRegistry.clear();
        modules.forEach(x => {
          this.modulesRegistry.set(x.id, x);
        })
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  getSingleModule = async (moduleId: string) => {
    this.setLoadingInitial(true)
    try {
      const module = await agent.Learning.getModule(moduleId);
      const slides = module.slides;
      runInAction(() => {
        this.selectedModule = module;
        slides!.forEach(x => {
          this.slidesRegistry.set(x.id, x);
        })
      });
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoadingInitial(false)
    }
  }

  getSingleSlide = async (id: string) => {
    this.setLoadingInitial(true)
    try {
      const slides = await agent.Learning.getSlide(id);
      runInAction(() => {
        // slides.
        this.selectedSlide = slides;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  getUserCertificates = async () => {
    this.setLoadingInitial(true)
    try {
      const certificates = await agent.Learning.getUserCertificates();
      runInAction(() => {
        this.userCertificates = certificates;
      });
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoadingInitial(false)
    }
  }

  loadTrainings = async () => {
    this.setLoading(true)
    this.trainingsRegistry.clear();
    try {
      const trainings = await agent.Learning.listTrainings();
      runInAction(() => {
        trainings.forEach(x => {
          this.trainingsRegistry.set(x.id, x);
        })
      })

    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadTraining = async (id: string) => {
    this.setLoading(true)
    try {
      const training = await agent.Learning.loadTraining(id);
      runInAction(() => {
        this.selectedTraining = training;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadUserTrainingProgresses = async () => {
    this.setLoading(true)
    try {
      const progresses = await agent.Learning.loadUserProgress();
      runInAction(() => {
        this.userTrainingProgresses = progresses;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }


  learningTrainingOnload = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      await this.loadTrainingModules(id);
      await this.loadTraining(id);
      await this.loadUserTrainingProgresses();
      this.clearSelectedModule();
      this.slidesRegistry.clear();
    } catch (e) {
      console.log(e);
    } finally {
      runInAction(() => {
        this.setLoadingInitial(false)
      })
    }
  }

  createTraining = async (training: eTraining) => {
    try {
      const trainings = await agent.Learning.createTrainig(training);
      runInAction(() => {

      })
    } catch (error) {
      throw error
    }
  }


  updateTraining = async (trainingEdit: eTraining) => {
    this.setLoading(true)
    this.trainingsRegistry.clear();
    try {
      const training = await agent.Learning.update(trainingEdit);
      runInAction(() => {
        this.trainingsRegistry.set(training.id, training);
        toast.info('Successfully updated!')
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  deleteTraining = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Learning.delete(id);
      runInAction(() => {
        this.trainingsRegistry.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Training');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  updateModule = async (moduleEdit: eModule) => {
    this.setLoading(true)
    try {
      const module = await agent.Learning.updateModule(moduleEdit);
      runInAction(() => {
        this.modulesRegistry.set(module.id, module);
        toast.info('Successfully updated!')
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  deleteModule = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Learning.deleteModule(id);
      runInAction(() => {
        this.modulesRegistry.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Module');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  deleteSlide = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Learning.deleteSlide(id);
      runInAction(() => {
        this.slidesRegistry.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Slide');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  updateSlide = async (slideEdit: eSlide) => {
    this.setLoading(true)
    try {
      const slide = await agent.Learning.updateSlide(slideEdit);
      runInAction(() => {
        this.slidesRegistry.set(slide.id, slide);
        toast.info('Successfully updated!')
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  createModule = async (training: eModule) => {
    try {
      const trainings = await agent.Learning.createModule(training);
      runInAction(() => {

      })
    } catch (error) {
      throw error
    }
  }

  createSlide = async (slide: eSlide) => {
    try {
      const trainings = await agent.Learning.createSlide(slide);
      runInAction(() => {

      })
    } catch (error) {
      throw error
    }
  }

  createSlideContent = async (slideContent: eSlideContent) => {
    try {
      const createContent = await agent.Learning.createSlideContent(slideContent);
      runInAction(() => {

      })
    } catch (error) {
      throw error
    }
  }

  loadContent = async (id: string) => {
    this.setLoading(true)
    try {
      const content = await agent.Learning.viewSlideContent(id);
      runInAction(() => {
        this.slidesContentRegistry.clear();
        content.forEach(x => {
          this.slidesContentRegistry.set(x.id, x);
        })
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  updateContent = async (contentEdit: eSlideContent) => {
    this.setLoading(true)
    try {
      const content = await agent.Learning.updateContent(contentEdit);
      runInAction(() => {
        this.slidesContentRegistry.set(content.id, content);
        toast.info('Successfully updated!')
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  getSingleContent = async (id: string) => {
    this.setLoadingInitial(true)
    try {
      const content = await agent.Learning.getContent(id);
      runInAction(() => {
        this.selectedContent = content;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoadingInitial(false)
    }
  }


  deleteContent = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Learning.deleteContent(id);
      runInAction(() => {
        this.slidesContentRegistry.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting Content');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }


  // updateSlideContent = async (slideEdit: eSlide) => {
  //   this.setLoading(true)
  //   try {
  //     const slide = await agent.Learning.updateSlideContent(contentEdit);
  //     runInAction(() => {
  //       this.slidesContentRegistry.set(slide.id, slide);
  //       toast.info('Successfully updated!')
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     this.setLoading(false)
  //   }
  // }


  private reorderTrainingItem = async (id: string, increment?: boolean) => {
    this.setLoading(true)
    try {
      const arr = Array.from(this.trainingsRegistry.values())
      const currentItem = this.trainingsRegistry.get(id);
      if (!currentItem) {
        return;
      }
      const oldItemOrder = currentItem.trainingOrder;
      const newItemOrder = increment ? oldItemOrder + 1 : oldItemOrder - 1;
      const swapsOrderWith = arr.filter(x => x.trainingOrder === newItemOrder);
      await swapsOrderWith.forEach(async x => {
        x.trainingOrder = oldItemOrder;
        await agent.Learning.update(x);
        this.trainingsRegistry.set(x.id, x);
      });
      currentItem.trainingOrder = newItemOrder;
      await agent.Learning.update(currentItem);
      this.trainingsRegistry.set(currentItem.id, currentItem);
    } catch (error) {
      console.log(error)
      toast.error('Failed to update order');
    } finally {
      this.setLoading(false)
    }
  }

  trainingOrderDecrement = async (id: string) => {
    return await this.reorderTrainingItem(id, false);
  }
  trainingOrderIncrement = async (id: string) => {
    return await this.reorderTrainingItem(id, true);
  }



  private reorderModuleItem = async (id: string, increment?: boolean) => {
    this.setLoading(true)
    try {
      const arr = Array.from(this.modulesRegistry.values())
      const currentItem = this.modulesRegistry.get(id);
      if (!currentItem) {
        return;
      }
      const oldItemOrder = currentItem.moduleOrder;
      const newItemOrder = increment ? oldItemOrder + 1 : oldItemOrder - 1;
      const swapsOrderWith = arr.filter(x => x.moduleOrder === newItemOrder);
      await swapsOrderWith.forEach(async x => {
        x.moduleOrder = oldItemOrder;
        await agent.Learning.updateModule(x);
        this.modulesRegistry.set(x.id, x);
      });
      currentItem.moduleOrder = newItemOrder;
      await agent.Learning.updateModule(currentItem);
      this.modulesRegistry.set(currentItem.id, currentItem);
    } catch (error) {
      console.log(error)
      toast.error('Failed to update order');
    } finally {
      this.setLoading(false)
    }
  }

  moduleOrderDecrement = async (id: string) => {
    return await this.reorderModuleItem(id, false);
  }
  moduleOrderIncrement = async (id: string) => {
    return await this.reorderModuleItem(id, true);
  }


  private reorderSlideItem = async (id: string, increment?: boolean) => {
    this.setLoading(true)
    try {
      const arr = Array.from(this.slidesRegistry.values())
      const currentItem = this.slidesRegistry.get(id);
      if (!currentItem) {
        return;
      }
      const oldItemOrder = currentItem.slideOrder;
      const newItemOrder = increment ? oldItemOrder + 1 : oldItemOrder - 1;
      const swapsOrderWith = arr.filter(x => x.slideOrder === newItemOrder);
      await swapsOrderWith.forEach(async x => {
        x.slideOrder = oldItemOrder;
        await agent.Learning.updateSlide(x);
        this.slidesRegistry.set(x.id, x);
      });
      currentItem.slideOrder = newItemOrder;
      await agent.Learning.updateSlide(currentItem);
      this.slidesRegistry.set(currentItem.id, currentItem);
    } catch (error) {
      console.log(error)
      toast.error('Failed to update order');
    } finally {
      this.setLoading(false)
    }
  }

  slideOrderDecrement = async (id: string) => {
    return await this.reorderSlideItem(id, false);
  }
  slideOrderIncrement = async (id: string) => {
    return await this.reorderSlideItem(id, true);
  }

  // private reorderContent = async (id: string, increment?: boolean) => {
  //   this.setLoading(true)
  //   try {
  //     const arr = Array.from(this.slidesContentRegistry.values())
  //     const currentItem = this.slidesContentRegistry.get(id);
  //     if (!currentItem) {
  //       return;
  //     }
  //     const oldItemOrder = currentItem.contentOrder;
  //     const newItemOrder = increment ? oldItemOrder + 1 : oldItemOrder -1; 
  //     const swapsOrderWith = arr.filter(x => x.contentOrder === newItemOrder);
  //     await swapsOrderWith.forEach(async x => {
  //       x.contentOrder=oldItemOrder;
  //       await agent.Learning.updateContent(x);
  //       this.slidesContentRegistry.set(x.id, x);
  //     });
  //     currentItem.contentOrder = newItemOrder;
  //     await agent.Learning.updateContent(currentItem);
  //     this.slidesContentRegistry.set(currentItem.id, currentItem);
  //   } catch (error) {
  //     console.log(error)
  //     toast.error('Failed to update order');
  //   } finally {
  //     this.setLoading(false)
  //   }
  // }

  // contentOrderDecrement = async (id:string) => {
  //   return await this.reorderContent(id, false);
  // }
  // contentOrderIncrement = async (id: string) => {
  //   return await this.reorderContent(id, true);
  // }

}



