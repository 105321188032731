import { observer } from 'mobx-react-lite'
import React from 'react'
import { Container } from 'semantic-ui-react'

export default observer(function ELearningStickyFooter() {



    return (
        <>
            <div style={{ position: 'fixed', bottom: '0px', width: '100%', height: '50px', background: 'whitesmoke', }}>
                <Container>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '20%' }}>
                            <img
                                src={"/images/EN-Funded by the EU-PANTONE.jpg"}
                                alt="Ilo logo"
                                style={{ width: '200px', padding: '5px', background: 'whitesmoke' }}
                            />
                        </div>
                        <div style={{ width: '80%', fontSize:'11px', color:'#2185d0' }}>
                            Оваа платформа за е-учење е креирана со финансиска поддршка од Европската Унија,
                            во рамки на проектот Платформа за вработување и социјални прашања II.
                            Содржината е единствена одговорност на Државниот инспекторат за труд
                            и не ги одразува ставовите на Европската Унија и на Меѓународната организација на трудот.
                        </div>

                    </div>

                    {/* {selectedTraining && <div style={{padding:'5px',}}>
                        <TrainingDetailsFooter progress={trainingProgressPercent || 0}
                        training={selectedTraining} />
                    </div>} */}
                </Container>
            </div>
        </>
    )
})