import React, { useEffect } from "react";
import {  FormikHelpers } from "formik";
import {
  Segment,
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { ConstructionWorksInterface } from "../../app/models/Forms/ConstructionWorksInterface";
import agent from "../../app/api/agent";
import { FormPDF } from "./pdf/FormPDF";
import { PdfFormFieldInfo } from "./pdf/FormPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useStore } from "../../app/stores/store";
import moment from "moment-timezone";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConstructionForm from "./commonForms/Reports/ConstructionForm";


const initialValues: ConstructionWorksInterface = {
  cityId: null,
  employerName: "",
  employerAddress: "",
  streetNumber: "",
  employerPlace: "",
  employerMunicipality: "",
  employerPostalCode: "",
  tradeNumber: "",
  date: null,
  place: "",
  taxNumber: "",
  bankAccount: "",
  bankName: "",
  fullname: "",
  embg: "",
  address: "",
  phoneNumber: "",
  email: "",
  workActivity: "",
  startingDate: null,
  safetyAtWork: "",
  name: "",
  employeesNumber: null,
  workEquipment: "",
  submitterFullname: "",
  submitterWorkPosition: "",
  submitterPhoneNumber: "",
  submitterEmail: "",
};

export default observer(function ConstructionWorksForm() {
  const { modalStore, codesStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { citiesDropdown, loadCities } = codesStore;
  const navigate = useNavigate();

  useEffect(() => {
    loadCities();
  }, [loadCities]);

  if (!citiesDropdown) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: ConstructionWorksInterface,
    actions: FormikHelpers<ConstructionWorksInterface>
  ) => {
    try {
      await agent.Forms.construction(values);
    } catch {
      toast.error("Неуспешно испратено известување");
      return;
    }

    const city = citiesDropdown.find((x) => x.value === values.cityId)?.text;

    const infoArray: PdfFormFieldInfo[] = [
      {
        inputMainLabel:
          "1. Назив на работодавачот на подносителот на известувањето:",
        inputValue: values.employerName,
      },
      {
        inputMainLabel:
          "2. Седиште на работодавачот на подносителот на известувањето, во согласност со уписот во Централен регистар:",
      },
      {
        inputLabel: "улица:",
        inputValue: values.employerAddress,
      },
      {
        inputLabel: "број:",
        inputValue: values.streetNumber,
      },
      {
        inputLabel: "место:",
        inputValue: values.employerPlace,
      },
      {
        inputLabel: "општина:",
        inputValue: values.employerMunicipality,
      },
      {
        inputLabel: "поштенски број:",
        inputValue: values.employerPostalCode,
      },
      {
        inputMainLabel: "3. Трговски број:",
        inputValue: values.tradeNumber,
      },
      {
        inputLabel: "датум:",
        inputValue: moment
          .utc(values.date)
          .local()
          .format("D MMMM YYYY")
          .toString(),
      },
      {
        inputLabel: "место:",
        inputValue: values.place,
      },
      {
        inputMainLabel: "4. Даночен број:",
        inputValue: values.taxNumber,
      },
      {
        inputMainLabel: "5. Жиро сметка, број:",
        inputValue: values.bankAccount,
      },
      {
        inputLabel: "Банка-депонент:",
        inputValue: values.bankName,
      },
      {
        inputMainLabel: "6. Податоци за одговорното лице на работодавачот:",
      },
      {
        inputLabel: "Име и презиме:",
        inputValue: values.fullname,
      },
      {
        inputLabel: "ЕМБГ:",
        inputValue: values.embg,
      },
      {
        inputLabel: "Адреса (ул./број):",
        inputValue: values.address,
      },
      {
        inputLabel: "Телефонски број:",
        inputValue: values.phoneNumber,
      },
      {
        inputLabel: "E-mail:",
        inputValue: values.email,
      },
      {
        inputMainLabel:
          "7. Место (адреса) каде ќе се изведуваат градежните работи:",
        inputValue: values.workActivity,
      },
      {
        inputMainLabel: "8. Датум на започнување на градежните работи:",
        inputValue: moment
          .utc(values.startingDate)
          .local()
          .format("D MMMM YYYY")
          .toString(),
      },
      {
        inputMainLabel:
          "9. Дали имате план за безбедност и здравје при работа, согласно Член 6 од Правилниот за минималните барања за безбедност и здравје при работа на привремени и мобилни градилишта?",
        inputValue: values.safetyAtWork.toString(),
      },
      {
        inputMainLabel:
          "10. Координатор за прашања за безбедност и здравје при работа:",
      },
      {
        inputLabel: "Име и презиме:",
        inputValue: values.name,
      },
      {
        inputMainLabel: "11. Број на вработени на градилиштето:",
        inputValue: values.employeesNumber?.toString(),
      },
      {
        inputMainLabel:
          "12. Опрема за работа која ќе се користи на градилиштето:",
        inputValue: values.workEquipment,
      },
      {
        inputMainLabel: "Пополнето од:",
      },
      {
        inputLabel: "Име и презиме:",
        inputValue: values.submitterFullname,
      },
      {
        inputLabel: "Работно место:",
        inputValue: values.submitterWorkPosition,
      },
      {
        inputLabel: "Телефонски број:",
        inputValue: values.submitterPhoneNumber,
      },
      {
        inputLabel: "E-mail адреса:",
        inputValue: values.submitterEmail,
      },
    ];
    openModal(
      <Segment placeholder>
        <Grid>
          <GridColumn>
            <div>
              <PDFDownloadLink
                document={
                  <FormPDF
                    values={infoArray}
                    certificationLabel={
                      "место за заверка на Државниот инспекторат за труд"
                    }
                    institutionLabel={"Државен инспекторат за труд"}
                    regionLabel={"Подрачје:"}
                    regionValue={`Град ${city}`}
                    subtitle={
                      "(Член 23, став 2 од Законот за безбедност и здравје при работа „Службен весник на Република Македонија“ бр.92/07)"
                    }
                    title={"ИЗВЕСТУВАЊЕ ЗА ЗАПОЧНУВАЊЕ НА ГРАДЕЖНИ РАБОТИ"}
                  />
                }
                fileName="ГрадежниРаботи.pdf"
                className="btn-form-submit"
              >
                <p
                  onClick={(e) => e.preventDefault()}
                  className="text-form-modal"
                >
                  Дали сакате да превземете во PDF-формат?
                </p>
                <button
                  className="button-form-modal"
                  onClick={() => {
                    navigate("/");
                    // closeModal();
                  }}
                >
                  Превземи
                </button>
              </PDFDownloadLink>
            </div>
          </GridColumn>
        </Grid>
        <div className="close-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            X
          </button>
        </div>
      </Segment>
    );
  };

  return (
    <ConstructionForm
      citiesDropdown={citiesDropdown}
      handleSubmit={handleSubmit}
    />
  );
});
