import React, { useEffect } from "react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { Segment, Button, ButtonGroup } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ConstructionWorksInterface } from "../../../app/models/Forms/ConstructionWorksInterface";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import agent from "../../../app/api/agent";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import ConstructionForm from "../commonForms/Reports/ConstructionForm";

export default observer(function ConstructionWorkEdit() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { codesStore, formStore } = useStore();
  const { citiesDropdown, loadCities } = codesStore;
  const { selectedConstructionForm, loadSingleConstructionForm } = formStore;

  useEffect(() => {
    if (id) loadSingleConstructionForm(id);
    loadCities();
  }, [loadSingleConstructionForm, loadCities, id]);

  if (!citiesDropdown || !selectedConstructionForm) {
    return <LoadingComponent />;
  }


  const handleSubmit = async (
    values: ConstructionWorksInterface,
    actions: FormikHelpers<ConstructionWorksInterface>
  ) => {
    try {
      await agent.Forms.updateConstruction(values).then(() => {
        toast.success(t("successfully-edited-report!"));
      });
    } catch {
      toast.error(t("failed-to-send-report!"));
      return;
    }
  };

  return (
    <>
      <AdminHeader header={t("edit-construction-at-work")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedConstructionForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedConstructionForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedConstructionForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <ConstructionForm
        handleSubmit={handleSubmit}
        citiesDropdown={citiesDropdown}
        initialValues={selectedConstructionForm}
      />
    </>
  );
});
