import { Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Segment,
  Form,
  Header,
  Label,
  Button,
  DropdownItemProps,
  Icon,
} from "semantic-ui-react";
import SearchInput from "../../../../app/common/Inputs/SearchInput";
import { UnknownSubmitterInterface } from "../../../../app/models/Forms/UnknownSubmitterInterface";
import MyCheckbox from "../../inputs/MyCheckbox";
import MyCheckboxGroup from "../../inputs/MyCheckboxGroup";
import MyMultipleSelect from "../../inputs/MyMultipleSelect";
import MySelectInput from "../../inputs/MySelectInput";
import MyTextArea from "../../inputs/MyTextArea";
import MyTextInput from "../../inputs/MyTextInput";
import UnknownSubmitterSchema from "../../schemas/UnknownSubmitterSchema";
import FormsLogo from "../FormsLogo";
import "./Grievances.css";

interface Props {
  handleSubmit: (
    values: UnknownSubmitterInterface,
    actions: FormikHelpers<UnknownSubmitterInterface>
  ) => Promise<void>;
  nkdDropdown: DropdownItemProps[];
  citiesDropdown: DropdownItemProps[] | undefined;
  vrBzrDropdown: DropdownItemProps[];
  vrRoDropdown: DropdownItemProps[];
}

const initValue: UnknownSubmitterInterface = {
  region: [],
  employerName: "",
  activity: null,
  address: "",
  cityId: null,
  violatedRight: [],
  request: "",
  // date: null,
};

export default function UknowForm({
  handleSubmit,
  nkdDropdown,
  citiesDropdown,
  vrBzrDropdown,
  vrRoDropdown,
}: Props) {
  const navigate = useNavigate();
  return (
    <>
      <style>
        {`
        .background_img {
          background-image: url("/images/background.png");
          background-repeat: no-repeat;
          background-position: bottom;
          background-size: contain;
        }
    `}
      </style>
      <Grid className="form-frame" stackable>
        <Segment className="form-segment">
          <Formik
            initialValues={initValue}
            validationSchema={UnknownSubmitterSchema}
            onSubmit={handleSubmit}
            render={({
              touched,
              errors,
              values,
              setFieldValue,
              submitForm,
            }) => (
              <div className="background_img">
                <Form
                  className="form-margins"
                  autoComplete="off"
                >
                  <header>
                    <FormsLogo />
                  </header>
                  <main>
                    <div className="form-main-title">
                      <Header>
                        <div className="form-title-top">
                          Пријава
                        </div>
                        <div className="form-title-bottom">
                          од анонимен подносител
                        </div>
                      </Header>
                    </div>
                    <div className="form-area">
                      <p
                        className="asterisk">
                        Област на која се однесува пријавата:
                      </p>
                      <MyCheckboxGroup
                        touched={touched.region}
                        error={errors.region}
                      >
                        <Grid className="form-area-checkboxes">
                          <MyCheckbox
                            type="checkbox"
                            name="region"
                            value="безбедност и здравје при работа"
                            label="безбедност и здравје при работа"
                          />
                          <MyCheckbox
                            type="checkbox"
                            name="region"
                            value="работни односи"
                            label="работни односи"
                          />
                        </Grid>
                      </MyCheckboxGroup>
                    </div>
                    <ol className="ol">
                      <li>
                        <MyTextInput
                          label="Работодавач/компанија за кој/а се жалите"
                          name="employerName"
                          type="text"
                          placeholder="Внесете работодавач/компанија за кој/а се жалите"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Дејност-шифрарник</p>
                        <SearchInput
                          name="activity"
                          setFieldValue={setFieldValue}
                          source={nkdDropdown}
                          placeholder="Внесете дејност на работодавачот"
                          defaultValue={values ? values.activity : null}
                        />
                        {errors.activity && touched.activity ? (
                          <Label basic color="red" style={{ marginTop: "3px" }}>
                            {errors.activity}
                          </Label>
                        ) : null}
                      </li>
                      <li>
                        <MyTextInput
                          label="Адреса на субјектот за кој се жалите"
                          name="address"
                          type="text"
                          placeholder="Внесете адреса на субјектот"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Град/Регион</p>
                        <MySelectInput
                          name="cityId"
                          options={citiesDropdown || []}
                          placeholder="Внесете град"
                        />
                      </li>
                      <li>
                        <p className="asterisk">Прекршено право</p>
                        <MyMultipleSelect
                          name="violatedRight"
                          options={
                            values.region.includes("работни односи") &&
                            values.region.includes(
                              "безбедност и здравје при работа"
                            )
                              ? [...vrBzrDropdown, ...vrRoDropdown]
                              : values.region.includes("работни односи")
                              ? vrRoDropdown
                              : values.region.includes(
                                  "безбедност и здравје при работа"
                                )
                              ? vrBzrDropdown
                              : []
                          }
                          placeholder="Одберете прекршено право"
                        />
                      </li>
                      <li>
                        <MyTextArea
                          label="Вашата преставка е"
                          name="request"
                          rows={8}
                          placeholder="Внесете преставка"
                        />
                      </li>
                    </ol>
                  </main>
                  <footer className="form-buttons-position">
                        <Button
                          style={{
                            background: "rgb(161, 7, 5)",
                            color: "white",
                          }}
                          onClick={() => navigate("/")}
                        >
                          <Icon name="arrow left" />
                        </Button>
                        <Button
                          type="submit"
                          style={{
                            background: "rgb(161, 7, 5)",
                            color: "white",
                          }}
                          onClick={submitForm}
                        >
                          Зачувај
                        </Button>
                  </footer>
                </Form>
              </div>
            )}
          />
        </Segment>
      </Grid>
    </>
  );
}
