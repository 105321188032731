import _ from "lodash";
import React from "react";
import {
  Pagination,
  Icon,
  DropdownItemProps,
} from "semantic-ui-react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "../../../../../client/src/features/categoryPage/CategoryPage.css"


interface IProps {
  handlePaginationChange: (pageNumber: string | number | undefined) => void;
  handlePerPageChange: (pageSize: string | undefined) => void;
  numPages: number;
  PerPageOptions?: DropdownItemProps[];
  perPage: number;
  defaultPage?: number;
  activePage?: number;
}

const AdminTablePagination: React.FC<IProps> = ({
  defaultPage = 1,
  activePage,
  handlePaginationChange,
  numPages,
}) => {

  return (
    <>
      <div className="category-pagination">
        <Pagination
          secondary
          activePage={activePage || defaultPage}
          onPageChange={(e, data) => handlePaginationChange(data.activePage)}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          firstItem={null}
          lastItem={null}
          prevItem={{
            content: <MdKeyboardArrowLeft
              size={18}
            />
          }}
          nextItem={{
            content: <MdKeyboardArrowRight
              size={18}
            />
          }}
          totalPages={numPages}
        />
      </div>
    </>
  );
};

export default AdminTablePagination;
