import React, { useEffect, useState } from "react";
import MainHeaderTop from "./HeaderTop/mainHeaderTop";
import MainHeaderBottomMobile from "./HeaderBottom/HeaderBottomMobile/MainHeaderBottomMobile";
import MainHeaderBottomDesktop from "./HeaderBottom/HeaderBottomDesktop/MainHeaderBottomDesktop";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";

interface Props {
  isFixed?: boolean;
}
export default observer(function MainHeader ({ isFixed }: Props) {
  const [isScrolledTop, setIsScrolledTop] = useState(window.scrollY === 0);
  const {navigationStore: {loadPublicNavigation}} = useStore();
  useEffect(() => {
    if (!isFixed) {
      return;
    }

    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsScrolledTop(true);
        return;
      }
      setIsScrolledTop(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed]);

  React.useEffect(() => {
    loadPublicNavigation();
  }, [loadPublicNavigation]);


  const headerClass = !isScrolledTop
    ? "sticky"
    : !isFixed
    ? "normal"
    : "normal seethrough";

  return (
    <header className={headerClass}>
      <MainHeaderTop />
      <MainHeaderBottomDesktop />
      <MainHeaderBottomMobile />
    </header>
  );
})