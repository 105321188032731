import _ from "lodash";
import "i18next";
import { action, makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import { DropdownItemProps } from "semantic-ui-react";
import { v4 } from "uuid";
import agent from "../api/agent";

import { Post,  PostValues } from "../models/CMS/post";
import { FilterParam } from "../models/Pagination/FilterParam";
import { Pagination, PagingParams } from "../models/Pagination/pagination";
import { t } from "i18next";


export default class PostStore {
 
  postRegistry = new Map<string, Post>();
  target: string | null = null;

  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  relatedProductPagination : Pagination | null = null;
  pagingParams = new PagingParams();
  filterRegistry = new Map<string, FilterParam>();
  staticFilterRegistry = new Map<string, FilterParam>();
  uploading = false;

  postStatusOptions : DropdownItemProps[] = [
    {value:"pending", text: t("Pending").toString()},
    {value:"draft", text: t('Draft').toString()},
    {value:"published", text: t('Published').toString()},
    {value:"trash", text: t('Trash').toString()}
  ];

  get sortedPosts() {
    return Array.from(this.postRegistry.values()).sort((a, b) => b.postDate.getTime() - a.postDate.getTime())
  }

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  }

  addFilterParam = (name: string, value: string) => {
    const param = new FilterParam(name, value);
    this.filterRegistry.set(param.filterName, param);
    this.pagingParams = new PagingParams(1, this.pagingParams.pageSize);
    this.loadPosts();
  }

  
  addMultipleFilterParam = (params :{name: string, value: string| undefined}[]) => {
    params.forEach(x => {
      const {name, value} = x;
      if(!value){
        this.filterRegistry.delete(name);
        return 
      }
      const param = new FilterParam(name, value);
      this.filterRegistry.set(param.filterName, param);
      this.pagingParams = new PagingParams(1, this.pagingParams.pageSize);
    });

    if(!params.some(x=> x.value)){
      this.loadPosts();
    }
  }

  addStaticFilterParam = (name: string, value: string) => {
    const param = new FilterParam(name, value);
    this.staticFilterRegistry.set(param.filterName, param);
    if(!this.pagingParams)
     this.pagingParams = new PagingParams(1, 10);
     
    this.loadPosts();
  }

    
  setMultipleStaticFilterParam = (params: {name: string, value: string}[]) => {
    this.staticFilterRegistry.clear();
    params.forEach(p=> {
      const {name, value} = p;
      const param = new FilterParam(name,value);
      this.staticFilterRegistry.set(param.filterName, param);
      // this.pagingParams = new PagingParams(1,this.pagingParams.pageSize);
    })

    this.loadPosts();
  }


  removeFilterParam = (id: string) => {
    this.filterRegistry.delete(id);
    this.loadPosts();
  }

  clearFilterParams = () => {
    this.filterRegistry.clear();
    this.loadPosts();
  }

  clearStaticFilterParams = () => {
    this.staticFilterRegistry.clear();
  }

  get filterParams() {
    return Array.from(this.filterRegistry.values());
  }

  setPagingParams = (pagingParams: PagingParams) => {
    if(pagingParams.pageSize < 0){
      pagingParams.pageSize = this.pagingParams.pageSize;
    }
    this.pagingParams = pagingParams;
  }


  get axiosParams() {
    const params = new URLSearchParams();
    params.append('pageNumber', this.pagingParams.pageNumber.toString());
    params.append('pageSize', this.pagingParams.pageSize.toString());
    const filters = Array.from(this.filterRegistry.values());
    filters.forEach(x => {
      params.append(x.filterName, x.filterValue);
    });
    const staticFilters = Array.from(this.staticFilterRegistry.values());
    staticFilters.forEach(x => {
      params.append(x.filterName, x.filterValue);
    });

    return params;
  }

  selectedPost: Post | null = null;
  setSelectedPostById(id: string) {
    const post = this.postRegistry.get(id);
    this.selectedPost = post || null;
  }

  clearSelectedPost = ()=> {
    this.selectedPost = null;
  }

  constructor() {
    makeAutoObservable(this, {
      setSelectedPostById: action.bound,
    });

    reaction(() => this.pagingParams,
    params => {
      console.log('paging change')
      this.loadPosts();
    })
  }



  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }
  setTarget = (target: string | null) => {
    this.target = target;
  }



  loadSinglePost = async (postId: string) => {
    this.setLoading(true)
    try {
      const post = await agent.Posts.details(postId);
      runInAction(() => {
        this.selectedPost = post;
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  handlePost = (x: Post) => {
    x.postDate = new Date(x.postDate);
    x.postModified = new Date(x.postModified);
    return x;
  }

  loadPosts = async () => {
    this.setLoading(true);
    try {
      const result = await agent.Posts.list(this.axiosParams);
      runInAction(() => {
        this.postRegistry.clear();
        result.data.forEach(x => {
          this.postRegistry.set(x.id, this.handlePost(x))
        });
       this.setPagination(result.pagination);
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  loadCategoryPosts = async () => {
    this.setLoading(true)
    try {
      // const categoryPosts = await agent.Posts.listCategory();
      runInAction(() => { })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoading(false)
    }
  }

  deletePost = async (id: string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Posts.delete(id);
      runInAction(() => {
        this.postRegistry.delete(id);
      })
    } catch (e) {
      console.log(e);
      toast.error('Error deleting post');
    } finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  createPost = async (postValues: PostValues) => {
    this.setLoading(true)
    try {
      postValues.id = v4();
      const post = await agent.Posts.create(postValues);
      runInAction(() => { 
        this.postRegistry.set(post.id,this.handlePost(post));
      })
    } catch (error) {
      console.log(error)
      throw error;
    } finally {
      this.setLoading(false)
    }
  }

  updatePost = async (postValues: PostValues) => {
    this.setLoading(true)
    try {
      const post = await agent.Posts.update(postValues);
      runInAction(() => { 
        this.postRegistry.set(post.id,this.handlePost(post));
      })
    } catch (error) {
      console.log(error)
      throw error;
    } finally {
      this.setLoading(false)
    }
  }

  changeStatus = async (id: string, status: string) => {
    this.setLoading(true);
    try {
      const post = _.cloneDeep(this.postRegistry.get(id));
      if(!post){
        return;
      }
      await agent.Posts.statusChange({id, status});
      post.postStatus = status;
      runInAction(() => {
        this.postRegistry.set(post.id,this.handlePost(post));
      })
      this.loadPosts();
    } catch (error) {
      console.log(error)
      throw error;
    }
  }
}