import { Segment } from "semantic-ui-react";
import DocumentDropzone from "../../../app/common/documentUpload/documentDropzome";
import { useStore } from "../../../app/stores/store";

export default function AddNewDocument() {
  const {mediaStore: {uploadDocumentMedia}} = useStore();
  
  return <Segment><DocumentDropzone 
    uploadFile={uploadDocumentMedia}
  /></Segment>
}