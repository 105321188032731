import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { LoginFormValues, RegisterFormValues, User, UserFormValues } from "../models/user";
import { store } from "./store";

export default class UserStore {
  userRegistry = new Map<string, User>();
  user: User | null = null;
  selectedUser: User | null = null;
  target: string | null = null;

  setTarget = (target: string | null) => {
    this.target = target;
  }

  constructor() {
    makeAutoObservable(this);
  }

  get sortedUsers() {
    return Array.from(this.userRegistry.values());
  }

  get isLoggedIn() {
    return !!this.user;
  }

  get currentLoggedInUser() {
    return this.user
 }

  loading = false;
  loadingInitial = false;

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) => {
    this.loading = state;
  }

  login = async (creds: LoginFormValues) => {
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      runInAction(() => {
        this.user = user;
      })
      return user.role;
    } catch (error) {
      throw error
    }
  }

  updateUser = async (userEdit: UserFormValues) => {
    try {
      const user = await agent.Users.update(userEdit);
      runInAction(() => {
        this.user = user;
      })
    } catch (error) {
      console.log(error)
    }
  }


  
  changePassword = async (userId: string, password: string) => {
    try {
      const user = await agent.Users.changePassword(userId,password);
    } catch (error) {
      console.log(error)
      return false
    }
    return true;
  }




  logout = () => {
    store.commonStore.setToken(null);
    window.localStorage.removeItem('jwt');
    this.user = null;
  }

  getCurrentUser = async () => {
    try {
      const user = await agent.Account.current()
      runInAction(() => this.user = user);
    } catch (error) {
      console.error(error);
    }
  }

  deleteUser = async (id : string) => {
    this.setLoading(true);
    this.setTarget(id);
    try {
      await agent.Users.delete(id);
      runInAction(() => {
        this.userRegistry.delete(id);
      })
    }catch( e ) {
      console.log(e);
      toast.error('Error deleting user');
    }finally {
      this.setLoading(false);
      this.setTarget(null);
    }
  }

  loadUsers = async () => {
    this.setLoadingInitial(true)
    try {
      const users = await agent.Users.list();
      runInAction(() => {
        users.forEach(x => {
          this.userRegistry.set(x.id, x);
        })
      })
    } catch (error) {
      console.log(error)
    }finally{
      this.setLoadingInitial(false)
    }
  }

  loadUser = async (id: string) => {
    this.setLoadingInitial(true)
    try {
      const user = await agent.Users.details(id);
      runInAction(() => {
        this.selectedUser = user;
      })
    } catch (error) {
      console.log(error)
    }finally{
      this.setLoadingInitial(false)
    }
  }

  createUser = async (creds: UserFormValues) => {
    try {
      const user = await agent.Users.create(creds);
      runInAction(() => {
        this.user = user;
      })
    } catch (error) {
      throw error
    }
  }



}