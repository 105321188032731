import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Icon, Message, Table } from "semantic-ui-react";
import { useStore } from "../../../../stores/store";
import AdminHeader from "../../AdminHeader";
import InfoCopyModal from "../../../../common/modals/InfoCopyModal";
import { GenerateShortcode } from "../ShortcodeFunctions";
import { ContentPartTypes } from "../../../../models/Plugins/pluginTypes";

export default observer(function ContactLocationList() {
  const { t } = useTranslation();
  const { contactInfoStore, modalStore: {openModal, closeModal} } = useStore();
  const { deleteLocation, contactLocations, target, loadLocations } =
    contactInfoStore;


  React.useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  return (
    <>
      <AdminHeader header={t("contact-list-locations")} icon="list alternate outline" />
      <>
        {contactLocations.length === 0 && <Message
          content={t("no-contact-info-locatiions")}
          info />}
        {contactLocations.length > 0 && <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Title")}</Table.HeaderCell>
              <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {contactLocations.map((x) => {
              return (
                <Table.Row key={x.id}>
                  <Table.Cell>{x.name || ""}</Table.Cell>
                  <Table.Cell width={2} textAlign="center">
                    <ButtonGroup >
                      <Button
                        icon="eye"
                        inverted
                        primary
                        onClick={()=> {
                          openModal(<InfoCopyModal 
                            closeModal={closeModal}
                            value={GenerateShortcode(ContentPartTypes.CONTACT_INFO,x.id)} 
                          />)
                        }}
                      />
                      <Button
                        as={Link}
                        to={`/admin/plugins/contacts/edit/${x.id}`}
                        icon="edit"
                        inverted
                        color="green"
                      />
                      <Button
                        name={x.id}
                        loading={target === x.id}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteLocation(x.id);
                        }}
                        icon="close"
                        inverted
                        color="red"
                      />
                    </ButtonGroup>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>}

        <div style={{ textAlign: "right" }}>
          <Link
            to={`/admin/plugins/contacts/create/`}
            className="ui button teal"
          >
            <Icon name="plus" />
            {t("add-new-contact-info-location")}
          </Link>
        </div>
      </>
    </>
  );
});
