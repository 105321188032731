import moment from "moment";
import { object } from "yup";
import { EnsureDate } from "../../common/types/TypeFunctions";
import { Nkd } from "../CodeLists/Nkd";
import { City } from "../Regions/City";
import { formTypesToString, FormValue } from "./SharedFunctions";

export interface OvertimeWorkInterface {
  id?:string;
  dateSubmitted?: Date | null;
  idHeader?: string;
  cityId: number | null; //ro //connected with city ID
  city?: City | null;
  employerName: string;
  employerAddress: string;
  employerMunicipality: string,
  employerPostalCode: string,
  employerOccupation: number | null; //connected with NKD ID
  nkdCodes?: Nkd | null;
  workplaceLocation: string;
  startTime: Date | null;
  endTime: Date | null;
  organizationalUnit: string;
  workPlaces: JobPosition[]
  submitterFullname: string,
  submitterWorkPosition: string,
  submitterPhoneNumber: string,
  submitterEmail: string,
  // date: Date | null;
  userName?:string
  userId?:string
}

export class OvertimeWork implements OvertimeWorkInterface{
  id?: string | undefined;
  userName?: string | undefined;
  userId?: string | undefined;
  dateSubmitted?: Date | null | undefined;
  idHeader?: string | undefined;
  cityId: number | null = null;
  city: City | null = null;
  employerName: string = "";
  employerAddress: string = "";
  employerMunicipality: string = "";
  employerPostalCode: string = "";
  employerOccupation: number | null = null;
  nkdCodes: Nkd | null = null;
  workplaceLocation: string = "";
  startTime: Date | null = null;
  endTime: Date | null = null;
  organizationalUnit: string = "";
  workPlaces: JobPosition[] = [];
  submitterFullname: string = "";
  submitterWorkPosition: string = "";
  submitterPhoneNumber: string = "";
  submitterEmail: string = "";

  constructor(init: OvertimeWorkInterface){
    Object.assign(this,init);
    this.dateSubmitted = EnsureDate(this.dateSubmitted);
    this.startTime = EnsureDate(this.startTime);
    this.endTime = EnsureDate(this.endTime);
  }

  filteredObjectKeys() {
    const keys = Object.keys(this);
    const filterOut = ['id', 'cityId', 'idHeader', 'userId', "employerOccupation"];
    return keys.filter(x => !filterOut.some(y => y === x));
  }

  toStringArray() {
    return this.filteredObjectKeys().map((key) => {
      let value: FormValue = this[key as keyof OvertimeWorkInterface];
      [key, value] = formTypesToString(key, value);

      return {
        key,
        value
      }
    });
  }

}



export interface JobPosition
{
  jobPosition: string;
  employeesNumber: number | null;
}

export interface OvertimeWorktHeader {
  id:string;
  dateSubmitted: Date | null;
  detailsHistory: OvertimeWorkInterface[];
  latestVersion: OvertimeWorkInterface;
  status: string;
  statusId:string;
 
}