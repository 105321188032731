import _ from "lodash";
import "i18next";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Post } from "../models/CMS/post";
import { store } from "./store";
import { HtmlContentPart } from "../models/Plugins/pluginTypes";

export default class SinglePostStore {
  loading = false;
  loadingInitial = false;
  selectedPost: Post | null = null;
  contentRegistry = new Map<number, HtmlContentPart>();

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  }

  setLoading = (state: boolean) => {
    this.loading = state;
  }

  handlePost = (x: Post) => {
    x.postDate = new Date(x.postDate);
    x.postModified = new Date(x.postModified);
    return x;
  }

  get title() {
    return this.translation?.postTitle
  }

  get featuredImagePath() {
    return this.selectedPost?.featuredImagePath;
  }

  private get translation() {
    const selecetedLanguage = store.commonStore.language;
    return this.selectedPost?.postTranslations.find(x => x.languageId === selecetedLanguage)
  }

  get content() {
    return Array.from(this.contentRegistry.values())
      .filter(x => x.handled)
      .sort((a, b) => a.order - b.order)
  }

  private async loadContent() {
    this.contentRegistry.clear();
    function getContentArray(postContent: string) {
      const regex = /\[(SC:.*?)\]/gm;
      return postContent.split(regex)
        .map((text, index) => new HtmlContentPart(text, index));
    }

    if (this.translation) {
      const { postContent } = this.translation;
      const contentArray = getContentArray(postContent);
      contentArray.forEach(x => {
        if (x.handled) {
          return this.contentRegistry.set(x.order, x);
        }
        if (!x.paramsObject) {
          console.error({ msg: "No Params object found", x });
          return;
        }
        agent.Plugin.getShortcode(x.paramsObject).then(value => {
          x.info = JSON.parse(value.jsonInfo);
          x.handled= true;
          runInAction(() => {
            this.contentRegistry.set(x.order, x);
          })
        });
      })
    }
  }

  loadSinglePostBySlug = async (postType: string, slug: string) => {
    this.setLoadingInitial(true)
    try {
      const post = await agent.Posts.detailsBySlug(postType, slug);
      runInAction(() => {
        this.selectedPost = post;
        this.loadContent();
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.setLoadingInitial(false)
    }
  }

}