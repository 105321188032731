import { Formik, FieldArray, Field } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  Segment,
  Image,
  Form,
  Grid,
  GridColumn,
  ButtonGroup,
  Button,
  Icon,
  Header,
  Tab,
  SemanticICONS,
  Label,
} from "semantic-ui-react";
import PhotoSelectWidget from "../../../../../app/common/imageUpload/photoSelectWidget";
import ColorPicker from "../../../../../app/common/Inputs/ColorPicker";
import { SliderSlide } from "../../../../../app/models/CMS/Homepage/sliderSlide";
import MySelectInput from "../../../../forms/inputs/MySelectInput";
import MyTextArea from "../../../../forms/inputs/MyTextArea";
import MyTextInput from "../../../../forms/inputs/MyTextInput";
import IconSearchInput from "../../../../../app/common/Inputs/IconSearchInput";
import { observer } from "mobx-react-lite";
import MyCheckboxButton from "../../../../forms/inputs/MyCheckboxButton";
import { CMSMedia } from "../../../../../app/models/Media/media";

interface Props {
  initialValues?: SliderSlide;
  onSubmit: (data: SliderSlide) => void;
  mediaList : CMSMedia[];
}

export default observer(function SliderForm({
  initialValues,
  onSubmit,
  mediaList
}: Props) {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [selectedImagePath, setSelectedImagePath] = useState<string | undefined>()

  useEffect(()=> {
    const initialImage = mediaList.find(x=> x.id === initialValues?.imageId)
    const initialImagePath = initialImage ? `/media/images/${initialImage.path}` : undefined;
    setSelectedImagePath(initialImagePath)
  },[mediaList, setSelectedImagePath, initialValues])

  // iconSetting
  const [selectedIcon, setSelectedIcon] = useState<SemanticICONS | any>("");


  const initValues = initialValues || {
    buttonIcon: "",
    buttonIconPosition: "",
    buttonLink: "",
    headerColor: "",
    showButton: true,
    showHeader: true,
    showSubtitle: true,
    headerFontSize: 0,
    id: "",
    imageId: "",
    itemOrder: 0,
    photoId: "",
    subHeaderColor: "",
    subHeaderFontSize: 0,
    translations: [
      {
        id: v4(),
        buttonContent: "",
        headerContent: "",
        subHeaderContent: "",
        languageId: "EN",
      },
      {
        id: v4(),
        buttonContent: "",
        headerContent: "",
        subHeaderContent: "",
        languageId: "MK",
      },
      {
        id: v4(),
        buttonContent: "",
        headerContent: "",
        subHeaderContent: "",
        languageId: "SQ",
      },
    ]
  };

  const iconPositionOpts = [
    { key: "L", value: "L", text: "Left" },
    { key: "R", value: "R", text: "Right" },
  ];

  return (
    <>
      <style>
        {`
      .ui.segment.image-segment,
      .ui.segment.image-segment:hover div {
        display: flex !important;
        min-height:343px;
      }
    `}
      </style>
      <Formik
        initialValues={initialValues || initValues}
        enableReinitialize={!!initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ setFieldValue, submitForm, values }) => (
          <Segment>
            <Form>
              <Grid>
                <GridColumn width={6}>
                  <Segment attached="top">
                    <Header as={"h3"} attached="top">
                      {t("opsti-informacii")}
                    </Header>
                    <Segment attached="bottom">
                      <MyTextInput
                        label={t("item-order")}
                        name={"itemOrder"}
                        type="number"
                      />
                      <ButtonGroup fluid>
                        <MyCheckboxButton
                          active={values.showHeader}
                          content={t('show-header')}
                          onClick={() => setFieldValue('showHeader', !values.showHeader)}
                        />
                        <MyCheckboxButton
                          active={values.showSubtitle}
                          content={t('show-subtitle')}
                          onClick={() => setFieldValue('showSubtitle', !values.showSubtitle)}
                        />
                        <MyCheckboxButton
                          active={values.showButton}
                          content={t('show-button')}
                          onClick={() => setFieldValue('showButton', !values.showButton)}
                        />
                      </ButtonGroup>

                    </Segment>
                  </Segment>

                  <Segment attached="top">
                   {!!values.showHeader && <>
                    <Header as={"h3"} attached="top">
                      {t("header")}
                    </Header>
                    <Segment attached="bottom">
                      <ColorPicker label={t("color")} name={"headerColor"} />
                      <MyTextInput
                        label={t("font-size")}
                        name={"headerFontSize"}
                      />
                      <FieldArray name="translations">
                        {() => {
                          const translations = values.translations || [];
                          const panes = translations
                            .sort((a, b) => {
                              if (a.languageId === "MK") return -1;
                              if (b.languageId === "MK") return 1;
                              if (a.languageId === "EN") return 1;
                              if (b.languageId === "EN") return -1;

                              return 0;
                            })
                            .map((x, index) => {
                              return {
                                menuItem: x.languageId,
                                render: () => (
                                  <Tab.Pane key={x.languageId}>
                                    <MyTextArea
                                      name={`translations.${index}.headerContent`}
                                      label={t("content")}
                                    />

                                  </Tab.Pane>
                                ),
                              };
                            });
                          return <Tab panes={panes} />;
                        }}
                      </FieldArray>
                    </Segment>
                    </>}


                  {!!values.showSubtitle && <>
                    <Header as={"h3"} attached="top">
                      {t("subheader")}
                    </Header>
                    <Segment attached="bottom">
                      <ColorPicker label={t("color")} name={"subHeaderColor"} />
                      <MyTextInput
                        label={t("font-size")}
                        name={"subHeaderFontSize"}
                      />
                      <FieldArray name="translations">
                        {() => {
                          const translations = values.translations || [];
                          const panes = translations
                            .sort((a, b) => {
                              if (a.languageId === "MK") return -1;
                              if (b.languageId === "MK") return 1;
                              if (a.languageId === "EN") return 1;
                              if (b.languageId === "EN") return -1;

                              return 0;
                            })
                            .map((x, index) => {
                              return {
                                menuItem: x.languageId,
                                render: () => (
                                  <Tab.Pane key={x.languageId}>
                                    <MyTextArea
                                      name={`translations.${index}.subHeaderContent`}
                                      label={t("content")}
                                    />

                                  </Tab.Pane>
                                ),
                              };
                            });
                          return <Tab panes={panes} />;
                        }}
                      </FieldArray>
                    </Segment>
                    </>}

                    {!!values.showButton && <>
                    <Header as={"h3"} attached="top">
                      {t("button")}
                    </Header>
                    <Segment attached="bottom">
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "60%" }}>
                          <Label style={{ marginBottom: "5px" }}>
                            {t("icon")}
                          </Label>
                          <IconSearchInput
                            setFieldValue={setFieldValue}
                            name="buttonIcon"
                            handleResultSelect={(icon) => {
                              setSelectedIcon(icon);
                            }}

                          />
                        </div>
                        <div style={{ width: "40%" }}>
                          <Label style={{ marginBottom: "5px" }}>{t("icon-position")}</Label>
                          <MySelectInput
                            // label={t("icon-position")}
                            name={"buttonIconPosition"}
                            options={iconPositionOpts}
                          />
                        </div>
                      </div>
                      <MyTextInput label={t("link-path")} name={"buttonLink"} />
                      <FieldArray name="translations">
                        {() => {
                          const translations = values.translations || [];
                          const panes = translations
                            .sort((a, b) => {
                              if (a.languageId === "MK") return -1;
                              if (b.languageId === "MK") return 1;
                              if (a.languageId === "EN") return 1;
                              if (b.languageId === "EN") return -1;
                              return 0;
                            })
                            .map((x, index) => {
                              return {
                                menuItem: x.languageId,
                                render: () => (
                                  <Tab.Pane key={x.languageId}>
                                    <MyTextInput
                                      name={`translations.${index}.buttonContent`}
                                      label={t("content")}
                                    />
                                  </Tab.Pane>
                                ),
                              };
                            });
                          return <Tab panes={panes} />;
                        }}
                      </FieldArray>

                    </Segment>
                    </>}

                  </Segment>
                  <Segment attached="bottom">
                    <ButtonGroup fluid>
                      <Button
                        color="red"
                        onClick={(ev) => {
                          ev.preventDefault();
                          navigate("/admin/homepage/slider/list");
                        }}
                      >
                        <Icon name="arrow left" />
                      </Button>
                      <Button
                        color="green"
                        type="submit"
                        content={t("submit")}
                        onClick={() => submitForm()}
                      />
                    </ButtonGroup>
                  </Segment>
                </GridColumn>
                <GridColumn width={10}>
                  <Segment textAlign="right">
                    <Segment className={`${!editMode && "hidden"}`}>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          setEditMode(false);
                          setFieldValue("imageId", initValues.imageId)
                        }}
                        icon="close"
                        color="red"
                        style={{ marginBottom: "15px" }}
                      />
                      <PhotoSelectWidget
                        extraContent={(id, path) => (
                          <Button
                            disabled={id === values.imageId}
                            fluid
                            content={t("select")}
                            color="green"
                            onClick={() =>{
                              setFieldValue("imageId",id)
                              setSelectedImagePath(path);
                              setEditMode(false);
                            }
                          
                          }
                          />
                        )}
                      />
                    </Segment>
                  </Segment>
                  {!editMode && (
                    <Segment
                      className="image-segment"
                      onClick={() => {
                        setEditMode(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        src={selectedImagePath ||  `/images/no-image.jpg`}
                        style={{ width: "300px", margin: "auto" }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          background: "#babababa",
                          opacity: "0.8",
                          height: "100%",
                          display: "none",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Icon name="edit" size="huge" color="green" />
                      </div>
                    </Segment>
                  )}
                </GridColumn>
              </Grid>
            </Form>
          </Segment>
        )}
      </Formik>
    </>
  );
})
