import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./AdminSidebar.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { RiSettings4Line, RiLogoutCircleRLine } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../../stores/store";
import { BsFillPinAngleFill } from "react-icons/bs";
import {
  MdOutlinePages,
  MdOutlinePermMedia,
  MdOutlineWork,
} from "react-icons/md";
import {
  AiOutlineDashboard,
  AiOutlineMail,
  AiOutlineTable,
  AiOutlineUserSwitch,
} from "react-icons/ai";
import { BiBookOpen } from "react-icons/bi";
import { TiPuzzleOutline } from "react-icons/ti";
import { observer } from "mobx-react-lite";
import AuthorizedSubMenu from "./AuthorizedSubMenu";

const AdminSideBar = () => {
  const { t } = useTranslation();
  const {
    userStore: { logout, user },
  } = useStore();
  const navigate = useNavigate();
  const [menuCollapse, setMenuCollapse] = useState(false);

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    const menuIconClick = () => {
      menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };
  };
  return (
    <>
      <div className="sidebar-header">
        <ProSidebar collapsed={menuCollapse} style={{ height: "100vh" }}>
          <SidebarHeader style={{ height: "70px" }} onClick={menuIconClick}>
            <div className="sidebar-logo">
              <img
                src={"/images/LOGO_white.png"}
                alt="Државен Инспекторат за труд"
              />
              <div className="sidebar-vertical"></div>
              <div>
                <Trans
                  i18nKey="state-labor-inspectorate"
                  components={{ newLine: <br /> }}
                />
              </div>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="circle">
              <MenuItem icon={<AiOutlineDashboard size={18} />}>
                {t("dashboard")}
                <Link to="/admin" />
              </MenuItem>

              <AuthorizedSubMenu
                name="Media"
                icon={<MdOutlinePermMedia size={18} />}
                title={t("media")}
              >
                <MenuItem>
                  {t("list-media")}
                  <Link to="/admin/media/list" />
                </MenuItem>
                <MenuItem>
                  {t("add-new-photo")}
                  <Link to="/admin/media/addPhoto" />
                </MenuItem>
                <MenuItem>
                  {t("add-new-document")}
                  <Link to="/admin/media/addDocument" />
                </MenuItem>
              </AuthorizedSubMenu>

              <AuthorizedSubMenu
                name="Users"
                icon={<AiOutlineUserSwitch size={18} />}
                title={t("users")}
              >
                <MenuItem>
                  {t("list-users")}
                  <Link to="/admin/users/list" />
                </MenuItem>
                <MenuItem>
                  {t("create-user")}
                  <Link to="/admin/users/create" />
                </MenuItem>
              </AuthorizedSubMenu>
              <AuthorizedSubMenu
                name="Posts"
                icon={<BsFillPinAngleFill size={18} />}
                title={t("posts")}
              >
                <MenuItem>
                  {t("list-posts")}
                  <Link to="/admin/posts/list" />
                </MenuItem>
                <MenuItem>
                  {t("create-post")}
                  <Link to="/admin/posts/create" />
                </MenuItem>
                <MenuItem>
                  {t("categories")}
                  <Link to="/admin/posts/category/list" />
                </MenuItem>
                <MenuItem>
                  {t("create-category")}
                  <Link to="/admin/posts/category/create" />
                </MenuItem>
              </AuthorizedSubMenu>

              <AuthorizedSubMenu
                name="Pages"
                icon={<MdOutlinePages size={18} />}
                title={t("pages")}
              >
                <MenuItem>
                  {t("list-pages")}
                  <Link to="/admin/pages/list" />
                </MenuItem>
                <MenuItem>
                  {t("create-page")}
                  <Link to="/admin/pages/create" />
                </MenuItem>
                <MenuItem>
                  {t("categories")}
                  <Link to="/admin/pages/category/list" />
                </MenuItem>
                <MenuItem>
                  {t("create-category")}
                  <Link to="/admin/pages/category/create" />
                </MenuItem>
              </AuthorizedSubMenu>

              <AuthorizedSubMenu
                name="Announcements"
                icon={<MdOutlinePages size={18} />}
                title={t("announcements")}
              >
                <MenuItem>
                  {t("list-announcements")}
                  <Link to="/admin/announcements/list" />
                </MenuItem>
                <MenuItem>
                  {t("create-announcement")}
                  <Link to="/admin/announcements/create" />
                </MenuItem>
                <MenuItem>
                  {t("categories")}
                  <Link to="/admin/announcements/category/list" />
                </MenuItem>
                <MenuItem>
                  {t("create-category")}
                  <Link to="/admin/announcements/category/create" />
                </MenuItem>
              </AuthorizedSubMenu>

              <AuthorizedSubMenu
                name="Homepage Settings"
                icon={<RiSettings4Line size={18} />}
                title={t("homepage-settings")}
                // icon={<Icon className="setting" style={{ marginBottom: "18px" }} />}
              >
                {/* <MenuItem>
                  {t("isotope-grid")}
                  <Link to="/admin/homepage/isotope/list" />
                </MenuItem> */}
                <MenuItem>
                  {t("navigation")}
                  <Link to="/admin/homepage/nav/list" />
                </MenuItem>
                <MenuItem>
                  {t("slider")}
                  <Link to="/admin/homepage/slider/list" />
                </MenuItem>
              </AuthorizedSubMenu>

              {/* Plagins */}
              <AuthorizedSubMenu
                name="Plugins"
                icon={<TiPuzzleOutline size={18} />}
                title={t("plugins")}
              >
                <MenuItem>
                  {t("accordion")}
                  <Link to="/admin/plugins/accordion/list" />
                </MenuItem>
                <MenuItem>
                  {t("director-section")}
                  <Link to="/admin/plugins/director" />
                </MenuItem>
                <MenuItem>
                  {t("contacts-section")}
                  <Link to="/admin/plugins/contacts/list" />
                </MenuItem>
              </AuthorizedSubMenu>
              {/* Plagins  */}

              <AuthorizedSubMenu
                name="Reports"
                icon={<TbReportAnalytics size={18} />}
                title={t("Reports")}
              >
                <MenuItem>
                  {t("workplace-accident")}
                  <Link to="/admin/reports/accident" />
                </MenuItem>
                <MenuItem>
                  {t("construction-works")}
                  <Link to="/admin/reports/construction" />
                </MenuItem>
                <MenuItem>
                  {t("night-shift-work")}
                  <Link to="/admin/reports/nightshift" />
                </MenuItem>
                <MenuItem>
                  {t("overtime-work")}
                  <Link to="/admin/reports/overtimeworks" />
                </MenuItem>
                <MenuItem>
                  {t("starting-an-activity")}
                  <Link to="/admin/reports/startingactivity" />
                </MenuItem>
                <MenuItem>
                  {t("work-on-holiday")}
                  <Link to="/admin/reports/workonholidays" />
                </MenuItem>
                <MenuItem>
                  {t("work-on-sunday")}
                  <Link to="/admin/reports/workonsundays" />
                </MenuItem>
                <MenuItem>
                  {t("grievances")}
                  <Link to="/admin/reports/grievances" />
                </MenuItem>
              </AuthorizedSubMenu>

              <AuthorizedSubMenu
                name="Regional Email Settings"
                icon={<AiOutlineMail size={18} />}
                title={t("regional-email-settings")}
              >
                <MenuItem>
                  {t("bzr")}
                  <Link to="/admin/email/bzr" />
                </MenuItem>
                <MenuItem>
                  {t("ro")}
                  <Link to="/admin/email/ro" />
                </MenuItem>
              </AuthorizedSubMenu>

              <AuthorizedSubMenu
                name="E-learning"
                icon={<BiBookOpen size={18} />}
                title={t("e-learning")}
              >
                <MenuItem>
                  {t("learning-dashboard")}
                  <Link to="/e-learning/dashboard" />
                </MenuItem>
                <MenuItem>
                  {t("list-training")}
                  <Link to="/admin/e-learning/listTraning" />
                </MenuItem>
                <MenuItem>
                  {t("create-training")}
                  <Link to="/admin/e-learning/createTraning" />
                </MenuItem>
                <MenuItem>
                  {t("view-training-progress")}
                  <Link to="/admin/e-learning/completedReport" />
                </MenuItem>
              </AuthorizedSubMenu>

              <AuthorizedSubMenu
                name="Operator"
                icon={<MdOutlineWork size={18} />}
                title={t("operator")}
              >
                <MenuItem icon={<AiOutlineTable size={18} />}>
                  {t("forms")}
                  <Link to="/admin/operator/list" />
                </MenuItem>
              </AuthorizedSubMenu>
            </Menu>
          </SidebarContent>
          <SidebarFooter style={{ height: "75px" }}>
            <Menu iconShape="circle">
              <MenuItem
                icon={<RiLogoutCircleRLine size={18} />}
                onClick={() => {
                  logout();
                  navigate("/login");
                }}
              >
                {t("logout")}
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default observer(AdminSideBar);
