import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Icon, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { eSlide } from "../../../app/models/ELearning/eSlide";
import MyTextInput from "../../forms/inputs/MyTextInput";
import MySelectInput from "../../forms/inputs/MySelectInput";

export default observer(function CreateSlide() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, traningId } = useParams();

  const {
    learningStore: {
      createSlide,
      loadTrainingModules,
      sildeTypes,
      loadSlideType,
    },
  } = useStore();

  React.useEffect(() => {
    if (id) {
      loadTrainingModules(id);
      loadSlideType();
    }
  }, [loadTrainingModules, loadSlideType, id]);

  const initialValues: eSlide = {
    id: "",
    slideName: "",
    slideDescription: "",
    slideOrder: 0,
    backgroundImage: "",
    moduleId: id!,
    moduleOrder: 0,
    nextSlideId: "",
    prevSlideId: "",
    slideContents: [],
    slideTypeId: "",
    slideType: {
      id: "",
      slideTypeDesc: "",
      slideTypeName: "",
    },
  };

  return (
    <>
      <AdminHeader
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
        header={t("create-slide")}
        subheader={t("create-slide")}
      />
      <Segment>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          //   onSubmit={() => {}}
          onSubmit={(values) => {
            values.id = uuid();
            createSlide(values).then(() =>
              navigate(`/admin/e-learning/listSlides/${id}/${traningId}`)
            );
          }}
        >
          {({ errors, touched, values, submitForm }) => (
            <Form autoComplete="off">
              <Grid>
                <Grid.Column width={10}>
                  <MyTextInput name="slideName" label={t("slide-name")} />
                  <MyTextInput
                    name="slideDescription"
                    label={t("slide-description")}
                  />
                  {/* <MyTextInput name="moduleId" label={t("slide-module")} /> */}
                </Grid.Column>
                <Grid.Column width={6}>
                  <MyTextInput name="slideOrder" label={t("slide-order")} />
                  <div style={{ paddingTop: "20px" }}>
                    <MySelectInput
                      name="slideTypeId"
                      label={t("slide-type")}
                      options={sildeTypes!}
                    />
                  </div>
                </Grid.Column>
              </Grid>
              <Segment>
                <Button
                  color="red"
                  onClick={() => navigate(`/admin/e-learning/listSlides/${id}/${traningId}`)}
                >
                  <Icon name="arrow left" />
                </Button>
                <Button color="teal" type="submit">
                  {t("create")}
                </Button>
              </Segment>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
