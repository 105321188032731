import { Icon } from "semantic-ui-react"
interface Props {
  onClick: ()=> void;
  isRight: boolean;
  backgroundColor: string;
  content: string;
}

export default function SinglePencil({onClick, isRight, backgroundColor, content}: Props) {

  return (<div className={`pencil-wrap pencil-wrap-${isRight ? 'right' : 'left'}`}
    style={{backgroundColor}}
    onClick={onClick} >
    <div className={`pencil-box pencil-box-${isRight ? 'right' : 'left'}`} >
    {content}
    </div>
    <div className={`pencil-arrow pencil-arrow-${isRight ? 'right' : 'left'}`}>
    </div>
    <div  className={`pencil-info pencil-info-${isRight ? 'right' : 'left'}`}>
      <Icon name='info' circular />
    </div>
  </div>) 

}