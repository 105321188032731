import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon, Segment } from "semantic-ui-react";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../../app/stores/store";
import MyTextInput from "../../../forms/inputs/MyTextInput";
import MyTextArea from "../../../forms/inputs/MyTextArea";

export default observer(function EditContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, slideId } = useParams();
  const {
    learningStore: {
      updateContent,
      getSingleContent,
      selectedContent,
      loadContent,
    },
  } = useStore();

  React.useEffect(() => {
    if (id) {
      getSingleContent(id);
      loadContent(id);
    }
  }, [getSingleContent, loadContent, id]);

  return (
    <>
      <div>
        <AdminHeader
          icon="pin"
          iconStyles={{ transform: "rotateZ(45deg)" }}
          header={t("edit-content")}
          subheader={t("edit-your-content")}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Formik
            enableReinitialize
            initialValues={selectedContent!}
            onSubmit={(values) => {
              if (values) {
                updateContent(values).then(
                  () =>
                    // {sortedSlides.map(x=>
                    navigate(`/admin/e-learning/viewSlide/${id}/${slideId}`)
                  // )}
                );
              }
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ width: "80%" }}>
                    <MyTextInput name="header" label={t("Title")} />
                    <MyTextArea rows={7} name="body" label={t("content")} />
                    <MyTextInput name="contentOrder" label={t("order")} />
                    <MyTextInput name="typeId" label={t("type-ID")} />
                    <Button
                      style={{ marginTop: "10px" }}
                      color="teal"
                      type="submit"
                      content={t("submit")}
                    ></Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Segment>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Button
            color="red"
            onClick={() => navigate(`/admin/e-learning/viewSlide/${id}/${slideId}`)} >
            <Icon name="arrow left" />
          </Button>
        </Segment>
      </div>
    </>
  );
});
