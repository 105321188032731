import * as yup from "yup";

const UnknownSubmitterSchema = yup.object().shape({
  region: yup
    .array()
    .min(1, "Одберете најмалку една од понудените области")
    .of(yup.string().required("Ова поле е задолжително")),
  employerName: yup.string().required("Ова поле е задолжително").max(255, "Називот не смее да надминува повеќе од 255 карактери"),
  activity: yup.number().required("Ова поле е задолжително").nullable(),
  address: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  cityId: yup.number().required("Одберете град").nullable(),
  violatedRight: yup
    .array()
    .min(1, "Одберете најмалку едно прекршено право")
    .of(yup.number().required("Ова поле е задолжително").nullable()),
  request: yup.string().required("Ова поле е задолжително").max(1000, "Текстот не смее да надминува повеќе од 1000 карактери"),
  // date: yup.date().required("Одберете датум").nullable(),
});
export default UnknownSubmitterSchema;
