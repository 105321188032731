import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TaxonomyTerm } from "../../../../../app/models/CMS/taxonomyTerm";
import { useStore } from "../../../../../app/stores/store";
import PostCategoryForm from "../../components/PostCategoryForm";

const singularName = 'Announcement'; 
const pluralName = 'Announcements'; 
const linkPath = '/admin/announcements'; 
const postType = 'announcement'; 
const postTaxonomyType = 'announcement_categories'; 
const iconName = 'pin'

export default observer(function AnnouncementCategoryCreate() {
  const { postCategoryStore: { createPostCategory, loadPostCategories, clearSelectedCategory} } = useStore();

  React.useEffect(()=> {
    loadPostCategories(postTaxonomyType);

    return ()=> clearSelectedCategory();
  },[loadPostCategories, clearSelectedCategory])

  const navigate = useNavigate();
  const onSubmit = (values: TaxonomyTerm) => {
    values.taxonomySlug = postTaxonomyType
    createPostCategory(values)
    .then(() => navigate(`${linkPath}/category/list`))
  };

  return (<PostCategoryForm 
    goBack={() => navigate(`${linkPath}/category/list`)}
    onSubmit={onSubmit}
    />)
})