import moment from "moment";
import { EnsureDate } from "../../common/types/TypeFunctions";
import { City } from "../Regions/City";
import { formTypesToString, FormValue } from "./SharedFunctions";

export interface AccidentAtWorkInterface {
  id?:string;
  idHeader?: string;
  cityId: number | null;  //bzr //connected with city ID
  employerName: string;
  employerAddress: string;
  employerMunicipality: string,
  employerPostalCode: string,
  workplaceLocation: string;
  injuredFullname: string;
  injuredAddress: string;
  injuredMunicipality: string;
  injuredPostalCode: string;
  birthDate: Date | null;
  gender: string;
  occupation: string;
  employmentPeriod: string;
  accidentTime: Date | null;
  shift: string;
  hours: string;
  injuryDescription: string;
  accidentDescription: string;
  doctorOpinion: string;
  investigation: string;
  damage: string;
  submitterFullname: string;
  submitterWorkPosition: string;
  submitterPhoneNumber: string;
  submitterEmail: string;
  userName?:string
  userId?:string
  dateSubmitted?: Date | null
  // date: Date | null;
}

export class AccidentAtWork implements AccidentAtWorkInterface {
  id?: string | undefined;
  userName?: string | undefined;
  userId?: string | undefined;
  dateSubmitted?: Date | null | undefined;
  idHeader?: string | undefined;
  cityId: number | null = null;
  city: City | null = null;
  employerName: string = "";
  employerAddress: string = "";
  employerMunicipality: string = "";
  employerPostalCode: string = "";
  workplaceLocation: string = "";
  injuredFullname: string = "";
  injuredAddress: string = "";
  injuredMunicipality: string = "";
  injuredPostalCode: string = "";
  birthDate: Date | null = null;
  gender: string = "";
  occupation: string = "";
  employmentPeriod: string = "";
  accidentTime: Date | null = null;
  shift: string = "";
  hours: string = "";
  injuryDescription: string = "";
  accidentDescription: string = "";
  doctorOpinion: string = "";
  investigation: string = "";
  damage: string = "";
  submitterFullname: string = "";
  submitterWorkPosition: string = "";
  submitterPhoneNumber: string = "";
  submitterEmail: string = "";

  constructor(init: AccidentAtWorkInterface){
    Object.assign(this,init);
    this.dateSubmitted = EnsureDate(this.dateSubmitted);
  }

  filteredObjectKeys() {
    const keys = Object.keys(this);
    const filterOut = ['id', 'cityId', 'idHeader', 'userId', "employerOccupation"];
    return keys.filter(x => !filterOut.some(y => y === x));
  }

  toStringArray() {
    return this.filteredObjectKeys().map((key) => {
      let value: FormValue = this[key as keyof AccidentAtWorkInterface];
      [key, value] = formTypesToString(key, value);

      return {
        key,
        value
      }
    });
  }

}

export interface AccidentAtWorkHeader {
  id:string;
  dateSubmitted: Date | null;
  detailsHistory: AccidentAtWorkInterface[];
  latestVersion: AccidentAtWorkInterface;
  status: string;
  statusId:string;
  userName?:string
  userId?:string
}

