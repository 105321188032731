import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import BoxHeading from "../../../app/layout/HomePage/BoxHeading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useNavigate } from "react-router-dom";
import DocumentsAndLinkItem from "./UsefulLinksItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../UsefulLinks/UsefulLinks.css";
import { autoAction } from "mobx/dist/internal";

export default observer(function UsefulLinks() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    homePageStore: { loadLatestNews, news },
    commonStore: { language },
  } = useStore();
  React.useEffect(() => {
    loadLatestNews();
  }, [loadLatestNews]);

  const settings = {
    infinite: true,
    speed: 4500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="useful-links-slider">
      <BoxHeading heading={t("useful-links")} specialClass="background-grey" />
      <div className="ui container" style={{ marginTop: "40px" }}>
        <Slider {...settings} arrows={false}>
          <DocumentsAndLinkItem
            image="/images/icons/izvestuvanja_dokumenti/ministerstvo-header.png"
            to="https://www.mtsp.gov.mk/"
          />
          <DocumentsAndLinkItem
            image="/images/footerImages/Vlada_logo.png"
            to="https://vlada.mk/?ln=mk"
          />
          <DocumentsAndLinkItem
            image="/images/icons/izvestuvanja_dokumenti/logo-inspekciski-mk.png"
            to="http://is.gov.mk/"
          />
          <DocumentsAndLinkItem
            image="/images/footerImages/Agencija_logo.png"
            to="http://av.gov.mk/"
          />
          <DocumentsAndLinkItem
            image="/images/icons/izvestuvanja_dokumenti/mojtrud.jfif"
            to="https://mojtrudmoiprava.mk"
          />
          <DocumentsAndLinkItem
            image="/images/icons/izvestuvanja_dokumenti/zanimanja-mk.png"
            to="https://zanimanja.mk/"
          />
        </Slider>
      </div>
    </div>
  );
});
