import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../AdminHeader";
import { Accordion } from "../../../../models/CMS/Plugin/accordionItem";
import { useStore } from "../../../../stores/store";
import { Button, Icon, Segment } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { v4 } from "uuid";
import MyTextInput from "../../../../../features/forms/inputs/MyTextInput";

export default observer(function AccordionNavigation() {
  const {
    accordionStore: { createAccordion },
  } = useStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = (values: Accordion) => {
    createAccordion(values);
  };

  return (
    <>
      <AdminHeader
        header={t("create-accordion")}
        icon="list alternate outline"
      />
      <Segment>
        <Formik
          initialValues={{ id: v4(), name: "" }}
          enableReinitialize
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {({ submitForm }) => (
            <Form autoComplete="off">
              <>
                <Segment>
                  <MyTextInput name="name" label={t("accordion-title")} />
                  <Button
                    color="red"
                    onClick={() => {
                      navigate("/admin/plugins/accordion/list");
                    }}
                  >
                    <Icon name="arrow left" />
                  </Button>
                  <Button
                    color="teal"
                    type="submit"
                    onClick={() => {
                      submitForm();
                      navigate("/admin/plugins/accordion/list");
                    }}
                  >
                    {t("save")}
                  </Button>
                </Segment>
              </>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
