import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./NavBarLogo.css";

const NavBarLogo = () => {
  const { t } = useTranslation();
  return (
    <Link to="/" className="header_logo">
      <img src={"/images/LOGO_white.png"} alt="Државен Инспекторат за труд" />
      <div className="vertical_logo"></div>
      <div className="logo_text">
        <Trans
          i18nKey="state-labor-inspectorate"
          components={{ newLine: <br /> }}
        />
      </div>
    </Link>
  );
};

export default NavBarLogo;
