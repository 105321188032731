import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Button,
  ButtonProps,
  Header,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";
import DocumentTable from "../../../table/DocumentTable";

interface Props {
  cancel: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => void;
  success: (documentName: string, documentPath: string) => void;
}

export default function ModalEditorButton({ success, cancel }: Props) {
  const { t } = useTranslation();
  const [buttonLink, setButtonLink] = React.useState("");
  const [buttonName, setButtonName] = React.useState("");
  return (
    <>
      <Header icon>
        <Icon name="save" />
        {t("add-button")}
      </Header>
      <Modal.Content>
        <Input
          type="text"
          label={t("button-name-label")}
          value={buttonName}
          onChange={(e, data) => setButtonName(data.value.toString())}
          fluid
        />
      <Input
          type="text"
          label={t("button-link-label")}
          value={buttonLink}
          onChange={(e, data) => setButtonLink(data.value.toString())}
          fluid
        />
  
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          inverted
          onClick={() => {
            if (!buttonName) {
              toast.error(t("please-enter-label-name"));
              return;
            }

            if (!buttonLink) {
              toast.error(t("please-enter-link"));
              return;
            }

            success(buttonName, buttonLink);
          }}
        >
          <Icon name="check" />
        </Button>
        <Button color="red" inverted onClick={cancel}>
          <Icon name="remove" />
        </Button>
      </Modal.Actions>
    </>
  );
}
