import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TaxonomyTerm } from "../../../../../app/models/CMS/taxonomyTerm";
import { useStore } from "../../../../../app/stores/store";
import PostCategoryForm from "../../components/PostCategoryForm";

export default observer(function PostCategoryCreate() {
  const { postCategoryStore: { createPostCategory, loadPostCategories, clearSelectedCategory} } = useStore();

  React.useEffect(()=> {
    loadPostCategories('post_categories');

    return ()=> clearSelectedCategory();
  },[loadPostCategories, clearSelectedCategory])

  const navigate = useNavigate();
  const onSubmit = (values: TaxonomyTerm) => {
    values.taxonomySlug = 'post_categories'
    createPostCategory(values)
    .then(() => navigate('/admin/posts/category/list'))
  };

  return (<PostCategoryForm 
    goBack={() => navigate('/admin/posts/category/list')}
    onSubmit={onSubmit}
    />)
})