import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { Image, Icon } from "semantic-ui-react";
import { DirectorInfo } from "../../../app/models/Plugins/pluginTypes";
import { Styles } from "../../../app/models/styles";

interface Props {
  directorInfo: DirectorInfo;
}

const styles: Styles = {
  image: { width: '200px'},
  imageWrap:{ float: 'left', padding: "10px", paddingTop:"0"},
  inner: {border: '1px solid #e5e5e5',padding:'20px'},
  clearfix: {clear: "both"},
  directorName: {fontSize: "24px", textAlign: 'center', marginBottom: 0},
  email: {textAlign:'center'},
  header: {fontWeight: 700}
}

export default function DirectorInfoPageMarkup({ directorInfo }: Props) {
  const {t} = useTranslation();
  return <div>
    <h1 style={styles.header}>{t("director")}</h1>
    <div style={styles.inner}>
      <div style= {styles.imageWrap}>
        <Image style={styles.image} src={`/media/images/${directorInfo.directorPhoto?.path}`} />
        <p style={styles.directorName}>{directorInfo.firstName + ' ' + directorInfo.lastName}</p>
        <p style={styles.email} ><a href={`mailto:${directorInfo.email}`}><Icon name="mail" />{t("email")}</a></p>
      </div>
      <Markup content={directorInfo.excerpt} />
      <p>
      </p>
      <p style={{clear:'both'}} />
    </div>
  </div>
}