import { t } from 'i18next';
import React from 'react';
import { Button, DropdownItemProps } from 'semantic-ui-react';
import { FilterParam } from '../../models/Pagination/FilterParam';

interface Props 
{
  filterParams : FilterParam[];
  removeFilterParam : (id: string)=> void;
  codeLists? : {[key: string] : DropdownItemProps[]}
}



export default function ReportTableFilters({filterParams, removeFilterParam, codeLists}: Props) {
  return <>
  {filterParams.map(x=> {
  const filterValue = !!codeLists && !!codeLists[x.filterName] ? 
    codeLists[x.filterName].find(y=> y.value?.toString() === x.filterValue)?.text 
    || x.filterValue
    : x.filterValue;

  return <Button
    key={x.filterName}
    content={t('report_filter_' + x.filterName) + " : " + filterValue}
    color='blue'
    circular
    icon="minus"
    size='mini'
    onClick={(e) => {
      e.preventDefault();
      removeFilterParam(x.filterName)
    }} />
  })}
  
 </>
}