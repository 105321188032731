import moment from "moment";
import { EnsureDate } from "../../common/types/TypeFunctions";
import { Nkd } from "../CodeLists/Nkd";
import { City } from "../Regions/City";
import { formTypesToString, FormValue } from "./SharedFunctions";

export interface KnownSubmitterInterface {
  id?: string;
  dateSubmitted?: Date | null;
  idHeader?: string;
  // known submitter
  entity: string;
  submitterName: string;
  submitterAddress: string;
  email: string;
  phoneNumber: string;
  //common
  region: string[]; //ro and bzr checkbox
  employerName: string;
  activity: number | null; //connected with NKD ID
  address: string;
  cityId: number | null; // connected with City ID
  city?: City | null;
  violatedRight: number[]; // connected with vrBzr and vrRo
  request: string;
  userName?: string
  userId?: string
  nkdCodes?: Nkd | null;


}

export class KnownSubmitter implements KnownSubmitterInterface {
  id?: string | undefined;
  userName?: string | undefined;
  userId?: string | undefined;
  dateSubmitted?: Date | null | undefined;
  idHeader?: string | undefined;
  region: string[] = [];
  entity: string = "";
  submitterName: string = "";
  submitterAddress: string = "";
  email: string = "";
  phoneNumber: string = "";
  employerName: string = "";
  activity: number | null = null;
  nkdCodes: Nkd | null = null;
  address: string = "";
  cityId: number | null = null;
  city: City | null = null;
  violatedRight: number[] = [];
  request: string = "";

  constructor(init: KnownSubmitterInterface){
    Object.assign(this,init);
    this.dateSubmitted = EnsureDate(this.dateSubmitted);
  }

  filteredObjectKeys() {
    const keys = Object.keys(this);
    const filterOut = ['id', 'cityId', 'idHeader', 'userId', 'activity'];
    return keys.filter(x => !filterOut.some(y => y === x));
  }

  toStringArray() {
    return this.filteredObjectKeys().map((key) => {
      let value: FormValue = this[key as keyof KnownSubmitterInterface];
      [key, value] = formTypesToString(key, value);

      return {
        key,
        value
      }
    });
  }

}


export interface KnownSubmitterHeader {
  id: string;
  dateSubmitted: Date | null;
  detailsHistory: KnownSubmitterInterface[];
  latestVersion: KnownSubmitterInterface;
  status: string;
  statusId: string;

}
