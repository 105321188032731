import _ from "lodash";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Segment, ButtonGroup, ButtonOr } from "semantic-ui-react";
import PostPaginatedTable from "../../../../features/admin/posts/components/PostPaginatedTable";
import DropdownModal from "../../../common/modals/DropdownModal";
import OperFormsFilterModal from "../../../common/modals/OperFormsFilterModal";
import PostFilterModal from "../../../common/modals/PostFilterModal";
import PaginatedReportTable from "../../../common/table/PaginatedReportTable";
import ReportTableFilters from "../../../common/table/ReportTableFilters";
import { IPaginatedReportMeta } from "../../../common/table/ReportTableFunctions";
import ReportTablePagination from "../../../common/table/ReportTablePagination";
import { useStore } from "../../../stores/store";
import LoadingComponent from "../../LoadingComponent";
import AdminHeader from "../AdminHeader";

const meta: IPaginatedReportMeta[] = [
  { key: "id", type: "hide", value: "true" },
  { key: "nkdId", type: "hide", value: "true" },
  { key: "dateSubmitted", type: "colName", value: "date-submitted" },
  { key: "regionCode", type: "colName", value: "region-code" },
  { key: "statusId", type: "lookup", value: "statuses" },
  { key: "statusId", type: "hide", value: "true" },
  { key: "statusId_replaced", type: "colName", value: "status" },
  { key: "latestVersionId", type: "hide", value: "true " },
  { key: "edb", type: "colName", value: "edb" },
  { key: "formType", type: "colName", value: "form-type" },
  { key: "employerName", type: "colName", value: "employer-name" },
  { key: "nkdCode", type: "colName", value: "nkd-code" },
  { key: "nkdDesc", type: "colName", value: "nkd-description" },
  { key: "employerAddress", type: "colName", value: "employer-address" },
  { key: "unitAddress", type: "colName", value: "unit-address" },
];

export default observer(function FormTableList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    modalStore,
    formStore: {
      setPagingParams,
      pagingParams,
      addFilterParam,
      clearFilterParams,
      filterParams,
      loadingInitial,
      removeFilterParam,
      pagination,
      operetedForms,
      loadForms,
      changeStatus,
      loadStatuses,
      formStatusOptions,
    },
  } = useStore();

  const { openModal, closeModal } = modalStore;

  React.useEffect(() => {
    loadForms();
    loadStatuses();
  }, [loadForms, loadStatuses]);

  const handlePerPageChange = (value?: string) => {
    const pageSize = parseInt(value || "0");
    setPagingParams({
      pageNumber: 1,
      pageSize,
    });
  };

  const handlePaginationChange = (activePage?: string | number) => {
    if (activePage) {
      const pageNumber = parseInt(activePage.toString());
      const { pageSize } = pagingParams;
      setPagingParams({
        pageNumber,
        pageSize,
      });
    }
  };

  const PerPageOptions = [
    { text: 10, value: 10 },
    { text: 50, value: 50 },
  ];

  if (!formStatusOptions) {
    return <LoadingComponent />;
  }

  const actionButtons = (obj: any) => (
    <ButtonGroup>
      <Button
        onClick={(e) => {
          navigate(`../${obj.formType}/view/${obj.id}`);
        }}
        primary
      >
        {t("view")}
      </Button>
      <ButtonOr text={t("or")}></ButtonOr>
      <Button
        onClick={(e) => {
          openModal(
            <DropdownModal
              closeModal={closeModal}
              onSuccess={(data) => {
                changeStatus(obj.id, data, obj.formType);
                closeModal();
              }}
              options={formStatusOptions}
              defaultValue={obj.statusId}
              headerContent={t("change-status")}
              key={obj.id}
              placeholder={t("choose-status")}
            />
          );
        }}
        color="green"
      >
        {t("change-status")}
      </Button>
    </ButtonGroup>
  );

  return (
    <>
      <AdminHeader header={t("form-list")} icon="table" />
      <Segment loading={loadingInitial} attached="bottom">
        <Button
          content={t("add-filter")}
          onClick={(e) => {
            openModal(
              <OperFormsFilterModal
                closeModal={closeModal}
                setFilter={addFilterParam}
              />
            );
          }}
        />
        {filterParams.length > 0 && (
          <Button
            content={t("clear-all-filters")}
            onClick={(e) => {
              clearFilterParams();
            }}
          />
        )}

        <ReportTableFilters
          filterParams={filterParams}
          removeFilterParam={removeFilterParam}
          codeLists={{ statusId: formStatusOptions }}
        />
        <PaginatedReportTable
          codeLists={{ statuses: formStatusOptions }}
          data={operetedForms.map((x) => {
            return {
              ...x,
              dateSubmitted: moment(x.dateSubmitted).format(
                "DD-MM-YYYY HH:MM a"
              ),
            };
          })}
          meta={meta}
          actionButtons={actionButtons}
        />
        <ReportTablePagination
          numPages={pagination?.totalPages || 0}
          perPage={pagination?.itemsPerPage || 0}
          PerPageOptions={PerPageOptions}
          activePage={pagination?.currentPage}
          handlePerPageChange={handlePerPageChange}
          handlePaginationChange={handlePaginationChange}
        />
      </Segment>
    </>
  );
});
