import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { Segment, Button, ButtonGroup } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import agent from "../../../app/api/agent";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { WorkOnSundayInterface } from "../../../app/models/Forms/WorkOnSundayInterface";
import { useStore } from "../../../app/stores/store";
import SundayForm from "../commonForms/Reports/SundayForm";
import AdminHeader from "../../../app/layout/admin/AdminHeader";

export default observer(function WorkOnSundayForm() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { codesStore, formStore } = useStore();
  const { citiesDropdown, loadCities, loadNkds, nkdDropdown } = codesStore;
  const { selectedWorkOnSundayForm, loadSingleWorkOnSundayForm } = formStore;

  useEffect(() => {
    if (id) loadSingleWorkOnSundayForm(id);
    loadCities();
    loadNkds();
  }, [loadCities, loadNkds, loadSingleWorkOnSundayForm, id]);

  if (!citiesDropdown || !nkdDropdown || !selectedWorkOnSundayForm) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: WorkOnSundayInterface,
    actions: FormikHelpers<WorkOnSundayInterface>
  ) => {
    try {
      await agent.Forms.updateWorkOnSunday(values).then(() => {
        toast.success(t("successfully-edited-report!"));
      });
    } catch {
      toast.error(t("failed-to-send-report!"));
      return;
    }
  };

  return (
    <>
      <AdminHeader header={t("edit-work-on-sunday")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedWorkOnSundayForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <SundayForm
        handleSubmit={handleSubmit}
        citiesDropdown={citiesDropdown}
        nkdDropdown={nkdDropdown}
        initialValues={selectedWorkOnSundayForm}
      />
    </>
  );
});
