import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Card, Container, Divider, Grid, Header, Message, Segment } from "semantic-ui-react"
import LoadingComponent from "../../app/layout/LoadingComponent"
import { useStore } from "../../app/stores/store"
import ELearningHeader from "./ELearningHeader"
import ELearningStickyHeader from "./ELearningStickyHeader"
import ModuleCard from "./ModuleCard"

export default observer(function LearningModule() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { learningStore: { selectedModule,
    getSingleModule,
    loadingInitial,
    sortedSlides } } = useStore();

  useEffect(() => {
    if (id) {
      getSingleModule(id);
    } else {
      navigate('/e-learning/dashboard');
    }
  }, [getSingleModule, navigate, id])

  if (loadingInitial || !selectedModule) {
    return <LoadingComponent />
  }

  return (
  <>
  <ELearningStickyHeader />
  <Container style={{ marginTop: '20px' }}>
    <ELearningHeader
      content={`Добре дојдовте во Модул: ${selectedModule.moduleName} `}
    />
    <Segment className="learning-training" style={{background:'whitesmoke'}} >
      <Grid stackable>
        <Grid.Column width={4}>
          <Segment style={{border:'none', background:'whitesmoke'}}>
            <Button fluid onClick={() => {
              navigate(`/e-learning/training/${selectedModule?.trainingId}`)
            }} content="Назад" icon="chevron left" labelPosition="left" color="red" />
          </Segment>
          <Segment style={{background:'whitesmoke'}}>
            <Header style={{border:'none', background:'whitesmoke'}} as="h2" attached="top" textAlign="center" content="Добре дојдовте на екранот за обука" />
            <Divider style={{background:'#2185d0'}}/>
            <br />
            <p>
              За да продолжите со обуката, ве молиме да го изберете модулот кој сакате да го разгледате повторно или да одберете продолжете.
            </p>
            {/* <p>
              To proceed with the training please choose the module that you wish to revisit, or
              choose continue.
            </p> */}
          </Segment>
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment style={{ minHeight: "500px", background:'whitesmoke' }}>
            <Segment style={{border:'none', background:'whitesmoke', borderBottom:'2px solid #2185d0'}}>
              Потребно време: {selectedModule?.timeRequired} минути
            </Segment>
            <Segment style={{border:'none', background:'whitesmoke'}}>
              {sortedSlides.length === 0 && <Message content="За овој модул нема слајдови" />}
              {sortedSlides.length > 0 && <Card.Group style={{ gap: '5px' }} >
                {sortedSlides.map((slide, i, arr) => {
                  const currentSlideIx = arr.findIndex(x => x.id === selectedModule.userProgress?.slideId);
                  const moduleFinished = selectedModule.moduleOpen;
                  const locked = i !== 0 && currentSlideIx < i && !moduleFinished;
                  return <ModuleCard key={slide.id}
                    locked={locked}
                    name={slide.slideName}
                    // backgroundImage={slide.backgroundImage}
                    popupContent="Овој слајд сеуште не е отворен"
                    onClick={() => navigate(`/e-learning/slide/${slide.id}`)}
                    selected={false}
                  />

                })}
              </Card.Group>
              }
            </Segment>

          </Segment>
        </Grid.Column>
      </Grid>
    </Segment>
  </Container>
  {/* <ELearningStickyFooter /> */}
  </>
  )
})