import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Segment } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store"
import ImageParagraph1 from "./slides/ImageParagraph1";
import './LearningSlide.css';
import { toast } from "react-toastify";
import AccordionParagraph1 from "./slides/AccordionParagraph1";
import FlipCardSlide1 from "./slides/FlipCardSlide1";
import StairCaseParagraph from "./slides/StairCaseParagraph";
import PencilCaseParagraph1 from "./slides/PencilCaseParagraph1";
import FlipCardSlide2 from "./slides/FlipCardSlide2";
import VideoSlide from "./slides/VideoSlide";
import PencilCaseParagraph2 from "./slides/PencilCaseParagraph2";
import AccordionParagraph2 from "./slides/AccordionParagraph2";
import NumbersDiagramParagraph from "./slides/NumbersDiagramParagraph";
import LearningQuiz from "./quiz/LearningQuiz";
import FlipCardSlide3 from "./slides/FlipCardSlide3";


const colorSkins = [
  ['#e63d3d', '#3f3f78', '#f9a824', '#fcd836', '#94cfc9'], // 0
  ['#f2622c', '#320e3b', '#7f96ff', '#a6cfd5', '#5acbd3'], // 1
  ['#f18a22', '#8ac926', '#b91372', '#73d2de', '#eba7b4'], // 2
  ['#a0d244', '#00a5e0', '#997be0', '#fa8834', '#ce84ad'], // 3
  ['#f7ce1a', '#00bbf9', '#fa7921', '#d81e5b', '#9cd7b7'], // 4
  ['#59d86b', '#d4899a', '#648983', '#22b6d4', '#e49f44'], // 5
  ['#1fd6ba', '#f2c512', '#da0368', '#067bc1', '#e26b93'], // 6
  ['#1fd5ba', '#f96900', '#192bc2', '#00a6ed', '#db5375'], // 7
  ['#228edc', '#48fff8', '#e44735', '#f8c54e', '#12d7d0'], // 8
  ['#1257DF', '#F26430', '#FFDF64', '#59D2FE', '#E9190F'], // 9
  ['#6a00bc', '#2d82b7', '#ef476f', '#7ee8fa', '#95d117'], // 10
  ['#c51db8', '#253c78', '#fe938b', '#f6ae2d', '#6eb2d7'], // 11
  ['#e5366d', '#ffbe0c', '#72e1d1', '#8338eb', '#3a86fe'], // 12
  ['#f587aa', '#65c6b5', '#a40e4d', '#4ed27e', '#fbab64'], // 13
  // ['#f5cb42','#f5cb42','#f5cb42'],  // 14
]


export default observer(function LearningSlide() {
  const { id } = useParams();
  const { learningStore: { loadCurrentSlide, selectedSlide, getQuizResults, loadingInitial, nextDisabled } } = useStore();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (id) {
      loadCurrentSlide(id);
    }
  }, [id, loadCurrentSlide])

  if (loadingInitial || !selectedSlide) {
    return <LoadingComponent />
  }


  const slideContents = () => {
    switch (selectedSlide.slideType!.slideTypeName) {
      case 'Image-Paragraph-1':
        return <ImageParagraph1 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Accordion-1':
        return <AccordionParagraph1 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Accordion-2':
        return <AccordionParagraph2 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Flip-Card-1':
        return <FlipCardSlide1 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Staircase-1':
        return <StairCaseParagraph colorSchemeArray={colorSkins[selectedSlide.moduleOrder /*- 1 */]} slideContents={selectedSlide.slideContents} />
      case 'Pencil-case-1':
        return <PencilCaseParagraph1 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Flip-Card-2':
        return <FlipCardSlide2 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Flip card-3':
        return <FlipCardSlide3 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Video-1':
        return <VideoSlide colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Paragraph-Pensil-case-1':
        return <PencilCaseParagraph2 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Pencil-case-2':
        return <PencilCaseParagraph2 colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Numbers-Diagram-1':
        return <NumbersDiagramParagraph colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} slideContents={selectedSlide.slideContents} />
      case 'Quiz':
        return <LearningQuiz colorSchemeArray={colorSkins[selectedSlide.moduleOrder]} quiz={selectedSlide.quiz} />
      default:
        return <div>Unkown SlideType</div>
    }
  }

  return (<div style={{ paddingTop: '20px' }}>
    <Container >
      <Segment>
        <Button content="Назад" icon="chevron left" labelPosition="left" color="red"
          onClick={() => navigate(`/e-learning/module/${selectedSlide.moduleId}`)} />
        <div style={{float:'right', padding:'10px', fontSize:'16px', fontWeight:'bold'}}>
           {selectedSlide.trainingName} / {selectedSlide.moduleName}
        </div>
      </Segment>
      {slideContents()}
      <Segment>
        {selectedSlide.prevSlideId && <Button floated="left"
          onClick={() => navigate(`/e-learning/slide/${selectedSlide.prevSlideId}`)}>Претходно</Button>}
        <Button floated="right"
          disabled={nextDisabled}
          onClick={async () => {
            if (selectedSlide.quiz) {
              const quizResults = await getQuizResults()
              if (!quizResults) {
                toast.error('Квизот не е положен', { position: toast.POSITION.TOP_RIGHT });
                navigate(`/e-learning/module/${selectedSlide.moduleId}`);
                return;
              }
            }

            if (!selectedSlide.nextSlideId) {
              toast.info('Модулот е завршен');
              navigate(`/e-learning/module/${selectedSlide.moduleId}`);
              return;
            }

            navigate(`/e-learning/slide/${selectedSlide.nextSlideId}`)
          }}>Следно</Button>
        <p style={{ clear: 'both' }} />
      </Segment>
    </Container>
  </div>)
})