import _ from "lodash";
import React from "react";
import { FilterOption } from "../types/FilterOption";
import FilterModal from "./FilterModal";

interface Props {
  closeModal: () => void;
  setFilter: (filterName: string, filterValue: string) => void
}

export default function PhotoFilterModal({ closeModal, setFilter }: Props) {
  const imageType = [
    { key: "productImage", value: 1, text: "Product Image" },
    { key: "image", value: 2, text: "Image" }
]


  const filterOptions: FilterOption[] = [
    { name: 'name', text: 'Name', type: 'text' },
    { name: 'TypeId', text: 'Image Type', type: 'select', options: imageType }

  ]

  return (
    <>
      <FilterModal
        filterOptions={filterOptions}
        closeModal={closeModal}
        setFilter={setFilter} />
    </>
  )
}