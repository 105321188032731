import React from "react";
import { useField } from "formik";
import { Form, Icon, Label, Popup } from "semantic-ui-react";
import "./InputStyle.css";

interface Props {
  placeholder?: string;
  name: string;
  rows?: number;
  label?: string;
  info?: string;
}

export default function MyTextArea(props: Props) {
  const [field, meta] = useField(props.name);
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      {props.label && (
        <>
          <label className="label">{props.label}</label>
          {props.info && (
            <Popup
              trigger={<Icon name="info circle" />}
              content={props.info}
              position="right center"
            />
          )}
        </>
      )}
      <textarea {...field} {...props} className="input-forms" />
      {meta.touched && !!meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
