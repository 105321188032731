import { constants } from "http2";
import _ from "lodash";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import agent from "../api/agent";
import { CMSMedia } from "../models/Media/media";
import { FilterParam } from "../models/Pagination/FilterParam";
import { Pagination, PagingParams } from "../models/Pagination/pagination";

export default class MediaStore {
  mediaRegistry = new Map<string, CMSMedia>();
  loading = false;
  loadingInitial = false;
  uploading = false;
  filterRegistry = new Map<string, FilterParam>();
  staticFilterRegistry = new Map<string, FilterParam>();
  previousStaticFilterRegistry = new Map<string, FilterParam>();
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  uploadTarget: string | null = null;

  constructor() {
    makeAutoObservable(this)
    reaction(()=> this.pagingParams, 
    params => {
      this.loadMedia();
    })
  }

  get mediaList () {
    return Array.from(this.mediaRegistry.values());
  }

  get documentList () {
    return Array.from(this.mediaRegistry.values());
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append('pageNumber',this.pagingParams.pageNumber.toString());
    params.append('pageSize',this.pagingParams.pageSize.toString());
    const filters = Array.from(this.filterRegistry.values());
    filters.forEach(x=>{
      params.append(x.filterName,x.filterValue);
    });
    const staticFilters = Array.from(this.staticFilterRegistry.values());
    staticFilters.forEach(x=>{
      params.append(x.filterName,x.filterValue);
    });

    return params;
  }

  setLoadingInitial = (state: boolean) =>{
    this.loadingInitial = state;
  }
  setLoading = (state: boolean) =>{
    this.loading = state;
  }
  
  setPagingParams = (pagingParams : PagingParams) => {
    this.pagingParams = pagingParams;
  }

  addFilterParam = (name: string, value: string) => {
    const param = new FilterParam(name,value);
    this.filterRegistry.set(param.filterName, param);
    this.pagingParams = new PagingParams(1,this.pagingParams.pageSize);
    this.loadMedia();
  }

  addStaticFilterParam = (name: string, value: string) => {

    const param = new FilterParam(name,value);
    this.staticFilterRegistry.set(param.filterName, param);
      if(!this.pagingParams)
    this.pagingParams = new PagingParams(1,10);
    this.loadMedia();
  }

  setMultipleStaticFilterParam = (params: {name: string, value: string}[]) => {
    this.staticFilterRegistry.clear();
    params.forEach(p=> {
      const {name, value} = p;
      const param = new FilterParam(name,value);
      this.staticFilterRegistry.set(param.filterName, param);
      // this.pagingParams = new PagingParams(1,this.pagingParams.pageSize);
    })
    this.loadMedia();
  }


  removeFilterParam = (id:string) => {
    this.filterRegistry.delete(id);
    this.loadMedia();
  }

  clearFilterParams = () => {
    this.filterRegistry.clear();
    this.loadMedia();
  }

  clearStaticFilterParams = () => {
    this.staticFilterRegistry.clear();
  }

  get filterParams () {
    return Array.from(this.filterRegistry.values());
  }
  
  loadMedia = async () => {
    this.setLoadingInitial(true);
    try {
      const result = await agent.Files.list(this.axiosParams);
      this.mediaRegistry.clear();
      result.data.forEach((media) => {
        this.mediaRegistry.set(media.id,media);
      })
      this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (err) {
      console.log(err);
      this.setLoadingInitial(false);
    }
  }

  loadDocuments = async () => {
    this.previousStaticFilterRegistry = _.cloneDeep(this.staticFilterRegistry);
    this.clearStaticFilterParams();
    this.addStaticFilterParam('TypeId','3');
  }
  unloadDocuments = async () => {
    this.staticFilterRegistry = _.cloneDeep(this.previousStaticFilterRegistry);
    this.loadMedia();
  }


  uploadPhotoMedia = async (file : string, photoName: string) => {
    this.uploading = true;
    try {
      const id = v4();
      const payload = file.substring(file.indexOf(',') +1);
      const mimeType = file.substring(file.indexOf(":")+1,file.indexOf(";"));

      const formData = new FormData();
      formData.append('Id',id);
      formData.append('Photo',payload);
      formData.append('PhotoName',photoName);
      formData.append('MimeType',mimeType);
      formData.append('TypeId','1');
      
      await agent.Files.uploadPhoto(formData);

    } catch (error) {
      toast.error("Photo upload failure")
      runInAction(()=>{
        this.uploading = false;
      })
    }
  }

  uploadDocumentMedia = async (file : string, documentName: string) => {
    this.uploading = true;
    try {
      const id = v4();
      const payload = file.substring(file.indexOf(',') +1);
      const mimeType = file.substring(file.indexOf(":")+1,file.indexOf(";"));

      const formData = new FormData();
      formData.append('Id',id);
      formData.append('Document',payload);
      formData.append('DocumentName',documentName);
      formData.append('MimeType',mimeType);
      formData.append('TypeId','3');
      
      await agent.Files.uploadDocument(formData);

    } catch (error) {
      toast.error("Document upload failure")
      runInAction(()=>{
        this.uploading = false;
      })
    }
  }

  deleteMedia = async (id: string) => {
    this.loading = true;
    this.uploadTarget = id;
    try {
      await agent.Files.delete(id);
      runInAction(()=>{
        this.loadMedia()
      });

    } catch (error) {
      
    } finally {
      runInAction(()=>{
        this.loading = false;
        this.uploadTarget = null;
      })
    }
  }

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  }
}

