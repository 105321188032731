import React, { useEffect } from "react";
import Slider from "react-slick";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Icon, SemanticICONS, Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderImages.css";

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slider_arrow next_arrow"
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className="slider_arrow prev_arrow"
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

export default observer(function SliderImages() {
  const {
    sliderStore: { sliderSlides },
    commonStore: { language },
  } = useStore();
  const navigate = useNavigate();

  const filterdSlider = sliderSlides.filter((x) =>
    x.translations.map((x) => x.languageId === language)
  );

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Slider {...settings}>
      {filterdSlider &&
        filterdSlider.sort((a,b)=> a.itemOrder - b.itemOrder).map((x,i) => (
            <div className="slide" key={i}>
              <Image src={`/media/images/${x.image?.path}`} />
              <div className="slide-content">
                <p
                  style={{
                    fontSize: x.headerFontSize,
                    color: x.headerColor,
                    marginBottom: "-3px",
                  }}
                >
                  {
                    x.translations.find((x) => x.languageId === language)
                      ?.headerContent
                  }
                </p>
                <p
                  style={{
                    fontSize: x.subHeaderFontSize,
                    color: x.subHeaderColor,
                  }}
                >
                  {
                    x.translations.find((x) => x.languageId === language)
                      ?.subHeaderContent
                  }
                </p>
                <button onClick={() => navigate(`${x.buttonLink}`)}>
                  {
                    x.translations.find((x) => x.languageId === language)
                      ?.buttonContent
                  }{" "}
                  <Icon
                    name={x.buttonIcon as SemanticICONS}
                    style={{ marginLeft: "5px" }}
                  />
                </button>
              </div>
            </div>
        ))}
    </Slider>
    // </div>
  );
});
