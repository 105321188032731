import { Formik, FormikHelpers } from "formik";
import {
  Grid,
  Segment,
  Form,
  Header,
  GridColumn,
  DropdownItemProps,
} from "semantic-ui-react";
import { AccidentAtWorkInterface } from "../../../../app/models/Forms/AccidentAtWorkInterface";
import MyDateInput from "../../inputs/MyDateInput";
import MyRadio from "../../inputs/MyRadio";
import MyRadioGroup from "../../inputs/MyRadioGroup";
import MyTextArea from "../../inputs/MyTextArea";
import MyTextInput from "../../inputs/MyTextInput";
import { AccidentAtWorkSchema } from "../../schemas/AccidentAtWorkSchema";
import FormsLogo from "../FormsLogo";
import MiddleHeader from "./MiddleHeader";
import { ButtonsReportsFooter } from "./ButtonsReportsFooter";
import "./AccidentForm.css";

const initValues: AccidentAtWorkInterface = {
  cityId: null,
  employerName: "",
  employerAddress: "",
  employerMunicipality: "",
  employerPostalCode: "",
  workplaceLocation: "",
  injuredFullname: "",
  injuredAddress: "",
  injuredMunicipality: "",
  injuredPostalCode: "",
  birthDate: null,
  gender: "",
  occupation: "",
  employmentPeriod: "",
  accidentTime: null,
  shift: "",
  hours: "",
  injuryDescription: "",
  accidentDescription: "",
  doctorOpinion: "",
  investigation: "",
  damage: "",
  submitterFullname: "",
  submitterWorkPosition: "",
  submitterPhoneNumber: "",
  submitterEmail: "",
  // date: null,
};

interface Props {
  citiesDropdown: DropdownItemProps[] | undefined;
  handleSubmit: (
    values: AccidentAtWorkInterface,
    actions: FormikHelpers<AccidentAtWorkInterface>
  ) => Promise<void>;
  initialValues?: AccidentAtWorkInterface | null;
}

export default function AccidentForm({
  citiesDropdown,
  handleSubmit,
  initialValues,
}: Props) {
  return (
    <>
      <style>
        {`
            .background_img {
              background-image: url("/images/background.png");
              background-repeat: no-repeat;
              background-position: 85% 15%;
              background-size: contain;
            }
          `}
      </style>
      <Grid className="form-frame" stackable>
        <Segment className="form-segment">
          <Formik
            initialValues={initialValues || initValues}
            validationSchema={AccidentAtWorkSchema}
            enableReinitialize={!!initialValues}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, handleSubmit }) => (
              <div className="background_img">
                <Form
                  className="form-margins"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <header>
                    <FormsLogo />
                    <MiddleHeader citiesDropdown={citiesDropdown} />
                  </header>
                  <main>
                    <div className="accident-main-title">
                      <Header>
                        <div className="accident-title-top">Известување</div>
                        <div className="accident-title-bottom">
                          за несреќа на работа
                        </div>
                      </Header>
                      <Header.Subheader>
                        (Член 36 од Законот за безбедност и здравје при работа
                        <br />
                        „Службен весник на Република Македонија“ бр.92/07)
                      </Header.Subheader>
                    </div>
                    <ol className="ol">
                      <li>
                        <MyTextInput
                          label="Назив на работодавачот"
                          name="employerName"
                          type="text"
                          placeholder="Внесете назив на работодавачот"
                        />
                      </li>
                      <li>
                        <p className="asterisk">
                          Седиште на работодавачот
                        </p>
                        <p>Улица, број, место</p>
                        <MyTextInput
                          name="employerAddress"
                          type="text"
                          placeholder="Внесете улица, број и место"
                        />
                        <p>Општина</p>
                        <MyTextInput
                          name="employerMunicipality"
                          type="text"
                          placeholder="Внесете општина"
                        />
                        <p>Поштенски број</p>
                        <MyTextInput
                          name="employerPostalCode"
                          type="text"
                          placeholder="Внесете поштенски број"
                        />
                      </li>
                      <li>
                        <MyTextInput
                          label="Локација на работното место (продавница, магацин, кат, адреса)"
                          name="workplaceLocation"
                          type="text"
                          placeholder="Внесете локација на работното место"
                        />
                      </li>
                      <li>
                        <p className="asterisk">
                          Лични податоци на повредениот
                        </p>
                        <p >Име и презиме</p>
                        <MyTextInput
                          name="injuredFullname"
                          type="text"
                          placeholder="Внесете име и презиме"
                        />
                        <p>Адреса на постојано живеење (улица, број, место)</p>
                        <MyTextInput
                          name="injuredAddress"
                          type="text"
                          placeholder="Внесете адреса на живеење"
                        />
                        <p>Општина</p>
                        <MyTextInput
                          name="injuredMunicipality"
                          type="text"
                          placeholder="Внесете општина"
                        />
                        <p>Поштенски број</p>
                        <MyTextInput
                          name="injuredPostalCode"
                          type="text"
                          placeholder="Внесете поштенски број"
                        />
                        <p>
                          Датум на раѓање
                        </p>
                        <MyDateInput
                          name="birthDate"
                          dateFormat="dd/MM/yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Одберете датум на раѓање"
                        />
                        <li>
                          <p
                            className="asterisk">
                            Пол:
                          </p>
                          <MyRadioGroup
                            touched={touched.gender}
                            error={errors.gender}
                          >
                            <Grid style={{ marginTop: "5px" }}>
                              <MyRadio
                                type="radio"
                                name="gender"
                                value="машки"
                                label="машки"
                              />
                              <MyRadio
                                type="radio"
                                name="gender"
                                value="женски"
                                label="женски"
                              />
                            </Grid>
                          </MyRadioGroup>
                        </li>
                      </li>
                      <li>
                        <MyTextInput
                          label="Професија на работно место на повредената личност"
                          name="occupation"
                          type="text"
                          placeholder="Внесете професија на повредената личност"
                        />
                      </li>
                      <li>
                        <p className="asterisk" style={{ marginBottom: "5px" }}>
                          Колку време е вработен кај работодавачот?
                        </p>
                        <MyRadioGroup
                          touched={touched.employmentPeriod}
                          error={errors.employmentPeriod}
                        >
                          <Grid stackable >
                            <GridColumn 
                              width={4}>
                              <MyRadio
                                type="radio"
                                name="employmentPeriod"
                                value="една недела"
                                label="една недела"
                              />
                              <MyRadio
                                type="radio"
                                name="employmentPeriod"
                                value="недела - еден месец"
                                label="недела - еден месец"
                              />
                            </GridColumn>
                            <GridColumn
                              width={4}>
                              <MyRadio
                                type="radio"
                                name="employmentPeriod"
                                value="1 - 6 месеци"
                                label="1 - 6 месеци"
                              />
                              <MyRadio
                                type="radio"
                                name="employmentPeriod"
                                value="6 месеци - 1 година"
                                label="6 месеци - 1 година"
                              />
                            </GridColumn>
                            <GridColumn
                              width={4}>
                              <MyRadio
                                type="radio"
                                name="employmentPeriod"
                                value="1 - 5 години"
                                label=" 1 - 5 години"
                              />
                              <MyRadio
                                type="radio"
                                name="employmentPeriod"
                                value="над 5 години"
                                label="над 5 години"
                              />
                            </GridColumn>
                            <GridColumn width={4} verticalAlign="bottom" >
                              <MyRadio
                                type="radio"
                                name="employmentPeriod"
                                value="не е вработен"
                                label="не е вработен"
                              />
                            </GridColumn>
                          </Grid>
                        </MyRadioGroup>
                      </li>
                      <li>
                        <label className="asterisk">
                          Датум и час на случување на несреќата
                        </label>
                      </li>
                      <MyDateInput
                        name="accidentTime"
                        placeholderText="Внесете датум и час на случување на несреќата"
                        timeInputLabel="Време:"
                        dateFormat="dd/MM/yyyy h:mm aa"
                        showTimeInput
                      />
                      <p style={{ margin: "5px 0" }}>смена:</p>
                      <MyRadioGroup
                        touched={touched.shift}
                        error={errors.shift}
                      >
                        <Grid style={{ margin: "5px 0", paddingLeft: "0" }} stackable>
                          <MyRadio
                            type="radio"
                            name="shift"
                            value="денска"
                            label="денска"
                          />
                          <MyRadio
                            type="radio"
                            name="shift"
                            value="попладневна"
                            label="попладневна"
                          />
                          <MyRadio
                            type="radio"
                            name="shift"
                            value="ноќна"
                            label="ноќна"
                          />
                        </Grid>
                      </MyRadioGroup>
                      <p style={{ margin: "5px 0" }}>
                        часови работено од доаѓањето на работа:
                      </p>
                      <MyTextInput
                        name="hours"
                        type="text"
                        placeholder="Внесете изработени часови"
                      />
                      <li>
                        <MyTextArea
                          label="Опис на повредата (не е третирана, прва помош, лекар (не е хоспитализиран), хоспитализиран"
                          name="injuryDescription"
                          rows={8}
                          placeholder="Внесете опис на повредата"
                        />
                      </li>
                      <li>
                        <MyTextArea
                          label="Каде и како се случила несреќата?"
                          name="accidentDescription"
                          rows={8}
                          placeholder="Внесете каде и како се случила несреќата"
                        />
                      </li>
                      <li>
                        <span
                          style={{ display: "block", marginBottom: "5px" }}
                          className="asterisk"
                        >
                          Дали имате наод и мислење од лекар?
                        </span>
                        <MyRadioGroup
                          touched={touched.doctorOpinion}
                          error={errors.doctorOpinion}
                        >
                          <Grid style={{ marginTop: "5px" }} stackable>
                            <MyRadio
                              type="radio"
                              name="doctorOpinion"
                              value="да"
                              label="да"
                            />
                            <MyRadio
                              type="radio"
                              name="doctorOpinion"
                              value="не"
                              label="не"
                            />
                          </Grid>
                        </MyRadioGroup>
                      </li>
                      <li>
                        <span
                          style={{ display: "block", marginBottom: "5px" }}
                          className="asterisk"
                        >
                          Дали е спроведена истрага?
                        </span>
                        <MyRadioGroup
                          touched={touched.investigation}
                          error={errors.investigation}
                        >
                          <Grid style={{ marginTop: "5px" }}>
                            <MyRadio
                              type="radio"
                              name="investigation"
                              value="да"
                              label="да"
                            />
                            <MyRadio
                              type="radio"
                              name="investigation"
                              value="не"
                              label="не"
                            />
                          </Grid>
                        </MyRadioGroup>
                      </li>
                      <li>
                        <span
                          style={{ display: "block", marginBottom: "5px" }}
                          className="asterisk"
                        >
                          Дали е настаната значителна штета?
                        </span>
                        <MyRadioGroup
                          touched={touched.damage}
                          error={errors.damage}
                        >
                          <Grid style={{ marginTop: "5px" }} stackable>
                            <MyRadio
                              type="radio"
                              name="damage"
                              value="да"
                              label="да"
                            />
                            <MyRadio
                              type="radio"
                              name="damage"
                              value="не"
                              label="не"
                            />
                          </Grid>
                        </MyRadioGroup>
                      </li>
                    </ol>
                  </main>
                  <footer>
                    <Grid
                      style={{ display: "block", margin: "15px 0  10px 40px" }} stackable>
                      <Grid.Row
                        style={{
                          display: "block",
                        }}
                      >
                        <Header size="small" className="asterisk">
                          Пополнето од:
                        </Header>
                        <p style={{ margin: "0 0 5px 2px" }}>Име и презиме</p>
                        <MyTextInput
                          name="submitterFullname"
                          type="text"
                          placeholder="Внесете име и презиме"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>Работно место</p>
                        <MyTextInput
                          name="submitterWorkPosition"
                          type="text"
                          placeholder="Внесете работно место"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>
                          Телефонски број
                        </p>
                        <MyTextInput
                          name="submitterPhoneNumber"
                          type="tel"
                          placeholder="Внесeте телефонски број"
                        />
                        <p style={{ margin: "8px 0 5px 2px" }}>Е-mail адреса</p>
                        <MyTextInput
                          name="submitterEmail"
                          type="email"
                          placeholder="Внесете e-mail адреса"
                        />
                      </Grid.Row>
                      <ButtonsReportsFooter />
                    </Grid>
                  </footer>
                </Form>
              </div>
            )}
          </Formik>
        </Segment>
      </Grid>
    </>
  );
}
