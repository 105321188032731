import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, Icon, Table } from "semantic-ui-react";
import { useStore } from "../../../stores/store";
import LoadingComponent from "../../LoadingComponent";
import AdminHeader from "../AdminHeader";

export default observer(function NavigationList() {
  const {
    navigationStore: {
      loadingInitial,
      loadNavigation,
      sortedNavItems,
      target,
      deleteNavigation,
      parentNavigationDropdown,
    },
  } = useStore();
  const { t } = useTranslation();

  React.useEffect(() => {
    loadNavigation();
  }, [loadNavigation]);
  if (loadingInitial) return <LoadingComponent />;

  return (
    <>
      <AdminHeader header={t("navigation-list")} icon="location arrow" />
      <>
        <Table celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("title-mk")}</Table.HeaderCell>
              <Table.HeaderCell>{t("title-al")}</Table.HeaderCell>
              <Table.HeaderCell>{t("title-en")}</Table.HeaderCell>
              <Table.HeaderCell>{t("parent")}</Table.HeaderCell>
              <Table.HeaderCell>{t("link-path")}</Table.HeaderCell>
              <Table.HeaderCell>{t("order")}</Table.HeaderCell>
              <Table.HeaderCell>{t("actions")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sortedNavItems.map((x) => {
              const mkTrans = x.translations?.find(
                (x) => x.languageId === "MK"
              );
              const sqTrans = x.translations?.find(
                (x) => x.languageId === "SQ"
              );
              const enTrans = x.translations?.find(
                (x) => x.languageId === "EN"
              );
              return (
                <>
                  <Table.Row key={x.id}>
                    <Table.Cell>{mkTrans?.title || ""}</Table.Cell>
                    <Table.Cell>{sqTrans?.title || ""}</Table.Cell>
                    <Table.Cell>{enTrans?.title || ""}</Table.Cell>
                    <Table.Cell>{x.parentId}</Table.Cell>
                    <Table.Cell>{x.linkPath || ""}</Table.Cell>
                    <Table.Cell>{x.order || ""}</Table.Cell>

                    <Table.Cell>
                      <ButtonGroup>
                        <Button
                          as={Link}
                          to={`/admin/homepage/nav/edit/${x.id}`}
                          icon="edit"
                          inverted
                          color="green"
                        />
                        <Button
                          name={x.id}
                          loading={target === x.id}
                          onClick={(e) => {
                            e.preventDefault();
                            deleteNavigation(x.id);
                          }}
                          icon="close"
                          inverted
                          color="red"
                        />
                      </ButtonGroup>
                    </Table.Cell>
                  </Table.Row>
                </>
              );
            })}
          </Table.Body>
        </Table>

        <div style={{ textAlign: "right" }}>
          <Link to={"/admin/homepage/nav/create"} className="ui button teal">
            <Icon name="plus" />
            {t("add-new-navigation")}
          </Link>
        </div>
      </>
    </>
  );
});
