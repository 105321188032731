import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, FieldArray } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Form, Icon, Segment, Tab } from "semantic-ui-react";
import { v4 } from "uuid";
import MyTextInput from "../../../../features/forms/inputs/MyTextInput";
import SelectInput from "../../../common/Inputs/SelectInput";
import { NavigationItem } from "../../../models/CMS/Homepage/navigationItem";
import { useStore } from "../../../stores/store";

interface Props {
  initialValues?: NavigationItem;
  onSubmit: (data: NavigationItem) => void;
}

export default observer(function NavigationForm({
  onSubmit,
  initialValues,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const initValues: NavigationItem = initialValues || {
    id: v4(),
    parentId: null,
    linkPath: "",
    order: 0,
    translations: [
      {
        id: v4(),
        title: "",
        languageId: "MK",
      },
      { id: v4(), title: "", languageId: "EN" },
      { id: v4(), title: "", languageId: "SQ" },
    ],
  };

  const {
    navigationStore: { parentNavigationDropdown, loadNavigation },
  } = useStore();

  React.useEffect(() => {
    loadNavigation();
  }, [loadNavigation]);

  return (
    <>
      <Segment>
        <Formik
          initialValues={initValues}
          enableReinitialize
          onSubmit={(values) => {
            console.log(values);

            onSubmit(values);
          }}
        >
          {({ errors, values, submitForm }) => (
            <Form autoComplete="off">
              <>
                <FieldArray name="translations">
                  {() => {
                    const translations = values.translations || [];
                    const panes = translations
                      .sort((a, b) => {
                        if (a.languageId === "MK") return -1;
                        if (b.languageId === "MK") return 1;
                        if (a.languageId === "EN") return 1;
                        if (b.languageId === "EN") return -1;

                        return 0;
                      })
                      .map((x, index) => {
                        return {
                          menuItem: x.languageId,
                          render: () => (
                            <Tab.Pane key={x.languageId}>
                              <Segment>
                                <MyTextInput
                                  name={`translations.${index}.title`}
                                  label={t("navigation-title")}
                                />
                              </Segment>
                            </Tab.Pane>
                          ),
                        };
                      });
                    return <Tab panes={panes} />;
                  }}
                </FieldArray>
                <Segment>
                  <MyTextInput name="linkPath" label={t("link-path")} />
                  <MyTextInput name="order" label={t("order")} />
                  <SelectInput
                    name="parentId"
                    options={parentNavigationDropdown}
                    label={t("parent-category")}
                  />
                </Segment>
                <Segment>
                  <Button
                    color="red"
                    onClick={() => {
                      navigate("/admin/homepage/nav/list");
                    }}
                  >
                    <Icon name="arrow left" />
                  </Button>
                  <Button
                    color="teal"
                    type="submit"
                    onClick={() => {
                      submitForm();
                      navigate("/admin/homepage/nav/list");
                    }}
                  >
                    {t("save")}
                  </Button>
                </Segment>
              </>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
