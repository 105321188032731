import moment from "moment";
import { EnsureDate } from "../../common/types/TypeFunctions";
import { City } from "../Regions/City";
import { formTypesToString, FormValue } from "./SharedFunctions";

export interface ConstructionWorksInterface {
  id?:string;
  idHeader?: string;
  cityId: number | null;   //bzr
  city?: City | null;
  employerName: string;
  employerAddress: string;
  streetNumber: string;
  employerPlace: string;
  employerMunicipality: string;
  employerPostalCode: string;
  tradeNumber: string;
  date: Date | null;
  place: string;
  taxNumber: string;
  bankAccount: string;
  bankName: string;
  fullname: string;
  embg: string;
  address: string;
  phoneNumber: string;
  email: string;
  workActivity: string;
  startingDate: Date | null;
  safetyAtWork: string;
  name: string;
  dateSubmitted?: Date | null;
  employeesNumber: number | null;
  workEquipment: string;
  submitterFullname: string,
  submitterWorkPosition: string,
  submitterPhoneNumber: string,
  submitterEmail: string,
  userName?:string
  userId?:string
}

export  class ConstructionWorks implements ConstructionWorksInterface{
  id?: string | undefined;
  userName?: string | undefined;
  userId?: string | undefined;
  dateSubmitted?: Date | null = null;
  idHeader?: string | undefined;
  cityId: number | null = null;
  city: City | null = null;
  employerName: string = "";
  employerAddress: string = "";
  streetNumber: string = "";
  employerPlace: string = "";
  employerMunicipality: string = "";
  employerPostalCode: string = "";
  tradeNumber: string = "";
  date: Date | null = null;
  place: string = "";
  taxNumber: string = "";
  bankAccount: string = "";
  bankName: string = "";
  fullname: string = "";
  embg: string = "";
  address: string = "";
  phoneNumber: string = "";
  email: string = "";
  workActivity: string = "";
  startingDate: Date | null = null;
  safetyAtWork: string = "";
  name: string = "";
  employeesNumber: number | null = null;
  workEquipment: string = "";
  submitterFullname: string = "";
  submitterWorkPosition: string = "";
  submitterPhoneNumber: string = "";
  submitterEmail: string = "";

  constructor(init: ConstructionWorksInterface){
    Object.assign(this,init);
    this.dateSubmitted = EnsureDate(this.dateSubmitted);
  }

  filteredObjectKeys() {
    const keys = Object.keys(this);
    const filterOut = ['id', 'cityId', 'idHeader', 'userId', "employerOccupation"];
    return keys.filter(x => !filterOut.some(y => y === x));
  }

  toStringArray() {
    return this.filteredObjectKeys().map((key) => {
      let value: FormValue = this[key as keyof ConstructionWorksInterface];
      [key, value] = formTypesToString(key, value);

      return {
        key,
        value
      }
    });
  }

}




export interface  ConstructionWorksHeader {
  id: string;
  dateSubmitted: Date | null;
  detailsHistory: ConstructionWorksInterface[];
  latestVersion: ConstructionWorksInterface;
  status: string;
  statusId:string;
 
}
