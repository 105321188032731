import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Input, Search, SearchResultData } from "semantic-ui-react";
import { useStore } from "../../stores/store";

interface Props {
  name: string;
  setFieldValue: (fieldName: string, searchValue: any) => void;
  placeholder?: string;
  source: any[];
  defaultValue?: number | null;
}

export default observer(function SearchInput({
  setFieldValue,
  placeholder,
  source,
  name,
  defaultValue
}: Props) {
  const [results, setResults] = useState<any[]>([]);
  const [locked, setLocked] = useState<boolean>(false);
  const [searchFieldValue, setSearchFieldValue] = useState("");

  useEffect(()=> {
    if(defaultValue){
      setLocked(true);
      const obj = source.find(x=> x.value === defaultValue)
      setSearchFieldValue(obj.title || obj.description);
      setFieldValue(name, defaultValue);
    }
  },[defaultValue, setLocked, setSearchFieldValue, setFieldValue, source, name])


  const handleSearchChange = (e: any, { value }: any) => {
    setSearchFieldValue(value);

    setTimeout(() => {
      if (value.length < 1) {
        setSearchFieldValue("");
        setFieldValue(name,undefined)
        return true;
      }
      const re = new RegExp(_.escapeRegExp(value), "i");
      const isMatch = (result: any) =>
        re.test(result.title) || re.test(result.description);
      setResults(_.filter(source, isMatch));
    }, 300);
  };

  return (
    <>
      <style>
        {`
          .search-style .ui.input{
            width: 100% !important; 
            opacity: 1 !important
          }
          .search-style .ui.input.disabled input{
            opacity: 0.45 !important
          }
          .search-style {
            width: 100% !important;
          }
        `}
      </style>
      <div className="search-style">
      {locked && <><Input type='text' disabled value={searchFieldValue} 
      action={{
        color: 'red',
        icon: 'close',
        onClick: (ev:any)=>{
          ev.preventDefault()
          setFieldValue(name, null);
          setSearchFieldValue('');
          setLocked(false);
        } 
      }}
      />
      
      </>}
      {!locked && <Search
        
        value={searchFieldValue}
        placeholder={placeholder}
        onResultSelect={(e, { result }) => {
          setFieldValue(name, result.value);
          setSearchFieldValue(result.description);
          setLocked(true);
        }}
        onSearchChange={_.debounce(handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        options={source}
      /> }
      </div>
    </>
  );
});
