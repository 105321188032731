import { t } from "i18next";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminHeader from "../../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { TaxonomyTerm } from "../../../../../app/models/CMS/taxonomyTerm";
import { useStore } from "../../../../../app/stores/store";
import PostCategoryForm from "../../components/PostCategoryForm";

const singularName = "Page";
const pluralName = "Pages";
const linkPath = "/admin/pages";
const postType = "page";
const postTaxonomyType = "page_categories";
const iconName = "pin";

export default observer(function PageCategoryEdit() {
  const { id } = useParams();
  const {
    postCategoryStore: {
      updatePostCategory,
      loadingInitial,
      loadPostCategory,
      clearSelectedCategory,
      selectedCategory,
      loadPostCategories,
    },
  } = useStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    loadPostCategories(postTaxonomyType).then((x) => {
      if (id) loadPostCategory(id);
    });

    return () => clearSelectedCategory();
  }, [loadPostCategory, id, clearSelectedCategory, loadPostCategories]);

  const onSubmit = (values: TaxonomyTerm) => {
    values.taxonomySlug = postTaxonomyType;
    toast.info("Success");
    updatePostCategory(values).then(() =>
      navigate(`${linkPath}/category/list`)
    );
  };

  if (loadingInitial || !selectedCategory) return <LoadingComponent />;

  return (
    <>
      <AdminHeader
        header={t("edit-page-category")}
        icon={iconName}
        iconStyles={{ transform: "rotateZ(45deg)" }}
      />
      <PostCategoryForm
        onSubmit={onSubmit}
        goBack={() => navigate(`${linkPath}/category/list`)}
        initialValues={selectedCategory}
      />
    </>
  );
});
