import React from "react";
import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";
import "./InputStyle.css";

interface Props {
  placeholder?: string;
  name: string;
  label?: string;
  type?: string;
  info?: string;
}

export default function MyTextInput(props: Props) {
  const [field, meta] = useField(props.name);
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      {props.label && (
        <>
          <label className="label">{props.label}</label>
        </>
      )}
      <input
        {...field}
        {...props}
        value={field.value}
        className="input-forms"
        autoComplete="off"
      />
      {meta.touched && !!meta.error ? (
        <Label basic color="red" style={{ marginTop: "3px" }}>
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}
