import { observer } from "mobx-react-lite";
import { SubMenu } from "react-pro-sidebar";
import { useStore } from "../../../stores/store";
import { isUserAuthorizedForPage } from "./AuthorizedAdminPages";

interface Props {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  title: string;
  name: string;
  defaultOpen?: boolean;
  open?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  firstchild?: boolean;
  popperarrow?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export default observer(function AuthorizedSubMenu(props: Props) {
  const {
    userStore: { user },
  } = useStore();
  
  if (!!user && !isUserAuthorizedForPage(props.name, user.role || "")) {
    return <></>;
  }

  return (
    <SubMenu title={props.title} icon={props.icon}>
      {props.children}
    </SubMenu>
  );
});
