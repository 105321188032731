import React from 'react'
import { Header, Segment } from 'semantic-ui-react'

interface Props {
    content: string;
}

export default function ELearningHeader({content} : Props) {

    return (
        <>
            <Segment style={{background:'whitesmoke'}}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '20%', marginTop: '13px', marginLeft: '5px' }}>
                        <div className="logo-dit-elearning">
                            <img
                                src={"/images/dit_logo.png"}
                                alt="Државен Инспекторат за труд"
                            />
                        </div>
                    </div>
                    <div style={{ width: '65%' }}>
                        <Header as={'h2'}
                            textAlign='center'
                            attached="top"
                            image='/images/icons/school.png'
                            style={{ border: 'none', background:'whitesmoke' }}
                            content={content}
                        />
                    </div>
                    <div style={{ width: '15%' }}>
                        <img
                            src={"/images/footerImages/Ilo_logo.png"}
                            alt="Ilo logo"
                            style={{ width: '150px', padding: '10px' }}
                        /> 
                    </div>
                </div>
            </Segment>
        </>
    )
}