import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { Segment, Button, ButtonGroup } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { NightShiftInterface } from "../../../app/models/Forms/NightShiftInterface";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import agent from "../../../app/api/agent";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import NightForm from "../commonForms/Reports/NightForm";

// const initialValues: NightShiftInterface = {
//   cityId: null,
//   employerName: "",
//   employerAddress: "",
//   employerMunicipality: "",
//   employerPostalCode: "",
//   employerOccupation: null,
//   workplaceLocation: "",
//   startTime: null,
//   endTime: null,
//   submitterFullname: "",
//   submitterWorkPosition: "",
//   submitterPhoneNumber: "",
//   submitterEmail: "",
//   // date: null,
// };

export default observer(function NightShiftEdit() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { codesStore, formStore } = useStore();
  const { citiesDropdown, loadNkds, loadCities, nkdDropdown } = codesStore;
  const { selectedNightShiftForm, loadSingleNightShiftForm } = formStore;

  useEffect(() => {
    if (id) loadSingleNightShiftForm(id);
    loadCities();
    loadNkds();
  }, [loadCities, loadNkds, loadSingleNightShiftForm, id]);

  if (!citiesDropdown || !nkdDropdown || !selectedNightShiftForm) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: NightShiftInterface,
    actions: FormikHelpers<NightShiftInterface>
  ) => {
    try {
      await agent.Forms.updateNightShift(values).then(() => {
        toast.success(t("successfully-edited-report!"));
      });
    } catch {
      toast.error(t("failed-to-send-report!"));
      return;
    }
  };

  return (
    <>
      <AdminHeader header={t("edit-night-shift")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedNightShiftForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedNightShiftForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedNightShiftForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>

      <NightForm
        handleSubmit={handleSubmit}
        citiesDropdown={citiesDropdown}
        nkdDropdown={nkdDropdown}
        initialValues={selectedNightShiftForm}
      />
    </>
  );
});
