import { Markup } from 'interweave';
import React from 'react'
import { eSlideContent } from '../../../app/models/ELearning/eSlide';
import AccordionElement from '../components/AccordionElement/AccordionElement';

interface Props {
    slideContents?: eSlideContent[];
    colorSchemeArray: string[];
}

export default function AccordionParagraph1({ slideContents, colorSchemeArray }: Props) {

    const headerSticky = slideContents?.find(x => x.type.typeName === 'Header-sticky')?.body;
    // const paragraph = slideContents?.find(x => x.type.typeName === 'Paragraph')?.body;
    const paragraph = sortedContentArray( slideContents, 'Paragraph')

    const accordionItems = sortedContentArray(slideContents, 'Accordion-Item');
    const backgroundColor = colorSchemeArray[0];

    return (
        <>
            <div className='slide-img-par-1'>
                <div className='header-sticky' style={{backgroundColor}}>
                    {headerSticky}
                </div>
                <div className='paragraph-accordion'>
                    {/* <Markup content={paragraph} /> */}
                     {paragraph?.map(x=>x.body.replaceAll("<p>", " ").replaceAll("</p>", " ").replaceAll('•',' '))}
                </div>
                <div>
                    {accordionItems.map(x => (<AccordionElement  backgroundColor={colorSchemeArray[0] } header={`${x.header}`}
                    content={`${x.body}`.replaceAll("<p>", " ").replaceAll("</p>", " ")} />))}
                </div>
            </div>
        </>
    )
}

function sortedContentArray(slideContents: eSlideContent[] | undefined, contentTypeName: string) {
    return slideContents?.filter(x => x.type.typeName === contentTypeName).sort((a, b) => a.contentOrder - b.contentOrder) || [];
}