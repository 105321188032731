import { MdKeyboardArrowDown } from "react-icons/md";
import { Languages } from "../../../../common/types/CustomTypes";
import { NavigationItem } from "../../../../models/CMS/Homepage/navigationItem";
import { useNavigate } from "react-router-dom";

interface Props {
  setActive: (a: number) => void;
  active: number;
  setTitleOrder: (a: number) => void;
  titleOrder: number;
  x: NavigationItem;
  language: Languages;
}

const HeaderInfoItemMobile = ({
  setActive,
  active,
  setTitleOrder,
  titleOrder,
  x,
  language,
}: Props) => {
  const navigate = useNavigate();
  const mkTrans = x.translations?.find((x) => x.languageId === "MK");
  const sqTrans = x.translations?.find((x) => x.languageId === "SQ");
  const enTrans = x.translations?.find((x) => x.languageId === "EN");

  return (
    <li
      onClick={(ev) => {
        ev.stopPropagation();

        if (x.children && x.children.length) {
          if (titleOrder === x.order) {
            if (active === 1) {
              setActive(-1);
            } else {
              setActive(1);
            }
          } else {
            setActive(1);
          }
          setTitleOrder(x.order); 
        } else {
          navigate(x.linkPath);
        }
      }}
    >
      
      <div className="dropdown-title">
        {x.translations?.find((x) => x.languageId === language)?.title}
        <MdKeyboardArrowDown
          size={15}
          style={{
            display: x.children && x.children.length > 0 ? "block" : "none",
          }}
        />
      </div>
      {x.children && x.children.length > 0 && (
        <ul
          className={`dropdown ${
            x.order === titleOrder && active === 1 ? "open" : ""
          }`}
        >
          {x.children
            .sort((a, b) => a.order - b.order)
            .map((y) => (
              <li key={y.id}
                onClick={(ev) => {
                  ev.stopPropagation();
                  navigate(y.linkPath);
                }}
              >
                {y.translations?.find((z) => z.languageId === language)?.title}
              </li>
            ))}
        </ul>
      )}
    </li>
  );
};
export default HeaderInfoItemMobile;
