import { useState } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { CompactPicker } from "react-color";
import { Button, Input, Label } from "semantic-ui-react";
interface Props {
  placeholder?: string;
  name: string;
  label?: string;
  type?: string;
  info?: string;
}

export default function ColorPicker(props: Props) {
  const [editMode, setEditMode] = useState(false);
  const [field, meta, helpers] = useField(props.name);
  const { t } = useTranslation();

  return (
    <>
      <Label>{props.placeholder || t("choose-color")}</Label>
      {!editMode && (
        <>
          <Input type="text" disabled value={field.value} />
          <Button icon="edit" onClick={() => setEditMode(true)} />
          <div
            style={{
              backgroundColor: field.value,
              display: "inline-block",
              height: "20px",
              width: "20px",
              border: "1px solid black",
            }}
          ></div>
        </>
      )}
      {!!editMode && (
        <div style={{ marginBottom: "10px" }}>
          <CompactPicker
            onChange={(col) => {
              setEditMode(false);
              helpers.setValue(col.hex);
            }}
          />{" "}
        </div>
      )}
    </>
  );
}
