import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container } from "semantic-ui-react";
import "./MainFooter.css";

const MainFooter = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="background-red">
      <Container>
        <div className="footer_above_line">
          <div className="logo_text">
            <div className="logo">
              <img
                src={"/images/LOGO_white.png"}
                alt="Државен Инспекторат за труд"
              />
              <div className="vertical_logo"></div>
              <div style={{ lineHeight: "130%" }}>
                <Trans
                  i18nKey="state-labor-inspectorate"
                  components={{ newLine: <br /> }}
                />
              </div>
            </div>

            <div className="footer-paragraph">
              <p>
                <Trans
                  i18nKey="paragraph-text"
                  components={{ newLine: <br /> }}
                />
              </p>
            </div>
          </div>
          <div className="footer-img-links">
            <div className="img-links-1">
              <img src={"/images/footerImages/Tel_logo.png"} alt="Tel logo" />
              {/* <a href="https://www.ilo.org/global/lang--en/index.htm">
                <img
                  src={"/images/footerImages/Ilo_logo.png"}
                  alt="Ilo logo"
                  style={{ paddingTop: "20px" }}
                />
              </a> */}
              {/* <a href="http://av.gov.mk/">
                <img
                  src={"/images/footerImages/Agencija_logo.png"}
                  alt="Agencija logo"
                  style={{ paddingTop: "20px" }}
                />
              </a> */}
            </div>
            <div className="img-links-2">
              {/* <a href="https://www.vlada.mk/">
                <img
                  src={"/images/footerImages/Vlada_logo.png"}
                  alt="Vlaga logo"
                />
              </a> */}
              {/* <a href="https://sobranie.mk/">
                <img
                  src={"/images/footerImages/Sobranie_logo.png"}
                  alt="Sobranie logo"
                />
              </a> */}
            </div>
          </div>
        </div>

        <hr className="hr" />
        <div className="funded-by-eu">
          <div className="funded-eu-logo">
            <img
              src={"/images/footerImages/EN-Funded by the EU-PANTONE.jpg"}
              alt="Funded by the EU"
            />
          </div>
          <div>
            <p>
              <Trans
                i18nKey="paragraph-financial-support"
                components={{ newLine: <br /> }}
              />
            </p>
          </div>
          <div className="ilo-logo-footer">
            <a href="https://www.ilo.org/" target="_blank" rel="noreferrer" >
            <img
              // className="ilo-logo-size"
              style={{ width: "159px", height: "auto", paddingLeft: "20px" }}
              src={"/images/footerImages/Ilo_logo.png"}
              alt="Ilo Logo"
            />
            </a>
          </div>
        </div>
        <div className="copyright">
          Copyright &copy; 2022 {t("copyright-state-labor-inspectorate")}
          <div className="vl"></div>
          <a href="/single/page/Politika-za-privatnost">
            {t("privacy-policy")}
          </a>
          <div className="vl"></div>{" "}
          <a href="/single/page/Politika-na-kolacinja">{t("cookie-policy")}</a>
        </div>
      </Container>
    </div>
  );
};

export default MainFooter;
