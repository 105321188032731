import React, { Fragment, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Icon,
  Divider,
  Card,
  Statistic,
  Table,
  Header,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../app/stores/store";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const AdminDashboard = () => {
  const { t } = useTranslation();
  const { statisticStore } = useStore();

  const {
    submittetRegion,
    loadSubmittedRegion,
    loadSubmittedRegionByType,
    submittetRegionType,
    loadProcessedRegion,
    processedRegionStat,
    loadProcessedRegionType,
    processedRegiType,
    loadProcessedGrievance,
    processedRegionGrievance,
  } = statisticStore;

  useEffect(() => {
    loadSubmittedRegion();
    loadSubmittedRegionByType();
    loadProcessedRegion();
    loadProcessedRegionType();
    loadProcessedGrievance();
  }, [
    loadSubmittedRegion,
    loadSubmittedRegionByType,
    loadProcessedRegion,
    loadProcessedGrievance,
    loadProcessedRegionType
  ]);

  return (
    <Fragment>
      <AdminHeader
        icon="dashboard"
        header={t("dashboard")}
        subheader={t("welcome-to-the-admin-dashboard")}
      />
      <Divider horizontal>{t("statistics")}</Divider>
      <Card.Group itemsPerRow={4}>
        <Card
          color="blue"
          style={{ transition: "0.3s all" }}
          className="card-test-d"
          as={Link}
          to="/"
        >
          <Card.Content textAlign="center">
            <Icon
              name="home"
              size="massive"
              style={{ marginBottom: "30px" }}
              color="blue"
            />
            <Card.Header style={{ marginBottom: "20px" }}>
              <Trans
                i18nKey="State-labor-inspectorate"
                components={{ newLine: <br /> }}
              />
            </Card.Header>
            <Card.Description>{t("to-the-application")}</Card.Description>
          </Card.Content>
        </Card>

        <Card color="red">
          <Card.Content>
            <Card.Header>{t("submitted-forms-by-region")}</Card.Header>
            <Card.Description>
              <Statistic.Group horizontal size="tiny">
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top" as const,
                      },
                      title: {
                        display: true,
                      },
                    },
                  }}
                  data={{
                    labels:
                      submittetRegion && submittetRegion.map((x) => x.text),
                    datasets: [
                      {
                        label: t("submitted-forms"),
                        data:
                          submittetRegion &&
                          submittetRegion.map((x) => x.value),
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                      },
                    ],
                  }}
                />
              </Statistic.Group>
            </Card.Description>
          </Card.Content>
        </Card>
        <Card color="green">
          <Card.Content>
            <Card.Header>{t("processed-forms-by-region")}</Card.Header>
            <Card.Description>
              <Statistic.Group horizontal size="tiny">
                <Bar
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top" as const,
                      },
                      title: {
                        display: true,
                      },
                    },
                  }}
                  data={{
                    labels:
                      processedRegionStat &&
                      processedRegionStat?.map((x) => x.text),
                    datasets: [
                      {
                        label: t("submitted-forms"),
                        data:
                          processedRegionStat &&
                          processedRegionStat.map((x) => x.value),
                        backgroundColor: "rgba(255, 206, 86, 0.2)",
                      },
                    ],
                  }}
                />
              </Statistic.Group>
            </Card.Description>
          </Card.Content>
        </Card>
        <Card color="yellow">
          <Card.Content>
            <Card.Header>{t("processed-complaints")}</Card.Header>
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top" as const,
                  },
                  title: {
                    display: true,
                  },
                },
              }}
              data={{
                labels:
                  processedRegionGrievance &&
                  processedRegionGrievance?.map((x) => x.text),
                datasets: [
                  {
                    label: t("processed-complaints"),
                    data:
                      processedRegionGrievance &&
                      processedRegionGrievance.map((x) => x.value),
                    backgroundColor: "rgba(201, 132, 132, 0.8)",
                  },
                ],
              }}
            />
          </Card.Content>
        </Card>
      </Card.Group>
      <Card.Group itemsPerRow={2}>
        {!!submittetRegionType && (
          <Card color="yellow">
            <Card.Content>
              <>
                <Card.Header>
                  {t("filed-forms-by-region-and-form-type")}
                </Card.Header>
                {_.map(_.groupBy(submittetRegionType, "type"), (value, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Header content={t(key)} />
                      <Table compact>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{t("Region")}</Table.HeaderCell>
                            <Table.HeaderCell>
                              {t("submitted-forms")}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {value.map((x, i) => (
                            <Table.Row key={i}>
                              <Table.Cell>{x.text}</Table.Cell>
                              <Table.Cell>{x.value}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </React.Fragment>
                  );
                })}
              </>
            </Card.Content>
          </Card>
        )}
        {!!processedRegiType && (
          <Card color="yellow">
            <Card.Content>
              <>
                <Card.Header>
                  {t("processed-forms-by-region-and-form-type")}
                </Card.Header>
                {_.map(_.groupBy(processedRegiType, "type"), (value, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Header content={t(key)} />
                      <Table compact>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{t("Region")}</Table.HeaderCell>
                            <Table.HeaderCell>
                              {t("submitted-forms")}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {value.map((x,i) => (
                            <Table.Row key={i}>
                              <Table.Cell>{x.region}</Table.Cell>
                              <Table.Cell>{x.noOfBrojs}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </React.Fragment>
                  );
                })}
              </>
            </Card.Content>
          </Card>
        )}
      </Card.Group>
    </Fragment>
  );
};
export default observer(AdminDashboard);
