import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Label, Message, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import MainFooter from "../../../app/layout/MainFooter";
import MainHeader from "../../../app/layout/MainHeader/MainHeader";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../forms/inputs/MyTextInput";
import "./LoginForm.css";

export default observer(function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore } = useStore();

  return (
    <>
      <div className="login-form">
        <MainHeader />
        <div>
          <div className="loginForm-position">
            <div className="box-heading red">
              <h2>{t("login")}</h2>
            </div>
            <Segment className="loginForm-segment" padded>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  error: null,
                  token: "",
                  username: "",
                }}
                onSubmit={(values, { setErrors }) =>
                  userStore
                    .login(values)
                    .then((role) => {
                      if (role === "learner") {
                        navigate("/e-learning/dashboard");
                        return;
                      }
                      navigate("/admin");
                    })
                    .catch((error) =>
                      setErrors({ error: "Invalid email or password" })
                    )
                }
              >
                {({ handleSubmit, isSubmitting, errors }) => (
                  <Form
                    className="ui form"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <MyTextInput name="email" placeholder={t("e-mail")} />
                    <MyTextInput
                      name="password"
                      placeholder={t("password")}
                      type="password"
                    />
                    <ErrorMessage
                      name="error"
                      render={() => (
                        <Label
                          style={{ maginBottom: "10px" }}
                          basic
                          color="red"
                          content={errors.error}
                        />
                      )}
                    />
                    <Button
                      loading={isSubmitting}
                      className="login-btn"
                      content={t("login")}
                      type="submit"
                      fluid
                    />
                  </Form>
                )}
              </Formik>
            </Segment>
          </div>
        </div>
      </div>
      <footer>
        <MainFooter />
      </footer>
    </>
  );
});
