import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Button,
  ButtonProps,
  Header,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";
import DocumentTable from "../../../table/DocumentTable";

interface Props {
  cancel: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => void;
  success: (documentName: string, documentPath: string) => void;
}

export default function ModalEditorDocument({ success, cancel }: Props) {
  const { t } = useTranslation();
  const [documentPath, setDocumentPath] = React.useState("");
  const [documentName, setDocumentName] = React.useState("");
  return (
    <>
      <Header icon>
        <Icon name="save" />
        {t("add-document")}
      </Header>
      <Modal.Content>
        <Input
          type="text"
          label={t("document-link-label")}
          value={documentName}
          onChange={(e, data) => setDocumentName(data.value.toString())}
          fluid
        />
        <DocumentTable
          selectedPath={documentPath}
          setDocumentPath={setDocumentPath}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          inverted
          onClick={() => {
            if (!documentName) {
              toast.error(t("please-select-label-name"));
              return;
            }

            if (!documentPath) {
              toast.error(t("please-select-document"));
              return;
            }

            const docPath = `/media/documents/${documentPath}`;
            success(documentName, docPath);
          }}
        >
          <Icon name="check" />
        </Button>
        <Button color="red" inverted onClick={cancel}>
          <Icon name="remove" />
        </Button>
      </Modal.Actions>
    </>
  );
}
