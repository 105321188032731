import React, { useEffect, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Table, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import UserTableRow from "./UserTableRow";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../../app/stores/store";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import ModalDialog from "../../../../app/common/modals/ModalDialog";

export default observer(function UsersList() {
  const { t } = useTranslation();
  const { userStore, modalStore } = useStore();
  const { openModal, closeModal} = modalStore;
  const {
    loadUsers,
    sortedUsers,
    loadingInitial,
    deleteUser,
    target,
    loading,
  }  = userStore;
  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  if (loadingInitial)
    return <LoadingComponent content={t("loading-users...")} />;

  return (
    <Fragment>
      <AdminHeader
        icon="users"
        header={t("Users")}
        subheader={t("list-of-authorized-application-users")}
      />
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Username")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Email")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Region-BZR")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Region-RO")}</Table.HeaderCell>
            <Table.HeaderCell>{t("Actions")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedUsers.map((user) => (
            <UserTableRow
              key={user.id}
              user={user}
              submitting={loading}
              target={target || ""}
              deleteUser={() => {
                openModal(
                <ModalDialog 
                cancel={closeModal}
                noText='Cancel'
                confirm={() => {
                deleteUser(user.id);
                closeModal();
              }}
              title={t(
                "are-you-sure-you-want-to-delete-this-user"
              )}
              description={t(
                "once-you-delete-the-user-the-data-will-be-lost-forever"
              )}
                />
                )
              }}
            />
          ))}
        </Table.Body>
      </Table>
      <div style={{ textAlign: "right" }}>
        <Link to="/admin/users/create" className="ui button teal">
          <Icon name="plus" />
          {t("add-new-user")}
        </Link>
      </div>
    </Fragment>
  );
});
