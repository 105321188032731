import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  NightShift,
  NightShiftInterface,
} from "../../../app/models/Forms/NightShiftInterface";
import { useStore } from "../../../app/stores/store";
import JsonView from "./JsonView";

export default observer(function NightShiftView() {
  const { formStore } = useStore();

  const { selectedNightShiftForm, loadSingleNightShiftForm, loadingInitial } =
    formStore;
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) loadSingleNightShiftForm(id);
  }, [loadSingleNightShiftForm, id]);


  if (loadingInitial || !selectedNightShiftForm) {
    return <LoadingComponent />;
  }

  

  const jsonArr = new NightShift(selectedNightShiftForm).toStringArray();

  


  return (
    <>
      <AdminHeader header={t("view-night-shift")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedNightShiftForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedNightShiftForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedNightShiftForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <JsonView arrJSONData={jsonArr} />
    </>
  );
});
