import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { NavigationItem } from "../../../models/CMS/Homepage/navigationItem";
import { useStore } from "../../../stores/store";
import LoadingComponent from "../../LoadingComponent";
import AdminHeader from "../AdminHeader";
import NavigationForm from "./NavigationForm";

export default observer(function EditNavigation() {
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    navigationStore: {
      updateNavigation,
      loadingInitial,
      loadSingleNavigation,
      selectedNavItem,
    },
  } = useStore();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (id) {
      loadSingleNavigation(id);
    }
  }, [loadSingleNavigation, id]);

  if (loadingInitial || !selectedNavItem) {
    return <LoadingComponent />;
  }

  const onSubmit = (values: NavigationItem) => {
    updateNavigation(values).then(() => {
      navigate("/admin/homepage/nav/list");
    });
  };

  return (
    <>
      <AdminHeader header={t("edit-navigation")} icon="location arrow" />
      <NavigationForm onSubmit={onSubmit} initialValues={selectedNavItem} />
    </>
  );
});
