import * as yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const StartingActivitySchema = yup.object().shape({
  cityId: yup.number().required("Ова поле е задолжително").nullable(),
  employerName: yup.string().required("Ова поле е задолжително").max(255, "Називот не смее да надминува повеќе од 255 карактери"),
  employerAddress: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  streetNumber: yup.string().required("Ова поле е задолжително").max(255, "Бројот не смее да надминува повеќе од 255 карактери"),
  employerPlace: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employerMunicipality: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  employerPostalCode: yup.string().required("Ова поле е задолжително").max(50, "Бројот не смее да надминува повеќе од 50 карактери"),
  legalEntityName: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  embs: yup.string().required("Ова поле е задолжително").max(100, "Бројот не смее да надминува повеќе од 100 карактери"),
  date: yup.date().required("Одберете датум").nullable(),
  place: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  taxNumber: yup.string().required("Ова поле е задолжително").max(100, "Бројот не смее да надминува повеќе од 100 карактери"),
  bankAccount: yup.string().required("Ова поле е задолжително").max(100, "Бројот не смее да надминува повеќе од 100 карактери"),
  bankName: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  fullname: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  address: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Внесете валиден телефонски број")
    .required("Ова поле е задолжително").max(50, "Телефонскиот број не смее да надминува повеќе од 50 карактери"),
  email: yup
    .string()
    .email("Внесете валидна e-mail адреса")
    .required("Ова поле е задолжително").max(100, "Електронската пошта не смее да надминува повеќе од 100 карактери"),
  workActivity: yup.number().required("Ова поле е задолжително").nullable(),
  workplaceAddress: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  startingDate: yup
    .date()
    .required("Одберете датум на започнување со дејност")
    .nullable(),
  submitterFullname: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  submitterWorkPosition: yup.string().required("Ова поле е задолжително").max(255, "Текстот не смее да надминува повеќе од 255 карактери"),
  submitterPhoneNumber: yup
    .string()
    .matches(phoneRegExp, "Внесете валиден телефонски број")
    .required("Ова поле е задолжително").max(50, "Телефонскиот број не смее да надминува повеќе од 50 карактери"),
  submitterEmail: yup
    .string()
    .email("Внесете валидна e-mail адреса")
    .required("Ова поле е задолжително").max(100, "Бројот не смее да надминува повеќе од 100 карактери"),
});
