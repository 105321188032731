import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DropdownItemProps, Select } from "semantic-ui-react";
import agent from "../../app/api/agent";
import ReportTable from "../../app/common/table/ReportTable";
import AdminHeader from "../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../app/layout/LoadingComponent";

export default function LearningProgressReport() {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [chosenTrainingId, setChosenTrainingId] = useState<string | undefined>();
  const [trainingOptions, setTrainingOptions] = useState<DropdownItemProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    agent.Learning.listTrainings().then(data => {
      setTrainingOptions(data.map(x => {
        return {
          value: x.id,
          text: x.trainingName
        }
      }));
      setIsLoading(false);
    })
  }, [setTrainingOptions]);

  if (isLoading) return <LoadingComponent />

  return (<>
    <AdminHeader icon="list" header={t('view-training-progress')} />

    <Select onChange={(ev, data) => {
      if (data.value) {
        setIsLoading(true);
        setChosenTrainingId(data.value.toString())
        agent.Reports.finishedTrainings(data.value.toString()).then(x => {
          setData(x.map(x => {
            x.completed = t(x.completed)
            return x
          }));
        })
          .catch((err) => {
            toast.error(err);
            setChosenTrainingId(undefined);
          })
          .finally(() => setIsLoading(false));
      }
    }}
      value={chosenTrainingId}
      options={trainingOptions}
      placeholder={t("choose-training")}
    />

    {!!data && !!data.length &&
      <ReportTable
        data={data}
        meta={[
          {
            key: "id",
            type: "hide",
            value: true
          },
          {
            key: "userName",
            type: "colName",
            value: t("user-name")
          },
          {
            key: "fullName",
            type: "colName",
            value: t("full-name")
          },
          {
            key: "completed",
            type: "colName",
            value: t("report-completed")
          },
          {
            key: "validFrom",
            type: "colName",
            value: t("valid-from")
          },
          {
            key: "validUntil",
            type: "colName",
            value: t("valid-until")
          }
        ]}
      />}
  </>)
}