import React from 'react'
import { RiInformationFill } from 'react-icons/ri';
import './StairCase.css'

interface Props {
  backgroundColor: string;
  text: string;
  onClick: () => void;
  offsetTop: number;
  offsetRight: number;
}


export default function StairCaseItem({ backgroundColor, text, onClick, offsetTop, offsetRight }: Props) {
  const top = `${offsetTop}px`
  const right = `${offsetRight}px`
  return (
    <div style={{ backgroundColor, top, right }} className='stair-box' onClick={onClick}>
      <div className='stair-box-text'>
        {text}
      </div>
      <div className='stair-box-icon' >
        <RiInformationFill size={24} />
      </div>
    </div>
  )
}