import React from "react";
import { NewsCard } from "./NewsCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { CategorySearch } from "./CategorySearch";
import MainFooter from "../../app/layout/MainFooter";
import MainHeader from "../../app/layout/MainHeader/MainHeader";
import "./CategoryPage.css";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import { Container, Divider, Segment } from "semantic-ui-react";
import ReportTablePaginationCategoryCustom from "../../app/common/table/ReportTablePaginationCategoryCustom";

export default observer(function CategoryPage() {
  const { postType, slug } = useParams();
  const { t } = useTranslation();
  const { postStore: { setMultipleStaticFilterParam, pagination, sortedPosts, setPagingParams,
    addMultipleFilterParam, loading, pagingParams }
    , postCategoryStore: { loadPostCategoryBySlug, selectedCategory }
    , commonStore: { language }
  } = useStore();
  React.useEffect(() => {
    if (postType && slug) {
      loadPostCategoryBySlug(`${postType}_categories`, slug).then((x) => {
        setMultipleStaticFilterParam([
          { name: 'type', value: postType },
          { name: 'categoryId', value: x.id },
          { name: 'postStatus', value: 'published' }
        ])
      })
    }
  }, [postType, slug, setMultipleStaticFilterParam, loadPostCategoryBySlug])

  const categoryTrans = selectedCategory?.translations.find(x => x.languageId === language);
  const termName = categoryTrans?.termName;
  const termDesc = categoryTrans?.termDescription;

  const handlePerPageChange = (value?: string) => {
    const pageSize = parseInt(value || "0");
    setPagingParams({
        pageNumber: 1,
        pageSize 
    });
};


const handlePaginationChange = (activePage?: string | number) => {
    if (activePage) {
        const pageNumber = parseInt(activePage.toString());
        const { pageSize } = pagingParams;
        setPagingParams({
            pageNumber ,
            pageSize,
        });
    }
};


  return (
    <>
      <div>
        <MainHeader />
      </div>
      <main className="main-style-categoryPage" >
        <div className="heading-wrapper background-grey " style={{ marginBottom: "20px" }}>
        </div>
        <Container>
          <section style={{ marginBottom: '50px', textAlign: "center" }}>
            {/* <Segment  compact loading={loading} style={{margin:'auto'}}> */}
            <CategorySearch
              onSubmit={(values) => {
                const paramsArr = [];
                paramsArr.push({ name: 'keyword', value: values.term })
                paramsArr.push({ name: 'year', value: values.year?.getFullYear().toString() })
                addMultipleFilterParam(paramsArr);
              }} />
            {/* </Segment> */}
          </section>
          <Divider />

          <section>
            <div className="category-news">
              <ul className="category-post-sort">
                {sortedPosts.map(x => (<li>
                  <NewsCard post={x} key={x.id} language={language} />
                </li>
                ))}
              </ul>
            </div>

            <ReportTablePaginationCategoryCustom
                numPages={pagination?.totalPages || 0}
                perPage={pagination?.itemsPerPage || 0}
                activePage={pagination?.currentPage}
                handlePerPageChange={handlePerPageChange}
                handlePaginationChange={handlePaginationChange}
            />

            {/* <div className="category-pagination">
              <div className="category-arrow"
                onClick={(e) => {
                  e.preventDefault();
                  if (pagination && pagination.currentPage > 1) {
                    setPagingParams({
                      pageNumber: pagination!.currentPage - 1,
                      pageSize: -1
                    })
                  }
                }}
              >
                <MdKeyboardArrowLeft
                  size={21}
                  style={{ display: "flex", alignItems: "center" }}
                />
              </div>
              <div className="category-pages">
                {Array.from(Array(pagination?.totalPages).keys()).map(x => (
                  <div className={x + 1 === pagination?.currentPage ? "active" : ''}
                    onClick={(e) => {
                      e.preventDefault();
                      setPagingParams({
                        pageNumber: x + 1,
                        pageSize: -1
                      })
                    }}
                  >
                    {x + 1}
                  </div>
                ))}
              </div>
              <div className="category-arrow"
                onClick={(e) => {
                  e.preventDefault();
                  if (pagination && pagination.currentPage < pagination.totalPages) {
                    setPagingParams({
                      pageNumber: pagination.currentPage + 1,
                      pageSize: -1
                    })
                  }
                }}
              >
                <MdKeyboardArrowRight
                  size={21}
                  style={{ display: "flex", alignItems: "center" }}
                />
              </div>
            </div> */}

          </section>
        </Container>
      </main>
      <footer>
        <MainFooter />
      </footer>
    </>
  );
});
