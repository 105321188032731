import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import { Segment, Button, ButtonGroup } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import agent from "../../../app/api/agent";
import { OvertimeWorkInterface } from "../../../app/models/Forms/OvertimeWorkInterface";
import OvertimeWork from "../commonForms//Reports/OvertimeForm";
import AdminHeader from "../../../app/layout/admin/AdminHeader";

export default observer(function OvertimeWorkEditForm() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {  codesStore, formStore } = useStore();
  const { citiesDropdown, loadCities, loadNkds, nkdDropdown } = codesStore;
  const { selectedOvertimeWorkForm, loadSingleOvertimeWorkForm } = formStore;

  useEffect(() => {
    if (id) loadSingleOvertimeWorkForm(id);
    loadCities();
    loadNkds();
  }, [loadCities, loadNkds, loadSingleOvertimeWorkForm, id]);

  if (!citiesDropdown || !nkdDropdown || !selectedOvertimeWorkForm) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: OvertimeWorkInterface,
    actions: FormikHelpers<OvertimeWorkInterface>
  ) => {
    try {
      await agent.Forms.updateOvertimeWork(values).then(() => {
        toast.success(t("successfully-edited-report!"));
      });
    } catch {
      toast.error(t("failed-to-send-report!"));
      return;
    }
  };

  return (
    <>
      <AdminHeader header={t("edit-overtime-work")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedOvertimeWorkForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedOvertimeWorkForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedOvertimeWorkForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>
      <OvertimeWork
        handleSubmit={handleSubmit}
        citiesDropdown={citiesDropdown}
        nkdDropdown={nkdDropdown}
        initialValues={selectedOvertimeWorkForm}
      />
    </>
  );
});
