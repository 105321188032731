import React, { useEffect } from "react";
import { FormikHelpers } from "formik";
import {
  Segment,
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { WorkOnSundayInterface } from "../../app/models/Forms/WorkOnSundayInterface";
import agent from "../../app/api/agent";
import { FormPDF } from "./pdf/FormPDF";
import { PdfFormFieldInfo } from "./pdf/FormPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useStore } from "../../app/stores/store";
import moment from "moment-timezone";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import "./FormsModal.css";
import { toast } from "react-toastify";
import SundayForm from "./commonForms/Reports/SundayForm";


export default observer(function WorkOnSundayForm() {
  const { modalStore, codesStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const { citiesDropdown, loadCities, loadNkds, nkdDropdown } = codesStore;
  const navigate = useNavigate();

  useEffect(() => {
    loadCities();
    loadNkds();
  }, [loadCities, loadNkds]);

  if (!citiesDropdown || !nkdDropdown) {
    return <LoadingComponent />;
  }

  const handleSubmit = async (
    values: WorkOnSundayInterface,
    actions: FormikHelpers<WorkOnSundayInterface>
  ) => {
    try {
      await agent.Forms.workOnSunday(values);
    } catch {
      toast.error("Неуспешно испратено известување");
      return;
    }

    const city = citiesDropdown.find((x) => x.value === values.cityId)?.text;

    const occupation = nkdDropdown.find(
      (x) => x.value === values.employerOccupation
    )?.description;

    const infoArray: PdfFormFieldInfo[] = [
      {
        inputMainLabel: "1. Назив на работодавачот:",
        inputValue: values.employerName,
      },
      {
        inputMainLabel: "2. Седиште на работодавачот:",
      },
      {
        inputLabel: "Улица, број, место:",
        inputValue: values.employerAddress,
      },
      {
        inputLabel: "Општина:",
        inputValue: values.employerMunicipality,
      },
      {
        inputLabel: "Поштенски број:",
        inputValue: values.employerPostalCode,
      },
      {
        inputMainLabel: "3. Даночен број",
        inputValue: values.taxNumber,
      },
      {
        inputMainLabel: "4. ЕМБС (Единствен матичен број на субјект)",
        inputValue: values.embs,
      },
      {
        inputMainLabel: "5. Дејност на работодавачот (Главна приходна шифра)",
        inputValue: occupation?.toString(),
      },
      {
        inputMainLabel: "Б. Податоци за одговорно лице кај работодавачот",
      },
      {
        inputMainLabel: "6. Име и презиме",
        inputValue: values.responsibleFullname,
      },
      {
        inputMainLabel: "7. Адреса на живеење или престојување",
        inputValue: values.responsibleAddress,
      },
      {
        inputLabel: "Општина:",
        inputValue: values.responsibleMunicipality,
      },
      {
        inputLabel: "Поштенски број:",
        inputValue: values.responsiblePostalCode,
      },
      {
        inputMainLabel: "В. Податоци за работниот процес",
      },
      {
        inputMainLabel: "8. Место на вршење на работниот процес",
        inputValue: values.workPlace,
      },
      {
        inputMainLabel: "9. Опис на работниот процес",
        inputValue: values.workProcess,
      },
      {
        inputMainLabel:
          "За член 134 став (3) алинеи 42 и 43 од Законот за работните односи",
      },
      {
        inputMainLabel: "Алинеја 42",
      },
      {
        inputMainLabel:
          "10. Број и датум на склучен договор за техничка соработка за вршење на придружни и помошни дејности",
      },
      {
        inputLabel: "Број на договор:",
        inputValue: values.contractNumber?.toString(),
      },
      {
        inputLabel: "Датум:",
        inputValue: moment
          .utc(values.contractDate)
          .local()
          .format("D MMMM YYYY")
          .toString(),
      },
      {
        inputMainLabel: "Алинеја 43",
      },
      {
        inputMainLabel: " Работни места на кои ќе се работи во недела:",
        inputValue: values.sundayJobPositions
          .map((x) => {
            return (
              "Работнo место: " +
              x.jobPosition +
              "\n" +
              "Број на извршители: " +
              x.employeesNumber +
              "\n" +
              "Опис на работата од наведеното работно место: " +
              x.workDescription +
              "\n" +
              "Образложение за причините за работното ангажирање: " +
              x.reasons +
              "\n" +
              "Почеток на работно време: " +
              moment
                .utc(x.startTime)
                .local()
                .format("D MMMM YYYY, h:mm A")
                .toString() +
              "\n" +
              "Крај на работно време: " +
              moment
                .utc(x.endTime)
                .local()
                .format("D MMMM YYYY, h:mm A")
                .toString()
            );
          })
          .join(" \n\n "),
      },
      {
        inputMainLabel: "Пополнето од:",
      },
      {
        inputLabel: "Име и презиме:",
        inputValue: values.submitterFullname,
      },
      {
        inputLabel: "Работно место:",
        inputValue: values.submitterWorkPosition,
      },
      {
        inputLabel: "Телефонски број",
        inputValue: values.submitterPhoneNumber,
      },
      {
        inputLabel: "E-mail адреса",
        inputValue: values.submitterEmail,
      },
    ];
    openModal(
      <Segment placeholder>
        <Grid>
          <GridColumn>
            <div>
              <PDFDownloadLink
                document={
                  <FormPDF
                    values={infoArray}
                    certificationLabel={
                      "место за заверка на Државниот инспекторат за труд"
                    }
                    institutionLabel={"Државен инспекторат за труд"}
                    regionLabel={"Подрачје:"}
                    regionValue={`Град ${city}`}
                    subtitle={
                      "(Член 134 Став 2 од Законот за работните односи „Службен весник на Република Македонија“ бр.288/2011)"
                    }
                    title={"ИЗВЕСТУВАЊЕ ЗА ВРШЕЊЕ НА РАБОТА ВО НЕДЕЛА"}
                  />
                }
                fileName="РаботаВоНедела.pdf"
                className="btn-form-submit"
              >
                <p
                  onClick={(e) => e.preventDefault()}
                  className="text-form-modal"
                >
                  Дали сакате да превземете во PDF-формат?
                </p>
                <button
                  className="button-form-modal"
                  onClick={() => {
                    navigate("/");
                    // closeModal();
                  }}
                >
                  Превземи
                </button>
              </PDFDownloadLink>
            </div>
          </GridColumn>
        </Grid>
        <div className="close-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            X
          </button>
        </div>
      </Segment>
    );
  };

  return (
    <SundayForm
      handleSubmit={handleSubmit}
      citiesDropdown={citiesDropdown}
      nkdDropdown={nkdDropdown}
    />
  );
});
