import IframeResizer from "iframe-resizer-react";
import { Markup } from "interweave";
import { Languages } from "../../../app/common/types/CustomTypes";
import { Accordion } from "../../../app/models/CMS/Plugin/accordionItem";
import { ButtonInfo, ContactsInfoLocation, ContentPartTypes, DirectorInfo, HtmlContentPart, IFrameInfo } from "../../../app/models/Plugins/pluginTypes";
import { Styles } from "../../../app/models/styles";
import AccordionPageMarkup from "./AccordionPageMarkup";
import ContactInfoPageMarkup from "./ContactInfoPageMarkup";
import DirectorInfoPageMarkup from "./DirectorInfoPageMarkup";
interface Props {
  contentPart: HtmlContentPart;
  language: Languages;
}

const styles: Styles = {
  button: {
    backgroundColor: "rgb(218, 20, 43)",
    color: 'white',
    border: '1px solid rgb(218, 20, 43)',
    padding: '7px 27px',
    borderRadius: '6px',
    cursor: 'pointer',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)'
  }


}

export default function PageMarkup({ contentPart, language }: Props) {
  if (contentPart.type === ContentPartTypes.NORMAL)
    return <Markup content={contentPart.text} />;

  if (!contentPart.handled)
    return <></>

  switch (contentPart.type) {
    case ContentPartTypes.CONTACT_INFO:
      const contactInfo = (contentPart.info as ContactsInfoLocation);
      return <ContactInfoPageMarkup contactInfoArray={contactInfo.contactsInfos} />;
    case ContentPartTypes.DIRECTOR_INFO:
      const directorInfo = (contentPart.info as DirectorInfo);
      return <DirectorInfoPageMarkup directorInfo={directorInfo} />;
    case ContentPartTypes.ACCORDION:
      const accordionInfo = (contentPart.info as Accordion);
      return <AccordionPageMarkup accordion={accordionInfo} language={language} />;
    case ContentPartTypes.BUTTON:
      const info = (contentPart.info as ButtonInfo);
      return <button style={styles.button} onClick={()=> {window.location.href = info.link} }>{info.name}</button>
    case ContentPartTypes.IFRAME:
      const iframeInfo = (contentPart.info as IFrameInfo);
      const height = parseInt(iframeInfo.height);
      return <IframeResizer
        src={iframeInfo.src}
        scrolling
        style={{ width: '1px', minWidth: `${iframeInfo.width}%`}}
        minHeight={Number.isNaN(height) ? 100 : height}
      />
    case ContentPartTypes.BZR_MAP:
      return <div className="iframe-wrapper">
        <IframeResizer
          src={process.env.REACT_APP_PROD === '0' ? "/mapBzr.html" : "/mapBzrProd.html"}
          allowFullScreen={!1}
          title="mapRo"
          style={{ width: '1px', minWidth: '100%' }}
        />
      </div>
    case ContentPartTypes.RO_MAP:
      return <div className="iframe-wrapper">
        <IframeResizer
          // autoResize={false}
          src={process.env.REACT_APP_PROD === '0' ? "/mapRo.html" : "/mapRoProd.html"}
          allowFullScreen={!1}
          title="mapRo"
          style={{ width: '1px', minWidth: '100%' }}
        />
      </div>
    default:
      return <></>

  }
}