import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Header, Segment } from "semantic-ui-react";
import PhotoWidgetCropper from "./photoWidgetCropper";
import PhotoWidgetDropzone from "./photoWidgetDropzone";

interface Props {
  setAddPhotoMode?: (state: boolean) => void;
  uploadPhoto: (dataUrl: string) => void;
  selectedRatio?: number | undefined;
}

export default function PhotoUploadWidget({
  setAddPhotoMode,
  uploadPhoto,
  selectedRatio,
}: Props) {
  const { t } = useTranslation();
  const [files, setFiles] = useState<any>([]);
  const [cropper, setCropper] = useState<Cropper>();

  function onCrop() {
    if (cropper) {
      const dataUrl = cropper.getCroppedCanvas().toDataURL();
      uploadPhoto(dataUrl);
      if (setAddPhotoMode) setAddPhotoMode(false);
    }
  }
  useEffect(() => {
    return () => {
      files.forEach((file: any) => {
        URL.revokeObjectURL(file.preview);
      });
    };
  }, [files]);

  return (
    <>
      {setAddPhotoMode && (
        <Segment>
          {" "}
          <Button
            icon="caret left"
            content="Back"
            color="teal"
            onClick={() => setAddPhotoMode && setAddPhotoMode(false)}
          />
        </Segment>
      )}
      <p style={{ clear: "both" }} />
      <Header as="h5" attached="top" content={t("step-1-add-photo")} />
      <Segment attached>
        <PhotoWidgetDropzone setFiles={setFiles} />
      </Segment>
      <Header as="h5" attached="top" content={t("step-2-crop-photo")} />
      <Segment attached>
        {files && files.length > 0 && (
          <PhotoWidgetCropper
            key={selectedRatio}
            setCropper={setCropper}
            imagePreview={files[0].preview}
            aspectRatio={selectedRatio}
          />
        )}
      </Segment>
      <Header as="h5" attached="top" content={t("step-3-upload-photo")} />
      <Segment attached style={{ textAlign: "center" }}>
        {files && files.length > 0 && (
          <>
            <div
              className="img-preview"
              style={{
                minHeight: "500px",
                width: "100%",
                overflow: "hidden",
                border: "3px solid",
              }}
            />
            <ButtonGroup size="tiny" widths={2}>
              <Button
                icon="check"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCrop();
                }}
                positive
              />
              <Button icon="close" onClick={() => setFiles([])} />
            </ButtonGroup>
          </>
        )}
      </Segment>
    </>
  );
}
