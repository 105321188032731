import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {
  AccidentAtWork,
  AccidentAtWorkInterface,
} from "../../../app/models/Forms/AccidentAtWorkInterface";
import { useStore } from "../../../app/stores/store";
import JsonView from "./JsonView";

export default observer(function AccidentAtWorkView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formStore } = useStore();
  const { loadingInitial, selectedAccidentForm, loadSingleAccidentForm } =
    formStore;

  useEffect(() => {
    if (id) loadSingleAccidentForm(id);
  }, [loadSingleAccidentForm, id]);

  if (loadingInitial || !selectedAccidentForm) {
    return <LoadingComponent />;
  }

  const jsonArr = new AccidentAtWork(selectedAccidentForm).toStringArray();

  return (
    <>
      <AdminHeader header={t("view-accident-at-work")} icon="wpforms" />
      <Segment>
        <ButtonGroup>
          <Button
            icon="arrow left"
            color="red"
            onClick={() => navigate("/admin/operator/list")}
          />
          <Button
            content={t("view")}
            color="blue"
            onClick={() =>
              navigate(`../view/${selectedAccidentForm!.idHeader}`)
            }
          />
          <Button
            content={t("edit")}
            color="red"
            onClick={() =>
              navigate(`../edit/${selectedAccidentForm!.idHeader}`)
            }
          />
          <Button
            content={t("history")}
            color="green"
            onClick={() =>
              navigate(`../history/${selectedAccidentForm!.idHeader}`)
            }
          />
        </ButtonGroup>
      </Segment>

      <JsonView arrJSONData={jsonArr} />
    </>
  );
});
