import { ContactsInfo } from "../../../app/models/Plugins/pluginTypes"
import { Styles } from "../../../app/models/styles"
import ContactInfoCard from "./ContactInfoCard"

interface Props 
{
  naslov : string
  orgUnit: ContactsInfo[]
}

const styles: Styles = {
  wrapper: { display: 'flex', flexWrap:'wrap'},
}


export default function ContactInfoGroup({naslov, orgUnit}: Props) {
    return <div style={styles.outerWrapper}>
      <h3>{naslov ? naslov : ''}</h3>
      <div style={styles.wrapper}>
      {orgUnit.sort((a,b)=> a.orderOfAppearance - b.orderOfAppearance).map(contact => <ContactInfoCard key={contact.id} contact={contact} />)}
      </div>
    </div> 
}