import React from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Icon, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { useStore } from "../../../app/stores/store";
import MyTextArea from "../../forms/inputs/MyTextArea";
import MyTextInput from "../../forms/inputs/MyTextInput";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function EditModule() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    learningStore: {
      updateModule,
      selectedModule,
      getSingleModule,
    },
  } = useStore();

  React.useEffect(() => {
    if (id) {
      getSingleModule(id);
    }
  }, [getSingleModule, id]);

  if (!selectedModule) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div>
        <AdminHeader
          icon="pin"
          iconStyles={{ transform: "rotateZ(45deg)" }}
          header={t("edit-module")}
          subheader={t("edit-your-module")}
        />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Formik
            enableReinitialize
            initialValues={selectedModule}
            // onSubmit={() => {}}
            onSubmit={(values) => {
              if (values) {
                updateModule(values).then(() =>
                  navigate(`/admin/e-learning/listModules/${selectedModule?.trainingId}`)
                );
              }
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ width: "80%" }}>
                    <MyTextInput name="moduleName" label={t("module-name")} />
                    <MyTextArea
                      rows={7}
                      name="moduleDesc"
                      label={t("module-description")}
                    />
                    <MyTextInput
                      name="timeRequired"
                      label={t("time-required")}
                    />
                    <Button
                      style={{ marginTop: "10px" }}
                      color="teal"
                      type="submit"
                      content={t("submit")}
                    ></Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Segment>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Segment>
          <Button
            color="red"
            onClick={() =>
              navigate(
                `/admin/e-learning/listModules/${selectedModule.trainingId}`)}>
            <Icon name="arrow left" />
          </Button>
        </Segment>
      </div>
    </>
  );
});
