import React from 'react'
import { Icon } from 'semantic-ui-react'
import './FlipCard.css'

interface Props {
    textColor: string;
    text: string;
    textBack: string;
    offsetRight: number;
    backgroundColor: string;

}


export default function FlipCard({ textColor, text, textBack, offsetRight, backgroundColor }: Props) {

    const right = `${offsetRight}px`;
    
    return (
        <>
            <div className="cardflip" style={{right}} >
                <div className="contentFlipCard">
                    <div className="front">
                        <div className='border-top-line' style={{backgroundColor}} />

                        <div className='green-text'>
                            {textColor}
                            <div className='black-text'>
                                {text}
                            </div>
                        </div>
                        <div style={{ marginLeft: '210px', marginTop: '145px' }}>
                            <Icon name='sync' color='grey' />
                        </div>

                    </div>
                    <div className="back">
                        <div className='border-top-line' style={{backgroundColor}} />
                        {/* <div className='green-text'> */}
                            {/* {textBack} */}
                            <div className='black-text-back'>
                                {textBack}

                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}