import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Header,
  Input,
  Modal,
  Select,
} from "semantic-ui-react";

interface Props {
  closeModal: () => void;
  onSuccess: (data: number) => void;
  placeholder?: string;
  headerContent?: string;
  defaultValue?: number;
  options: DropdownItemProps[];
}

export default function DropdownModal({
  closeModal,
  onSuccess,
  placeholder,
  headerContent,
  options,
  defaultValue,
}: Props) {
  const [inputValue, setInputValue] = useState<number | undefined>(
    defaultValue
  );
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Header
        icon="file video outline"
        content={headerContent || "Add New Video"}
      />
      <Modal.Content>
        <Dropdown
          type="text"
          error={hasError}
          selection
          options={options}
          placeholder={placeholder || "https://youtube.com/my-video"}
          value={inputValue}
          onChange={(e, data) => {
            setHasError(false);
            setInputValue(parseInt(data.value?.toString() || ""));
          }}
        />
        <Button
          color="teal"
          labelPosition="left"
          icon="save"
          content={t("save")}
          onClick={() => {
            if (inputValue) {
              onSuccess(inputValue);
              return;
            }
            setHasError(true);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" content="Cancel" onClick={closeModal} />
      </Modal.Actions>
    </>
  );
}
