import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import HomePage from "./features/homePage/HomePage";
import OvertimeWorkForm from "./features/forms/OvertimeWorkForm";
import WorkOnHolidayForm from "./features/forms/WorkOnHolidayForm";
import NightShiftForm from "./features/forms/NightShiftForm";
import WorkOnSundayForm from "./features/forms/WorkOnSundayForm";
import ConstructionWorksForm from "./features/forms/ConstructionWorksForm";
import AccidentAtWorkForm from "./features/forms/AccidentAtWorkForm";
import StartingActivityForm from "./features/forms/StartingActivityForm";
import UnknownSubmitterForm from "./features/forms/UnknownSubmitterForm";
import KnownSubmitterForm from "./features/forms/KnownSubmitterForm";
import "react-datepicker/dist/react-datepicker.css";
import { useStore } from "./app/stores/store";
import LoadingComponent from "./app/layout/LoadingComponent";
import LoginForm from "./features/users/LoginForm/LoginForm";
import { observer } from "mobx-react-lite";
import AdminLayout from "./app/layout/admin/AdminLayout";
import UsersList from "./features/admin/users/List/UsersList";
import "react-toastify/dist/ReactToastify.css";
import UserCreate from "./features/admin/users/UserCreate";
import UserEdit from "./features/admin/users/UserEdit";
import IzvestuvanjaPage from "./features/homePage/Izvestuvanja/Izvestuvanja";
import LearningDashboard from "./features/eLearning/LearningDashboard";
import LearningTraining from "./features/eLearning/LearningTraining";
import LearningModule from "./features/eLearning/LearningModule";
import ModalContainer from "./app/common/modals/ModalContainer";
import Certificates from "./features/eLearning/Certificates";
import "./App.css";
import IstopeList from "./features/admin/HomePage/Isotope/IsotopeList";
import IsotopeCreate from "./features/admin/HomePage/Isotope/IsotopeCreate";
import IsotopeEdit from "./features/admin/HomePage/Isotope/isotopeEdit";
import AddNewMedia from "./features/admin/media/addNewMedia";
import MediaList from "./features/admin/media/mediaList";
import LearningSlide from "./features/eLearning/LearningSlide";
import NumbersDiagram from "./features/eLearning/components/NumbersDiagram/NumbersDiagram";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PostCategoryCreate from "./features/admin/posts/Post/category/PostCategoryCreate";
import PostCategoryEdit from "./features/admin/posts/Post/category/PostCategoryEdit";
import PostCategoryList from "./features/admin/posts/Post/category/PostCategoryList";
import PostCreate from "./features/admin/posts/Post/PostCreate";
import PostEdit from "./features/admin/posts/Post/PostEdit";
import PostsList from "./features/admin/posts/Post/PostsList";
import PageList from "./features/admin/posts/Page/PageList";
import PageCreate from "./features/admin/posts/Page/PageCreate";
import PageEdit from "./features/admin/posts/Page/PageEdit";
import PageCategoryList from "./features/admin/posts/Page/category/PageCategoryList";
import PageCategoryCreate from "./features/admin/posts/Page/category/PageCategoryCreate";
import PageCategoryEdit from "./features/admin/posts/Page/category/PageCategoryEdit";
import AnnouncementList from "./features/admin/posts/Announcement/AnnouncementList";
import AnnouncementCreate from "./features/admin/posts/Announcement/AnnouncementCreate";
import AnnouncementEdit from "./features/admin/posts/Announcement/AnnouncementEdit";
import AnnouncementCategoryList from "./features/admin/posts/Announcement/category/AnnouncementCategoryList";
import AnnouncementCategoryCreate from "./features/admin/posts/Announcement/category/AnnouncementCategoryCreate";
import AnnouncementCategoryEdit from "./features/admin/posts/Announcement/category/AnnouncementCategoryEdit";
import CategoryPage from "./features/categoryPage/CategoryPage";
import ConstructionReport from "./features/admin/Reports/ConstructionReport";
import OvertimeworksReport from "./features/admin/Reports/OvertimeworksReport";
import WorkOnHolidaysReport from "./features/admin/Reports/WorkOnHolidays";
import StartingActivityReport from "./features/admin/Reports/StartingActivity";
import WorkOnSundaysReport from "./features/admin/Reports/WorkOnSundays";
import GrievancesReprot from "./features/admin/Reports/GrievancesReprot";
import NightShiftReport from "./features/admin/Reports/NightShiftReport";
import PostPage from "./features/postPage/PostPage";
import Dashboard from "./features/admin/Dashboard/Dashboard";

import BzrEmailList from "./features/admin/Email/BzrEmailList";
import ROEmailList from "./features/admin/Email/ROEmailList";
import AddNewDocument from "./features/admin/media/AddNewDocument";
import CreateNavigation from "./app/layout/admin/navigation/CreateNavigation";

import NavigationList from "./app/layout/admin/navigation/NavigationList";
import EditNavigation from "./app/layout/admin/navigation/EditNavigation";
import SliderList from "./features/admin/HomePage/Slider/SliderList";

import SliderCreate from "./features/admin/HomePage/Slider/SliderCreate";
import SliderEdit from "./features/admin/HomePage/Slider/SliderEdit";
import AccordionList from "./app/layout/admin/Plugin/Accordion/AccordionList";
import AccordionCreate from "./app/layout/admin/Plugin/Accordion/AccordionCreate";

import AccordionEdit from "./app/layout/admin/Plugin/Accordion/AccordionEdit";
import AccordionItemCreate from "./app/layout/admin/Plugin/Accordion/AccordionItem/AccordionItemCreate";
import AccordionItemEdit from "./app/layout/admin/Plugin/Accordion/AccordionItem/AccordionItemEdit";
import FormTableList from "./app/layout/admin/FormTable/FormTableList";
import ArchivePage from "./features/categoryPage/ArchivePage";

import AccidentReport from "./features/admin/Reports/NewAccidentReport";
import ListTraining from "./features/manageElearning/ListTraning";
import CreateTraining from "./features/manageElearning/CreateTraning";
import EditTraning from "./features/manageElearning/EditTraning";
import CreateModule from "./features/manageElearning/moduls/CreateModule";
import ListModules from "./features/manageElearning/moduls/ListModules";
import EditModule from "./features/manageElearning/moduls/EditModule";
import ListSlides from "./features/manageElearning/slides/ListSlides";
import CreateSlide from "./features/manageElearning/slides/CreateSlide";
import EditSlide from "./features/manageElearning/slides/EditSlides";
import CreateSlideContent from "./features/manageElearning/slides/slideContent/CreateSlideContent";

import ViewSlideContent from "./features/manageElearning/slides/slideContent/ViewSlideContent";
import EditContent from "./features/manageElearning/slides/slideContent/EditContent";
import AccidentAtWorkEdit from "./features/forms/editForms/AccidentAtWorkEdit";
import AccidentAtWorkView from "./features/forms/formView/AccidentAtWorkView";
import AccidentAtWorkHistory from "./features/forms/historyForm/AccidentAtWorkHistory";
import ConstructionWorkView from "./features/forms/formView/ConstructionWorkView";
import KnownSubmitterEdit from "./features/forms/editForms/KnownSubmitterEdit";
import KnownSubmitterView from "./features/forms/formView/KnownSubmitterView";
import NightShiftEdit from "./features/forms/editForms/NightShiftEdit";
import OvertimeWorkEdit from "./features/forms/editForms/OvertimeWorkEdit";
import OvertimeWorkView from "./features/forms/formView/OvertimeWorkView";
import StartingActivityEdit from "./features/forms/editForms/StartingActivityEdit";
import StartingActivityView from "./features/forms/formView/StartingActivityView";
import WorkOnHolidayEdit from "./features/forms/editForms/WorkOnHolidayEdit";
import WorkOnHolidayView from "./features/forms/formView/WorkOnHolidayView";
import WorkOnSundayEdit from "./features/forms/editForms/WorkOnSundayEdit";
import WorkOnSundayView from "./features/forms/formView/WorkOnSundayView";
import ConstructionWorkEdit from "./features/forms/editForms/ConstructionWorkEdit";
import ConstructionWorksHistory from "./features/forms/historyForm/ConstructionWorksHistory";
import KnownSubmitterHistory from "./features/forms/historyForm/KnownSubmitterHistory";
import NightShiftHistory from "./features/forms/historyForm/NightShiftHistory";
import OvertimeWorkHistory from "./features/forms/historyForm/OvertimeWorkHistory";
import StartingActivityHistory from "./features/forms/historyForm/StartingActivityHistory";
import WorkOnHolidayHistory from "./features/forms/historyForm/WorkOnHolidayHistory";
import WorkOnSundayHistory from "./features/forms/historyForm/WorkOnSundayHistory";
import NightShiftView from "./features/forms/formView/NightShiftView";
import ContactLocationEdit from "./app/layout/admin/Plugin/Contacts/ContactLocationEdit";
import DirectorInfoPlugin from "./app/layout/admin/Plugin/Director/DirectorInfoPlugin";
import LearningProgressReport from "./features/manageElearning/LearningProgressReport";
import ContactLocationList from "./app/layout/admin/Plugin/Contacts/ContactLocationList";
import ContactLocationCreate from "./app/layout/admin/Plugin/Contacts/ContactLocationCreate";
import { ProtectedRoute } from "./app/layout/ProtectedRoute";

export default observer(function App() {
  const { commonStore, userStore } = useStore();
  const { token, setAppLoaded } = commonStore;
  const { getCurrentUser } = userStore;

  useEffect(() => {
    if (token) {
      getCurrentUser().finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
  }, [token, getCurrentUser, setAppLoaded]);

  if (!commonStore.appLoaded) return <LoadingComponent />;

  return (
    <>
      <ModalContainer />
      <ToastContainer position="bottom-right" hideProgressBar />
      <Routes>
        <Route path="/" element={<HomePage />}  />
        <Route path="obrasci" element={<IzvestuvanjaPage />} />
        <Route path="category/:postType/:slug" element={<CategoryPage />} />
        <Route path="archive/:postType/" element={<ArchivePage />} />

        <Route path="single/:postType/:slug" element={<PostPage />} />

        <Route path="login" element={<LoginForm />} />

        <Route path="forms">
          <Route path="work-on-holiday" element={<WorkOnHolidayForm />} />
          <Route path="night-shift" element={<NightShiftForm />} />
          <Route path="overtime-work" element={<OvertimeWorkForm />} />
          <Route path="starting-activity" element={<StartingActivityForm />} />
          <Route path="work-on-sunday" element={<WorkOnSundayForm />} />
          <Route path="accident-at-work" element={<AccidentAtWorkForm />} />
          <Route path="construction-works" element={<ConstructionWorksForm />} />
          <Route path="known-submitter" element={<KnownSubmitterForm />} />
          <Route path="unknown-submitter" element={<UnknownSubmitterForm />} />
        </Route>
        <Route path="/admin" element={<ProtectedRoute 
          token={token}
          component={AdminLayout}/>} 
        >
          <Route path="" element={<Dashboard />} />

          <Route path="operator">
            <Route path="list" element={<FormTableList />}>
          </Route>
            <Route path="AccidentAtWork">
              <Route path="view/:id" element={<AccidentAtWorkView />} />
              <Route path="edit/:id" element={<AccidentAtWorkEdit />} />
              <Route path="history/:id" element={<AccidentAtWorkHistory />} />
            </Route>
            <Route path="ConstructionWorks">
              <Route path="edit/:id" element={<ConstructionWorkEdit />} />
              <Route path="view/:id" element={<ConstructionWorkView />} />
              <Route path="history/:id" element={<ConstructionWorksHistory />} />
            </Route>
            <Route path="Grievance">
              <Route path="edit/:id" element={<KnownSubmitterEdit />} />
              <Route path="view/:id" element={<KnownSubmitterView />} />
              <Route path="history/:id" element={<KnownSubmitterHistory />} />
            </Route>
            <Route path="NightShifts">
              <Route path="view/:id" element={<NightShiftView />} />
              <Route path="edit/:id" element={<NightShiftEdit />} />
              <Route path="history/:id" element={<NightShiftHistory />} />
            </Route>
            <Route path="OvertimeWorks">
              <Route path="edit/:id" element={<OvertimeWorkEdit />} />
              <Route path="view/:id" element={<OvertimeWorkView />} />
              <Route path="history/:id" element={<OvertimeWorkHistory />} />
            </Route>
            <Route path="StartingActivities">
              <Route path="edit/:id" element={<StartingActivityEdit />} />
              <Route path="view/:id" element={<StartingActivityView />} />
              <Route path="history/:id" element={<StartingActivityHistory />} />
            </Route>
            <Route path="WorkOnHolidays">
              <Route path="edit/:id" element={<WorkOnHolidayEdit />} />
              <Route path="view/:id" element={<WorkOnHolidayView />} />
              <Route path="history/:id" element={<WorkOnHolidayHistory />} />
            </Route>
            <Route path="WorkOnSundays">
              <Route path="edit/:id" element={<WorkOnSundayEdit />} />
              <Route path="view/:id" element={<WorkOnSundayView />} />
              <Route path="history/:id" element={<WorkOnSundayHistory />} />
            </Route>
          </Route>
          <Route path="posts">
            <Route path="list" element={<PostsList />} />
            <Route path="create" element={<PostCreate />} />
            <Route path="edit/:id" element={<PostEdit />} />
            <Route path="category">
              <Route path="list" element={<PostCategoryList />} />
              <Route path="create" element={<PostCategoryCreate />} />
              <Route path="edit/:id" element={<PostCategoryEdit />} />
            </Route>
          </Route>
          <Route path="pages">
            <Route path="list" element={<PageList />} />
            <Route path="create" element={<PageCreate />} />
            <Route path="edit/:id" element={<PageEdit />} />
            <Route path="category">
              <Route path="list" element={<PageCategoryList />} />
              <Route path="create" element={<PageCategoryCreate />} />
              <Route path="edit/:id" element={<PageCategoryEdit />} />
            </Route>
          </Route>

          <Route path="announcements">
            <Route path="list" element={<AnnouncementList />} />
            <Route path="create" element={<AnnouncementCreate />} />
            <Route path="edit/:id" element={<AnnouncementEdit />} />
            <Route path="category">
              <Route path="list" element={<AnnouncementCategoryList />} />
              <Route path="create" element={<AnnouncementCategoryCreate />} />
              <Route path="edit/:id" element={<AnnouncementCategoryEdit />} />
            </Route>
          </Route>
          <Route path="homepage">
            <Route path="isotope">
              <Route path="list" element={<IstopeList />} />
              <Route path="create/:lang" element={<IsotopeCreate />} />
              <Route path="edit/:id" element={<IsotopeEdit />} />
            </Route>
            <Route path="nav">
              <Route path="list" element={<NavigationList />}></Route>
              <Route path="create" element={<CreateNavigation />}></Route>
              <Route path="edit/:id" element={<EditNavigation />}></Route>
            </Route>
            <Route path="slider">
              <Route path="list" element={<SliderList />}></Route>
              <Route path="create" element={<SliderCreate />}></Route>
              <Route path="edit/:id" element={<SliderEdit />}></Route>
            </Route>
          </Route>
          <Route path="plugins">
            <Route path="accordion">
              <Route path="list" element={<AccordionList />} />
              <Route path="create" element={<AccordionCreate />} />
              <Route path="edit/:id" element={<AccordionEdit />} />
              <Route path="item">
                <Route path="create/:id" element={<AccordionItemCreate />} />
                <Route path="edit/:id" element={<AccordionItemEdit />} />
              </Route>
            </Route>
            <Route path="contacts">
              <Route path="list" element={<ContactLocationList />} />
              <Route path="create" element={<ContactLocationCreate />} />
              <Route path="edit/:id" element={<ContactLocationEdit />} />
            </Route>
            <Route path="director" element={<DirectorInfoPlugin />} />
          </Route>
          <Route path="media">
            <Route path="list" element={<MediaList />} />
            <Route path="addPhoto" element={<AddNewMedia />} />
            <Route path="addDocument" element={<AddNewDocument />} />
          </Route>
          <Route path="users">
            <Route path="list" element={<UsersList />} />
            <Route path="edit/:id" element={<UserEdit />} />
            <Route path="create" element={<UserCreate />} />
          </Route>
          <Route path="reports">
            <Route path="accident" element={<AccidentReport />} />
            <Route path="construction" element={<ConstructionReport />} />
            <Route path="nightshift" element={<NightShiftReport />} />
            <Route path="overtimeworks" element={<OvertimeworksReport />} />
            <Route path="startingactivity" element={<StartingActivityReport />} />
            <Route path="workonholidays" element={<WorkOnHolidaysReport />} />
            <Route path="workonsundays" element={<WorkOnSundaysReport />} />
            <Route path="grievances" element={<GrievancesReprot />} />
          </Route>
          <Route path="email">
            <Route path="bzr" element={<BzrEmailList />} />
            <Route path="ro" element={<ROEmailList />} />
          </Route>
          <Route path="e-learning">
            <Route path="listTraning" element={<ListTraining />} />
            <Route path="createTraning" element={<CreateTraining />} />
            <Route path="edit/:id" element={<EditTraning />} />
            <Route path="editSlide/:id" element={<EditSlide />} />
            <Route path="listModules/:id" element={<ListModules />} />
            <Route path="listTraning/:id/listModules/edit/:id" element={<EditModule />} />
            <Route path="listSlides/:id/:traningId" element={<ListSlides />} />
            <Route path="createModule/:id" element={<CreateModule />} />
            <Route path="createSlide/:id/:traningId" element={<CreateSlide />} />
            <Route path="viewSlide/:id/:slideId" element={<ViewSlideContent />} />
            <Route path="createSlideContent/:id/:slideId" element={<CreateSlideContent />} />
            <Route path="editContent/:id/:slideId" element={<EditContent />} />
            <Route path="completedReport" element={<LearningProgressReport />} />
          </Route>
        </Route>

        <Route path="e-learning">
          <Route path="dashboard" element={<LearningDashboard />} />
          <Route path="certificates" element={<Certificates />} />
          <Route path="training/:id" element={<LearningTraining />} />
          <Route path="module/:id" element={<LearningModule />} />
          <Route path="slide/:id" element={<LearningSlide />} />
        </Route>

        <Route path="numbersdiagram" element={<NumbersDiagram />} />
      </Routes>
    </>
  );
});
