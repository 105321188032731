


export const isUserAuthorizedForPage = (page: string, userType : string) => {
  


  
  if(AuthorizedAdminPages[userType].some(x=> x === 'allpagesallowed' )){
    return true
  }
  if(AuthorizedAdminPages[userType].some(x=> x === 'nopagesallowed')){
    return false
  }

  return AuthorizedAdminPages[userType]?.some(x=> x === page) || false
}



interface Props {
  [key: string]: string[]
  }

const AuthorizedAdminPages: Props = {

  superAdmin : ['allpagesallowed'],
  admin : ['allpagesallowed'],
  contentAdmin: ['Media','Posts', 'Pages', 'Announcements', 'Homepage Settings', 'Plugins'],
  superOperator: ['Operator'],
  operator: ['Operator'],
  learner : ['nopagesallowed'],
  trainer: ['E-learning']
}