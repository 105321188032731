import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Icon, Segment } from "semantic-ui-react";
import AdminHeader from "../../../app/layout/admin/AdminHeader";
import { eModule } from "../../../app/models/ELearning/eModule";
import MyTextArea from "../../forms/inputs/MyTextArea";
import MyTextInput from "../../forms/inputs/MyTextInput";
import { v4 as uuid } from "uuid";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";

export default observer(function CreateModule() {
  const { t } = useTranslation();
  const {
    learningStore: { createModule, selectedModule, loadTrainingModules },
  } = useStore();
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      loadTrainingModules(id);
    }
  }, [loadTrainingModules, id]);

  const initialValues: eModule = {
    id: "",
    moduleName: "",
    moduleDesc: "",
    moduleOrder: 0,
    timeRequired: 0,
    trainingId: id,
  };

  const navigate = useNavigate();

  console.log(selectedModule?.trainingId);

  // if (!selectedModule) {
  //   return <LoadingComponent />;
  // }

  return (
    <>
      <AdminHeader
        icon="pin"
        iconStyles={{ transform: "rotateZ(45deg)" }}
        header={t("module")}
        subheader={t("create-module")}
      />
      <Segment>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            values.id = uuid();
            createModule(values).then(() =>
              navigate(`/admin/e-learning/listModules/${id}`)
            );
          }}
        >
          {({ errors, touched, values, submitForm }) => (
            <Form autoComplete="off">
              <Grid>
                <Grid.Column width={16}>
                  <MyTextInput name="moduleName" label={t("module-name")} />
                  <MyTextArea
                    rows={7}
                    name="moduleDesc"
                    label={t("module-description")}
                  />
                  <MyTextInput name="timeRequired" label={t("time-required")} />
                  <MyTextInput name="moduleOrder" label={t("module-order")} />
                  {/* <MyTextInput name="trainingId" label="ID" /> */}
                </Grid.Column>
              </Grid>
              <Segment>
                <Button
                  color="red"
                  onClick={() =>
                    navigate(
                      `/admin/e-learning/listModules/${id}`) } >
                  <Icon name="arrow left" />
                </Button>
                <Button color="teal" type="submit">
                  {t("create")}
                </Button>
              </Segment>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
});
