import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainFooter from "../../app/layout/MainFooter";
import ContactMap from "../../app/layout/ContactMap/ContactMap";
import SliderImages from "./SliderImages";
import MainHeader from "../../app/layout/MainHeader/MainHeader";
import HeroMenu from "./HeroMenu/HeroMenu";
import HomePageDocuments from "./Documents/HomePageDocuments";
import "./HomePage.css";
import BoxHeading from "../../app/layout/HomePage/BoxHeading";
import { Container } from "semantic-ui-react";
import UsefulLinks from "./UsefulLinks/UsefulLinks";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import IframeResizer from "iframe-resizer-react";

export default observer(function HomePage() {
  const { t } = useTranslation();
  const {
    sliderStore: { loadSlider },
  } = useStore();
  useEffect(() => {
    loadSlider()
  }, [loadSlider])
  console.log(process.env.REACT_APP_PROD)

  return (
    <>
      <MainHeader isFixed={true} />
      <main>
        <section>
          <SliderImages />
        </section>
        <section style={{ height: "50px" }}>
          <HeroMenu />
        </section>
        <div className="background-grey below-hero"></div>
        <section>
          <BoxHeading
            heading={t("documents")}
            specialClass="top-attached background-red inverted"
          />
          <div className="background-grey documents-wrapper">
            <Container>
              <HomePageDocuments />
            </Container>
          </div>
        </section>
        {/* <section>
          <HomePageBlog />
        </section> */}
        <section>
          <UsefulLinks />
        </section>
          <section className="map_container">
            <div className="iframe-wrapper">
              <IframeResizer 
                 src={process.env.REACT_APP_PROD === '0' ? "/mapBzr.html" :  "/mapBzrProd.html"} 
                 allowFullScreen={!1} 
                 title="mapBzr" 
                 style={{ width: '1px', minWidth: '100%'}}
              />
            </div>
            <div className="iframe-wrapper">
            <IframeResizer 
              autoResize={true}
              src={process.env.REACT_APP_PROD === '0' ? "/mapRo.html" :  "/mapRoProd.html"} 
              allowFullScreen={!1} 
                 title="mapRo" 
                 style={{ width: '1px', minWidth: '100%'}}
              />
            </div>
          </section>
        <section>
          <ContactMap />
        </section>
      </main>
      <footer>
        <MainFooter />
      </footer>
    </>
  );
})
