import { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import AdminHeader from "../../../../app/layout/admin/AdminHeader";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { SliderSlide } from "../../../../app/models/CMS/Homepage/sliderSlide";
import { useStore } from "../../../../app/stores/store";
import SliderForm from "./shared/SliderForm";

export default observer(function SliderCreate() {
  const {
    sliderStore: { loadingInitial, createSlide },
    mediaStore: { mediaList },
  } = useStore();
  const { t } = useTranslation();
  // const [selectedPhotoId, setSelectedPhotoId] = useState<string | null>(null);
  const navigate = useNavigate();

  if (loadingInitial) {
    return <LoadingComponent />;
  }

  const onSubmit = (values: SliderSlide) => {
    // const newPhoto = mediaList.find((x) => x.id === selectedPhotoId);
    // if (newPhoto) {
    //   values.imageId = newPhoto.id;
    //   values.photoId = newPhoto.path;
    // }
    values.id = v4();
    createSlide(values).then(() =>{
      toast.info('successfully-saved')
      navigate(`/admin/homepage/slider/edit/${values.id}`)
    });
  };

  return (
    <>
      <AdminHeader header={t("create-new-slide")} icon="film" />
      <SliderForm
        onSubmit={onSubmit}
        mediaList={mediaList}
      />
    </>
  );
});
