import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button, ButtonGroup, ButtonOr, Segment } from "semantic-ui-react";
import ModalDialog from "../../../../app/common/modals/ModalDialog";
import PostFilterModal from "../../../../app/common/modals/PostFilterModal";
import PaginatedReportTable from "../../../../app/common/table/PaginatedReportTable";
import ReportTableFilters from "../../../../app/common/table/ReportTableFilters";
import { IPaginatedReportMeta } from "../../../../app/common/table/ReportTableFunctions";
import ReportTablePagination from "../../../../app/common/table/ReportTablePagination";
import { useStore } from "../../../../app/stores/store";

const meta: IPaginatedReportMeta[] = [
  { key: "id", type: "hide", value: "true" },
  { key: "postTranslations", type: "hide", value: "true" },
  { key: "taxonomyTermIds", type: "hide", value: "true" },
  { key: "featuredImageId", type: "hide", value: "true" },
  { key: "taxonomyTermId", type: "hide", value: "true" },
  { key: "postType", type: "hide", value: "true" },
  { key: "postModified", type: "hide", value: "true" },
  { key: "postDate", type: "hide", value: "true" },
  { key: "postName", type: "colName", value: "Post-name" },
  { key: "taxonomyNames", type: "colName", value: "category-name" },
  { key: "slug", type: "colName", value: "slug" },
  { key: "postStatus", type: "colName", value: "status" },
  { key: "featuredImagePath", type: "imageReplace", value: true },
  { key: "featuredImagePath", type: "order", value: "1" },
  { key: "featured-image", type: "order", value: "1" },
  { key: "featuredImagePath", type: "colName", value: "featured-image" },
];
interface Props {
  linkPath: string;
  isTrash?: boolean;
}
export default observer(function PostPaginatedTable({
  linkPath,
  isTrash,
}: Props) {
  const { t } = useTranslation();
  const navgiate = useNavigate();
  const {
    modalStore,
    postStore: {
      setPagingParams,
      pagingParams,
      addFilterParam,
      clearFilterParams,
      filterParams,
      loadingInitial,
      loading,
      removeFilterParam,
      sortedPosts,
      pagination,
      changeStatus,
      deletePost,
    },
  } = useStore();

  const { openModal, closeModal } = modalStore;

  const handlePerPageChange = (value?: string) => {
    const pageSize = parseInt(value || "0");
    setPagingParams({
      pageNumber: 1,
      pageSize,
    });
  };

  const handlePaginationChange = (activePage?: string | number) => {
    if (activePage) {
      const pageNumber = parseInt(activePage.toString());
      const { pageSize } = pagingParams;
      setPagingParams({
        pageNumber,
        pageSize,
      });
    }
  };

  const actionButtons = (obj: any) => (
    <ButtonGroup>
      <Button
        onClick={(e) => {
          navgiate(`${linkPath}/edit/${obj.id}`);
          clearFilterParams();
        }}
        primary
      >
        {t("edit")}
      </Button>
      <ButtonOr text={t("or")}></ButtonOr>
      <Button
        onClick={(e) => {
          changeStatus(obj.id, "trash");
        }}
        color="red"
      >
        {t("move-to-trash")}
      </Button>
    </ButtonGroup>
  );

  const actionButtonsTrash = (obj: any) => (
    <ButtonGroup>
      <Button
        onClick={(e) => {
          changeStatus(obj.id, "draft");
        }}
        primary
      >
        {t("restore")}
      </Button>
      <ButtonOr text={"or"}></ButtonOr>
      <Button
        onClick={(e) => {
          openModal(
            <ModalDialog
              cancel={closeModal}
              noText="Cancel"
              confirm={() => {
                deletePost(obj.id);
                closeModal();
              }}
              title={t("are-you-sure-you-want-to-delete-this-post")}
              description={t(
                "once-you-delete-the-post-the-data-will-be-lost-forever"
              )}
            />
          );
        }}
        color="red"
      >
        {t("delete")}
      </Button>
    </ButtonGroup>
  );

  const PerPageOptions = [
    { text: 10, value: 10 },
    { text: 50, value: 50 },
  ];

  return (
    <Segment loading={loadingInitial || loading} attached="bottom">
      <Button
        content={t("add-filter")}
        onClick={(e) => {
          openModal(
            <PostFilterModal
              closeModal={closeModal}
              setFilter={addFilterParam}
            />
          );
        }}
      />
      {filterParams.length > 0 && (
        <Button
          content={t("clear-all-filters")}
          onClick={(e) => {
            clearFilterParams();
          }}
        />
      )}

      <ReportTableFilters
        filterParams={filterParams}
        removeFilterParam={removeFilterParam}
      />
      <PaginatedReportTable
        data={sortedPosts}
        meta={meta}
        actionButtons={isTrash ? actionButtonsTrash : actionButtons}
      />
      <ReportTablePagination
        numPages={pagination?.totalPages || 0}
        perPage={pagination?.itemsPerPage || 0}
        PerPageOptions={PerPageOptions}
        activePage={pagination?.currentPage}
        handlePerPageChange={handlePerPageChange}
        handlePaginationChange={handlePaginationChange}
      />
    </Segment>
  );
});
