import React from "react";
import { Button } from "semantic-ui-react";
import "./InputStyle.css";

interface Props {
  content: string;
  onClick: ()=> void;
  active: boolean;
}

export default function MyCheckboxButton({content,onClick,active}: Props) {
  return (
    <Button
    toggle
    active={active}
    content={content}
    onClick={onClick}
  />
  );
}
