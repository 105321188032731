import { Outlet } from "react-router-dom";
import AdminSideBar from "./sidebar/AdminSideBar";
import './AdminLayout.css';

export default function AdminLayout() {
  return (
    <div className="admin">
      <AdminSideBar />
      <main>
        <Outlet />
      </main>
    </div>
  )
}